import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackOpen } from '../../redux/reducers/modal/snackBarSlice';
import { Slide } from '@mui/material';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMsg() {
  const {open,type,msg} = useSelector(state => state.snackBar)
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSnackOpen(false));
  };

  return (
    <Stack spacing={7} sx={{ width: '100%' }}>
      {msg.map((msgVal,i) => (
        <Snackbar key={i} open={open} TransitionComponent={Slide} anchorOrigin={{vertical: 'top',horizontal: 'right'}} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={type || "error"} sx={{ width: '100%' }}>
            {msgVal || 'Something went wrong!'}
          </Alert>
        </Snackbar>
      ))}
      {/* <Alert severity="error">{msgVal}</Alert>
      <Alert severity="warning">{msgVal}</Alert>
      <Alert severity="info">{msgVal}</Alert>
      <Alert severity="success">{msgVal}</Alert> */}
    </Stack>
  );
}