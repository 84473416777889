import React, { useEffect, useState } from 'react'
import Stats from '../../components/mini/Stats'
import { FlightClassOutlined } from '@mui/icons-material'
import {ReactComponent as TransactionIcon} from '../../assets/icons/creditlimit.svg'
import { getTourBookings } from '../../controllers/tour/getBookings'


let lists = [
  {label: 'Total Bookings',amount: 0,icon: <FlightClassOutlined />},
  {label: 'Received Payments',amount: 0,icon: <TransactionIcon className='w-5 h-5' />},
  {label: 'Pending Payments',amount: 0,icon: <TransactionIcon className='w-5 h-5' />},
  {label: 'Cancelled Bookings',amount: 0,icon: <FlightClassOutlined />},
] 
export default function Packages() {
  const [data,setData] = useState(lists);

  useEffect(() => {
    load()
  },[])
  async function load() {
    const res = await getTourBookings();
    if(res.return) {
      // console.log(' --> ',res.data)
      let total = 0;
      let payed = 0;
      let pending = 0;
      let cancelled = 0;

      res.data.map(d => {
        total = res.data.length;
        if(d.status === 'PENDING')
          pending++;
        else if(d.status === 'PAID_FOR')
          payed++;
        else if(d.status === 'CANCELLED')
          cancelled++;

        return true;
      })

      setData(d => {
        let temp = [...d];
        temp[0].amount = total;
        temp[1].amount = payed;
        temp[2].amount = pending;
        temp[3].amount = cancelled;
        return temp
      })

    }
  }

  return (
    <div className='text-gray-700 bg-secondary p-4'>
      <h5 className='py-3'>Packages</h5>
      <div className='flex gap-4 flex-wrap '>
        {
          data.map((list,ind) => (
            <Stats data={list} key={ind} />
          ))
        }
      </div>
    </div>
  )
}
