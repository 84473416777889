import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  title: "Dashboard"
}

export const titleSlice = createSlice({
  name: 'title',
  initialState,
  reducers: {
    setHeaderTitle: (state,action) => {
      state.title = action.payload;
    },
  }
})

export const {setHeaderTitle} = titleSlice.actions;

export default titleSlice.reducer;