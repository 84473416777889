import mergeRecursive from "../../features/utils/mergeRecursive";

export const creditTemp = {
  "id": "1512fdc6-694c-4fae-bc2c-a6ac37cc8f56",
  "from": "CARD",
  "to": "BOOKING",
  "paymentReference": "53snjlv6al",
  "transactionHash": null,
  "confirmedById": null,
  "bookedTripId": "44364584-e3e2-4abd-abbd-10a4ca81bad5",
  "transactionStatus": "PENDING",
  "fromWalletId": null,
  "toWalletId": null,
  "createdDate": "2023-05-12T21:17:37.032Z",
  "updatedDate": "2023-05-12T21:17:37.033Z",
  "userId": "d6a525f9-2e5d-497c-9789-a2198488c516",
  "amount": 2424017,
  "bookedActivityId": null,
  "bookedHotelId": null,
  "confirmedBy": null,
  "user": {
      "id": "d6a525f9-2e5d-497c-9789-a2198488c516",
      "username": null,
      "firstName": "Guest",
      "email": "asd@ldkf.com",
      "lastName": "User",
      "password": "guestPasswordNotHashed",
      "otp": null,
      "profile": null,
      "isAdmin": false,
      "phoneNumber": "234-910867889",
      "type": "CUSTOMER",
      "customSettingid": null,
      "gender": "MALE",
      "companyName": "PERSONAL",
      "addressLine1": "No 1 BTM Holidays street",
      "addressLine2": null,
      "postalCode": "105102",
      "cityName": "Lagos",
      "countryCode": "NG",
      "createdDate": "2023-05-12T21:14:50.640Z",
      "updatedDate": "2023-05-12T21:14:52.306Z"
  }
}

export function templateCredit(obj) {
  let data = mergeRecursive({...obj},creditTemp);
  return {...data,
    customer: data.user.firstName+ " "+data.user.lastName,
    creditDate: data.createdDate,
    creditedBy: data.user.firstName+ " "+data.user.lastName,
    balance: data.amount,
    status: data.transactionStatus
  };
}


export function templateCreditReport(obj) {
  let data = mergeRecursive({...obj},creditTemp);
  let res = {
    customer: data.user.firstName+ " "+data.user.lastName,
    creditDate: data.createdDate,
    creditedBy: data.user.firstName+ " "+data.user.lastName,
    balance: data.amount,
    status: data.transactionStatus
  };

  res = Object.values(res);

  return res;
}
