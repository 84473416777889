import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getFlightOfferPrice from "../../controllers/flight/getOfferPrice";
import ModalLocal from "../mini/ModalLocal";
import getVerteilFarerule from "../../controllers/flight/getVerteilFarerule";
import { Cancel } from "@mui/icons-material";
import Collapse from "../mini/Collapse";

export default function ViewFareRule(data) {
  // const dispatch = useDispatch();
  // const scrollRef = createRef();
  const [open,setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [curDetail,setCurDetail] = useState();
  const {bookingData} = useSelector(state => state.flightBooking);

  useEffect(() => {
    if(data)
      setCurDetail(data?.data)
  },[data])

  // function handleScroll(ev) {
    // if(ev.deltaY > 0)
    //   scrollRef.current.scrollTop += 30;
    // else 
    //   scrollRef.current.scrollTop -= 30;
    
  // }

  console.log('on fare rule: ',data.data)

  async function showDetail() {
    let userId = null;
    if(bookingData.as)
      userId = bookingData.as.id;

    setLoading(true);
    const res = await getFlightOfferPrice({offer: data.data},userId);
    setLoading(false);
    if(res.return) {
      console.log(' ---- ',res.data)
      // dispatch(setBookingData({...bookingData,offer: res.data,beforePrice: obj}))

      setCurDetail(res.data)
    }
  }

  function openFareRule() {
    // dispatch(setModal(true))
    // dispatch(setModalComp(modalComp));
    setOpen(true);
    if(!data?.data?.fareRule)
      showDetail();
  }

  // const modalComp = (
  //   <div className='!max-h-screen realtive px-2 !w-full  py-5' onWheel={handleScroll}>
  //     <div className='bg-secondary rounded-md p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
  //       <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
  //       <div className="flex justify-center p-4">
  //         {loading ? <div className="load"></div> : null}
  //       </div>
  //       <div dangerouslySetInnerHTML={{__html: curDetail?.fareRule || (!loading && "No Fare Rules")}} className="max-h-[calc(100vh-100px)] overflow-y-auto">
  //       </div>
  //     </div>
  //   </div>
  // )
  // console.log('farerule : ',data)
  return (
    <div>
      <button onClick={openFareRule} className='py-4 text-theme1'>View fare rule</button>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='bg-secondary rounded-md p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
          <div className='btn_close' onClick={() => setOpen(false)}>X</div>
          <div className="flex flex-col items-center gap-4 justify-center p-4 border-theme1">
            {loading ? <div className="load"></div> : null}
            {loading ? <div className="">Please Wait</div> : null}
          </div>
          <div dangerouslySetInnerHTML={{__html: curDetail?.fareRule || (!loading ? "No Fare Rules" : '')}} className="max-h-[calc(100%-20px)] overflow-y-auto">
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}

export function ViewFareRuleVerteil(data) {
  // const dispatch = useDispatch();
  // const scrollRef = createRef();
  const [open,setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [curDetail,setCurDetail] = useState();
  const {bookingData} = useSelector(state => state.flightBooking);

  useEffect(() => {
    if(data)
      setCurDetail(data?.data)
  },[data])

  // function handleScroll(ev) {
    // if(ev.deltaY > 0)
    //   scrollRef.current.scrollTop += 30;
    // else 
    //   scrollRef.current.scrollTop -= 30;
    
  // }

  console.log('on fare rule: ',data.data)

  async function showDetail() {
    let userId = null;
    if(bookingData.as)
      userId = bookingData.as.id;

    setLoading(true);
    const res = await getFlightOfferPrice({offer: data.data},userId);
    // const res = await getVerteilFarerule(data?.data?.verteilPrice,userId);
    setLoading(false);
    if(res.return) {
      console.log(' ---- ',res.data)
      // dispatch(setBookingData({...bookingData,offer: res.data,beforePrice: obj}))

      setCurDetail(res.data)
    }
  }

  function openFareRule() {
    // dispatch(setModal(true))
    // dispatch(setModalComp(modalComp));
    setOpen(true);
    if(!data?.data?.fareRule)
      showDetail();
  }

  // const modalComp = (
  //   <div className='!max-h-screen realtive px-2 !w-full  py-5' onWheel={handleScroll}>
  //     <div className='bg-secondary rounded-md p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
  //       <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
  //       <div className="flex justify-center p-4">
  //         {loading ? <div className="load"></div> : null}
  //       </div>
  //       <div dangerouslySetInnerHTML={{__html: curDetail?.fareRule || (!loading && "No Fare Rules")}} className="max-h-[calc(100vh-100px)] overflow-y-auto">
  //       </div>
  //     </div>
  //   </div>
  // )
  // console.log('farerule : ',data)
  function getType(type) {
    if(type === 'ADT')
        return 'Adult';
    else if(type === 'CHD')
        return 'Child';
    else if(type === 'INF')
        return 'Infant';
    return type;
}

  return (
    <div>
      <button onClick={openFareRule} className='py-4 text-theme1'>View fare rule</button>
      {/* <ModalLocal open={open} setOpen={setOpen}>
        <div className='bg-secondary rounded-md p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
          <div className='btn_close' onClick={() => setOpen(false)}>X</div>
          <div dangerouslySetInnerHTML={{__html: curDetail?.fareRule || (!loading ? "No Fare Rules" : '')}} className="max-h-[calc(100%-20px)] overflow-y-auto">
          </div>
        </div>
      </ModalLocal> */}
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='w-[550px] max-w-full bg-secondary rounded-md h-full !max-h-[calc(80vh)] overflow-y-auto relative p-5 gap-4'>
          <div className='flex justify-between items-center gap-4 relative p-4'>
              <h5>Fare Rules</h5>
              <Cancel onClick={() => setOpen(false)} />
              {/* <div className='rounded-full w-5 h-5 bg-primary text-secondary flex items-center justify-center' onClick={() => false}>X</div> */}
          </div>
          <div className="flex flex-col items-center gap-4 justify-center p-4 border-theme1">
            {loading ? <div className="load"></div> : null}
            {loading ? <div className="">Please Wait</div> : null}
            {!loading && (!curDetail?.length && !curDetail?.checkedBagAllowance) ? 
              <div className="flex gap-4 flex-wrap">
                <span>No Fare rules returned!</span>
                <button onClick={() => showDetail()}>Reload</button>
              </div>
            :null}
          </div>

          {loading || (!curDetail?.length && !curDetail?.checkedBagAllowance) ? null
          :
          <div className="flex flex-col gap-2">
            <b>Carry on allowance</b>
            {
              curDetail?.carryOnAllowance?.info?.map((obj,i) => (
                <div className="grid grid-cols-4 gap-4" key={i}>
                  <span>
                    Applicable Party : {obj?.AllowanceDescription?.ApplicableParty || '------'}
                  </span>
                  <span>
                    Piece Allowed : {obj?.PieceAllowance?.at(0)?.TotalQuantity || '------'}
                  </span>
                  <span>
                    Weight Allowed : {obj?.WeightAllowance?.MaximumWeight?.at(0)?.Value || '------'} {obj?.WeightAllowance?.MaximumWeight?.at(0)?.UOM}
                  </span>
                </div>
              ))
            }
          </div>
          }

          {loading || (!curDetail?.length && !curDetail?.checkedBagAllowance) ? null
          :
          <div className="flex flex-col gap-2">
            <b>Checked bag allowance</b>
            {
              curDetail?.checkedBagAllowance?.info?.map((obj,i) => (
                <div className="grid grid-cols-4 gap-4" key={i}>
                  <span>
                    Applicable Party : {obj?.PieceAllowance?.at(0)?.ApplicableParty || '------'}
                  </span>
                  <span>
                    Piece Allowed : {obj?.PieceAllowance?.at(0)?.TotalQuantity || '------'}
                  </span>
                  <span>
                    Weight Allowed : {obj?.WeightAllowance?.MaximumWeight?.at(0)?.Value || '------'} {obj?.WeightAllowance?.MaximumWeight?.at(0)?.UOM}
                  </span>
                </div>
              ))
            }
          </div>
          }

          <br />
          <div className="flex flex-col gap-4">
            {curDetail?.miniFareRules?.info?.map((obj,i) => {
              const key = obj?.ObjectKey?.split('.');
              const CurrencyAmount = obj?.Details?.Detail?.at(0)?.Amounts?.Amount?.at(0)?.CurrencyAmountValue;
              return (
                <div key={i}>
                  {key?.at(1)} {key?.at(2) || obj?.Details?.Detail?.at(0)?.Type}
                  <div className="grid grid-cols-3 flex-wrap gap-4">
                    <span className="capitalize flex flex-col items-start">
                      {key?.at(2)?.toLowerCase()} Allowed: {obj?.ChangeAllowedInd || obj?.CancelAllowedInd ? <span className="bg-green-300 text-xs px-2">True</span>:<span className="bg-red-300 text-xs px-2">False</span>}
                    </span>
                    <span className="capitalize flex flex-col items-start">
                      {key?.at(2)?.toLowerCase()} Fee: {obj?.ChangeFeeInd || obj?.CancelFeeInd ? <span className="bg-green-300 text-xs px-2">True</span>:<span className="bg-red-300 text-xs px-2">False</span>}
                    </span>
                    <span>
                      Amount: {CurrencyAmount?.Code} {CurrencyAmount?.value}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>


          {
            // Verteil Detail Fare Rule
          // curDetail?.map((type,i) => (
          //     <Collapse key={i}
          //         className='relative'
          //         labelClass='sticky top-0 bg-secondary px-4 '
          //         label={
          //             <div className='flex flex-col'>
          //                 <h5>{getType(type.PTC)}</h5>
          //                 <h5>
          //                     {type?.detailFareRule?.Rules?.Departure?.AirportCode?.value}
          //                     -
          //                     {type?.detailFareRule?.Rules?.Arrival?.AirportCode?.value}
          //                 </h5>
          //             </div>
          //         }
          //     >
          //         <div className='px-4 '>
          //             {type?.detailFareRule?.Rules?.Rule?.map((Rule,id) => (
          //                 <div key={id} className='flex flex-wrap gap-3 '>
          //                     {Rule.Text?.map((text,i) => (
          //                         <div key={i}>{text}</div>
          //                     ))}
          //                 </div>
          //             ))}
          //         </div>
          //     </Collapse>
          // ))
          }
        </div>
      </ModalLocal>
    </div>
  )
}