export const hexToRgb = (hex) => {
  // Remove the # symbol if present
  hex = hex.replace('#', '');
  
  // Convert the hexadecimal value to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b, a: 1 };
};