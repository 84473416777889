import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { Breadcrumbs } from '@mui/material';
import { pacakgeDetailData } from '../../../data/package/packageDetail';
import BookingDetail from './BookingDetail';
import { getSingleTourBooking } from '../../../controllers/tour/getSingleTourBooking';

export default function Index() {
  const [data,setData] = useState(pacakgeDetailData);
  const {id} = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle("Tours"));
    load(id);
  },[id,dispatch])
  
  async function load(id) {
    const res = await getSingleTourBooking(id);
    if(res.return) {
      // let data = templateDetails(res.data)
      setData(res.data);
    }
  }

  
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      <div className='p-4 bg-secondary'>
        <Breadcrumbs separator=">">
          <Link to="/packages">Pacakges</Link>,
          <span >{data.tourProBookingId}</span>
        </Breadcrumbs>
        <h3 className='!text-[#444]'>{data.product_name}</h3>


        <div className='flex gap-4 flex-wrap'>
          <div className='flex-1 flex flex-col gap-10 pb-4'>
            <div className='mt-8'>
              <BookingDetail data={data} />
            </div>
            <div className=''>
            </div>
          </div>
          <div>
            <MinDetail data={data} />
          </div>
        </div>
      </div>
    </div>
  )

}

function MinDetail({data}) {
  let temp = pacakgeDetailData;

  return (
    <div>
      <h5 className='px-4'>Flight Details</h5>
      <hr />
      <div className='px-4 pt-5 flex flex-col gap-4'>
        <div>
          <div>Status</div>
          {
            data.status === 'Confirmed' ? 
              <span className='text-xs p-2 inline-block rounded-md !border-[#45BF43] border !bg-[#58ffb038]'>Confirmed</span>
              :
              <span className='text-xs p-2 inline-block rounded-md !border-[#aaa] border'>{data.status}</span>
          }
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between gap-10'>
            <div>Package</div>
            <div>Issuing Date</div>
          </div>
          <div className='flex  justify-between gap-10'>
            <b className='flex gap-1'>
              {data.packageInfoData.product_name}
            </b>
            <b>
              {(new Date(`${data.bookedBy.updatedDate}`).toDateString())}
            </b>
          </div>
        </div>
      </div>
    </div>
  )
}