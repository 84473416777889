import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, MenuItem } from '@mui/material';
import Pagination from '../../../components/mini/Pagination';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import DotMenu from '../../../components/mini/DotMenu';
import getAddons from '../../../controllers/settings/flights/getAddons';
import { def } from '../../../config';
import RemoveAddonModal from './RemoveAddonModal';
import { Link } from 'react-router-dom';


const columns = [
  {field: 'name',headerName: 'Add-on Name',flex:1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex gap-4 items-center'>
          {params.value}
        </div>
      )
    }
  },
  {field: 'descriptions',headerName: 'Description',flex: 1,minWidth: 250,
    renderCell: (params) => {
      if(!params.value || !Array.isArray(params.value))
        return null;

      return (
        <div className='flex flex-col'>
          {params.value.map((descr,i) => (
            <p key={i}>{descr}</p>
          ))}
        </div>
      )
    }
  },
  {field: 'price',headerName: 'Price',minWidth: 170,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex '>
          {def.currency}
          {params.value}
        </div>
      )
    }
  },
  {field: 'action',headerName: 'Action',flex: 1,minWidth: 130,
    renderCell: (params) => {
      // if(!params.value)
      // return null;

      return (
        <ActionCol params={params} />
      )
    }
  },
];

function ActionCol({params}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    
    dispatch(setModalComp(<RemoveAddonModal id={id} />))

  }
  return (
    <div className='flex justify-between w-full relative'>
      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
        <MenuItem>
          <Link className='w-full' to={"edit/"+params.id}>Edit</Link>
        </MenuItem>
      </DotMenu>
    </div>
  )
}


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const apiRef = useGridApiRef();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle('Add-ons'))

    async function load() {
      setLoading(true);
      const res = await getAddons();
      setLoading(false);
      if(res.return) {
        setData(res.data)
      }
    }
    load();
  },[dispatch])

  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='w-full bg-secondary p-4'>
        <div className='flex justify-end pb-4'>
          <Link to="/settings/addons/create" className='btn2'>Create Add-on</Link>
        </div>
        <Box className=''>
          <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
            // getRowId={(row) => row.id}
            apiRef={apiRef}
            initialState={{
              sorting: {
                sortModel: [{field: "bookingDate",sort: 'desc'}]
              },
            }}
            filterModel={filter}
            onFilterModelChange={(newFilter) => setFilter(newFilter)}
            
            // slots={{pagination: Pagination}}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}

            // onCellEditStart={params => setRowId(params.id)}
            // hideFooterPagination
            checkboxSelection
          />
          <Pagination perPage={10} total={46} apiRef={apiRef} />
        </Box>
      </div>
    </div>
  )
}
