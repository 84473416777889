import React from 'react'

export default function Index() {
  return (
    <div className='flex items-center justify-center w-full'>
      <div className='w-full h-full'>
        <iframe title='Advisory' className='w-full h-full' src='https://www.timaticweb2.com/integration/external?ref=d7942c545513dd2b80bce2fb0022469b&clear=true'>
        </iframe>
        {/* <a href="https://www.timaticweb2.com/integration/external?ref=d7942c545513dd2b80bce2fb0022469b&clear=true">
          https://www.timaticweb2.com/integration/external?ref=d7942c545513dd2b80bce2fb0022469b&clear=true
        </a> */}
      </div>
    </div>
  )
}
