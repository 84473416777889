import { Skeleton } from '@mui/material'
import React from 'react'

export default function HotelLoading() {
  return (
    <div className='w-full bg-secondary p-2 flex gap-2'>
        <Skeleton variant='rectangular' width={80} height={80} />
        <div className='flex flex-col items-start justify-center flex-1 gap-2'>
            <div className='flex w-full items-start justify-between gap-4 '>
                <Skeleton width={100} />
                <div>
                    <Skeleton width={60} />
                    <Skeleton width={120} />
                </div>
            </div>
            <div className='flex w-full flex-col'>
                <Skeleton className='w-full' />
                <Skeleton className='w-[75%]' />
            </div>
            {/* <Skeleton className='w-full h-[70px]' /> */}
        </div>
    </div>
  )
}
