import mergeRecursive from "../../features/utils/mergeRecursive";

export const offerSearchTemp = {
  "destination": "",
  "start_date": "",
  "end_date": "",
  "rooms": [
    {
      "adults": 1,
      "children": 0,
      "infants": 0,
      "units": 1
    }
  ]
}

export function templateOfferSearch(obj) {
  let data = mergeRecursive({...obj},offerSearchTemp);
  return data;
}
