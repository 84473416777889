import React, { useEffect, useState } from 'react'
import { getCurrencySymbol } from '../features/utils/currency'
import { getTransactions } from '../controllers/transactions/getTransactions';
import { useSpring, animated } from 'react-spring';
import { def } from '../config';
import { formatMoney } from '../features/utils/formatMoney';

function StatsOverview() {
  const [data,setData] = useState({all: 0,flights: 0,packages: 0,pending: 0})

  useEffect(() => {
    async function load() {
      const res = await getTransactions();
      if(res.return) {
        let data = {
          all: res.data.reduce((p,c) => ({amount: p.amount + c.amount}),{amount: 0})['amount'],
          flights: res.data.reduce((p,c) => ({...c,amount: (p.amount + (c.to !== 'BOOKING' ? 0 : c.amount))}),{amount: 0})['amount'],
          packages: res.data.reduce((p,c) => ({...c,amount: (p.amount + (c.to !== "TOURPRO_BOOKING" ? 0 : c.amount))}),{amount: 0})['amount'],
          hotels: res.data.reduce((p,c) => ({...c,amount: (p.amount + (c.to !== "HOTEL_BOOKING" ? 0 : c.amount))}),{amount: 0})['amount'],
          pending: res.data.reduce((p,c) => ({...c,amount: (p.amount + (c.transactionStatus !== 'PENDING' ? 0 : c.amount))}),{amount: 0})['amount'],
        }

        // console.log("calc: ",data);
        setData(data);
      }
    }
    load();
  },[])

  function Number({n}) {
    const {number} = useSpring({
      from: {number: 0},
      number: n,
      delay: 200,
      config: {mass: 1,tension: 40, friction: 10},
    })
    return <animated.div>{number.to((n) => formatMoney(n.toFixed(0)))}</animated.div>
  }
  
  return (
    <div className='flex gap-2 py-4 !text-white overflow-x-auto '>
      <div className='flex-1 min-w-[200px] bg-gradient-to-r from-[#4AD994] to-[#57F998] p-4 rounded-md'>
        <h3 className='whitespace-nowrap flex gap-1'>{def.currency} <Number n={data.all} /></h3>
        <span className='whitespace-nowrap'>All Transactions</span>
      </div>
      <div className='flex-1 min-w-[200px] bg-gradient-to-r from-[#FDB765] to-[#FFE485] p-4 rounded-md'>
        <h3 className='whitespace-nowrap flex gap-1'>{def.currency} <Number n={data.flights} /></h3>
        <span className='whitespace-nowrap'>Flights</span>
      </div>
      <div className='flex-1 min-w-[200px] bg-gradient-to-r from-[#27848B] to-[#40D5E0] p-4 rounded-md'>
        <h3 className='whitespace-nowrap flex gap-1'>{def.currency} <Number n={data.packages} /></h3>
        <span className='whitespace-nowrap'>Packages</span>
      </div>
      <div className='flex-1 min-w-[200px] bg-gradient-to-r from-[#FF6D59] to-[#FFD584] p-4 rounded-md'>
        <h3 className='whitespace-nowrap flex gap-1'>{def.currency} <Number n={data.hotels} /></h3>
        <span className='whitespace-nowrap'>Hotels</span>
      </div>
      <div className='flex-1 min-w-[200px] bg-gradient-to-r from-[#FF6D59] to-[#FFD584] p-4 rounded-md'>
        <h3 className='whitespace-nowrap flex gap-1'>{def.currency} <Number n={data.pending} /></h3>
        <span className='whitespace-nowrap'>Pending</span>
      </div>
    </div>
  )
}

export default React.memo(StatsOverview)