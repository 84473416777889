import { Breadcrumbs} from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RoomDisplay from '../../../components/hotel/RoomDisplay';
import { walletPayHotel } from '../../../controllers/hotel/walletPay';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import HotelPayment from '../../../components/payment/HotelPayment';
import PaymentMethod from '../../../components/payment/PaymentMethod';
import PromoCode from '../../../components/payment/PromoCode';


export default function Payment({next,back,data}) {
  const {hotelBooking} = useSelector(state => state.hotelBooking);
  
  
  let orderData = null;
  try {
    orderData = hotelBooking.booked.orderData
  } catch(ex) {}

  return (
    <div className='flex flex-col gap-3 flex-1'>
      <Breadcrumbs separator=">" className='text-xs'>
        <Link to="/search/hotels">Hotels</Link>
        <span className='cursor-pointer' onClick={() => back && back()}>Detail</span>
        <span className='capitalize'>{data && data.name.toLowerCase()}</span>
      </Breadcrumbs>
      <div className='bg-secondary rounded-md flex flex-col '>
        <RoomDisplay review />
      </div>
      <div className='flex flex-col '>
        {/* <HotelPayment returnData={next} orderData={hotelBooking && hotelBooking.booked && hotelBooking.booked.orderData} /> */}
        <PaymentMethod packageName='hotel' order={orderData} returnData={() => next()} />
        <PromoCode id={orderData.id} type="hotel" />
      </div>
    </div>
  )
}

