import { MoreHorizOutlined } from '@mui/icons-material'
import { Menu, MenuList } from '@mui/material'
import React, { useState } from 'react'

export default function DotMenu({children,anchor,setAnchor,parentControl}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(parentControl ? anchor : anchorEl);
  const handleClick = (event) => {
    parentControl ? setAnchor(event.currentTarget) :
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    parentControl ? setAnchor(null) : 
      setAnchorEl(null);
  };

  return (
    <div className='max-w-10  right-0 min-w-10 bg-white cursor-pointer '>
      <MoreHorizOutlined onClick={handleClick} />
      <Menu
        anchorEl={parentControl ? anchor : anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuList>
          {children}
        </MenuList>
      </Menu>
    </div>
  )
}
