export const passengerDataTemp = {
  "birthDate":"",
  "firstName":"",
  "lastName":"",
  "nationality":"",
  "gender":"",
  "passportId":"",
  "boardingUserType":"",
  "email":"",
  "phoneNumber":"",
  "issuanceLocation":"",
  "issuanceDate":"",
  "issuanceCountry":"",
  "validityCountry":"",
  "passportExpirationDate":"",


  "idType": "",
  "title": "",
  "countryCode": "",

  "uniqueId": "",
  "emergencyContactNumber": "",
  "alternateEmail": "",
  "passportIssuedBy": "",
  "primaryProfile": "",
  "primaryProfileRelation": "",
  "btmPrivilege": "",
  "companyDepartment": "",
  "companyDesignation": "",
  "nationalIdNumber": "",
  "nationalIdExpiry": "",
  "drivingLicenseNumber": "",
  "drivingLicenseExpiryDate": "",
  "airlinePreference": "",
  "seatPreference": "",
  "mealsPreference": "",
  "frequentFlyerAirlineAndNumberList": [
      {
          "airline": "",
          "number": "",
      },
      {
          "airline": "",
          "number": "",
      }
  ],
  "hotelPrivilegeCityHotelAndNumberList": [
      {
          "city": "",
          "hotel": "",
          "number": "",
      },
      {
          "city": "",
          "hotel": "",
          "number": "",
      }
  ],
  "dependentsList": [
      {
          "name": "",
          "relation": "",
      },
      {
          "name": "",
          "relation": "",
      }
  ],
  "holdingVisaList": [
      {
          "countryName": "",
          "from": "",
          "expiry": "",
      }
  ],
  "specialNotesList": [
      "special note 1",
      "special note 2"
  ],
  "travelHistoryList": [
      "travel history 1",
      "travel history 2"
  ]
}

export const passengerDataTempFilled = {
  "birthDate":"6/19/1999",
  "firstName":"Test",
  "lastName":"User",
  "nationality":"ET",
  "gender":["MALE","FEMALE"][parseInt(Math.random()*2)],
  "passportId":"1000",
  "boardingUserType":"ADULT",
  "email":`test${parseInt(Math.random()*999)}@gmail.com`,
  "phoneNumber":"251-940067965",
  "issuanceLocation":"ET",
  "issuanceDate":"6/19/2023",
  "issuanceCountry":"ET",
  "validityCountry":"ET",
  "passportExpirationDate":"6/19/2024"
}