import { importPNRTemp } from "../../data/flight/importPNRData";
import { getCurrencySymbol } from "../utils/currency";
import mergeRecursive from "../utils/mergeRecursive";

export default function template(obj) {
  let temp = {
    bookedBy: {
      firstName: "",
      lasName: "",
    },
    createdDate: "",
    flightData: {
      booked_flights: [{
        airline_detail: {name: ""},
        origin: {location: "",date: "",time: ""},
        destination: {location: ""},
      },
      {
        airline_detail: {name: ""},
        origin: {location: "",date: "",time: ""},
        destination: {location: ""},
      }],
      currency: ''
    },
    promoCodesId: "",
    editedtotalPrice: "",
    templated: 'true'
  }
  
  // try {
  //   if(obj.flightData.booked_flights.length === 1) {
  //     console.log(obj);
  //     const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));
  //     console.log("temped - ",data);

  //   }
  // } catch(ex) {console.log("--")}
  const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));

  let supplier = 'Amadeus_NG';

  supplier = (data?.flightProvider || supplier)?.replaceAll('_',' ');
  if(supplier === 'AMADEUS')
    supplier = 'Amadeus NG'

  // console.log(" -== ",obj);
  let result = {
    customer: data.bookedBy.firstName + " " + data.bookedBy.lastName,
    bookingDate: data.createdDate,
    tripDetails: {
      // [100].flightData.booked_flights[0].airline_detail.name
      airline: data.flightData.booked_flights[0].airline_detail.name,
      path: {
        origin: data.flightData.booked_flights[0].origin.location,
        destination: data.flightData.booked_flights[0].destination.location
      }
    },
    departureDate: {
      date: data.flightData.booked_flights[0].origin.date,
      time: data.flightData.booked_flights[0].origin.time
    },
    PNR: {code:data.bookingId,supplier},
    amount: {price:data.editedtotalPrice,promo: data.promoCodesId},
    ...data
  }

  // if(data.booked_flights.length > 1)
  //   result["returnDate"] = {
  //     date: data.flightData.booked_flights[1].origin.date,
  //     time: data.flightData.booked_flights[1].origin.time,
  //   }


  return result;
}

export function templateImport(obj) {
  let temp = {...importPNRTemp,
    promoCodesId: '',
    // templated: 'true',
    // flightData: {
    //   booked_flights: [{airline_detail: {name: ''}}]
    // }
  };
  // try {
  //   if(obj.flightData.booked_flights.length === 1) {
  //     console.log(obj);
  //     const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));
  //     console.log("temped - ",data);

  //   }
  // } catch(ex) {console.log("--")}
  const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));

  console.log(" -== ",data);

  let customer = "";
  let pricing = "";
  try {
    let travellerInfo = data.pnrData.activePNRimage.travellerInfo[0] || data.pnrData.activePNRimage.travellerInfo;
    let passengerData = travellerInfo.passengerData[0] || travellerInfo.passengerData;
    customer = passengerData.travellerInformation.passenger.firstName + " " + passengerData.travellerInformation.traveller.surname
  } catch(ex) {}
  try {
    pricing = data.pnrData.activePNRimage.pricingRecordGroup.productPricingQuotationRecord[0] || data.pnrData.activePNRimage.pricingRecordGroup.productPricingQuotationRecord
  } catch(ex) {}

  let result = {...data,
    id: data.id,
    customer,
    bookingDate: data.createdDate,
    tripDetails: {
      // [100].flightData.booked_flights[0].airline_detail.name
      airline: data.flightData.booked_flights[0].airline_detail.name,
      path: {
        origin: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.boardpointDetail.cityCode,
        destination: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.offpointDetail.cityCode
      }
    },
    departureDate: {
      date: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.product.depDate,
      time: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.product.depTime
    },
    arrivalDate: {
      date: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.product.arrDate,
      time: data.pnrData.activePNRimage.originDestinationDetails.itineraryInfo.travelProduct.product.arrTime
    },
    PNR: data.pnrCode,
    status: data.ticketedBy ? "Ticketed" : "Not Ticketed",
    // pnrData.activePNRimage.pricingRecordGroup.productPricingQuotationRecord.documentDetailsGroup.totalFare.monetaryDetails.currency
    amount: pricing.documentDetailsGroup.totalFare.monetaryDetails.amount,
  }

  try {
    result.flightData.booked_flights?.map((obj,i) => {
      obj['origin'] = {
        date: result.departureDate.date,
        time: result.departureDate.time,
        location: result.tripDetails.path.origin,
      }
      obj['destination'] = {
        date: result.arrivalDate.date,
        time: result.arrivalDate.time,
        location: result.tripDetails.path.destination,
      }
      return true;
    })

  } catch(ex) {}

  // if(data.booked_flights.length > 1)
  //   result["returnDate"] = {
  //     date: data.flightData.booked_flights[1].origin.date,
  //     time: data.flightData.booked_flights[1].origin.time,
  //   }


  return result;
}
