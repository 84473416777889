import mergeRecursive from "../../features/utils/mergeRecursive";

export const flightSeatTemplate = {
  "seatmapInformation": {
    "flightDateInformation": {
        "flightDate": {
            "departureDate": "110623"
        },
        "boardPointDetails": {
            "trueLocationId": "LOS"
        },
        "offpointDetails": {
            "trueLocationId": "LHR"
        },
        "companyDetails": {
            "marketingCompany": "VS"
        },
        "flightIdentification": {
            "flightNumber": "0486",
            "bookingClass": "Y"
        }
    },
    "seatmapErrorInfo": {
        "errorDetails": {
            "processingLevel": 1,
            "code": 93
        }
    }
  }
}

export function templateSeats(obj) {
  let data = mergeRecursive({...obj},flightSeatTemplate);
  return data;
}
