import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'

export default function PasswordInput({value,onChange,label}) {
  const [password,setPassword] = useState(value || "");
  const [showPassword, setShowPassword] = useState(false);
  const [valid,setValid] = useState({password:null});

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  function handlePassword(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,password: null}))
    else if(!/^[A-Za-z0-9._%+!@#$%^&*()]$/i.test(ev.target.value))
      setValid((obj) => ({...obj,password:'Invalid Password'}))
    else setValid((obj) => ({...obj,password: null}))

    setPassword(ev.target.value)
    if(onChange)
      onChange(ev.target.value)
  }

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password" size='small'>{label || "Password"}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          value={password}
          required
          size='small'
          error={valid.password?true:false}
          onChange={handlePassword}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={label || "Password"}
        />
      </FormControl>

    </div>
  )
}
