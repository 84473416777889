import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function bookTour(data) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  // console.log("userId - ",userId)
  console.log("[req] bookingTour data - ",data)

  await fetchServer({method: "POST",url: path.api+'/tourpro/bookings/',data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log(" => ",res)
    if(res) {
      if(res.data.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      }
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}