import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function walletPay(id,userId) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  let headers = {
    Authorization: `Bearer ${token}`
  }

  if(userId)
    headers['user-id'] = userId;
  
  await fetchServer({method: "PATCH",url: path.api+'/flights/pay-for-order/wallet/'+id,
    headers
  })
  .then((res) => {
    console.log(" => ",res)
    if(res.data) {
      if(res.data.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      }
    }
    else if(res.error)
      result['msg'] = res.error.message;
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}