import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { decrypt } from "../../features/utils/crypto";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { shareFlightOffer } from "../../controllers/flight/shareFlightOffer";
import { Email } from "@mui/icons-material";
import ModalLocal from "../mini/ModalLocal";
import EmailInput from "../form/EmailInput";
import { Icon } from "@iconify/react";

export default function ShareFlightOffer({offer}) {
  const [openShare,setOpenShare] = useState(false);
  const [shareData,setShareData] = useState({offer,offerLink: '', origin: '', destination: '', email: ''})
  const [shareLoading,setShareLoading] = useState(false);

  const {user} = useSelector(state => state.user.userData)
  const {bookingData} = useSelector(state => state.flightBooking);
  const [searchParam] = useSearchParams();
  const q = searchParam.get('q');

  const dispatch = useDispatch();

  useEffect(() => {
    setShareData(shareData => ({...shareData,offer}))
  },[offer])
  

  async function shareFlight() {
    console.log('offers: ',offer)
    if(!offer.length) return dispatch(setAlertMsg(['warning','No Flight Offers! Please wait for flights to load.']))
    let reqData = {...shareData};
    // let {fareRule,...modObj} = obj;
    let req = JSON.parse(decrypt(q));
    req.destinations.map(dest => {
      dest.arrivalLocation = dest.arrivalLocation?.iata || dest.arrivalLocation;
      dest.departureLocation = dest.departureLocation?.iata || dest.departureLocation;
      return true;
    })
    reqData.origin = req?.destinations[0]?.departureLocation || "";
    reqData.origin = reqData.origin?.iata || reqData.origin
    reqData.destination = req?.destinations[0]?.arrivalLocation || "";
    reqData.destination = reqData.destination?.iata || reqData.destination
    let as = bookingData.as;
    if(as) {
      let {id,type,apiUserData} = as || {};
      reqData.as = {id,type,apiUserData}
    }
    // let offer = bookingData.beforePrice || bookingData.offer;
    let oldKey = {
        "currencyCode": "NGN",
        "originDestinations": [
          {
            "id": "11",
            "originLocationCode": "LOS",
            "destinationLocationCode": "LHR",
            "airPortTo": {
              "icao": "EGLL",
              "iata": "LHR",
              "name": "London Heathrow Airport",
              "city": "London",
              "state": "England",
              "country": "GB",
              "elevation": 83,
              "lat": 51.4706001282,
              "lon": -0.4619410038,
              "tz": "Europe/London"
            },
            "airPortFrom": {
              "icao": "DNMM",
              "iata": "LOS",
              "name": "Murtala Muhammed International Airport",
              "city": "Lagos",
              "state": "Lagos",
              "country": "NG",
              "elevation": 135,
              "lat": 6.5773701668,
              "lon": 3.321160078,
              "tz": "Africa/Lagos"
            },
            "departureDateTimeRange": {
              "date": "2024-03-10"
            }
          },
          {
            "id": "3",
            "originLocationCode": "LHR",
            "airPortTo": {
              "icao": "EGLL",
              "iata": "LHR",
              "name": "London Heathrow Airport",
              "city": "London",
              "state": "England",
              "country": "GB",
              "elevation": 83,
              "lat": 51.4706001282,
              "lon": -0.4619410038,
              "tz": "Europe/London"
            },
            "airPortFrom": {
              "icao": "DNMM",
              "iata": "LOS",
              "name": "Murtala Muhammed International Airport",
              "city": "Lagos",
              "state": "Lagos",
              "country": "NG",
              "elevation": 135,
              "lat": 6.5773701668,
              "lon": 3.321160078,
              "tz": "Africa/Lagos"
            },
            "destinationLocationCode": "LOS",
            "departureDateTimeRange": {
              "date": "2024-03-12"
            }
          }
        ],
        "travelers": [],
        "sources": ["GDS"],
        "searchCriteria": {
          "maxFlightOffers": 50,
          "flightFilters": {
            "cabinRestrictions": [
              {
                "cabin": "ALL",
                "coverage": "MOST_SEGMENTS",
                "originDestinationIds": ["11", "3"]
              }
            ]
          },
          "additionalInformation": {
            "chargeableCheckedBags": true,
            "brandedFares": true
          },
          "segments": 1
        }
      // "baggage": "",
      // "checkedBags": ""
    }
    let currencyObj = JSON.parse(window.localStorage.getItem('currencyObj')||'{}');
    reqData.offers = offer?.map(obj => {
      let {otherSegments,...restObj} = obj;
      return restObj;
    })?.slice(0,10);
    let agentSite = user?.apiUserData?.url || 'https://www.btmholidays.com';
    reqData.offerLink = `${agentSite}/search?key=${(new URLSearchParams(JSON.stringify(req))?.toString()?.replace(/=$/,''))}&oldKey=${(new URLSearchParams(JSON.stringify(oldKey)))?.toString()?.replace(/=$/,'')}&currency=${(new URLSearchParams(JSON.stringify(currencyObj)))}`
    // reqData.offerLink = `https://www.btmholidays.com/booking?trip=${JSON.stringify({offer,passengers: offer.passengers})}&guest=true&search=${JSON.stringify(req)}`
    // reqData.offerLink = path.site+`/flights/book/${q}${refQuery?refQuery:''}`
    // totalAmount: modObj.farePrice.fareTotal,formatedTotalAmount: modObj.totalAmount
    reqData.currency = currencyObj
    reqData.offer = undefined;

    if(!reqData.email) 
      return dispatch(setAlertMsg(['error','Email is required']))

    setShareLoading(true);
    const res = await shareFlightOffer(reqData);
    setShareLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Link sent to email']))
      setOpenShare(false);
    } else dispatch(setAlertMsg(['error',res.msg]))
    // console.log(req,reqData)
  }

  return (
    <div className="flex self-end  justifiy-end">
      {/* <Share className='cursor-pointer' onClick={() => setOpenShare(true)} /> */}
      {/* {!onBook ?  */}
      <div onClick={() => setOpenShare(true)} className="flex gap-2 items-center">
        <Email className='cursor-pointer'  />
        <Icon icon='material-symbols:share' />
      </div>
      {/* :null} */}
      <ModalLocal open={openShare} setOpen={setOpenShare}>
        <div className='bg-secondary rounded-md flex flex-col gap-3 p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
          <div className='btn_close' onClick={() => setOpenShare(false)}>X</div>
          <h3>Share flight offer</h3>
          <EmailInput value={shareData.email} required
            onChange={(email) => setShareData({...shareData,email})} />
          <button className='flex gap-2 btn2' onClick={shareFlight} disabled={shareLoading}>
            {shareLoading?<div className="load"></div>:null}
            Send link to email
          </button>
        </div>
      </ModalLocal>
    </div>

  )
}
