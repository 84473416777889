import { useState } from "react";
import { setModal } from "../../redux/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import { IssueTicket } from "../../controllers/flight/IssueTicket";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { IssueImportTicket } from "../../controllers/flight/IssueImportTicket";

export function IssueTicketModal({id,userId,type,returnData}) {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);

  async function handleTicket() {
    setLoading(true);
    let res = {return: false};
    if(type === 'imported')
      res = await IssueImportTicket(id);
    else
      res = await IssueTicket(id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Issue Ticket Successful.']))
    }
    else {
      dispatch(setAlertMsg(['error','Issue Ticket Failed!']))      
    }
    
    if(returnData)
      returnData();
    
  }
  return (
    <div className='h-full flex items-center'>
      <div className='bg-secondary p-6 pt-10 flex-col flex gap-4 justify-center relative'>
        <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
        <div>Are you sure you want to issue ticket?</div>
        <button className='btn1 self-center flex gap-2' onClick={handleTicket}>
          {loading?(<div className="load"></div>):null}
          Yes, issue ticket
        </button>
      </div>
    </div>
  )
}