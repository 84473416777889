import ModalLocal from "./mini/ModalLocal";

export default function DataLossModal({openDataLoss,setOpenDataLoss}) {
    function handleDataLoss(confirmed) {
        if(confirmed)  
            if(openDataLoss?.callback)
                openDataLoss.callback();
    }    
    
    return (
      <ModalLocal open={openDataLoss} setOpen={setOpenDataLoss}>
        <div className='card bg-secondary p-10 rounded-md flex flex-col gap-4'>
          <h3>You have unsaved changes, are you sure you want to leave this page?</h3>
          <div className='flex gap-6 justify-between'>
            <button className='btn1' onClick={() => setOpenDataLoss(false)}>Cancel</button>
            <button className='btn2' onClick={() => handleDataLoss(true)}>Continue</button>
          </div>
        </div>
      </ModalLocal>
  
    )
  }