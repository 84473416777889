import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function getUsers(q) {
  var response = {return: 0,msg: "Error",data: null}

  let token = store.getState().user.userData.accessToken;
  // console.log('q got : ',q)

  await fetchServer({method: 'GET',url: path.api+"/users"+(q?'?'+q:''),
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    // console.log(" [req] ",res)
    if(res && res.data.success) {
      response = {return: 1,msg: "Successfull",data: res.data.data}
    }
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}