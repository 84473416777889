import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { decrypt } from '../../../features/utils/crypto';
import { useDispatch, useSelector } from 'react-redux';
import HotelOfferFilter from './OffersFilter';
import HotelSearchInput from '../../../components/search/HotelSearchInput';
import HotelDisplay from '../../../components/hotel/HotelDisplay';
import getHotelOffers from '../../../controllers/search/getHotelOffers';
import { setHotelBooking } from '../../../redux/reducers/hotel/hotelBookingSlice';
import HotelLoading from '../../../components/hotel/HotelLoading';
import ModalLocal from '../../../components/mini/ModalLocal';
import { TextField } from '@mui/material';
import CitySearch from '../../../components/search/CitySearch';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { requestHotel } from '../../../controllers/hotel/hotelRequest';
import ShareHotelOffer from '../../../components/hotel/ShareHotelOffer';


const fetchedData = createRef([]);

export default function Index() {
  const [data,setData] = useState([]);
  const {hotelBooking} = useSelector(state => state.hotelBooking);
  const [loading,setLoading] = useState(false);
  const [resMsg] = useState("No Result");
  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const [pag,setPag] = useState({limit: 5})

  const [openHotelSearch,setOpenHotelSearch] = useState(false);


  const dispatch = useDispatch();

  const fetchData = useCallback(async (req) => {
    console.log( 'q : ',q)
    if(!q) return {return: false};
    let obj = req || JSON.parse(decrypt(q));

    return await getHotelOffers(obj);
  },[q])


  useEffect(() => {
    let t = null;

    async function load() {
      setLoading(true);
      // let obj = JSON.parse(decrypt(q));
      // console.log(' -- ',q)
      // console.log(' -- ',obj)
      // const res = await getFlightOffers(obj);
      // dispatch(setHotelBooking({...hotelBooking,time: null}))
      let as = hotelBooking.as;

      const res = await fetchData();

      let obj = JSON.parse(decrypt(q));

      dispatch(setHotelBooking({as,request: obj,time: new Date().getTime()}))

      console.log('[p] res : ',res)
      setLoading(false);
  
      if(res.return) {
        fetchedData.current = res.data;
        setData(res.data)
      }
      // setResMsg(res.msg);
      // console.log(res);
    }  
    if(q)
      load();

    return () => clearTimeout(t);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchData,dispatch,q])



  // function search(reqObj) {
  //   if(reqObj) {
  //     let enc = encrypt(JSON.stringify(reqObj));
  //     navigate(`/search/flight/offers?q=${enc}`);
  //   }
  // }
  function handleSort(type) {
    if(type === 'Price') {
      let temp = data.sort((a,b) => Number((a?.price?.toString()?.replaceAll(',','') || a?.price) - (Number(b?.price?.toString()?.replaceAll(',','') || b?.price))))
      console.log(" --> ",temp)
      setData([...(temp)])
    }
    else
      setData(fetchedData.current || [])
      // console.log(fetchedData.current)
  }

  
  return (
    <div className='w-full flex flex-col gap-2 bg-gray-100 py-4  '>
      {/* <PriceTimeout /> */}
      <div className='bg-secondary px-4'>
        <HotelSearchInput />
      </div>


      <div className='flex gap-4 '>
        <div className='bg-secondary self-end sticky bottom-0 rounded-2xl min-h-screen'>
          <HotelOfferFilter orgi={fetchedData.current || data} data={data} setData={setData} />
        </div>
        <div className='flex-1 flex flex-col gap-4'>

          <div className='flex justify-between text-xs px-2 py-1'>
            <b>Showing {pag.limit > data?.length ? data?.length : pag.limit} of <span className='text-red-400'>{data?.length} places</span></b>
            <div className='flex gap-2 items-center'>
              <div>
                sort by 
                <select onChange={(ev) => handleSort(ev.target.value)} className='border-0 bg-transparent'>
                  <option>Recommended</option>
                  <option>Price</option>
                </select>
              </div>
              <ShareHotelOffer offer={data?.slice(0,20)} />
            </div>
          </div>

          {
            loading ?
              [...Array(3)].map((_,i) => (
                <HotelLoading key={i} />
              ))
            : data?.length < 1 ?
              <h3 className='bg-secondary p-5 rounded-2xl flex items-center justify-center text-primary/30 uppercase'>{resMsg}</h3>
            : null              
          }

          {/* <div className='bg-secondary flex justify-center rounded-md'>
            <Tabs indicatorcolorx='text-primary' textColor='inherit' value={0} scrollButtons allowScrollButtonsMobile variant='scrollable' 
              className='div_mid w-full flex'>
              {
                Object.entries(cat).map(([key,val],i) => (
                <Tab key={i} className=' flex-1 flex items-start justify-start div_targ' 
                  // onClick={() => sortByCat(obj[1])}
                  label={(
                    <div className='text-start'>
                      <h5>{key}</h5>
                      <small>
                        {val || 0} places
                      </small>
                    </div>
                  )}
                />
                ))
              }
            </Tabs>
          </div> */}



          {data.slice(0,pag.limit).map((obj,i) => (
            <HotelDisplay key={i} data={obj} 
            // showDetail={async () => await showDetail(obj)} 
            />
          ))}
          {pag.limit < data.length ? 
            <button className='btn1' onClick={() => setPag(pag => ({...pag,limit: pag.limit + 5 > data.length ? data.length : pag.limit + 5}))}>Load More</button>
          : null}
          <div className='text-center py-4 flex justify-center items-center gap-4'>
            Haven't found the hotel you're looking for?
            <button className='btn2' onClick={setOpenHotelSearch}>Search Hotel</button>
          </div>
        </div>
        <ModalLocal open={openHotelSearch} setOpen={setOpenHotelSearch}>
          <div className='flex gap-4 justify-between bg-secondary p-6 rounded-md'>
            <HotelSearch data={data} />
          </div>
        </ModalLocal>
        <div className='self-end sticky bottom-0'>
          {/* <FlightOfferDetail data={data} setData={setData} obj={curDetail} /> */}
        </div>
      </div>
    </div>
  )
}

function HotelSearch({data}) {
  const [hotelName,setHotelName] = useState('');
  const [city,setCity] = useState();
  const [description,setDescription] = useState('');
  const [showForm,setShowForm] = useState(false);
  const [loading,setLoading] = useState(false);
  
  const dispatch = useDispatch();


  async function handleSubmit() {
    if(hotelName === '' || !city?.formatted_address)
    return dispatch(setAlertMsg(['error','Hotel name and city are required!']))
    setLoading(true);
    const res = await requestHotel({name:hotelName,city: city?.formatted_address,description});
    if(res.return) {
      dispatch(setAlertMsg(['success',`${hotelName} has been requested`]))
      setShowForm(false);
    } else dispatch(setAlertMsg(['error',res.msg]))
    setLoading(false);
  }

  let foundHotels = data?.filter(obj => obj.name?.toLowerCase()?.match(hotelName))
  return (
    <div className='flex flex-col gap-4'>
      <h4>Search for hotel</h4>
      <div className='flex gap-2 items-center'>
        <TextField label='Hotel Name' size='small' className='flex-1' required
          value={hotelName}
          onChange={(ev) => setHotelName(ev.target.value)}
          />
        {!showForm?
          <button className='btn1' onClick={() => setShowForm(true)}>Submit Hotel</button>
        :null}
      </div>
      <hr />
      {showForm?
        <div className='flex flex-col gap-4'>
          <CitySearch value={city} onChange={(obj) => setCity(obj)} label='City' required />
          <TextField multiline rows={4} placeholder='Description'
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
          />
          <div className='flex gap-2 items-center'>
            <button className='btn1' onClick={() => setShowForm(false)}>Cancel</button>
            <button className='btn2 flex gap-2' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Submit Hotel
            </button>
          </div>
        </div>
      :null}
      <div className='max-h-[50vh] overflow-auto'>
        {!showForm && hotelName && hotelName !== '' && foundHotels?.map((obj,i) => (
          <HotelDisplay key={i} data={obj} />
        ))}
        {!showForm && !foundHotels.length && hotelName && hotelName !== '' ? 
          <div className='flex flex-col gap-2 max-w-[400px]'>
            No hotel found with that name
            <p>
              Having trouble finding the hotel you're looking for?
              Remember that sometimes the spelling or formatting of a hotel name can vary. 
              To improve your search results, consider trying different ways to input the hotel name.
            </p>
          </div>
        :null}
      </div>
    </div>
  )
}