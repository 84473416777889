import React, { useCallback, useEffect, useState } from 'react'
import Flights from './Flights'
import Packages from './Packages'
import Insights from './Insights'
import getDashboard from '../../controllers/dashboard/getDashboard';
import Overview from './Overview';
import Hotels from './Hotels';
import ActivityLogs from './ActivityLogs';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../features/utils/formatMoney';
import { def } from '../../config';
import getNotifications from '../../controllers/Notifications/getNotifications';
import ModalLocal from '../../components/mini/ModalLocal';
import { NotificationsOutlined } from '@mui/icons-material';


let filterInit = {
  daily: {index: 0,days: 1,label: 'Daily'},
  weekly: {index: 1,days: 7,label: 'Weekly'},
  monthly: {index: 2,days: 30,label: 'Under 30 days'},
  quarterly: {index: 3,days: 360/4,label: 'Quarterly'},
  yearly: {index: 4,days: 360,label: 'Yearly'},
  all: {index: 4,days: 'All',label: 'All'},
}
function Index() {
  const [key,setKey] = useState(2);
  const [filter,setFilter] = useState(filterInit);
  const [data,setData] = useState();
  const [bookings,setBookings] = useState([]);
  const {user} = useSelector(state => state.user.userData);
  const handleBookings = useCallback((data) => {
    setBookings(data)
    console.log('changing dates')
  },[])
    useEffect(() => {
      try {
        load();
      } catch(ex) {}
    },[])
    async function load() {
      const res = await getDashboard();
      if(res.return) {
        setData(res.data)
        if(res.data.key) {
          setFilter(filt => {
            let temp = filt;
            temp.daily.index = res.data.key.daily;
            temp.weekly.index = res.data.key.weekly;
            temp.monthly.index = res.data.key.monthly;
            temp.quarterly.index = res.data.key.quarterly;
            temp.yearly.index = res.data.key.yearly;
            return temp;
          })
        }
      }
    }

  let tour = []
  let flight = []
  let hotel = []
  try {
    tour = data.tour[key]
    flight = data.flight[key]
    hotel = data.hotel[key]
  } catch(ex) {}

  // console.log('obj: ',data)
  return (
    <div className='p-4 flex flex-col px-4 w-full overflow-auto gap-4 bg-[#f3f3f3]'>
      <div className='flex gap-2 '>
        <div className='flex gap-2 flex-col'>
          <div className='flex gap-4  items-center flex-wrap'>
            <div className='flex gap-2 bg-secondary p-2 px-4 rounded-2xl'>
              <span className='text-gray-400'>Wallet Ballance : </span>
              <b>{def.currency}{user.wallet && formatMoney(Number(user.wallet[0].amount).toFixed(2))}</b>
            </div>
            <div className='flex gap-2 bg-secondary p-2 px-4 rounded-2xl'>
              <span className='text-gray-400'>Points Earned : </span>
              <b>{user.points && user.points.points}</b>
            </div>
            <div className='flex gap-2 bg-secondary p-2 px-4 rounded-2xl'>
              <span className='text-gray-400'>Level : </span>
              <b>{user.userLevel || "-"}</b>
            </div>
          </div>
          <Notifications />
        </div>
        <div className='flex-1 flex justify-end self-end'>
          <select className='text-primary/50 self-end p-2 px-4'
            defaultValue={key}
            onChange={(ev) => setKey(ev.target.value)}>
            {Object.entries(filter).map(([key,val],i) => (
              <option value={val.index} key={i}>{val.label}</option>
              ))}
          </select>
        </div>
      </div>

      <Insights 
       returnData={(data) => handleBookings(data)}
       obj={data} filter={{key,setKey,filter}}  />
      <Overview bookings={bookings} />
      <Flights obj={flight} />
      <Hotels obj={hotel} />
      <Packages obj={tour} />
      {/* <ActivityLogs limit={10} /> */}
    </div>
  )
}

function Notifications() {
  const [data,setData] = useState([]);
  const [selected,setSelected] = useState({});
  const [open,setOpen] = useState(false);


  useEffect(() => {
    if(selected.title)
      setOpen(true)
  },[selected])
  useEffect(() => {
    load();
  },[])
  async function load() {
    const res = await getNotifications();
    if(res.return) {
      let data = res.data;
      setData(data);
    }
  }

  return !data.length ? null : (
    <div className='flex items-center gap-4 w-full '>
      <div className=' p-2 px-3 flex gap-3 items-center'>
        <NotificationsOutlined className='text-sm' />
        <span className='h-[25px] border-r border-primary/30'></span>
      </div>
      <div className='overflow-x-auto flex items-center gap-4 h-full w-full'>
        {data.map((obj,i) => (
          <b className='bg-secondary text-[12px] text-[#444] shadow-md p-1 rounded-2xl px-4 cursor-pointer hover:shadow-lg transition-all hover:scale-105'
          key={i} onClick={() => setSelected(obj)}>{obj.title}</b>
        ))}
      </div>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-4 relative'>
          <div className='btn_close' onClick={() => setOpen(false)}>X</div>
          <div className='flex flex-col gap-4 max-w-[500px]'>
            <h3>{selected.title}</h3>
            <p>{selected.body}</p>
          </div>
        </div>
      </ModalLocal>
    </div>
  )  
}

export default Index