export const pacakgeDetailData = {
    "id": "",
    "packageInfoData": {
      "__v": "",
      "_id": "",
      "mark": "",
      "hotel": [],
      "photo": "",
      "flight": [],
      "details": "some details",
      "activity": [],
      "approved": true,
      "category": [],
      "latitude": "",
      "farePrice": {
        "fare_adult_price": 2500,
        "fare_child_price": 5000,
        "fare_infant_price": 5000
      },
      "image_key": "",
      "longitude": "",
      "created_by": [
        {
          "__v": 0,
          "city": "",
          "email": "",
          "phone": "",
          "last_name": "",
          "first_name": "",
          "website_url": "",
          "detailed_info": "",
          "nature_of_business": "Sports"
        }
      ],
      "exclusions": "",
      "highlights": "",
      "inclusions": "",
      "add_to_pool": true,
      "adult_price": 2500,
      "child_price": 5000,
      "infant_price": 5000,
      "product_code": "",
      "product_name": "",
      "sub_category": [
        {
          "subcategory_name": "sub cat 1"
        }
      ],
      "collection_id": "",
      "short_address": "",
      "detailed_address": "",
      "long_description": "",
      "number_of_adults": 5,
      "product_category": "",
      "number_of_infants": 1,
      "small_description": "",
      "number_of_children": 10,
      "terms_and_conditions": "",
      "product_stop_duration": "",
      "product_start_duration": "Monday, April 18,2023"
    },
    "packageType": "",
    "originalPriceForAdult": 2500,
    "originalPriceForChild": 5000,
    "originalPriceForInfant": 5000,
    "farePriceForAdult": 2500,
    "farePriceForChild": 5000,
    "farePriceForInfant": 5000,
    "numberOfAdults": 1,
    "numberOfChildren": 0,
    "numberForInfants": 0,
    "totalOriginalPrice": 2500,
    "totalFarePrice": 2500,
    "tourProBookingId": "",
    "tourProBookingData": {
      "__v": 0,
      "_id": "",
      "status": "",
      "tour_id": "",
      "customer_id": "",
      "booking_date": "",
      "payment_time": "",
      "booking_source": "",
      "payment_status": "",
      "mode_of_payment": "",
      "booking_comments": "",
      "number_of_adults": 1,
      "internal_comments": "",
      "number_of_infants": 0,
      "number_of_children": 0,
      "prices_and_rates_id": 110,
      "number_of_participants": 1
    },
    "status": "",
    "expiryDate": null,
    "userId": "",
    "bookedByAdminId": null,
    "bookedBy": {
      "id": "",
      "username": "",
      "firstName": "",
      "email": "",
      "lastName": "",
      "password": "",
      "otp": null,
      "profile": "",
      "isAdmin": true,
      "isSuperAdmin": false,
      "isActive": true,
      "phoneNumber": "",
      "type": "",
      "customSettingid": null,
      "gender": "",
      "companyName": "",
      "addressLine1": "",
      "addressLine2": null,
      "postalCode": "",
      "cityName": "",
      "countryCode": "",
      "createdDate": "",
      "updatedDate": "2022-11-07T05:46:29.326Z"
    },
    "bookedByAdmin": null,
    "Transaction": [
      {
        "id": "",
        "from": "",
        "to": "",
        "paymentReference": null,
        "transactionHash": null,
        "confirmedById": null,
        "bookedTripId": null,
        "transactionStatus": "",
        "fromWalletId": "",
        "toWalletId": null,
        "createdDate": "",
        "updatedDate": "",
        "userId": "",
        "amount": 2500,
        "currencyCode": "",
        "bookedActivityId": null,
        "bookedHotelId": null,
        "tourProBookingsId": "778e7440-b61d-4150-a647-9717523fbd36"
      }
    ],
    "_count": {
      "Transaction": 1
    }
  }