export const RolesData = [
  "ALL",
  "AMADEUS_SEARCH",
  "AMADEUS_BOOK",
  "AMADEUS_TICKET",
  "AMADEUS_CANCEL",
  "AMADEUS_IMPORT",
  "AMADEUS_REFUND",
  "AMADEUS_REISSUE",
  "AMADUES_HOTEL_SEARCH",
  "AMADUES_HOTEL_BOOK",
  "AMADUES_HOTEL_CANCEL",
  "HOTELBEDS_SEARCH",
  "HOTELBEDS_BOOK",
  "HOTELBEDS_CANCEL",
  "TOURPRO_SEARCH",
  "TOURPRO_BOOK",
  "TOURPRO_CANCEL",
  "SETTINGS",
  "PAYMENT",
  "MANAGE_USERS",
]