import { Skeleton } from '@mui/material'
import React from 'react'

export default function FlightLoading() {
  return (
    <div className='w-full bg-secondary p-2 flex gap-2'>
        <div className='flex flex-col items-center justify-center gap-2'>
            <Skeleton variant='rectangular' width={80} height={80} />
            <Skeleton width={100} />
        </div>
        <div className='flex flex-col items-start justify-center flex-1 gap-2'>
            <div className='flex gap-4 '>
                <Skeleton width={100} />
                {/* <Skeleton width={100} /> */}
            </div>
            <Skeleton width={200} />
        </div>
        <div className='flex flex-col items-center justify-center flex-1 gap-2'>
            <div className='flex gap-4 '>
                <Skeleton width={100} />
                {/* <Skeleton width={100} /> */}
            </div>
            <Skeleton width={100} />
        </div>
    </div>
  )
}
