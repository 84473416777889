import { Add, ArrowBack } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { clone } from '../../../features/utils/objClone'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice'
import createAddonReq from '../../../controllers/settings/flights/createAddon'
import updateAddonReq from '../../../controllers/settings/flights/updateAddon'
import getAddons from '../../../controllers/settings/flights/getAddons'


const init = {
  "name":"",
  "descriptions":[""],
  "price":''
}
export default function EditAddon() {
  const [data,setData] = useState(init);
  const [loading,setLoading] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    if(id) {
      getAddon()
    }
  },[id])

  async function getAddon() {
    let params = {
      id
    }
    const res = getAddons((new URLSearchParams(params)).toString());
    if(res)
      console.log(" --> ",res.data)
  }

  const dispatch = useDispatch();

  async function handleSubmit() {
        
    let filtered = clone(data);
    filtered = Object.fromEntries(Object.entries(filtered).filter(([key, value]) => value && value !== ""));
    filtered.descriptions = filtered.descriptions.filter(val => val !== "")
    
    setLoading(true);
    const res = await updateAddonReq(filtered,id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Add-on Update Successfull.']))
    } else dispatch(setAlertMsg(['error','Failed Updating Add-on']))

  }

  function setDescription(val,i) {
    let temp = clone(data);
    temp.descriptions[i] = val;
    setData(temp)
  }
  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='bg-secondary p-4'>
        <Link to="/settings/addons" className='flex gap-4'>
          <ArrowBack />
          <h3>Edit add-on</h3>
        </Link>
        <div className='inline-block py-4'>
          <div className='flex flex-col gap-2 w-auto'>
            <TextField size='small' label='Add-on Title'
              value={data.name} onChange={(ev) => setData(data => ({...data,name: ev.target.value}))} 
            />
            <div className='flex justify-end gap-2'>
              <button className='btn1'
                onClick={() => setData(data => ({...data,descriptions: [...data.descriptions,""]}))}
              ><Add /> Description</button>
            </div>
            {data.descriptions.map((val,i) => (
              <TextField key={i} multiline label='Description' rows={2} className='min-w-[70vh]'
                value={val} onChange={(ev) => setDescription(ev.target.value,i)}
              />
            ))}
            {/* <textarea placeholder='Description' className='min-w-[50%]'></textarea> */}
            <TextField size='small' type='number' label='Price'
              value={data.price} onChange={(ev) => setData(d => ({...data,price: ev.target.value}))}
            />
            <button className='btn2' onClick={handleSubmit}>
              {loading ? <div className='load'></div> : null}
              Update Add-on</button>
          </div>
        </div>
      </div>
    </div>
  )
}
