import { Flight } from '@mui/icons-material';
import { Step, StepConnector, StepLabel, Stepper, stepConnectorClasses, styled } from '@mui/material'
import React from 'react'


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.light,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
function QontoStepIcon(props) {
  const { active, className } = props;

  return (
    <div className={className}>
      {active ? (
        <Flight className="text-theme1 rotate-90" />
      ) : (
        <div className="w-1 h-1 rounded-full inline-block bg-theme1 " />
      )}
    </div>
  );
}

export default function FlightOrderProgress({step}) {
  
  return (
    <div>
      <Stepper alternativeLabel activeStep={step || 0} connector={<QontoConnector />} className='min-w-[400px]'>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <small className='-translate-y-3 inline-block'>Flight</small>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <small className='-translate-y-3 inline-block'>Detail</small>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <small className='-translate-y-3 inline-block'>Payment</small>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  )
}
