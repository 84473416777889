import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { customerTemp } from '../../data/customer/customerData';
import PhoneNumberInput from '../form/PhoneNumberInput';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { updatePassword } from '../../controllers/user/updatePassword';
import { updateProfile } from '../../controllers/user/updateProfile';
import { clone } from '../../features/utils/objClone';
import AgentProfile from './EditAgentProfile';
// import MultipleSelect from '../form/MultiSelect';
// import { updateRole } from '../../controllers/user/updateRole';
import CountriesInput from '../form/CountriesInput';
import RolesInput from '../form/RolesInput';
import ProfilePicture from './ProfilePicture';

export default function EditProfile({userData,reload}) {
  const dispatch = useDispatch();
  const [data,setData] = useState({...customerTemp,confirmPassword: '',newPassword: ''});
  const [loading,setLoading] = useState(false);
  const [passloading,setPassLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  // const [roleloading,setRoleLoading] = useState(false);
  // console.log('userData : ',userData)

  // let roles = [
  //   {label: 'All',value: "ALL"},
  //   {label: 'Search',value: "AMADEUS_SEARCH"},
  //   {label: 'Book',value: "AMADEUS_BOOK"},
  //   {label: 'Issue Ticket',value: "AMADEUS_TICKET"},
  //   {label: 'Cance Order',value: "AMADEUS_CANCEL"},
  //   {label: 'Import',value: "AMADEUS_IMPORT"},
  //   {label: 'Refund',value: "AMADEUS_REFUND"},
  //   {label: 'ReIssue', value:"AMADEUS_REISSUE"}
  // ];

  useEffect(() => {
    if(userData)
      setData({...clone(userData),confirmPassword: '',newPassword: ''})
  },[userData])


  async function handleProfileUpdate(ev) {
    ev.preventDefault();

    let {username,...upData} = clone(data);
    
    // console.log(upData);
    upData = Object.fromEntries(
      Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
    );
      
    const {email,phoneNumber,gender,companyName,nationality,lastName,firstName,cityName,addressLine1,addressLine2} = upData;


    setLoading(true);
    const res = await updateProfile({email,phoneNumber,gender,companyName,nationality,lastName,firstName,cityName,addressLine1,addressLine2},data.id);
    setLoading(false);
    if(res.return)
      return dispatch(setAlertMsg(['success','Profile updated.']));
    else return dispatch(setAlertMsg(['error',res.msg]))


  }

  async function handlePassword(ev) {
    ev.preventDefault();


    if(data.password === "" ||
      data.newPassword === "") return dispatch(setAlertMsg(['error','Please provide Inputs!']));

    if(data.newPassword !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));

    setPassLoading(true);
    const res = await updatePassword({password: data.password,newPassword: data.newPassword},data.id);
    setPassLoading(false);
    if(res.return)
      return dispatch(setAlertMsg(['success','Passwords updated.']));
    else return dispatch(setAlertMsg(['error',res.msg]))
      
  }

  // async function handleRole(ev) {
  //   ev.preventDefault();

  //   let roleValues = data.roles.map(obj => obj.value);
  //   setRoleLoading(true);
  //   const res = await updateRole({roles: roleValues},data.id);
  //   setRoleLoading(false);
  //   if(res.return)
  //     return dispatch(setAlertMsg(['success','Roles updated.']))
  //   else return dispatch(setAlertMsg(['error',res.msg]))
  // }

  function handleInputChange(obj) {
    setData({...data,...obj})
  }


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const isCompany = (!data?.companyName || data?.companyName === 'PERSONAL') ? false : true;
console.log(isCompany)

  return (
    <div className='w-full flex flex-col gap-5'>
      <Box className='my-2'>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="General Profile" />
          {isCompany ? 
            <Tab label="Agency Profile" />
          :null}
          <Tab label="Security" />
        </Tabs>
      </Box>
      {tabValue === 0 ? 
      <form action='' onSubmit={handleProfileUpdate} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Update General Profile</h2>

          <div className='flex gap-4'>
            <TextField size='small' label='Email' name='email'
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Phone"
              value={data.phoneNumber}
              onChange={(val) => handleInputChange({phoneNumber: val})}
              />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Gender' name='Gender' select
              value={data.gender}
              onChange={(ev) => handleInputChange({gender: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}>
                <MenuItem value='MALE'>Male</MenuItem>
                <MenuItem value='FEMALE'>Female</MenuItem>
            </TextField>
            <CountriesInput size='small' label='Nationality' name='Nationality'
              value={data.nationality}
              onChange={(val) => handleInputChange({nationality: val.name || val})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex flex-col justify-center gap-4'>
            <div>
              <TextField size='small' select 
                InputLabelProps={{shrink: true}}
                value={data?.companyName}
                onChange={(ev) => handleInputChange({companyName: ev.target.value})}
                label='Account Type'>
                <MenuItem value='PERSONAL'>Personal</MenuItem>
                <MenuItem value='Agency'>Agency</MenuItem>
              </TextField>
            </div>
            {/* {isCompany ? 
              <div>
                <TextField size='small' label='Company Name' name='companyName'
                  value={data.companyName}
                  onChange={(ev) => handleInputChange({companyName: ev.target.value})}
                  InputLabelProps={{
                    shrink: true,
                  }} />
              </div>
            :null} */}
          </div>
          <h6>Contact Person</h6>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' required
              value={data.lastName}
              onChange={(ev) => handleInputChange({lastName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' required
              value={data.firstName}
              onChange={(ev) => handleInputChange({firstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='City' name='city'
              value={data.cityName}
              onChange={(ev) => handleInputChange({cityName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Address Line 1' name='addressLine1' required
              value={data.addressLine1}
              onChange={(ev) => handleInputChange({addressLine1: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Address Line 2' name='addressLine2'
              value={data.addressLine2}
              onChange={(ev) => handleInputChange({addressLine2: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>

          <button className='btn2 self-start'>
            {loading ? (
              <div className='load'></div>
            ) : null}
            Update Profile</button>
        </div>
      </form>
      : tabValue === 1 && isCompany ?
        // ["SUB_AGENT","CORPORATE"].includes(data.type) ? 
          <AgentProfile label={data.type} userData={userData} /> 
        // : null
      : tabValue === 2 || (tabValue === 1 && !isCompany) ? 
      <form action='' onSubmit={handlePassword} className='flex flex-col gap-3 py-6 px-4'>
        <h6>Change Password</h6>
        <div className='flex gap-4'>
          <TextField size='small' label='Password' type='password'
            value={data.password}
            onChange={(ev) => handleInputChange({password: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField size='small' label='New Password' type='password'
            value={data.newPassword}
            onChange={(ev) => handleInputChange({newPassword: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField size='small' label='Confirm New Password' type='password'
            value={data.confirmPassword}
            onChange={(ev) => handleInputChange({confirmPassword: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />

        </div>
        <button className='btn2 self-start'>
          {passloading ? (
            <div className='load'></div>
          ) : null}
          Update Password</button>

      </form>
      :null}
      {/* <RolesInput value={data.roles} id={userData.id} /> */}
      {/* <form action='' onSubmit={handleRole} className='flex flex-col gap-3 py-6'>
        <h6>Change Role</h6>
        <div className='flex gap-4'>
          <MultipleSelect value={data.roles} onChange={(roles) => handleInputChange({roles})} className={"min-w-[300px]"} options={roles} />
        </div>
        <button className='btn2 self-start'>
          {roleloading ? (
            <div className='load'></div>
          ) : null}
          Update Role</button>

      </form> */}
    </div>
  )
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}