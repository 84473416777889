import React, { useEffect } from 'react'
import { ArrowBack, LocationOn } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import RoomDisplay from '../../../components/hotel/RoomDisplay';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';


export default function Confirmation({back,data,step}) {
  const {tourBooking} = useSelector(state => state.tourBooking)
  const dispatch = useDispatch();

  let guests = [];
  console.log('booked data: ', data)
  // let rooms = [{booked_guests: [{title: 'Mr',first_name: 'Test',last_name: 'One',type: 'Adult'}]}]
  // booked.data.booked_rooms[0].booked_guests
  try {
    guests = (" guests: ",tourBooking.bookingData.tourProBookingData.reservation_details);

    // rooms.map(room => {
    //   room.booked_guests.map(guest => guests.push(guest))
    //   return true;
    // })
  } catch(ex) {}
  useEffect(() => {
    dispatch(setTourBooking({...tourBooking,step}))
  },[step])

  // console.log('guests : ',guests)
  console.log(data)
  return (
    <div className='inline-block p-10'>
      <div className='bg-secondary rounded-md flex flex-col '>
        {/* <RoomDisplay review /> */}
      </div>
      <div className='flex flex-col gap-5 '>
        <small className='text-green-400'>Payment has been confirmed</small>
        {/* <Link to="/search#Hotel" className='flex gap-2 items-center'>
          <ArrowBack />
          <h3>Book More</h3>
          </Link> */}
        <div className='flex items-center gap-2'>
          <img src={tourBooking?.selected?.image} className='w-14 h-14 rounded-lg' alt='' />
          <b>
            {tourBooking?.selected?.product_name}
          </b>
        </div>

        {/* <div className='flex gap-2'>
          <img src={data.photo} alt='Hotel' className='w-16' />
          <div className='p-2 flex-1'>
            <h2 className='capitalize'>{data.product_name}</h2>
            <small className='capitalize'>
              <LocationOn className='sqr5' />
              {data.small_description}</small>
          </div>
        </div> */}
        <div className='flex'>
          <div className='flex flex-col p-2 '>
            <div className='rounded-full bg-blue-500 sqr2'></div>
            <div className='vr h-full border-blue-500'></div>
            <div className='rounded-full bg-blue-500 sqr2'></div>
          </div>
          <div className='flex flex-1 flex-col gap-4 justify-between'>
            <div className=''>{moment(data.start_duration).format('ddd, DD MMM YYYY, HH:MM')}</div>
            <small>Duration {moment(data.end_duration).diff(data.start_duration,'days')} nights</small>
            <div className=''>{moment(data.end_duration).format('ddd, DD MMM YYYY, HH:MM')}</div>
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <h3>Guest</h3>
          <div className='flex gap-3'>
            {/* <div className=''>
              <button className='btn2'>One Adult</button>
            </div> */}
            <table className='flex-1'>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Gender</td>
                  <td>Birth Date</td>
                </tr>
              </thead>
              <tbody>
                {guests.map((guest,i) => (
                  <tr key={i}>
                    <td>{guest.firstName} {guest.lastName}</td>
                    <td>{guest.gender}</td>
                    <td>{guest.birthDate || guest.birhDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='flex justify-between gap-3 py-5'>
          <Link to="/search#Tour" className='btn1'>Book another tour</Link>
          <Link to="/packages" className='btn1'>View all bookings</Link>
        </div>
      </div>
    </div>
  )
}
