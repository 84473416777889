export const promoReqData = {
  "code":"",
  "expiryDate":"",
  "discountAmount": 0,
  "discountPercentage": 0,
  "multipleUsage": false,
  "usedFor":"ALL",//FLIGHT, HOTEL, TOUR
  "userType":["CUSTOMER", "SUB_AGENT", "CORPORATE"]
}

export const promoCodesData = {
  "id": "a0a7fd88-7231-450a-9be5-ace219c011b9",
  "createdDate": "2023-05-09T20:23:38.550Z",
  "updatedDate": "2023-05-09T20:23:38.564Z",
  "code": "HOLIDAY",
  "expiryDate": "2025-01-01T00:00:00.000Z",
  "discountPercentage": 0,
  "discountAmount": 1000
}