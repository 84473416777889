import { Cancel } from "@mui/icons-material";
import { useMemo, useState } from "react";
import Collapse from "../../../components/mini/Collapse";
import { MenuItem, Rating, Tab, Tabs, TextField } from "@mui/material";
import FilterTravelLuggage from "../../../components/flight/filters/TravelLuggage";
import FilterCabin from "../../../components/flight/filters/FilterCabin";
import FilterStops from "../../../components/flight/filters/FilterStops";
import FilterExCountries from "../../../components/flight/filters/FilterExCountries";
import FilterPrice from "../../../components/flight/filters/FilterPrice";
import FilterAirlines from "../../../components/flight/filters/FilterAirlines";
import FilterTime from "../../../components/flight/filters/FilterTime";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decrypt, encrypt } from "../../../features/utils/crypto";
import { clone } from "../../../features/utils/objClone";


export default function HotelOfferFilter({orgi,data,setData}) {
  const [filters,setFilters] = useState({});
  let count = Object.entries(filters).filter(([key,val]) => val).length;
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const qObj = q && JSON.parse(decrypt(clone(q)));

  
  // useEffect(() => {
  //   console.log('filters: ',filters)
  // },[filters])


  function filterByCabin(data,objs) {
    // let newData = data.filter(obj => {
    //   if(objs.name === "") return true;
    //   if(obj.segments) {
    //     if(obj.segments[0].cabin)
    //       return obj.segments[0].cabin.toLowerCase() === objs.name.toLowerCase();
    //   }
    //   return false;
    // });

    let newQObj = qObj;
    newQObj.travelClass = objs.name
    // console.log('cabin: ',objs);
    // console.log('qObj: ',newQObj);

    let enc = encrypt(JSON.stringify(newQObj));
    navigate(`/search/flight/offers?q=${enc}`);

    return [];
  }

  function filterByStops(data,objs) {
    let newData = data.filter(obj => {
      if(obj.segments) {
        if(!objs.allowOv && obj.segments.every(item => item.numberOfStops > 0 && item.flights.every((flight,ind,arr) => {
          if (ind > 0) {
            const prevFlight = arr[ind - 1];
            const prevArrivalTime = prevFlight.arrivalTime;
            const currentDepartureTime = flight.departureTime;
            const timeDiff = new Date(`2000-01-01T${currentDepartureTime}:00`) - new Date(`2000-01-01T${prevArrivalTime}:00`);
            const hoursDiff = timeDiff / (1000 * 60 * 60);
            const isOvernightStopover = (
              hoursDiff >= 12 &&
              (prevArrivalTime < "06" || prevArrivalTime >= "18" || currentDepartureTime < "06" || currentDepartureTime >= "18")
            );
            return isOvernightStopover;
          }
          return true;
        })))
          return false;

        if(objs.data.name === "") return true;
          
        if(objs.data.name === 'nonstop')
          return obj.segments.every(item => item.numberOfStops === 0)
        else if(objs.data.name === "<=1")
          return obj.segments.every(item => item.numberOfStops <= 1)
        else if(objs.data.name === "<=2")
          return obj.segments.every(item => item.numberOfStops <= 2)
      }
      return false;
    })
    
    return newData;
  }

  function filterByCountry(data,objs) {
    let newData = data.filter(obj => {
      if(obj.segments)
        if(obj.segments.every(item => item.flights.every((flight) => objs.every((d) => !d.value || (d.alpha !== flight.arrivalCountryCode )))))
          return true;
      
      return false;
    })

    return newData;
  }
  function filterByAirline(data,objs) {
    let newData = data.filter(obj => {
      if(obj.segments)
        if(obj.segments.every(item => item.flights.every((flight) => objs.every((d) => !d.value || (d.name === flight.carrierName)))))
          return true;
      
      return false;
    })

    return newData;
  }
  function filterByPrice(data,obj) {
    if(obj.price === obj.max) return data;

    let newData = data.filter(offer => {
      return parseInt(offer.adult_price) <= obj.price;
    })

    return newData;
  }

  function setFilteredData(filter) {
    console.log('got Filter',filter);
    let datas = orgi;
    let res = Object.entries({...filters,...filter}).reduce((data,[key,val]) => {
      if(key === 'cabin')
        return filterByCabin(data,val)
      else if(key === 'stops')
        return filterByStops(data,val);
      else if(key === 'exCant')
        return filterByCountry(data,val);
      else if(key === 'price')
        return filterByPrice(data,val);
      else if(key === 'airlines')
        return filterByAirline(data,val);
      
      return data
    },datas)
    
    setFilters({...filters,...filter});
    setData(res);
    return res;
  }

  function clearFilter() {
    setFilters({})
    setData(orgi);
  }

  return (
    <div className='flex flex-col gap-5 p-6 max-w-[300px] min-h-[80vh]'>
      <div className='flex gap-6 justify-between items-center'>
        <h6>{count} filter Active</h6>
        {count > 0 ? (
          <button onClick={clearFilter}>
            <Cancel className='w-3 h-3' />
            &nbsp; Clear Filters
          </button>
        ) : null}
      </div>

      <FilterPrice min={1} max={100000} returnData={(obj) => setFilteredData({price: obj})} />
      <hr />
      {/* <Collapse label={<h4>Categories</h4>} show={true}>
        <div className="flex flex-col gap-2">
          <label className="flex gap-2 whitespace-nowrap cursor-pointer">
            <input type='radio' name='tourCat' />
            Category one
          </label>
          <label className="flex gap-2 whitespace-nowrap cursor-pointer">
            <input type='radio' name='tourCat' />
            Category two
          </label>
          <label className="flex gap-2 whitespace-nowrap cursor-pointer">
            <input type='radio' name='tourCat' />
            Category three
          </label>
        </div>
      </Collapse> */}
    </div>
  )
}