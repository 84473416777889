import React, { useEffect, useState } from 'react'
import { ContentCopy, Logout, MenuOpen, NotificationsOutlined } from '@mui/icons-material'
import { Avatar, List, ListItemText, ListSubheader, Menu, MenuItem, MenuList, TextField } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderTitle } from '../redux/reducers/titleSlice'
import ListItem from './mini/ListItem'
import { getMyPoints } from '../controllers/user/getMyPoints'
import ModalLocal from './mini/ModalLocal'
import { redeemPointReq } from '../controllers/user/redeemPoint'
import { setAlertMsg } from '../redux/reducers/modal/snackBarSlice'
import { formatMoney } from '../features/utils/formatMoney'
import { def, path } from '../config'
import { logout, setUser, setUserData } from '../redux/reducers/user/userSlice'
import getNotifications from '../controllers/Notifications/getNotifications'
import { getUser } from '../controllers/user/getUser'
import CurrencyManagment from './CurrencyManagment'
import { getReferralCode } from '../controllers/user/getReferral'


export default function Header() {
  const location = useLocation();
  const [menuOpen,setMenuOpen] = useState(false);
  const {user} = useSelector((state) => state.user.userData);

  let pathname = location.pathname.split("/");
  pathname = (pathname[1]);

  const {title} = useSelector((state) => state.title)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle(pathname))
  },[pathname,dispatch])


  function handleMenuOpen(event) {
    setMenuOpen(!menuOpen);  
  }

  return (
    <div className='p-3 bg-secondary'>
      <div className='flex justify-between items-center'>
        <div className='px-10'>
          <h3 className='capitalize'>{title || "Dashboard"}</h3>
        </div>
        <div className='flex-1 flex justify-center'>
          {/* <div className='self-center w-1/2 relative text-[#1362FC]'>
            <SearchInput />
          </div> */}
        </div>
        <div>
          <div className='flex items-center gap-4 relative'>
            <div>
              <CurrencyManagment />
            </div>
            <Notification />
            <div className='relative'>
              <div className='px-2 flex cursor-pointer gap-2 items-center relative' onClick={handleMenuOpen}>
                <div>
                  <Avatar className='uppercase'>{user.firstName[0]}</Avatar>
                </div>
                <div className='flex flex-col  justify-between'>
                  <h5 className='capitalize'>{user.firstName}</h5>
                  <small>{user.type}</small>
                </div>
              </div>
              <AccountMenu opened={menuOpen} close={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Notification() {
  const [data,setData] = useState([]);
  const [open,setOpen] = useState(false);

  useEffect(() => {
    load();
  },[])
  async function load() {
    const res = await getNotifications();
    if(res.return) {
      let data = res.data;
      setData(data);
    }
  }
  return (
    <div>
      <div className='rounded-full w-10 h-10 flex justify-center items-center shadow-md  cursor-pointer hover:shadow-primary/30'
       onClick={() => setOpen(true)}
      >
        <NotificationsOutlined className='text-theme1' />
      </div>
      <div className={`absolute top-full z-10 p-2 px-4 mt-3 min-w-[230px] right-0  flex flex-col gap-2 bg-secondary w-full shadow-xl ${open ? '' : 'hidden'}`}>
        <div className='flex gap-4 flex-wrap justify-between w-full'>
          <div className='flex flex-col gap-2 w-full'>
            <h5>Notification</h5>
            <hr />
            <div className='flex flex-col gap-2 max-h-[calc(100vh-200px)] overflow-hidden overflow-y-auto'>
              {data.map((obj,i) => (
                <div className='flex flex-col gap-1 text-xs max-w-[400px] border-b'>
                  <b className='capitalize'>{obj.title}</b>
                  <p>{obj.body}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed top-0 left-0 w-full h-full z-0 ${open ? '' : 'hidden'}`} onClick={() => setOpen(false)}></div>
    </div>
  )
}

function DropDown({children,label}) {
  const [open,setOpen] = useState(false);
  return (
    <div className='flex relative flex-col gap-2 p-2 ' onClick={() => setOpen(!open)}>
      {label}
      <div className={` ${open ? '':' hidden '}`}>
        {children}
      </div>
    </div>
  )
}

const AccountMenu = ({opened,close}) => {
  const [point,setPoint] = useState();
  const [redeemModal,setRedeemModal] = useState(false);
  const {user} = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  
  let wallet;
  try {
    wallet = user.wallet[0].amount
  } catch(ex) {}

  useEffect(() => {
    fetchPoint();
    if(opened) {
      loadUser();
    }
  },[opened])


  async function loadUser() {
    await getReferralCode();
    const res = await getUser("me");
    if(res.return) {
      dispatch(setUser(res.data));
    }
  }

  async function fetchPoint() {
    const res = await getMyPoints()
    if(res.return) {
      setPoint(res.data.points.points)
    }
  }

  function copyRef(id) {
    let input = document.getElementById(id);

    let text = document.createElement('textarea');
    text.value = input.value

    document.body.appendChild(text)

    text.select();
    text.setSelectionRange(0, text.value.length);
    document.execCommand('copy')

    dispatch(setAlertMsg(['success','Embed url copied.']))

    document.body.removeChild(text)
  } 
  return [
    <div className={`absolute top-full z-10 p-2 px-4 mt-3 min-w-[230px] right-0  flex flex-col gap-2 bg-secondary w-full shadow-md ${opened ? '' : 'hidden'}`}>
      <div className='flex gap-4 flex-wrap justify-between'>
        <div>
          <small className='text-gray-400'>Current Level</small>
          <h6 className='capitalize'>{user.userLevel}</h6>
        </div>
        <div>
          <small className='text-gray-400'>Target Level</small>
          <h6 className='capitalize'>{user.targetLevel}</h6>
        </div>
      </div>
      <div>
        <small className='text-gray-400'>Wallet</small>
        <h5>{def.currency}{(formatMoney(parseInt(wallet) || 0))}</h5>
      </div>
      <hr />
      <div>
        <small className='text-gray-400'>My Points</small>
        <h5>{point ? point : '-'}</h5>
      </div>
      <div className='flex gap-1 items-center overflow-hidden py-2'>
        <button className='btn2 flex-1 small !whitespace-nowrap' onClick={() => setRedeemModal(true)}>Redeem Points</button>
      </div>
      <ModalLocal open={redeemModal} setOpen={setRedeemModal}>
        <RedeemPoints returnData={() => fetchPoint()} />
      </ModalLocal>
      <div className='flex flex-wrap gap-2 justify-between items-center relative my-2' onClick={() => copyRef('refCode')}>
        <sup className='absolute'><small>Referral Code</small></sup>
        <input type='hidden' id={'refCode'} value={`${path.site}/search/embed?refCode=${user.referralCode}&userId=${user.id}`} />
        <input type='text' contentEditable={false} className='flex-1 border-none max-w-full ' value={`${path.site}/search/embed?refCode=${user.referralCode}` || "--"} />
        <ContentCopy className='text-sm cursor-pointer' />
      </div>
      <button className='self-end flex gap-4 justify-between '
        onClick={() => dispatch(logout())}
      >Logout <Logout /></button>
    </div>,
    <div className={`fixed top-0 left-0 w-full h-full z-0 ${opened ? '' : 'hidden'}`} onClick={close}></div>
  ]
};

function RedeemPoints({returnData}) {
  const [points,setPoints] = useState();
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  async function redeemPoint() {
    setLoading(true)
    const res = await redeemPointReq({points});
    setLoading(false)
    if(res.return) {
      dispatch(setAlertMsg(['success','Points Redeemed.']))
      if(res.data.updatedUser)
        dispatch(setUser(res.data.updatedUser))
      if(returnData)
        returnData();
    } else dispatch(setAlertMsg(['error',res.msg]))
  }
  return (
    <div className='bg-secondary p-6 rounded-xl shadow-md flex flex-col gap-3'>
      <TextField size='small' type='number' label='Amount' value={points} onChange={(ev) => setPoints(ev.target.value)} />
      <button className='btn2 flex-1 !whitespace-nowrap flex gap-2 items-center' onClick={() => redeemPoint(true)}>
        {loading ? <div className='load'></div> : null}
        Redeem Points</button>
      
    </div>
  )
}