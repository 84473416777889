import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Rating, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import { Favorite, LocationOn, Share } from '@mui/icons-material'
import RoomDisplay from './RoomDisplay'
import { templateDetails } from '../../data/hotel/detail'
import { getCurrencySymbol } from '../../features/utils/currency'
import { def } from '../../config'
import { formatMoney } from '../../features/utils/formatMoney'
import { clone } from '../../features/utils/objClone'
import GoogleMapReact from 'google-map-react'

function makeNum(numString) {
  return Number(numString?.toString()?.replaceAll(',',''))
}

export default function HotelDetail({next,back,loading,data}) {
  // let temp = hotelDataTemp;
  const [templated,setTemplated] = useState(templateDetails(data));
  // const perNightPrice = Number(templated?.price?.replaceAll(',','') || 0) / Number(templated?.nights)
  const [readMore,setReadMore] = useState(false);
  let lowestPrice = clone(data?.rooms || [])?.sort((p,c) => makeNum(p.price) - makeNum(c.price))?.map(obj => obj?.price)[0] || 0;
  console.log(data)

  const kkk = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA'
  // console.log(" 00 ",templated)
  useEffect(() => {
    setTemplated(data);
  },[data])

  function goBack() {
    window.history.back();
  }

  // const kkk = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA'
  const {longitude,latitude} = data?.position || {};
  const [center,setCenter] = useState({
    // lat: 10.99835602,
    // lng: 77.01502627
    lat: parseFloat(latitude || 0),
    lng: parseFloat(longitude || 0)
  })
  console.log('center: ',center)

  return (
    <div className='flex flex-col gap-3 flex-1'>
      <Breadcrumbs separator=">" className='text-xs'>
        <span className='cursor-pointer' onClick={goBack}>Hotels</span>
        <span className='capitalize'>{templated.name.toLowerCase()}</span>
      </Breadcrumbs>
      <div className='bg-secondary rounded-md flex flex-col '>
        <div className='flex flex-col gap-2 py-5 flex-1'>
          <div className='flex gap-2 justify-between items-center'>
            <div className='flex gap-4 items-center'>
              <h3 className='capitalize'>{templated.name.toLowerCase()}</h3>
              {/* <Rating readOnly value={5} size='small' /> */}
            </div>
            <div className='flex flex-col'>
              <small className='self-start'>starting from</small>
              <div className='flex'>
                <h4>{def.currency}{formatMoney(makeNum(lowestPrice)/(data.nights || 1))}</h4>
                /night
              </div>
              <small className='self-end'>{def.currency}{formatMoney(lowestPrice || templated?.price)}</small>
              <small className='self-end'>excl tax</small>
            </div>
          </div>
          <div className='flex justify-between items-center gap-4'>
            <div>
            <p className='capitalize'><LocationOn fontSize='small'  /> {templated.address.toLowerCase()} </p>
            {/* <div className='flex gap-2 items-center'>
              <div className='border border-theme1 p-1 text-xs'>{((571 / 1000) * 5).toFixed(1)}</div>
              <b>Very Good</b>
              <p>371 Reviews</p>
            </div> */}
            </div>
            <div className='flex justify-end px-2 gap-2 items-center'>
              {/* <div className='border border-theme1 p-1 sqr8 flex items-center justify-center text-[14px]'><Favorite fontSize='inherit' /></div> */}
              <div className='border border-theme1 p-1 sqr8 flex items-center justify-center text-[14px]'>
                <Share fontSize='inherit' />
              </div>
              {/* <button onClick={next} className=' btn2 '>Book now</button> */}
            </div>
          </div>
        </div>
        <div className='flex gap-2'>
          <div className='flex-1 grid grid-cols-3 gap-2' style={{ gridTemplateColumns: '50% auto auto' }}>
            {data.images && (data.images).slice(0,5).map((obj,i) => (
              <img key={i} src={obj.image} alt={obj.title} className={` object-cover w-full h-full ${i===0?' row-span-2 col-span-1 ':' col-span-1 row-start-auto '}`} />
            ))}
          </div>
        </div>
      </div>
      <div className='flex flex-col '>
        <Tabs indicatorColor='text-primary' textColor='inherit' value={0} scrollButtons allowScrollButtonsMobile variant='scrollable' 
          className='div_mid w-full flex '>
          {
            ["Overview","Rooms","Location","Amenities","Policies"].map((name,i) => (
              <Tab key={i} className=' flex items-start justify-start ' 
                // onClick={() => sortByCat(obj[1])}
                label={(
                  <h6>
                    <Link to={`#${name}`} className='text-start'>
                      {name}
                    </Link>
                  </h6>
                )}
              />
            ))
          }
        </Tabs>
        <hr className='mb-3'/>
        <div id="Overview" className='flex flex-col gap-2'>
          <h3>Overview</h3>
          <p className={`px-2 text-sm ${readMore ? '' : ' overflow-hidden max-h-[6.2rem] text-ellipsis '} `}
          >
            Starting at 40 sq. m., our contemporary guest rooms include HDTV, complimentary WiFi and a private balcony. Executive rooms and suites are located on higher floors and feature additional amenities, more space, and espresso machine. Guests in executive rooms and suites can also enjoy complimentary breakfast and daily refreshments, exclusive lounge access, complimentary WiFi and private check-in/check-out services. Our exclusive concierge will help maximize your stay. The Kids Club offers activities for children 12 and younger, while family-friendly amenities help make your stay comfortable. Indulge with expansive amenities, including our 24-hour Health Club, outdoor swimming pool overlooking the ocean, and eForea Spa with Turkish Hammam, female and male treatment rooms. There is also a hairdresser, barber, and gift shop with souvenirs, resort wear and more. Savor flavors from around the world, vibrant nightlife and exquisite cocktails at our nine restaurants and bars. From casual poolside drinks to adventurous menus, theres an array of choices to delight your palate. Room service is available around the clock, and a convenience shop offers snacks and drinks. Host stunning events, weddings, and professional meetings in our spacious Ballroom or on our panoramic outdoor Ocean Terrace. With plenty of meeting space, A/V support, floral specialists, and event planning services, our Hilton hotel can accommodate events of up to 800 guests.
            {templated.details.text}
          </p>
          <small className='px-2 cursor-pointer' onClick={() => setReadMore(!readMore)}>{readMore ? 'show Less' : 'Read more'}</small>
        </div>
        <hr className='my-3'/>
        <div id="Rooms">
          <h3>Available Rooms</h3>
        </div>
        <hr className='my-3'/>
        {loading?
          <div className='flex justify-center items-center p-2 border-[#333]'>
            <div className='load'></div>
          </div>
        :!data?.rooms || !data?.rooms?.length?
          <div className='flex justify-center items-center p-2 border-[#333]'>
            <div >No rooms returned</div>
          </div>
        :null}
        <div className='flex flex-col gap-4'>
          {
            data.rooms && (data.rooms).slice(0,5).map((obj,i) => (
              <RoomDisplay key={i} data={obj} />
            ))
          }
        </div>
        <hr className='my-3' />
        <div id="Location" >
          <h3>Location / Map</h3>
        </div>
        <div className='w-full h-[50vh]'>
          <GoogleMap center={center} />
          {/* <iframe title='location' 
          // src={`https://www.google.com/maps/embed/v1/place?key=${kkk}&q=${latitude},${longitude}`}
          src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.401237414226!2d-76.61185898424692!3d39.290385379508435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c80e1f6c01e1ab%3A0x8a1a0d2ea5a325e7!2sWhite%20House!5e0!3m2!1sen!2sus!4v1624883266576!5m2!1sen!2sus"}  
          className='border-0 w-full h-full min-h-[200px]' allowFullScreen="" loading="lazy"></iframe> */}
        </div>
        <hr className='my-3' />
        <div id='Amenities'>
          <h3>Amenities</h3>
        </div>
        <div className='grid grid-cols-2 p-5 gap-2'>
          {templated.details.facilities.map((obj,i) => (
            <span key={i} className='flex gap-2 items-center'>
              <img src={obj.icon} alt={obj.code} className='sqr4 object-cover' />
              {obj.title}
            </span>
          ))}
        </div>
        <hr className='my-3' />
        <div id='Policies'>
          <h3>Policies</h3>
        </div>
        <div className='grid grid-cols-2 p-5 gap-2'>
          {templated?.texts?.map((obj,i) => 
            obj?.title === 'General Policy Description' && (
              <span key={i} className='flex gap-2 items-center'>
                {obj.text}
              </span>
          ))}
        </div>
      </div>
    </div>
  )
}



function GoogleMap({center: gotCenter}) {
  const [center,setCenter] = useState({lat: 0,lng: 0})

  useEffect(() => {
    console.log(" ==> ",gotCenter);
    setCenter(gotCenter)
  },[gotCenter])

  return center.lat && center.lng ? (
    <GoogleMapReact
      className=' w-full h-full min-h-[200px]'
      bootstrapURLKeys={{ key: "AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA" }}
      center={center}
      defaultCenter={center}
      defaultZoom={11}
      onChange={({center}) => setCenter(center)}
    >
      {/* <PinComponent lat={center.lat} lng={center.lng} /> */}
      {/* <AnyReactComponent
        lat={parseFloat(latitude)}
        lng={parseFloat(longitude)}
        // text={"here"}
        className='w-[15px] h-[15px] rounded-full bg-red-500'
      /> */}
    </GoogleMapReact>
  ) : null
}