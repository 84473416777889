import mergeRecursive from "../../features/utils/mergeRecursive";

export const packageTemp =     {
  "id": "",
  "packageInfoData": {
    "__v": "",
    "_id": "",
    "mark": "",
    "hotel": "",
    "photo": "",
    "flight": "",
    "details": "",
    "activity": "",
    "approved": "",
    "category": "",
    "latitude": "",
    "farePrice": {
      "fare_adult_price": "",
      "fare_child_price": "",
      "fare_infant_price": 5000
    },
    "image_key": "",
    "longitude": "",
    "created_by": [
      {
        "__v": "",
        "city": "",
        "email": "",
        "phone": "",
        "last_name": "",
        "first_name": "",
        "website_url": "",
        "detailed_info": "",
        "nature_of_business": "Sports"
      }
    ],
    "exclusions": "",
    "highlights": "",
    "inclusions": "",
    "add_to_pool": "",
    "adult_price": "",
    "child_price": "",
    "infant_price": "",
    "product_code": "",
    "product_name": "",
    "sub_category": [
      {
        "subcategory_name": "sub cat 1"
      }
    ],
    "collection_id": "",
    "short_address": "",
    "detailed_address": "",
    "long_description": "",
    "number_of_adults": "",
    "product_category": "",
    "number_of_infants": "",
    "small_description": "",
    "number_of_children": "",
    "terms_and_conditions": "",
    "product_stop_duration": "",
    "product_start_duration": "Monday, April 18,2023"
  },
  "packageType": "",
  "originalPriceForAdult": "",
  "originalPriceForChild": "",
  "originalPriceForInfant": "",
  "farePriceForAdult": "",
  "farePriceForChild": "",
  "farePriceForInfant": "",
  "numberOfAdults": "",
  "numberOfChildren": "",
  "numberForInfants": "",
  "totalOriginalPrice": "",
  "totalFarePrice": "",
  "tourProBookingId": "",
  "tourProBookingData": {
    "__v": "",
    "_id": "",
    "status": "",
    "tour_id": "",
    "customer_id": "",
    "booking_date": "",
    "payment_time": "",
    "booking_source": "",
    "payment_status": "",
    "mode_of_payment": "",
    "booking_comments": "",
    "number_of_adults": "",
    "internal_comments": "",
    "number_of_infants": "",
    "number_of_children": "",
    "prices_and_rates_id": "",
    "number_of_participants": 1
  },
  "status": "",
  "expiryDate": "",
  "userId": "",
  "bookedByAdminId": "",
  "bookedBy": {
    "id": "",
    "username": "",
    "firstName": "",
    "email": "",
    "lastName": "",
    "password": "",
    "otp": "",
    "profile": "",
    "isAdmin": "",
    "isSuperAdmin": "",
    "isActive": "",
    "phoneNumber": "",
    "type": "",
    "customSettingid": "",
    "gender": "",
    "companyName": "",
    "addressLine1": "",
    "addressLine2": "",
    "postalCode": "",
    "cityName": "",
    "countryCode": "",
    "createdDate": "",
    "updatedDate": "2022-11-07T05:46:29.326Z"
  },
  "_count": {
    "Transaction": 0
  }
}

export function templatePackage(obj) {
  let data = mergeRecursive({...obj},packageTemp);
  return {...data,
    customer: data.bookedBy.firstName+ " "+data.bookedBy.lastName,
    bookingDate: data.createdDate,
    packageName: data.packageInfoData.product_name,
    amount: data.totalFarePrice,
    status: data.status
  };
}
