import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  open: false,
  msg: [],
  type: ''
}

export const snackBar = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    setSnackOpen: (state,action) => {
      state.open = action.payload;
    },
    setAlertMsg: (state,action) => {
      state.open = true;
      state.type = action.payload[0];
      // let msg = [...state.msg].slice(-1);
      // (msg[0] !== action.payload[1]) && msg.push(action.payload[1]);
      // console.log('apped',msg);
      state.msg = [action.payload[1]];
    }
  }
})

export const {setSnackOpen,setAlertMsg} = snackBar.actions

export default snackBar.reducer;