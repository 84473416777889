import React, { memo, useCallback, useEffect, useState } from 'react'
import Stats from '../../components/mini/Stats'
import { AccountBalanceWalletOutlined, AirplanemodeActiveOutlined, PersonOutline } from '@mui/icons-material'
import {ReactComponent as TransactionIcon} from '../../assets/icons/creditlimit.svg'
import { getUsers } from '../../controllers/user/getUsers'
import { getBookings } from '../../controllers/flight/getBookings'
import { getTransactions } from '../../controllers/transactions/getTransactions'
import { getPassengers } from '../../controllers/user/getPassengers'


let lists = [
  {label: 'Customers',amount: 0,icon: <PersonOutline />},
  {label: 'All Bookings',amount: 0,icon: <AirplanemodeActiveOutlined />},
  // {label: 'Issued Tickets',amount: 0,icon: <AirplanemodeActiveOutlined />},
  {label: 'All Transactions',amount: 0,icon: <AirplanemodeActiveOutlined />},
  {label: 'Received Payments',amount: 0,icon: <TransactionIcon className='w-5 h-5' />},
  // {label: 'Pending Bookings',amount: 0,icon: <AirplanemodeActiveOutlined />},
  // {label: 'Cancelled Bookings',amount: 0,icon: <AirplanemodeActiveOutlined />},
  {label: 'Pending Payments',amount: 0,icon: <TransactionIcon className='w-5 h-5' />},
  {label: 'Wallet Payments',amount: 0,icon: <AccountBalanceWalletOutlined />},
] 

function Flights({bookings}) {
  const [data,setData] = useState(lists);

  const getBookingsTotal = useCallback(() => {
    let totalPending = 0;
    let totalCancelled = 0;
    let totalExp = 0;
    let totalPaid = 0;
    let totalTicketed = 0;

    // console.log('got bookings',bookings)

    bookings.map(d => {
      if(d.status === "PENDING_PAYMENT")
        totalPending++;
      else if(d.status === 'CANCELLED')
        totalCancelled++;
      else if(d.status === 'EXPIRED')
        totalExp++;
      else if(d.status === 'PAID_FOR')
        totalPaid++;
      else if(d.status === 'TICKETED')
        totalTicketed++;

        
        return true;
    })

    return ({totalPending,totalCancelled,totalExp,totalPaid,totalTicketed})

  },[bookings])

  useEffect(() => {
    

    async function loadOrders() {
      let res = getBookingsTotal();
      console.log('stat',res.totalPending)
      
      setData(d => {
        let temp = d;
        temp[2].amount = res.totalTicketed;
        temp[4].amount = res.totalPaid;
        temp[5].amount = res.totalPending;
        temp[6].amount = res.totalCancelled;
        return temp
      });
    }
    // loadOrders();
    

  },[bookings,getBookingsTotal])
  
  useEffect(() => {
    
    loadCustomers();
    loadBookings();
    loadTransactions();

    async function loadTransactions() {
      const res = await getTransactions();
      let totalTrans = 0;
      // console.log('res',res)
  
      if(res.return) {
        totalTrans = res.data.length;

        let totalPending = 0;
        let totalCancelled = 0;
        let totalSuccess = 0;
        let totalWallet = 0;

        res.data.map(d => {
          if(d.transactionStatus === 'PENDING')
            totalPending++;
          else if(d.transactionStatus === 'CANCELLED')
            totalCancelled++;
          else if(d.transactionStatus.toLowerCase() === 'success')
            totalSuccess++;
          else console.log('--------------------',d.transactionStatus)
          if(d.from === 'WALLET')
            totalWallet++;
          return true;
        })
 
        // console.log('total Pending',totalPending);

        
        setData((d) => {
          let temp = d;
          temp[2].amount = totalTrans;
          temp[3].amount = totalSuccess;
          temp[4].amount = totalPending;
          temp[5].amount = totalWallet;

          
          return temp
        })
      }
  
  
    }
    async function loadBookings() {
      const res = await getBookings();
      let totalBooking = 0;
      // console.log('res',res)
  
      if(res.return) {
        totalBooking = res.data.length;
        
        setData((d) => {
          let temp = d;
          temp[1].amount = totalBooking;
          return temp
        })
      }
  
  
  
    }
    async function loadCustomers() {
      // let q = {
      //   type: 'CUSTOMER'
      // }
      const res = await getPassengers();
      let totalCustomers = 0;
      // console.log('res',res)
      if(res.return)
        totalCustomers = res.data.length;
  
      setData((d) => {
        let temp = d;
        temp[0].amount = totalCustomers;
        return temp
      })

  
    }
  
  },[])


  return (
    <div className='text-gray-700 bg-secondary p-4'>
      <h5 className='py-3'>Overview</h5>
      <div className='flex gap-4 flex-wrap '>
        {
          data.map((list,ind) => (
            <Stats data={list} key={ind} />
          ))
        }
      </div>
    </div>
  )
}

export default memo(Flights)