import React from 'react'

export default function FlightInfo({data}) {
  return (
    <div className='flex flex-col gap-3'>
      <h5>Flight Information</h5>
      <hr />
      <div className='flex flex-wrap gap-6 justify-between px-2'>
        {data.map((flight,i) => (
          <div key={i}>
            <div className='flex gap-3'>
              <img alt='turkish' src={flight.booked_segments[0].carrierIcon} width={20} height={17} />
              {flight.airline_detail.name}
            </div>
            <b className='py-2 inline-block'>
              {flight.origin.location} - {flight.destination.location}
              <span className='px-2 inline'>on</span>
              {(new Date(`${flight.origin.date}T${flight.origin.time}`).toDateString())} {flight.origin.time}
            </b>
            <div className='p-2 font-bold flex gap-7'>
              <small className=''>{flight.cabin}  {flight.class || data.bookingClass}</small>
              <small className=''>{flight.weightUnit || data.weightUnit} {flight.weightType || data.weightType}</small>
            </div>
          </div>
        ))}
      </div>
      <hr />
    </div>
  )
}
