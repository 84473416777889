import { ArrowDropDownOutlined, ArrowRightOutlined } from '@mui/icons-material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleSidebar } from '../../redux/reducers/sidebar/sidebarSlice';

export default function ListItem(props) {
  const {children,label,icon,show} = props;
  const [childShow,setchildShow] = useState(show);
  const {collapse} = useSelector((state) => state.sidebar);

  const dispatch = useDispatch();

  const childs = Array.isArray(children) ? children : [children];

  const location = useLocation();

  let pathname = location.pathname.split("/");
  pathname = (pathname[1]).toLowerCase();
  // pathname = pathname === "" ? "/" : pathname;

  const LinkComp = ({children}) => props.to ? (
    <Link to={props.to || "/"} className='flex-1 cursor-pointer flex gap-3 justify-center'>
      {children}
    </Link>
  ) : (
    <div className='flex-1 cursor-pointer flex gap-3 justify-center'>{children}</div>
  )
  
  return (
    <div onClick={props.onClick}>
      <div className={'flex gap-3 rounded-2xl items-center '+(props.className)+
        ("/"+pathname === props.path ? ' bg-gradient-to-r from-theme1/20 to-theme1/75 ' : '')}
        onClick={() => {dispatch(toggleSidebar(!children)); setchildShow(!childShow);}}>
          
        <LinkComp to={props.to || "/"}>
          {icon?(
            <div className='px-1 w-5 h-5 flex justify-center items-center'>
              {icon}
            </div>
          ):null}
          {
            collapse ? null : (
              <div className='flex-1 whitespace-nowrap'>
                {label}
              </div>
            )
          }
        </LinkComp>
        {children && !collapse ? 
          !childShow ? <ArrowRightOutlined /> : <ArrowDropDownOutlined />
          : null}
      </div>
      {children && !collapse ? (
        <div className={'flex flex-col p-2 overflow-hidden '+(childShow ? '' : 'hidden')}>
          {childs.map((elem,ind) => (
            <div className='flex self-stretch hover:text-theme1 py-1' onClick={() => dispatch(toggleSidebar(true))} key={ind}>
              <span className='px-1 w-6 flex justify-center'><ArrowRightOutlined /></span>
              <span className='whitespace-nowrap flex-1 flex flex-col !items-stretch'>{elem}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
