import { TextField } from '@mui/material'
import React from 'react'
// import { bookedHotelDataTemp } from '../../../data/hotel/bookedDetail'

export default function HotelDetails({data}) {
  // let temp = bookedHotelDataTemp;
  let temp = data;

  return (
    <div>
      <div>
        {/* <div className='text-end py-2'>
          <TextField select label="Manage this order" size='small' sx={{minWidth: 180}}>
            <></>
          </TextField>
        </div> */}
        <hr />
        <div className='mt-3 px-2'>
          <h4>{temp?.hotelData?.name}</h4>
          <small>{temp?.hotelData?.address}</small>
        </div>
        <div className='px-2'>
          <h4 className='my-4'>Details</h4>
          <div className=''>
            <div className='flex gap-2'><h5>Hotel Booking id: </h5> {temp?.hotelData?.code}</div>
            <div className='flex gap-2'><h5>Control Number: </h5> {temp?.hotelData?.control_number}</div>
            {/* <div className='flex gap-2'><h5>Description: </h5> {temp.hotelData.}</div> */}
            {/* <div className='flex gap-2'><h5>cancellation: </h5> {temp.hotelD}</div> */}
            {/* <div className='flex gap-2'><h5>guarenteed: </h5> {temp.bookingData.guarenteed}</div> */}
            <div className='flex gap-2'><h5>type: </h5> {(temp?.hotelData?.booked_rooms || temp?.hotelData?.bookedRooms)?.map(room => `[${room.type}] `)}</div>
            {/* <div className='flex gap-2'><h5>currency: </h5> {temp.}</div> */}
            {/* <div className='flex gap-2'><h5>rate: </h5> {temp.bookingData.rate}</div> */}
            {/* <div className='flex gap-2'><h5>ratePlan: </h5> {temp.bookingData.ratePlan}</div> */}
            <br />
            Total <h5>{temp?.hotelData?.price}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}
