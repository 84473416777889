import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../redux/reducers/titleSlice';
import { TextField } from '@mui/material';
import { agentSignupTemp } from '../data/user/agentSignupData';
import PhoneNumberInput from '../components/form/PhoneNumberInput';
import { setAlertMsg } from '../redux/reducers/modal/snackBarSlice';
import { signupReq } from '../controllers/user/subAgentSignup';
import ModalLocal from './mini/ModalLocal';
import Terms from './Terms';
import Policy from './Policy';

export default function Signup({login}) {
  const dispatch = useDispatch();
  const [data,setData] = useState(agentSignupTemp);
  const [loading,setLoading] = useState(false);

  const [termsOpen,setTermsOpen] = useState(false);
  const [policyOpen,setPolicyOpen] = useState(false);


  useEffect(() => {
    dispatch(setHeaderTitle("Sub Agents"))
  },[dispatch])

  async function createAgent(ev) {
    ev.preventDefault();

    if(data.password !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));
      
      // console.log(data)
      setLoading(true);
      const res = await signupReq(data)
      setLoading(false);
      if(res.return) {
        login && login();
        return dispatch(setAlertMsg(['success','Agent Creation Successfull.']))
      }
      else dispatch(setAlertMsg(['error',res.msg]))
  }
  function handleInputChange(obj) {
    setData({...data,...obj})
  }
  return (
    <div className='w-full p-10 m-2 flex justify-center '>
      <form action='' onSubmit={createAgent} className='inline-block max-w-full'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Sign up</h2>
          {/* <TextField size='small' label='Agency Name' name='companyName'
            value={data.companyName}
            onChange={(ev) => handleInputChange({companyName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} /> */}
          {/* <div className='flex gap-4'>
            <TextField size='small' label='CAC Reg No' name='cacRegNo'
              value={data.cacRegNo}
              onChange={(ev) => handleInputChange({cacRegNo: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Agency Location' name='location'
              value={data.location}
              onChange={(ev) => handleInputChange({location: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div> */}
          {/* <div className='flex gap-4'>
            <TextField size='small' label='Agency Email' name='email'
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Agency Phone"
              value={data.phoneNumber}
              onChange={(val) => handleInputChange({phoneNumber: val})}
              />

          </div>
          <h6>Contact Person</h6> */}
          <div className='flex gap-4 '>
            <TextField size='small' label='First Name' className='flex-1'
              value={data.firstName}
              onChange={(ev) => handleInputChange({firstName: ev.target.value,contactFirstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Last Name' className='flex-1'
              value={data.lastName}
              onChange={(ev) => handleInputChange({lastName: ev.target.value,contactSurName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4 flex-wrap'>
            <TextField size='small' label='Email'  className='flex-1 min-w-[100px]'
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Contact Phone" className='flex-1'
              value={data.phoneNumber}
              onChange={(val) => handleInputChange({phoneNumber: val})}
              />
          </div>
          {/* <h6>Login Information</h6> */}
          <div className='flex flex-col gap-4'>
            {/* <TextField size='small' label='Agency Email' 
              value={data.contactEmail}
              onChange={(ev) => handleInputChange({contactEmail: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} /> */}
            <TextField size='small' label='Password' type='password'
              value={data.password}
              onChange={(ev) => handleInputChange({password: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Re-enter Password' type='password'
              value={data.confirmPassword}
              onChange={(ev) => handleInputChange({confirmPassword: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <label className='flex items-center gap-2 flex-wrap'>
            <input type='checkbox' name='licence' />
            I agree to all the
            <span onClick={() => setTermsOpen(true)} className='underline cursor-pointer'>Terms</span>
            and
            <span onClick={() => setPolicyOpen(true)} className='underline cursor-pointer'>Privacy Policies</span>
          </label>
          <ModalLocal open={termsOpen} setOpen={setTermsOpen}>
            <div className="w-full flex justify-center">
              <div className='bg-secondary p-10 m-2 rounded-md max-h-screen w-[80%] overflow-y-auto flex flex-col gap-10'>
                <Terms />
                <button className='btn2' onClick={(ev) => {ev.preventDefault(); ev.stopPropagation(); setTermsOpen(false)}}>Agreed</button>
              </div>
            </div>
            {/* </div> */}
          </ModalLocal>
          <ModalLocal open={policyOpen} setOpen={setPolicyOpen}>
            <div className="w-full flex justify-center">
                <div className='bg-secondary p-10 m-2 rounded-md max-h-screen w-[80%] overflow-y-auto flex flex-col gap-10'>
                  <Policy />
                  <button className='btn2' onClick={(ev) => {ev.preventDefault(); ev.stopPropagation(); setPolicyOpen(false)}}>Agreed</button>
              </div>
            </div>
          </ModalLocal>
          <button className='btn2 '>
            {loading ? (
              <div className='load'></div>
            ) : null}
            Create Account</button>
          <div className='flex items-center gap-2 justify-center'>

            Already have an account? <button onClick={login} className='text-red-400'>Login</button>
          </div>
        </div>
      </form>
    </div>
  )
}
