import React from 'react';

export default function ModalLocal({children,open,setOpen}) {
  return (
    <div className={'fixed top-0 left-0 z-[100] w-full h-full bg-black/25 flex justify-center items-start '+(open ? 'block': 'hidden')} onClick={() => setOpen(false)}>
      <div className='realtive !z-[101] flex self-center' onClick={(ev) => ev.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}
