import React, { useEffect, useState } from 'react'
import MultipleSelect from '../form/MultiSelect'
import { MenuItem, TextField } from '@mui/material'
import ProfilePicture from './ProfilePicture'
import { agentProfileTemp } from '../../data/user/agentProfile'
import PhoneNumberInput from '../form/PhoneNumberInput'
import { clone } from '../../features/utils/objClone'
import { updateSubAgent } from '../../controllers/user/updateSubAgent'
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice'
import { useDispatch } from 'react-redux'
import { setUser, setUserData } from '../../redux/reducers/user/userSlice'
import { getUser } from '../../controllers/user/getUser'
import { SketchPicker } from 'react-color'
import ModalLocal from '../mini/ModalLocal'
import { Cancel } from '@mui/icons-material'
import { hexToRgb } from '../../features/utils/hexToRGB'


const promoptions = [
  {label: 'Facebook',value: 'Facebook'},
  {label: 'Twitter',value: 'Twitter'},
  {label: 'Instagram',value: 'Instagram'},
  {label: 'Linkedin',value: 'Linkedin'},
  {label: 'Snapchat',value: 'Snapchat'},
]
export default function AgentProfile({userData,returnData}) {
  let [data,setData] = useState({...agentProfileTemp,promos: promoptions});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(userData) {
      try {
        setData({...agentProfileTemp,...userData.apiUserData,promos: data.promos})
      } catch(ex) {}
    }
  },[userData])

  console.log(userData)

  
  async function handleSubmit(ev) {
    ev.preventDefault();
    if(!userData) return false;

    let upData = clone(data);
    upData = Object.fromEntries(
      Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
    );

    setLoading(true);
    const res = await updateSubAgent(upData,data.id);
    setLoading(false);
    if(res.return){
      const UD = await getUser("me");
      if(UD.return) {
        dispatch(setUser(UD.data))
      }
      return dispatch(setAlertMsg(['success','Profile updated.']));
    }
    else return dispatch(setAlertMsg(['error',res.msg]))

  }

  function handleChange(val) {
    setData(val);
    if(returnData)
      returnData(val)
  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-10 max-w-[600px]'>
      <div className='flex flex-col gap-6 w-auto'>
        {userData ? (
          <h2>Agent Profile</h2>
        ):(
          <h2>Create Agent</h2>
        )}

        <TextField size='small' label='Agency Name' name='companyName' required
          value={data.companyName}
          onChange={(ev) => handleChange({...data,companyName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField label="Company description" multiline rows={4}
          value={data.companyDescription || ""} 
          onChange={(ev) => handleChange({...data,companyDescription: ev.target.value})} 
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className='flex gap-4'>
          <TextField size='small' label='CAC Reg No' name='cacRegNo'
            value={data.cacRegNo}
            onChange={(ev) => handleChange({...data,cacRegNo: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField size='small' label='Agency Location' name='location' required
            value={data.location}
            onChange={(ev) => handleChange({...data,location: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />

        </div>

        <div className='flex gap-4'>
          <TextField size='small' label='Contact Surname' required
            value={data.contactSurName}
            onChange={(ev) => handleChange({...data,contactSurName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <TextField size='small' label='Contact First Name' required
            value={data.contactFirstName}
            onChange={(ev) => handleChange({...data,contactFirstName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
        </div>
        <div className='flex gap-4'>
          <TextField size='small' label='Contact Email' required
            value={data.contactEmail}
            onChange={(ev) => handleChange({...data,contactEmail: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
        </div>
      </div>


      <div className='flex flex-col gap-3'>
        <div>Telephone Number</div>
        {/* <div className='border border-[#777] rounded-md flex overflow-hidden'>
          <div className='w-[50px] px-4 py-2 bg-primary/30 text-center'>1</div>
          <div className='flex-1'>
          </div>
        </div> */}
        <PhoneNumberInput label=""
          value={data.contactPhone}
          onChange={(val) => handleChange({...data,contactPhone: val})}
          />
        <PhoneNumberInput label=""
          value={data.contactPhone2}
          onChange={(val) => handleChange({...data,contactPhone2: val})}
        />
        {/* <div className='border border-[#777] rounded-md flex overflow-hidden'>
          <div className='w-[50px] px-4 py-2 bg-primary/30 text-center'>2</div>
          <div className='flex-1'>
          </div>
        </div> */}
      </div>

      {/* <div className='flex flex-col gap-3'>
        <div className=''>
          <MultipleSelect label={'Promotion Options'} options={promoptions}
            value={data.promos}
            onChange={(val) => handleChange({promos: val})}
          />
        </div>
        <label className='flex items-center cursor-pointer gap-2'><input type='radio' name='prom' 
          onClick={() => handleChange({...data,receivePromotion: true})}
        /> I am happy to receive promotions via all the above</label>
        <label className='flex items-center cursor-pointer gap-2'><input type='radio' name='prom' 
          onClick={() => handleChange({...data,receivePromotion: false})}
        /> I dont want to receive promotions via all the above</label>
      </div> */}

      <div className='flex flex-col gap-3'>
        <h6>How did you hear about us:</h6>
        <TextField size='small' className='w-full'
         value={data.referalDescription}
         onChange={(ev) => handleChange({...data,referalDescription: ev.target.value})}
        />
      </div>

      <div className='flex flex-col gap-4'>
        <h6>If you were referred by a member of the JourneyEasyTM family please complete the section below</h6>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>JourneyEasyTM User Name:</span>
          <TextField size='small' className='' 
           value={data.referalUsername}
           onChange={ev => handleChange({...data,referalUsername: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>Relationship with new JourneyEasyTM Family member:</span>
          <TextField size='small' className='' 
           value={data.referalRelationShip}
           onChange={ev => handleChange({...data,referalRelationShip: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>JourneyEasyTM Account Number:</span>
          <TextField size='small' className='' 
           value={data.referalAccountNumber}
           onChange={ev => handleChange({...data,referalAccountNumber: ev.target.value})} />
        </div>
        <div className='flex justify-between gap-4'>
          <span className='flex-1 w-full'>Earn Points together?</span>
          <TextField size='small' className='min-w-[200px]' select
            value={data.earnPointsTogether}
            onChange={(ev) => handleChange({...data,earnPointsTogether: ev.target.value})}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </div>
      </div>

      {/* <div className=''>
        <ProfilePicture />
      </div> */}

      {data.promos.length > 0 && (
        <div className='flex flex-col gap-3'>
          <h2>Setup your connections</h2>
          <p>Let’s get you all set up so you can access your personal account.</p>
          {/* <h6>Social Media Handles</h6> */}
          <div className='flex flex-col gap-4'>
            <div className={`border border-[#777] rounded-md overflow-hidden ${data.promos.find(obj => obj.value === 'Facebook') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Facebook</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.facebookHandle}
                 onChange={(ev) => handleChange({...data,facebookHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md overflow-hidden ${data.promos.find(obj => obj.value === 'Twitter') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Twitter</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.twitterHandle}
                 onChange={(ev) => handleChange({...data,twitterHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Instagram') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Instagram</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.instagramHandle}
                 onChange={(ev) => handleChange({...data,instagramHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Linkedin') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Linkedin</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.linkedInHandle}
                 onChange={(ev) => handleChange({...data,linkedInHandle: ev.target.value})}
                 />
              </div>
            </div>
            <div className={`border border-[#777] rounded-md flex overflow-hidden ${data.promos.find(obj => obj.value === 'Snapchat') ? ' flex ': 'hidden'}`}>
              <div className='w-[120px] px-4 py-2 bg-primary/30 text-center'>Snapchat</div>
              <div className='flex-1'>
                <input type='text' className='border-0 p-2 w-full'
                 value={data.snapchatHandle}
                 onChange={(ev) => handleChange({...data,snapchatHandle: ev.target.value})}
                 />
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {
          userData ? 
            <button className='btn2'>
              {loading ? (<div className='load'></div>):null}
                Update Profile
              </button>
          : null
        }
      </div>

    </form>
  )
}
