import { FacebookOutlined, Instagram, Twitter, YouTube } from '@mui/icons-material'
import React, { useState } from 'react'
import ModalLocal from './mini/ModalLocal'
import Policy from './Policy';
import Terms from './Terms';

export default function Footer() {
  const [comp,setComp] = useState();
  const [open,setOpen] = useState(false);

  function show(elem) {
    setComp(elem);
    setOpen(true);
  }
  return (
    <div className='bg-primary/20 p-2 px-6 flex gap-2 justify-between'>
      <div className='flex items-center gap-4 text-xs font-bold text-primary'>
        <span className='cursor-pointer' onClick={() => show(<Policy />)}>Privacy Policy</span>
        <span className='cursor-pointer' onClick={() => show(<Terms />)}>Terms & Conditions</span>
        <ModalLocal open={open} setOpen={setOpen}>
          <div className="w-full flex justify-center">
            <div className='bg-secondary p-10 m-2 rounded-md max-h-screen w-[80%] overflow-y-auto flex flex-col gap-10'>
              {comp}
              <button className='btn2' onClick={(ev) => {ev.preventDefault(); ev.stopPropagation(); setOpen(false)}}>Close</button>
            </div>
          </div>
        </ModalLocal>
      </div>
      <div className='flex gap-4 '>
        <a href='https://www.instagram.com/journeyeasy_/'>
          <Twitter fontSize='small' className='text-primary/60'/>
        </a>
        <a href='https://www.twitter.com/btmjourneyeasy_'>
          <Instagram fontSize='small' className='text-primary/60'/>
        </a>
        <a href='https://www.youtube.com/channel/UCOhL7t_Ap4JyylrqOLxC65Q'>
          <YouTube  className='text-primary/60' />
        </a>
      </div>
    </div>
  )
}
