import React from 'react'
import ModalLocal from '../mini/ModalLocal';
import { Link } from 'react-router-dom';

export default function BookingFailed({open,setOpen,link}) {
  return (
    <div>
      <ModalLocal open={open} setOpen={setOpen} closable>
        <div className='flex flex-col gap-10 p-6 max-w-[600px] bg-secondary rounded-md'>
          <div className='flex flex-col gap-2'>
            <div className='!text-2xl'>Flight not available for booking.</div>
            <div className=''>We're sorry, but this flight is currently unavailable for booking. Please consider selecting other offers from our available options.</div>
          </div>
          <Link to={link} className='btn2 self-start'>Go back to flights</Link>
        </div>
      </ModalLocal>
    </div>
  )
}
