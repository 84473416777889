import React from 'react'
import { FitnessCenter, Flight, FlightLandOutlined, FlightTakeoff } from '@mui/icons-material';
// import { offerDataTemp } from '../../data/flight/offerData';

export default function FlightInfoCard({data,label}) {
  // let tdata = offerDataTemp.segments[0];
  return (
    <div className='border rounded-md w-full border-gray-300 bg-secondary'>
      <div className='flex gap-4 justify-between p-5'>
        <b>
          {label} - {data.departureDate}
          {/* {label} - {tdata.booked_flights[0].origin.date} */}
        </b>
        <b>
          {data.duration}
          {/* {tdata.booked_flights[0].duration} */}
        </b>
      </div>
      {
        data.flights.map((flight,i) => (
          <div key={i}>

            <div className='border-t '>
              <div className='p-2'>
                <div className='flex gap-2 py-2 items-'>
                  <img alt='airline' src={flight.carrierIcon} className='w-[50px] max-h-[30px] !object-contain' />
                  {flight.carrierName}
                </div>
                <div className='flex'>
                  <div className='flex flex-col text-[#aaa] w-full '>
                    <small className='font-bold flex gap-2 items-end'>
                      <FlightTakeoff /> 
                      <b className='text-primary min-w-[50px] '>{flight.departureTime}</b>
                      <p className=' '>{flight.departureAirportName} ({flight.departureLocation})</p>
                    </small>
                    <div className='px-2'>
                      <div className='vr'></div>
                    </div>
                    <small className='flex gap-2 items-center'>
                      <Flight className='rotate-90' /> 
                      <p className='max-w-[140px] min-w-[50px]'>{flight.duration}</p>
                      
                        {
                          flight.arrivalTime < flight.departureTime ? 
                          <b className='bg-[#f48a3885] rounded-md px-2 p-1 text-[#533218]'>Overnight</b>
                          :
                          <b className='bg-[#C8F0BC] rounded-md px-2 p-1 text-[#378e37]'>Daylight</b>
                        }
                    </small>
                    <div className='px-2'>
                      <div className='vr'></div>
                    </div>
                    <small className='font-bold flex gap-2 items-start'>
                      <FlightLandOutlined /> 
                      <b className='text-primary min-w-[50px] '>{flight.arrivalTime}</b>
                      <p className=''>{flight.arrivalAirportName} ({flight.arrivalLocation})</p>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-3 flex gap-6 items-center justify-end'>
              {/* <div className='flex gap-2'>
                <Flight className='rotate-90 w-4 h-4' />
                <WifiOutlined className='w-4 h-4' />
              </div> */}
              <small className='flex gap-1 items-center whitespace-nowrap'>
                <FitnessCenter className='text-sm font-bold rotate-[-45deg]' />
                {flight.weightUnit || data.weightUnit} {flight.weightType || data.weightType}
              </small>

              <h6 className='px-4 whitespace-nowrap'>{flight.cabin} {flight.bookingClass || data.bookingClass}</h6>
            </div>


          </div>
        ))
      }
    </div>
  )
}
