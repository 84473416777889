import { Breadcrumbs} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RoomDisplay from '../../../components/hotel/RoomDisplay';
import { walletPayHotel } from '../../../controllers/hotel/walletPay';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import HotelPayment from '../../../components/payment/HotelPayment';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';
import TourPayment from '../../../components/payment/TourPayment';
import PriceSummary from '../../../components/Tour/PriceSummary';
import PaymentMethod from '../../../components/payment/PaymentMethod';
import PromoCode from '../../../components/payment/PromoCode';


export default function Payment({next,back,data,step}) {
  const {tourBooking} = useSelector(state => state.tourBooking)
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTourBooking({...tourBooking,step}))
  },[step])
  
  console.log(' -- == data on payment',data)
  return (
    <div className='flex gap-5 w-full p-10'>
      <div className='flex flex-col gap-3 flex-1'>
        <Breadcrumbs separator=">" className='text-xs'>
          <Link to="/search#Tour">Tour</Link>
          <span className='cursor-pointer' onClick={() => back && back()}>Payment</span>
          {/* <span className='capitalize'>{data && data.name.toLowerCase()}</span> */}
        </Breadcrumbs>
        <div className='flex flex-col'>
          <PaymentMethod packageName='tour' order={tourBooking.bookingData} returnData={() => next()} loading={loading}/>
          <PromoCode id={tourBooking.bookingData && tourBooking.bookingData.id} type="tour" returnData={() => setLoading(false)} />

          {/* <TourPayment orderData={tourBooking.bookingData} returnData={next} /> */}
        </div>
      </div>
      <PriceSummary onBook />
    </div>
  )
}

