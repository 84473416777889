import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function bookFlight(data,userId) {
  var result = {return: 0,msg: 'Booking can not completed at this time. Kindly try another search.',data: {}}

  let token = store.getState().user.userData.accessToken;
  let guestToken = window.localStorage.getItem('guestToken');
  if((!token || token === '') && guestToken)
    token = guestToken;

  // console.log("userId - ",userId)
  console.log("[req] bookedFlight data - ",data,userId)

  await fetchServer({method: "POST",url: path.api+'/flights/order/',data,
    headers: {
      Authorization: `Bearer ${token}`,
      'user-id': userId
    }
  })
  .then((res) => {
    console.log(" => ",res)
    if(res) {
      if(res.data.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      }
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}