import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function walletPayTour(orderId,userId) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  // console.log(" --- on Single Booking Data ---")
  // console.log("data - ",data)
  // console.log(" --------- -----------")
  let headers = {
    Authorization: `Bearer ${token}`
  }

  if(userId)
    headers['user-id'] = userId;


  await fetchServer({method: "PATCH",url: path.api+'/tourpro/pay-for-order/wallet/'+orderId,
    headers
  })
  .then((res) => {
    console.log(" => ",res)
    if(res) {
      if(res.data.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      }
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}