import { MenuItem, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import FetchUsersInput from '../../components/form/FetchUsersInput'
import { def } from '../../config';
import { creditUser } from '../../controllers/payment/creditUser';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { getUsers } from '../../controllers/user/getUsers';
import { creditSelf } from '../../controllers/payment/creditSelf';
import { usePaystackPayment } from 'react-paystack';
import { sendPaymentReferenceReq } from '../../controllers/payment/sendPaymentReference';
import { convertMoney, formatMoney } from '../../features/utils/formatMoney';


export default function CreditManagment({data,returnData}) {
  const {user} = useSelector(state => state.user.userData);
  const [upd,setUpd] = useState(false);
  const [wallet,setWallet] = useState({});
  const [amount,setAmount] = useState(0)

  useEffect(() => {
    fetchUser();
  },[data])
  
  async function fetchUser() {
    let q = {
      id: user.id,
      type: user.type,
      q: user.email,
      phoneNumber: user.phoneNumber
      // limit: 100,
    }
    const res = await getUsers((new URLSearchParams(q)).toString());
    if(res.return) {
      // console.log(res.data)
      try {
        setAmount(res.data[0].wallet[0].amount);
        setWallet(res.data[0].wallet[0])
      } catch(ex) {}
      // setOption(res.data.filter((obj,ind) => ind === res.data.findIndex(o => o.id === obj.id)));
    }
  }
  function handleCreditReturn() {
    fetchUser();
    if(returnData)
      returnData();
  }

  if(!user.isSuperAdmin) return (
    <div>
      <div className='flex'>
        <div className='min-w-[150px] h-10 flex items-center px-3 bg-gray-500 text-white whitespace-nowrap'>Current Balance</div>
        <div className='flex-1 h-10 flex items-center px-3 bg-gray-200'>{def.currency}{formatMoney(Number(amount).toFixed(2))}</div>
        <div className='px-2'>
          <button className='btn1 rounded-md whitespace-nowrap' onClick={() => setUpd(!upd)}>Top up</button>
        </div>
      </div>
      {upd ? (
        <Topup returnData={handleCreditReturn} user={user} wallet={wallet} />
      ):null}
    </div>
  );
  
  return (
    <div className='bg-secondary flex flex-col gap-3'>
      <div className='flex gap-4 p-6 flex-wrap  border border-primary/10'>
        <CreditDebit />
        <UpdateBalance transactions={data} />
      </div>
    </div>
  )
}

function Topup({returnData,user}) {
  const [amount,setAmount] = useState(0);
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  async function sendReference(ref) {
    console.log(' -- ',ref)
    setLoading(true)
    const res = await sendPaymentReferenceReq(ref.reference);
    if(res.return) {
      dispatch(setAlertMsg(['success','Wallet Credited.']))
      if(returnData)
        returnData();
      setAmount(0);
    } else dispatch(setAlertMsg(['error',res.msg]))
    setLoading(false)
  } 
  const config = {
    email: user.email,
    amount: convertMoney(Number(amount*100)),
    // amount: amount*100,
    currency: def.currencyCode,
    publicKey: process.env.REACT_APP_paystackKey,
    text: 'Deposite Wallet',
  }
  const onClose = () => {}
  const onSuccess = (reference) => {sendReference(reference)}

  const initializePayment = usePaystackPayment(config);

  function handlePayment() {
    initializePayment(onSuccess,onClose)
  }
  async function handleSubmit() {
    setLoading(true);
    // console.log(wallet)
    const res = await creditSelf({amount});
    if(res.return) {
      dispatch(setAlertMsg(['success','Wallet Credited.']))
      if(returnData)
        returnData();
    } else dispatch(setAlertMsg(['error','Failed crediting wallet!']))
    setLoading(false);

  }
  return (
    <div className='py-5 px-2 flex justify-end'>
      <div className='flex gap-2'>
        <TextField value={amount} className='!rounded-none' size='small'
          onChange={(ev) => setAmount(ev.target.value)}
          prefix={def.currency}
          InputProps={{
            startAdornment: def.currency
          }}
        />
        <button className='btn2 flex-1 flex gap-2' onClick={handlePayment}>
          {loading ? (<div className='load'></div>) : null}
          Credit</button>
      </div>
    </div>
  )
}

function CreditDebit() {
  const [type,setType] = useState('credit');
  const [userType,setUserType] = useState('');
  const [user,setUser] = useState('')
  const [invoice,setInvoice] = useState('');
  const [amount,setAmount] = useState(0);
  const [loading,setLoading] = useState(false);
  // const amountLimit = 2000000;

  const dispatch = useDispatch();


  async function handleSubmit() {
    // if(amount > amountLimit) return dispatch(setAlertMsg(['error','Above Credit Limit']))
    let walletId = null;
    try {
      walletId = user.wallet[0].id;
    } catch(ex) {}

    if(walletId && type === 'credit') {
      setLoading(true);
      const res = await creditUser(walletId,{amount})
      setLoading(false);
      if(res.return) {
        dispatch(setAlertMsg(['success',`User Credited ${amount}`]))
      } else dispatch(setAlertMsg(['error','Failed Crediting User']))
    }
    console.log('User: ',user)
  }

  return (
    <div className='flex-1 flex flex-col gap-2 border border-primary/10 p-3'>
      <h4 className='py-2'>Credit or Debit</h4>
      <TextField select value={type} onChange={(ev) => setType(ev.target.value)} size='small'>
        <MenuItem value="credit">Credit</MenuItem>
        <MenuItem value="debit">Debit</MenuItem>
      </TextField>
      <TextField value={userType} select onChange={(ev) => setUserType(ev.target.value)} label="Select user type" size='small' className='min-w-[350px]'>
        {/* <MenuItem value="Customer">Customer</MenuItem> */}
        <MenuItem value="Sub_Agent">Agent</MenuItem>
        <MenuItem value="Company">Company</MenuItem>
      </TextField>
      <FetchUsersInput value={user} onChange={(val) => setUser(val)} label={userType} />
      {type==='debit'?(
        <TextField type='number' label="Invoice Number" size='small'
         value={invoice}
         onChange={(ev) => setInvoice(ev.target.value)}
        />
      ):null}
      <div className='flex gap-2'>
        <TextField value={amount} className='!rounded-none' size='small'
          onChange={(ev) => setAmount(ev.target.value)}
          prefix={def.currency}
          InputProps={{
            startAdornment: def.currency
          }}
        />
        <button className='btn2 flex-1 flex gap-2' onClick={handleSubmit}>
          {loading ? (<div className='load'></div>) : null}
          {type}</button>
      </div>
    </div>
  )
}

function UpdateBalance({transactions}) {
  const [userType,setUserType] = useState('');
  const [user,setUser] = useState('')
  const [amount,setAmount] = useState('');
  const [loading,setLoading] = useState(false);

  const [allCredited,setAllCredited] = useState(0)

  const dispatch = useDispatch();


  async function handleSubmit() {
    let walletId = null;
    try {
      walletId = user.wallet[0].id;
    } catch(ex) {}

    if(walletId) {

    }
    console.log('User: ',user)
  }

  function handleUserWallet(user) {
    setUser(user);
    try {
      setAmount(user.wallet[0].amount)
      let total = 0;
      transactions.map((data) => {
        if(data.user.id === user.id) {
          if(data.from === 'BANK')
            return total += parseInt(data.amount)
          // console.log('got user')
        }
        return 0
      })
      setAllCredited(total);
    } catch(ex) {}
  }

  return (
    <div className='flex-1 flex flex-col gap-2 border border-primary/10 p-3'>
      <h4 className='py-2'>Update Credit Balance</h4>
      <TextField select value={userType} onChange={(ev) => setUserType(ev.target.value)} label="Select user type" size='small' className='min-w-[350px]'>
        {/* <MenuItem value="Customer">Customer</MenuItem> */}
        <MenuItem value="Sub_Agent">Agent</MenuItem>
        <MenuItem value="Company">Company</MenuItem>
      </TextField>
      <FetchUsersInput value={user} label={userType} onChange={(val) => handleUserWallet(val)} />
      <div className='flex'>
        <div className='min-w-[150px] h-10 flex items-center px-3 bg-gray-500 text-white'>Credit Limit</div>
        <div className='flex-1 h-10 flex items-center px-3 bg-gray-200'>{def.currency}{allCredited}</div>
      </div>
      <div className='flex'>
        <div className='min-w-[150px] h-10 flex items-center px-3 bg-gray-500 text-white whitespace-nowrap'>Current Balance</div>
        <div className='flex-1 h-10 flex items-center px-3 bg-gray-200'>{def.currency}{amount}</div>
      </div>
      <div className='flex gap-2'>
        <TextField value={amount} className='!rounded-none' size='small'
          onChange={(ev) => setAmount(ev.target.value)}
          prefix={def.currency}
          InputProps={{
            startAdornment: def.currency
          }}
        />
        <button className='btn2 flex-1 flex gap-2' onClick={handleSubmit}>
          {loading ? (<div className='load'></div>):null}
          Update Balance</button>
      </div>
    </div>
  )
}