import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { formatNumber } from '../../features/utils/formatNumber'

function Number({n,callback=(n) => n}) {
  const {number} = useSpring({
    from: {number: 0},
    number: n,
    delay: 200,
    config: {mass: 1,tension: 40, friction: 10},
  })
  return <animated.div>{number.to((n) => callback(n.toFixed(0)))}</animated.div>
}

export default function Stats({data}) {
  const [obj,setObj] = useState();
  useEffect(() => {
    console.log('reloading')
    if(data)
      setObj(data)
  },[data])
  return obj && (
    <div className='stats_min flex justify-between'>
      <div className='flex flex-col'>
        <h3 className='text-black' ><Number callback={obj.callback} n={obj.amount} /></h3>
        <small>{obj.label}</small>
      </div>
      <div className='flex items-center'>
        {obj.icon}
      </div>
    </div>
  )
}
