import { useEffect, useState } from "react";
import Collapse from "../../mini/Collapse";
import AirlinesInput from "../../form/AirlinesInput";

export default function FilterAirlines({returnData,clear,orgi}) {
  const [airlines,setAirlines] = useState([
    // {
    //   "name": "Ethiopian Airlines",
    //   "id": "ET",
    //   "value": false
    // },
    // {
    //   "name": "Brussels Airlines",
    //   "id": "SN",
    //   "value": false
    // }
])

useEffect(() => {
  setAirlines(airlines => airlines.map(obj => ({...obj,value: false})))
},[clear])

console.log(orgi)

useEffect(() => {
  if(orgi) {
    let counts = {};
    let airlineObjs = orgi.flatMap((obj, i) =>
      obj?.segments?.flatMap((seg, j) => seg?.flights?.map((flight,k) => {
        let count = counts[`${flight.marketingCarrier}-${i}-${j}-${k}`]
        counts[`${flight.marketingCarrier}-${i}-${j}-${k}-${flight.carrierName}`] = (count || 0) + 1;
        return flight;
      }
    ))).flat();
    const flightCounts = {};

    console.log(airlineObjs)
    
    for (const key in counts) {
      const [airline, flightIndex] = key.split('-');
      const airlineFlightKey = `${airline}-${flightIndex}`;
      flightCounts[airlineFlightKey] = (flightCounts[airlineFlightKey] || 0) + 1;
    }
    
    const airlineCounts = {};
    
    for (const key in flightCounts) {
      const airline = key.split('-')[0];
      airlineCounts[airline] = (airlineCounts[airline] || 0) + 1;
    }

    airlineObjs = [...new Set(airlineObjs.map(obj => JSON.stringify({name: obj?.carrierName,id: obj?.marketingCarrier,value: false})))]

    setAirlines(airlineObjs.map(obj => {
      let airlineObj = JSON.parse(obj)
      airlineObj['count'] = airlineCounts[airlineObj.id]
      return airlineObj
    }))
  }
},[orgi])



  function handleChange(val) {
    if(val === null) return false;
    
    let filtered = [...airlines];
    if(!airlines.find(d => d.name === val.name))
      filtered = ([...airlines,{name: val.name,id: val.id,value: true}])

    setAirlines(filtered);
    // console.log(val);
    returnData(filtered);
  }
  function handleCheck(val,i) {
    let temp = [...airlines];
    temp[i].value = val;
    setAirlines(temp);

    returnData(airlines)
  }
  return (
    <Collapse show label={<h5>Airlines</h5>}>
    <AirlinesInput label={"Search here"} returnData={handleChange} />
    {airlines?.map((obj,i) => (
      <label key={i} className='flex gap-4 justify-between'>
        <span className='flex gap-2'>
          <input name='xcountries' checked={obj.value} onChange={(ev) => handleCheck(ev.target.checked,i)} type='checkbox' />
          <span>{obj.name} ({obj.count})</span>
        </span>
      </label>
    ))}
  </Collapse>
)
}
