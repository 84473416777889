import axios from "axios";
import { store } from "../redux/store";
import { logout } from "../redux/reducers/user/userSlice";

export default async function fetchServer({method,url,data,headers}) {
  console.log("fetching server")
  try {
    
    if(window.parent !== window) {
      let guestToken = window.localStorage.getItem('guestToken');
      headers.AuthorizationTest = guestToken;
      if(guestToken)
        headers.Authorization = "Bearer "+guestToken;
      else 
        headers.Authorization = null;

    }
    
  } catch(ex) {}

  const res = await axios({
    method,
    url,
    data,
    headers,
  })
  .catch(err => {
    if(err.response.status === 401) {
      window.localStorage.removeItem("userData");
      store.dispatch(logout());
    }
    return err.response.data;
  });
  return res;
}
