import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { Avatar } from "@mui/material";
import { Link, useParams } from 'react-router-dom';
import { getTransactions } from '../../controllers/transactions/getTransactions';
import { templateTransactions, templateTransactionsReport } from '../../data/transactions/transaction';
import { getCurrencySymbol } from '../../features/utils/currency';
import StatsOverview from '../../components/StatsOverview';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/reducers/titleSlice';
import DotMenu from '../../components/mini/DotMenu';
import downloadCSV from '../../features/utils/downloadCSV';
import { def } from '../../config';
import { formatMoney } from '../../features/utils/formatMoney';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))


const columns = [
  {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex gap-4 items-center'>
          <Avatar>{params.value[0]}</Avatar>
          {params.value}
        </div>
      )
    }
  },
  {field: 'date',headerName: 'Date',minWidth: 150,
    renderCell: (params) => {
      if(!params.value)
        return null;

      return (
        <div className='flex flex-col'>
          {(new Date(params.value)).toDateString()}
          <small>{(new Date(params.value)).toLocaleTimeString()}</small>
        </div>
      )
    }
  },
  {field: 'product',headerName: 'Product',minWidth: 170,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex flex-col '>
          {params.value}
        </div>
      )
    }
  },
  {field: 'paymentMethod', headerName: 'Payment Method',minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
        return null;

      return (
        <div className='flex flex-col '>
          {params.value}
        </div>
      )
    }
  },
  {field: 'amount', headerName: 'Amount',
    renderCell: (params) => {
      if(!params.value) return null;
      
      return (
        <div className='flex flex-col gap-0'>
          <b className='!font-normal'>{def.currency}{formatMoney(params.value)}</b>
        </div>
      )
    },
  },
  {field: 'status',headerName: 'Status',flex: 1,minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
          <DotMenu>
            <MenuItem><Link to={`/transactions/details/${params.id}`}>Detail</Link></MenuItem>
          </DotMenu>
        </div>
      )
    }
  },
];


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  // {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'SUCCESS'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PENDING'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  // {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [productType,setProductType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[type])
  async function load() {
    if(type)
      dispatch(setHeaderTitle(type+" Transactions"))

    let params = {
      userType: type || ""
    }
    params.userType = params.userType.toUpperCase()

    setLoading(true);
    const res = await getTransactions((type && type !== "" && type !== "all") ? (new URLSearchParams(params)).toString() : null);
    setLoading(false);
    if(res.return) {
      let data = res.data.map((row) => templateTransactions(row))
      
      // if(type && (type !== 'all'))
      //   data = data.filter(obj => obj.user.type.toLowerCase() === type);
  
      setFetchedData(data);
      setData(data);
    }
  }

  function filterType(val) {
    setProductType(val);
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
    
    setData(data => data.filter(obj => (
      (obj.product) ?
        obj.product.toLowerCase() === val.toLowerCase()
        :
        false
    )))
  }


  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.date)).toDateString().toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    
    // Prepend the column headers to the CSV data
    const csvData = data.map((row) => templateTransactionsReport(row));
    csvData.unshift(columns.map(col => col.headerName));
    // console.log('csvData', csvData)

    downloadCSV(csvData,'Transactions')
  }
  
  return (
    <div className='w-full p-4 m-2'>
      <div className='pb-4'>
        <StatsOverview />
      </div>
      <div className='py-5'>
        <TextField select value={productType} onChange={(ev) => filterType(ev.target.value)} label="Select Product" size='small' className='min-w-[350px]'>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Booking">Flight</MenuItem>
          <MenuItem value="Hotel">Hotel</MenuItem>
          <MenuItem value="Package">Package</MenuItem>
          <MenuItem value="Tour">Tour</MenuItem>
        </TextField>
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={`All (${fetchedData.length })`} />
          <Tab label="Paid" />
          <Tab label="Pending" />
          <Tab label="Cancelled" />
          <Tab label="Refund" />
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
