import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  tourBooking: {

  },
}
const UDStorage = window.localStorage.getItem('tourBooking');
if(UDStorage) {
  try {
    initialState = {tourBooking: JSON.parse(UDStorage)};
  } catch(ex) {console.log("Failed reading tourBooking from storage")}
  // console.log(" from LS : ",JSON.parse(UDStorage))
} 
else console.log("Got none from LS")

export const tourBookingSlice = createSlice({
  name: 'tourBooking',
  initialState,
  reducers: {
    setTourBooking: (state,action) => {
      console.log('to set',action.payload)
      try {
        state.tourBooking = action.payload;
        window.localStorage.setItem('tourBooking',JSON.stringify(action.payload))
      } catch(ex) {console.log('Failed setting booking data to storage')}
    },
    clearTourBooking: (state) => {
      state.tourBooking = initialState;
      window.localStorage.removeItem('tourBooking');
    }
  },
})

export const {setTourBooking,clearTourBooking} = tourBookingSlice.actions;

export default tourBookingSlice.reducer;