import React, { useState } from 'react'
import { Apartment } from '@mui/icons-material'
import IncDec from '../mini/IncDec'
import { getCurrencySymbol } from '../../features/utils/currency'
import { useDispatch, useSelector } from 'react-redux'
import { setHotelBooking } from '../../redux/reducers/hotel/hotelBookingSlice'
import moment from 'moment'
import { def } from '../../config'
import { formatMoney } from '../../features/utils/formatMoney'
import ReadMore from '../mini/ReadMore'
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice'

export default function RoomDisplay({review,data}) {
  const {hotelBooking} = useSelector(state => state.hotelBooking);
  const dispatch = useDispatch();
  const [num,setNum] = useState(0);

  const perNightPrice = Number(data?.price?.replaceAll(',','') || 0) / Number(data?.nights)


  console.log(data)
  let selectedRoom = null;

  try {
    selectedRoom = hotelBooking.selectedRooms[0].name;
    // @my_fix: only taking the first array to show
  } catch(ex) {}

  function selectRoom() {
    let selectedRooms = [...hotelBooking.selectedRooms] || [];
    let found = selectedRooms.filter(room => JSON.stringify(room) === JSON.stringify(data));
    // if(!found.length || found.length < num) {
    let n = Math.max(num - found.length,0);
    [...Array(n)].map(n => selectedRooms.push(data))
    const requestedNum = hotelBooking?.request?.rooms?.length || 0;
    if(selectedRooms.length > requestedNum)
      return dispatch(setAlertMsg(['warning','Your search includes only '+requestedNum+' room/s.']));
    if(n) dispatch(setHotelBooking({...hotelBooking,selectedRooms}))
    // }

  }
  function removeRoom() {
    let selectedRooms = [...hotelBooking.selectedRooms] || [];
    let found = selectedRooms.filter(room => JSON.stringify(room) === JSON.stringify(data));
    // if(!found.length || found.length < num) {
    let difference = selectedRooms.filter(room => !found.includes(room));

    if(difference) dispatch(setHotelBooking({...hotelBooking,selectedRooms: difference}))
    // }

  }

  console.log(' --> ',data)
  return data && (
    <div className='bg-secondary rounded-md shadow-md text-xs '>
      <div className=' flex '>
        {!review ? 
          <img src={data.image} alt='Hotel' className='w-1/4 object-cover' />
        :null}
        <div className='flex gap-3 items-center flex-1'>
          <div className='flex flex-col gap-2 p-5 flex-1'>
            <b className='capitalize'>
              {selectedRoom && selectedRoom.toLowerCase()}
              {/* Superior room - 1 double bed or 2 twin beds */}
            </b>
            {!review ?
              <div className='flex flex-col gap-2 flex-1 text-[10px]'>
                <p className='capitalize'>
                  {data.description.toLowerCase()}
                  {/* Flexible Rate, Deluxe Walk View, 2 Twins, Mini-fridge, 36sqm/387sqft, Wireless internet. */}
                </p>
                {/* <div className='flex flex-wrap'>
                  <div className='flex-1 flex items-center gap-2'><Restaurant className='text-sm' /> Outdoor Pool</div>
                  <div className='flex-1 flex items-center gap-2'><Restaurant className='text-sm' /> Spa and wellcenter</div>
                  <div className='flex-1 flex items-center gap-2'><Restaurant className='text-sm' /> Restaurant</div>
                </div> */}
              </div>
            : null}
          </div>
          <div className='flex flex-col gap-2 p-5 pl-0 items-end '>
            <div className='flex flex-wrap gap-2 items-end'>
              <h4>{def.currency}{formatMoney(Number(data.price?.toString()?.replaceAll(',','')) / (data.nights || 1))}</h4>
              <small className='text-theme1'>/night</small>
            </div>
            <p className=''>{def.currency}{formatMoney(data?.price)}</p>
            {!review ?
              <div className='flex flex-col gap-1 flex-1 items-end justify-end '>
                <div className='flex flex-wrap gap-2 items-center justify-end'>
                  <div className='border border-theme1 p-[2px] rounded-md'>
                    <IncDec value={num} returnData={(val) => setNum(val)} size={'small'} />
                  </div>
                  <button className='btn2 small whitespace-nowrap ' onClick={selectRoom}>Select room</button>
                </div>
                {hotelBooking.selectedRooms && hotelBooking.selectedRooms.find(obj => JSON.stringify(obj) === JSON.stringify(data)) && 
                  <button className='btn1 rounded-md small whitespace-nowrap ' onClick={removeRoom}>Remove</button>
                }
                {/* <small>Number of Nights: 14</small>
                <small>Number of Guests: 2 adults</small>
                <small>cancellation Amount: {def.currency}5,000</small>
                <small>cancellation deadline: Nov 5</small> */}
              </div>
            :null}
          </div>
        </div>
      </div>

      {review ? 
      <div className='flex flex-col gap-5 px-5 pb-5'>
        <div className='flex gap-2'>
          <div className=''>
            <img src={require('../../assets/img/hotel.png')} alt='Hotel Logo' className='w-[50px] object-cover' />
          </div>
          <div className='flex-1 flex flex-col gap-1 p-2'>
            <h6>{data.name}</h6>
            <ReadMore size={2}>{data.details.text}</ReadMore>
          </div>
        </div>
        <div className='w-full flex justify-between'>
          <div className='flex-1 flex flex-col gap-1'>
            {moment(data.start_date).format("dddd, MMM D")}
            <small>Checkin</small>
          </div>

          <div className='flex-1 flex gap-2 items-center justify-center'>
            <div className='flex items-center'>
              <div className='sqr1 bg-primary rounded-full'></div>
              <hr className='w-[30px] border-primary' />
            </div>
            <Apartment />
            <div className='flex items-center'>
              <hr className='w-[30px] border-primary' />
              <div className='sqr1 bg-primary rounded-full'></div>
            </div>
          </div>
          
          <div className='flex-1 flex flex-col gap-1 items-end'>
            {moment(data.end_date).format("dddd, MMM D")}
            <small>Checkout</small>
          </div>
        </div>
      </div>
      :null}
    </div>
  )
}
