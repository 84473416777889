import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../redux/reducers/modal/modalSlice';

export default function Modal(props) {
  const {open} = useSelector((state) => state.modal);
  let openStat = open;
  {
    const {open} = props
    if(open)
      openStat = open
  }

  if(openStat === 'false') openStat = false;

  const dispatch = useDispatch();
  return (
    <div className={'fixed top-0 left-0 z-[100] w-full h-full bg-black/25 flex justify-center items-start '+(openStat ? 'block': 'hidden')} onClick={() => dispatch(setModal(false))}>
      <div className='realtive !z-[101] self-center' onClick={(ev) => ev.stopPropagation()}>
        {props.children}
      </div>
    </div>
  )
}
