import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalLocal from '../mini/ModalLocal';
import { MenuItem, TextField } from '@mui/material';
import { ListBanksReq } from '../../controllers/payment/listBanks';
import { walletPayTour } from '../../controllers/tour/walletPay';
import { walletPayHotel } from '../../controllers/hotel/walletPay';
import { walletPay } from '../../controllers/payment/walletPay';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { bankPay } from '../../controllers/payment/bankPay';
import { bankPayHotel } from '../../controllers/payment/bankPayHotel';
import { bankPayTour } from '../../controllers/payment/bankPayTour';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { usePaystackPayment } from 'react-paystack';
import { cardFlightRefReq } from '../../controllers/payment/cardFlightRef';
import { cardTourRefReq } from '../../controllers/payment/cardTourRef';
import { cardHotelRefReq } from '../../controllers/payment/cardHotelRef';

export default function PaymentMethod({order,packageName='flight',returnData}) {
  const [meth,setMeth] = useState('wallet');
  const [loading,setLoading] = useState(false);
  const [res,setRes] = useState({success: false,msg: ''})
  const [listBanks,setListBanks] = useState(false);
  const [banks,setBanks] = useState([]);
  const [banksLoading,setBanksLoading] = useState(false);
  const [selectedBank,setSelectedBank] = useState();
  const {user} = useSelector(state => state.user.userData);

  let total = order.amount;
  if(packageName === 'tour') {
    total = order.totalFarePrice;
  } else if(packageName === 'flight') {
    if(order.row)
      total = order.row?.convertedCurrencyTotal || order.row.editedtotalPrice;
    else total = order?.convertedCurrencyTotal || order.editedtotalPrice;
  } else if(packageName === 'hotel') {
    total = order.editedtotalPrice;
  }
    

  const dispatch = useDispatch();
  console.log(' --> ',order,{total},{packageName})
  
  let userEmail = user?.email || order?.bookedBy?.email;
  let userType = user?.type || order?.bookedBy?.type;

  let orderId = order.id;
  let userId = order.userId;

  async function handleSubmit(msg) {  
    if(order.row)
      userId = order.row.userId; 
    if(meth === 'wallet') {
      setLoading(true);
      // let userId = order.userId;
      // if(order.row)
      //   userId = order.row.bookedBy && order.row.bookedBy.id;
      let res = {return: 0,msg: 'Payment Package Unknown!'};
      if(packageName === 'flight')
        res = await walletPay(orderId,userId);
      else if(packageName === 'hotel')
        res = await walletPayHotel(orderId,userId);
      else if(packageName === 'tour')
        res = await walletPayTour(orderId,userId);

      if(res.return) {
        setRes({success: true})
        dispatch(setAlertMsg(['success','Payment Successfull']))
        if(returnData)
          returnData(res)
      } else dispatch(setAlertMsg(['error',res.msg]));
      setLoading(false);
    } else if(meth === 'bank') {
      setLoading(true);
      let res = {return: false};
      if(!order.row || (order.row && order.row.transactions && !order.row.transactions.length)) {
        if(packageName === 'flight')
          res = await bankPay(order.id);
        else if(packageName === 'hotel')
          res = await bankPayHotel(order.id);
        else if(packageName === 'tour')
          res = await bankPayTour(order.id);
        }
      setLoading(false);
      if(res.return) {
        if(msg === 'verify') {
          setLoading(true);
          const confirmed = await confirmBankTransaction(orderId)
          setLoading(false);
          if(confirmed.return) {
            dispatch(setAlertMsg(['success','Payment Successfull']))
          } else  {
            dispatch(setAlertMsg(['warning','Payment Still Pending']))
          }
          if(returnData)
            returnData(confirmed.return)
          
          return true;
        }
        if(returnData)
          returnData(res)
        setRes({success: true,msg: 'Verify Payment Later'})
      } else dispatch(setAlertMsg(['error','Failed on payment']));
      setLoading(false);
    } else if(meth === 'card') {
      initializePayment(onSuccess,onClose);
    } else if(meth === 'hold')
      returnData && returnData(true)
  }

  async function sendReference({reference}) {
    let res = {return: false,msg: 'Failed sending reference! (0xSYS)'}

    setLoading(true);
    if(packageName === 'flight')
      res = await cardFlightRefReq({orderIds: [orderId],reference})
    else if(packageName === 'tour')
      res = await cardTourRefReq(orderId,{reference})
    else if(packageName === 'hotel')
      res = await cardHotelRefReq(orderId,{reference})
    setLoading(false);

    if(res.return) {
      dispatch(setAlertMsg(['success','Payment Successful.']))
      if(returnData)
        returnData(res);
    } else dispatch(setAlertMsg(['error',res.msg]))
  
  }

  const config = {
    email: userEmail,
    amount: Number(total).toFixed(2)*100,
    currency: order?.userChosenCurrency?.currencyCode,
    publicKey: process.env.REACT_APP_paystackKey,
    text: 'Deposite Wallet',
  }
  const onClose = () => {}
  const onSuccess = (reference) => {
    sendReference(reference)
  }

  const initializePayment = usePaystackPayment(config);



  async function loadBanksList() {
    let params = {
      limit: 10,
      skip: 0,
    }
    setListBanks(true);
    setBanksLoading(true);
    const res = await ListBanksReq((new URLSearchParams(params)).toString())
    setBanksLoading(false);
    if(res.return) {
      setBanks(res.data);
    }
  }

  let bookingAsType = (userType);
  if(order.bookedBy)
    bookingAsType = order.bookedBy.type

  return (
    <form onSubmit={(ev) => ev.preventDefault()} className='bg-secondary p-5 flex flex-col gap-4 relative'>

      <h2>Payment method</h2>
      <p>Choose your payment method</p>

      <hr />

      
        {user?.isSuperAdmin ? null : (
          <label className='flex gap-2'>
            <input type='radio' name='paymeth' checked={meth === 'card'} onChange={(ev) => setMeth(ev.target.value)} value="card" />
            Credit / Debit Card
          </label>
        )}

        {!user?.isSuperAdmin ? null : (
        <label className='flex gap-2'>
          <input type='radio' name='paymeth' checked={meth === 'bank'} onChange={(ev) => setMeth(ev.target.value)} onClick={() => loadBanksList()} value="bank" />
          Bank Transfer
        </label>
        )}

        <label className='flex gap-2'>
          <input type='radio' name='paymeth' checked={meth === 'hold'} onChange={(ev) => setMeth(ev.target.value)} value="hold" />
          Book on hold
        </label>

        {((bookingAsType === 'SUB_AGENT') ? (
          <label className='flex gap-2'>
            <input type='radio' name='paymeth' checked={meth === 'wallet'} onChange={(ev) => setMeth(ev.target.value)} value="wallet" />
            Wallet Balance
          </label>
        ) : null)}

      <div className='py-2 flex gap-6 justify-between text-primary/80'>
        <button className='btn2' onClick={handleSubmit}>{loading ? "Please Wait..." : "Make Payment"}</button>
      </div>


        <ModalLocal open={listBanks} setOpen={setListBanks}>
          <div className=' bg-secondary p-5 flex flex-col gap-5 relative'>
            <div className='py-5 -m-4 relative '>
              <div className='btn_close z-10' onClick={() => setListBanks(false)}>X</div>
            </div>

            {banksLoading?(
              <div className='w-full flex items-center border-primary justify-center p-2'>
                <div className='load'></div>
              </div>
            ):null}
            {selectedBank ? (
              <div className='flex flex-col'>
                <div className='flex justify-between gap-2'>
                  Account No : <span> {selectedBank.accountNumber}</span>
                </div>
                <div className='flex justify-between gap-2'>
                  Country : <span> {selectedBank.country}</span>
                </div>
              </div>
            ):null}
            <TextField label="Select Banks" value={selectedBank && selectedBank.bankName} select size='small' className='min-w-[250px]'>
              <MenuItem></MenuItem>
              {banks.map((obj,i) => (
                <MenuItem value={obj.bankName} onClick={() => setSelectedBank(obj)}>{obj.bankName}</MenuItem>
              ))}
            </TextField>
            <button className='btn2' onClick={() => handleSubmit('verify')}>{loading ? "Please Wait..." : "Verify"}</button>

            <button className='btn1' onClick={() => handleSubmit()}>Verify Later</button>
          </div>
        </ModalLocal>

    </form>
  )
}
