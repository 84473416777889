import { Flight } from "@mui/icons-material";
import FlightInfoCard from "./FlightInfoCard";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { def } from "../../config";
import { formatMoney } from "../../features/utils/formatMoney";
import { getCurrencySymbol } from "../../features/utils/currency";
import ViewFareRule, { ViewFareRuleVerteil } from "./ViewFareRule";
import { useState } from "react";
import ModalLocal from "../mini/ModalLocal";
import FlightOfferDisplay from "./FlightOfferDisplay";
import FlightDisplay from "./FlightDisplay";
import { Icon } from "@iconify/react";
import Collapse from "../mini/Collapse";
import { setBookingData } from "../../redux/reducers/flight/flightBookingSlice";
import { clone } from "../../features/utils/objClone";
import { checkBookable } from "../../controllers/flight/checkBookable";
import getSupplierOffice from "../../features/flights/getSupplierOffice";
// import { encrypt } from "../../features/utils/crypto";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { setBookingData } from "../../redux/reducers/flight/flightBookingSlice";
// import { offerDataTemp } from "../../data/flight/offerData";

export function FlightOfferDetail({obj,onBook,showDetail}) {
  const [searchParam] = useSearchParams();
  const q = searchParam.get('q');
  const {bookingData} = useSelector(state => state.flightBooking);
  const [selectedChange,setSelectedChange] = useState([]);
  const [modData,setModData] = useState(obj);
  const [loading,setLoading] = useState(false);

  // const dispatch = useDispatch();

  const [openSegments,setOpenSegments] = useState(false);
  
  let refQuery = searchParam.get('referralCode');
  

  if(refQuery)
    refQuery = `?referralCode=${refQuery}`

  let initLoc = "";
  try {
    initLoc = obj.segments[0].departureLocation || "";
  } catch(ex) {
    
  }

  async function handleSubmit() {
    setLoading(true);
    if(showDetail)
      await showDetail(modData)
    setLoading(false);
  }

  async function handleCheckBookable(seg,{i,j}) {
    let tempSelected = [...selectedChange];
    tempSelected[i] = {i,j,bookable: false,loading: true}
    setSelectedChange(tempSelected);

    let modData = {
      itineraries: [{
        from: seg.departureLocation,
        to: seg.arrivalLocation,
        segments: seg.flights.map(flight => ({
          from: flight.departureLocation,
          to: flight.arrivalLocation,
          bookingClass: flight.cabinCode,
          nrOfPassengers: Object.keys(obj.passengers||{}).length,
          flightNumber: flight?.flightNumber,
          arrivalDate: flight?.arrivalDate,
          departureDate: flight?.departureDate,
          marketingCarrier: flight?.marketingCarrier,
          // ...flight
        }))
      }],
      office: getSupplierOffice(obj?.supplier)
    }

    const res = await checkBookable(modData);
    if(res.return) {
      let modObj = clone(bookingData.beforePrice || obj)
      modObj.segments[i] = seg;

      setModData(modObj);
      // dispatch(setBookingData({...bookingData,offer: null,orderData: null,beforePrice: modObj}))
      tempSelected[i] = {i,j,bookable: true,loading: false}
    } else tempSelected[i] = {i,j,bookable: false,loading: false}

    setSelectedChange(tempSelected);
  }
  console.log(" -- ",obj)
  const data = obj;

  let bookable = (obj?.bookable||[])?.every(val => val);
  let fixes = []
  selectedChange.map(obj => {fixes[obj.i] = obj.bookable; return true;})
  if(fixes.length === obj?.bookable?.length)
    if(fixes.every(val => val))
      bookable = true;

  // const enc = encrypt(JSON.stringify({offer: obj}));
  // window.localStorage.setItem("offerDetail",enc);
  const totalPassengers = Object.values(bookingData?.offer?.passengers || {})?.reduce((cur,acc) => ({total: (cur?.total || 0) + (acc?.total || 0)}),{total: 0})?.total || 0
  console.log(totalPassengers);
  return !obj ? null : (
    <div className='bg-secondary p-6 min-h-screen self-end sticky bottom-0 overflow-clip rounded-2xl'>
      <div className='flex flex-col py-2 items-center gap-3'>
        <div className='flex gap-3'>
          <h5>
            {data.segments[0].flights[0].departureLocation}
          </h5>
          <div className='mr' /><Flight className='rotate-90' /><div className='mr' />
          <h5>
            {data.segments[0].flights[0].arrivalLocation}
          </h5>
        </div>
        {
          onBook === true ? (
            bookingData && bookingData.orderData ? (
              <h4 className='text-theme1 text-center'>{def.currency}{formatMoney(bookingData.orderData.editedtotalPrice)}</h4>
            ) : (
              <h4 className='text-theme1 text-center'>{def.currency}{(data.farePrice?.fareTotal && formatMoney(data.farePrice.fareTotal)) || data.formatedTotalAmount}</h4>
            )
          ) : (
            <h4 className='text-theme1 text-center'>{def.currency}{(data.farePrice?.fareTotal && formatMoney(data.farePrice.fareTotal)) || data.formatedTotalAmount}</h4>
          )
        }
        {bookingData.offer && bookingData.offer.passengers && Object.entries(bookingData.offer.passengers).map(([key,obj],i) => 
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-4 justify-between w-full" key={i}>
              <span className="capitalize">{obj?.total ? obj.total+'x ':''}{key}</span>
              {/* <span>{Object.keys(bookingData.offer.passengers).length}x Passenger</span> */}
              <span>
                {def.currency}
                {
                  formatMoney(obj.totalAmount)
                  // formatMoney(Object.values(bookingData.offer.passengers).map((val) => val.totalAmount).reduce((prev,cur) => prev + cur,0))
                }
              </span>
            </div>
            <div className="flex gap-4 justify-between pl-4">
              <span>Flight fare</span>
              <span>{def.currency}{formatMoney(obj.baseAmount)}</span>
            </div>
            <div className="flex gap-4 justify-between pl-4">
              <span>Tax</span>
              <span>{def.currency}{formatMoney(obj.totalAmount - obj.baseAmount)}</span>
            </div>
            <hr className="w-full" />
          </div>
        )}
        

        {totalPassengers > 1 && bookingData?.offer?.farePrice?.fareBase && (
          <div className="flex gap-4 justify-between w-full">
            Total Fare
            <span>
              {def.currency}{formatMoney(bookingData?.offer?.farePrice?.fareBase?.toString()?.replaceAll(',',''))}
            </span>
          </div>
        )}
        {bookingData?.offer?.farePrice?.appliedRules?.filter(obj => obj?.rule?.fareRuleType?.includes('SERVICE_FEE'))?.map((obj,i) => (
          obj?.rule?.fareRuleType ? (
            <div className='flex gap-4 justify-between w-full' key={i}>
              Service charge
              <span>
                {def.currency}{formatMoney(obj?.rule?.markUp)}
              </span>
            </div>
          ) :null
        ))}
        {totalPassengers > 1 && bookingData?.offer?.taxAmount && (
          <div className="flex gap-4 justify-between w-full">
            Total Tax
            <span>
              {def.currency}{formatMoney(bookingData?.offer?.taxAmount?.toString()?.replaceAll(',',''))}
            </span>
          </div>
        )}
        {bookingData.seats && bookingData.seats.length ? (
          <div className="w-full">
            <div className="flex gap-4 justify-between w-full">
              <span>{bookingData.seats.length}x seating</span>
              <span>{getCurrencySymbol(bookingData.seats[0].pricingDetail.currency)}{bookingData.seats.map(seat => seat.pricingDetail.fareAmount).reduce((total, fare) => total + fare, 0)}</span>
              {/* <span>{getCurrencySymbol(data.pricingDetail.currency)}{data.pricingDetail.amount}</span> */}
            </div>
            <hr className="w-full"/>
          </div>
        ):null}
        
        {bookingData?.orderData?.selectedUpgrades?.map((obj,i) => (
          <div className="w-full">
            <div className="flex gap-4 justify-between w-full" key={i}>
              <span>{obj?.name || 'Service'}</span>
              <span>{def.currency}{formatMoney(obj?.price)}</span>
            </div>
            <hr className="w-full"/>
          </div>
        ))}
        
        {bookingData.insurance && (
          <div className="w-full">
            <div className="flex gap-4 justify-between w-full">
              <span>Insurance</span>
              <span>{getCurrencySymbol(bookingData.insurance.insuranceAPIData.insuranceApiData[0].currency)||""}{formatMoney(bookingData.insurance.insuranceAPIData.insuranceApiData[0].fareAmount)}</span>
            </div>
            <hr className=" w-full" />
          </div>
        )}
        
        {data.segments.map((flights,i) => (
          <FlightInfoCard key={i} data={flights} label={initLoc === flights.arrivalLocation ? 'Return' : 'Departure'} />
        ))}
        {/* {data.segments[1] ? (
          <FlightInfoCard data={data.segments[1]} label='Return' />
        ):null} */}
        {/* <Link to="#" onClick={} className='py-4 text-theme1'>View fare rule</Link> */}
        {obj.supplier?.toLowerCase() === 'verteil' ? 
          <ViewFareRuleVerteil data={data} />
        :
          <ViewFareRule data={data} />
        }
        {!bookable ? 
          <label className="text-red-500 text-center font-bold">This flight is not available for booking</label>
        :null}
        {onBook ? null :
          bookable ? 
            <Link to={`/flights/book/${q}${refQuery?refQuery:''}`} className='btn2 text-center w-full'>Book</Link>
          : <button className="btn1 w-full" onClick={() => setOpenSegments(true)}>Select another flight</button>
          // data.fareRule ? 
          // (
          // ) 
          // : (
          //   <button disabled className='btn btn_nofocus justify-center cursor-not-allowed text-center w-full flex gap-2'>
          //     {/* <div className="load"></div> */}
          //     Book</button>
          // )
        }
        <ModalLocal open={openSegments} setOpen={setOpenSegments}>
          <div className='flex flex-col gap-10 max-w-[600px] bg-secondary p-10 max-h-[80vh] overflow-auto'>
            <div className='flex flex-col gap-6 relative'>
              <h4>Choose alternative options</h4>
              {obj?.otherSegments?.map((flights,i) => !obj?.bookable[i] && !flights.length)?.some(val => !val) ? 
                <h5 className="text-red-500 bg-red-100 p-1 px-2">This flight doesn't have alternative options. please select different flight</h5>
              :null}
              <div className="flex flex-col gap-4">
                {obj?.otherSegments?.map((flights,i) => !obj?.bookable[i] && (
                  // <FlightOfferDisplay key={i} data={{segments: obj}} showDetail={(obj) => console.log(obj)} />
                  <div key={i}>
                    <Collapse label={
                      <div className="flex gap-4 items-center">
                        <h3>{data.segments[i].departureLocation}</h3>
                        <Icon icon='ri:plane-fill' className="rotate-90" />
                        <h3>{data.segments[i].arrivalLocation}</h3>
                      </div>
                    }>
                      {flights.length ? flights.map((flight,j) => {
                        let curSeg = selectedChange.find(obj => obj.i === i && obj.j === j);
                        console.log(' --< ',curSeg)
                        return (
                          <div className={`cursor-pointer hover:shadow-md shadow-primary transition-all relative
                            ${ curSeg && (
                              curSeg?.loading ? 'border !border-primary !border-opacity-100':
                              curSeg?.bookable === true ? 'border-green-500': curSeg.bookable === false ? 'test':''
                            )}
                          `} key={i+j}
                            onClick={() => handleCheckBookable(flight,{i,j})}
                          >
                            {curSeg?.loading ? 
                              <div className="absolute top-0 left-1/2 border-primary py-3">
                                <div className="load"></div>
                              </div>
                            :null}
                            <FlightDisplay flight={flight} />
                          </div>
                        )
                      })
                      :
                        <p className="text-center">No Options</p>
                      }
                    </Collapse>
                  </div>
                ))}
              </div>
              <div className="flex gap-4  bg-secondary py-4">
                <button className='flex-1' onClick={() => setOpenSegments(false)}>Close</button>
                <button onClick={handleSubmit} className="btn2 flex-1">
                  {loading?<div className="load"></div>:'Change'}
                </button>
              </div>
            </div>
          </div>
        </ModalLocal>
        
      </div>
    </div>
  )
}

// function ViewFareRule(data) {
//   const dispatch = useDispatch();
//   const scrollRef = createRef();
  
//   function handleScroll(ev) {
//     // if(ev.deltaY > 0)
//     //   scrollRef.current.scrollTop += 30;
//     // else 
//     //   scrollRef.current.scrollTop -= 30;
    
//   }

//   function openFareRule() {
//     dispatch(setModal(true))
//     dispatch(setModalComp(modalComp));
//   }

//   const modalComp = (
//     <div ref={scrollRef} className='!max-h-screen realtive px-2 !w-full  py-5' onWheel={handleScroll}>
//       <div className='bg-secondary rounded-md p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
//         <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
//         <div dangerouslySetInnerHTML={{__html: data.data || "No Fare Rules"}} className="max-h-[calc(100vh-100px)] overflow-y-auto">
//         </div>
//       </div>
//     </div>
//   )
//   // console.log('farerule : ',data)
//   return data.data && (
//     <div>
//       <button onClick={openFareRule} className='py-4 text-theme1'>View fare rule</button>
//     </div>
//   )
// }