import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { MenuItem, TextField } from '@mui/material';
import { staffSignupData } from '../../../data/user/staffData';
import EmailInput from '../../../components/form/EmailInput';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import PasswordInput from '../../../components/form/PasswordInput';
import moment from 'moment';
import CountriesInput from '../../../components/form/CountriesInput';
import RolesInput from '../../../components/form/RolesInput';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { createStaffReq } from '../../../controllers/user/createStaff';

export default function Index() {
  const [data,setData] = useState({...staffSignupData,confirmPassword: ''});
  const [user,setUser] = useState({});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeaderTitle("Staff"))
  },[dispatch])

  

  async function createStaff(ev) {
    ev.preventDefault();

    if(data.password !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords don\'t match!']))

    setLoading(true);
    const res = await createStaffReq(data);
    setLoading(false);
    if(res.return) {
      try {
        setUser({id: res.data.id})
      } catch(ex) {}
      return dispatch(setAlertMsg(['success','Staff Member Created.']))
    } else dispatch(setAlertMsg(['error',res.msg]))
    console.log(data)
  }
  return (
    <div className='w-full p-4 m-2'>
      <form action='' onSubmit={createStaff} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Create a staff</h2>
          <TextField size='small' label='Title' select
            className='self-start min-w-[100px]'
            value={data.title}
            onChange={(ev) => setData({...data,title: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }}>
              <MenuItem value='Mr'>Mr</MenuItem>
              <MenuItem value='Ms'>Ms</MenuItem>
              <MenuItem value='Mrs'>Mrs</MenuItem>
          </TextField>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' 
              value={data.lastName}
              onChange={(ev) => setData({...data,lastName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' 
              value={data.firstName}
              onChange={(ev) => setData({...data,firstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <EmailInput size='small' label='Email' 
              value={data.email}
              onChange={(val) => setData({...data,email: val})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <PhoneNumberInput size='small' label='Phone' 
              value={data.phoneNumber}
              onChange={(val) => setData({...data,phoneNumber: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Birth Date' type='date'
              value={moment(data.dob || "").format("yyyy-MM-DD")}
              onChange={(ev) => setData({...data,dob: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          
          <RolesInput id={user.id} />

          <div className='flex gap-4'>
            <PasswordInput size='small' label='Password' 
              value={data.password}
              onChange={(val) => setData({...data,password: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PasswordInput size='small' label='Re-enter Password' 
              value={data.confirmPassword}
              onChange={(val) => setData({...data,confirmPassword: val})}
              />

          </div>

          <h5>Employment Details</h5>
          <div className='flex gap-4'>
            <TextField size='small' label='Employee Id' 
              value={data.employeeId}
              onChange={(ev) => setData({...data,employeeId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Department' 
              value={data.employeeDepartment}
              onChange={(ev) => setData({...data,employeeDepartment: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Designation' 
              value={data.employeeDesignation}
              onChange={(ev) => setData({...data,employeeDesignation: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='National Id' 
              value={data.nationalId}
              onChange={(ev) => setData({...data,nationalId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='National Id Expiry Date' type='date'
              value={moment(data.nationalIdExpiry || "").format("YYYY-MM-DD")}
              onChange={(ev) => setData({...data,nationalIdExpiry: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>

          <h5>Address Details</h5>
          <div className='flex gap-4'>
            <TextField size='small' label='Nationality' 
              value={data.nationality}
              onChange={(ev) => setData({...data,nationality: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <CountriesInput size='small' label='Country' 
              value={data.countryCode}
              onChange={(val) => setData({...data,countryCode: val.iata2})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='City' 
              value={data.cityName}
              onChange={(ev) => setData({...data,cityName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Address One' 
              value={data.addressLine1}
              onChange={(ev) => setData({...data,addressLine1: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Address Two' 
              value={data.addressLine2}
              onChange={(ev) => setData({...data,addressLine2: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <button className='btn2 self-start flex items-center gap-2'>
            {loading? <div className='load'></div>:null}
            Create Staff</button>
        </div>
      </form>
    </div>
  )
}
