import { Add, ArrowBack, Check } from '@mui/icons-material'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { bookFlight } from '../../../controllers/flight/bookFlight';
// import { useNavigate, useParams } from 'react-router-dom';
// import { decrypt, encrypt } from '../../../features/utils/crypto';
import { InsuranceDataTemp } from '../../../data/flight/InsuranceData';
import getFlightInsurance from '../../../controllers/flight/getInsurance';
import { useDispatch, useSelector } from 'react-redux';
import addFlightInsurance from '../../../controllers/flight/addInsurance';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import getFlightServices from '../../../controllers/flight/getFlightServices';
import { FlightServiceTemp } from '../../../data/flight/servicesData';
import ModalLocal from '../../../components/mini/ModalLocal';
import Collapse from '../../../components/mini/Collapse';
import getFlightOrder from '../../../controllers/flight/getFlightOrder';
import { MenuItem, TextField } from '@mui/material';
import applyFlightService from '../../../controllers/flight/applyFlightService';
import { def } from '../../../config';
import { Icon } from '@iconify/react';
import removeFlightService from '../../../controllers/flight/removeFlightService';

function FlightServices({back,next}) {
  // const [err,setErr] = useState("");
  // const {id} = useParams();
  // const navigate = memo(useNavigate())
  const [loading] = useState(false);
  const dispatch = useDispatch();
  const {bookingData} = useSelector((state) => state.flightBooking);
  const data = useMemo(() =>bookingData,[bookingData]);
  const array = bookingData.orderData && bookingData.orderData.bookingUsers.map(obj => obj)

  // console.log('array: ',array)

  // const data = JSON.parse(decrypt(id));
  // console.log("on flight service : ",data);

  useEffect(() => {
    if(data && data.page !== 'services')
      dispatch(setBookingData({...data,page: 'services'}));
    },[data,dispatch])
    

  async function handleSubmit() {
    next();
  }
  return (
    <form onSubmit={(ev) => ev.preventDefault()} className='p-3 flex flex-col gap-5 bg-secondary rounded-2xl '>
      <div className='p-4'>
        {/* <h6>Passenger 1</h6> */}
        <Services />
        <br />
        {
        array.map((obj,i) => (
          <Collapse key={i} show={i<5} label={<h6>Passenger {i+1} [{obj.boardingUserType}]</h6>}>
            <div className='flex gap-4 items-center'>
              <Insurance travelerId={obj.id} />
            </div>
          </Collapse>
        ))
        }
      </div>
      {/* <div className='p-4'>
        <p>Remark</p>
        <textarea className='border border-primary/20 p-3 min-h-[30px] w-full'></textarea>
        <label className='flex gap-3'>
          <input type='checkbox' />
          I have read and accept the rules and restrictions
        </label>
      </div>*/}
      <div className='p-3 flex gap-6 justify-between text-primary/80'>
        {/* <div>
          {back ? (
            <button className='p-2 ' onClick={back}><ArrowBack /> Back</button>
            ): null}
        </div> */}
        <button className='p-2 ' onClick={handleSubmit}> Skip</button>
        {data.orderData && data.orderData.id ? (
          <button className='btn2 min-w-[150px]' onClick={handleSubmit}>{loading ? "Please Wait..." : "Next"}</button>
        ):<button className='btn btn_nofocus min-w-[150px]' disabled>Next</button>}
      </div> 

    </form>
  )
}

function Services() {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const [data,setData] = useState(FlightServiceTemp);
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const [modalOpen,setModalOpen] = useState(false);

  const [selected,setSelected] = useState();
  const [options,setOptions] = useState([]);


  async function load() {
    if(!bookingData.orderData) return false;

    let params = {
      limit: 10
    }
    setLoading(true)
    const res = await getFlightServices((new URLSearchParams(params)).toString());
    setLoading(false);
    if(res.return) {
      setOptions(res.data)
      // setData(res.data)
      setModalOpen(true)
    } else dispatch(setAlertMsg(['warning','No Services']))
  }
  async function addService() {
    if(!bookingData.orderData) return false;

    setLoading(true);
    // let pricingDetail = {amount: 0,fareAmount: 0,...data.result.pricingDetail};
    // console.log("pricing detail : ",pricingDetail)
    // console.log(bookingData.orderData.id,{flightServiceId: selected})
    const res = await applyFlightService(bookingData.orderData.id,{flightUpgradeId: selected});
    setLoading(false);
    if(res.return) {
      getFlightOrder(bookingData.orderData.id)
      .then((res) => {
        if(res.return)
          dispatch(setBookingData({...bookingData,orderData: res.data,services: res.data}))
      })

      dispatch(setBookingData({...bookingData,services: res.data}))
    }
    else dispatch(setAlertMsg(['error',res?.msg || 'Failed Adding Service']))
    
    setModalOpen(false);
  }

  // let data = ServicesDataTemp;
  return (
    <div>
      <div className='flex gap-3 flex-wrap items-center'>
        {bookingData?.orderData?.selectedUpgrades?.map((obj,i) => (
          <ShowService key={i} data={obj} bookingData={bookingData} />
        ))}
        <button 
          onClick={load}
          className='btn1_outlined sml flex items-center gap-1 '>
            {loading ? 
              <div className='load'></div>
              :
              // bookingData.services ? 
              //   ([<Check />,'Service'])
              // : 
                [<Add className='h-4' />,'Add paid services']
              }
          </button>
      </div>
      <ModalLocal open={modalOpen} setOpen={setModalOpen}>
        <div className='h-full flex items-center'>
          <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-4 relative'>
            <div className='btn_close' onClick={() => setModalOpen(false)}>X</div>
            {/* <h4>Premium</h4> */}
            {/* <TextField select label='Choose Services' className='min-w-[200px]' size='small'
              value={(selected && selected.id) || ""} >
              {options.map((opt,i) => (
                <MenuItem key={i} className='flex flex-col gap-2'>
                  <div className='flex gap-1'>
                    {opt.name} {opt.price}
                  </div>
                  {opt.descriptions && opt.descriptions.map((descr,i) => (
                    <p key={i}>{descr}</p>
                  ))}
                </MenuItem>
              ))}
            </TextField> */}
            <div className='flex gap-2 overflow-x-auto max-w-[80vw] py-3 snap-x '>
              {options.map((opt,i) => (
                <div onClick={() => setSelected(opt.id)} type='radio' name='service' key={i} 
                  className={`m-2 min-w-[150px] flex overflow-hidden flex-col border snap-center bg-secondary shadow-md p-4 rounded-md flex-1 cursor-pointer ${selected === opt.id ? ' border-theme1 shadow-xl ':''}`}>
                  <div className='flex-1 max-w-full'>
                    <h3 className='break-words'>{opt.name}</h3>

                    {opt.descriptions && opt.descriptions.map((descr,i) => (
                      <p className=' p-1' key={i}>{descr}</p>
                    ))}
                  </div>
                  <h4 className='py-2'>{def.currency}{opt.price}</h4>
                </div>
              ))}
            </div>
            {/* <h2>{data.name}</h2>
            <h2>{data.price}</h2>
            <h2>{data.description}</h2> */}
            <button className='btn2' disabled={loading} onClick={addService}>{loading? 'Loading...' : 'Confirm Services'}</button>
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}

function ShowService({data,bookingData}) {
  const [open,setOpen] = useState(false);
  const [loading,setLoading] = useState(false);

  const dispatch = useDispatch();

  async function handleRemove() {
    setLoading(true);
    const res = await removeFlightService(bookingData.orderData.id,{flightUpgradeId: data?.id});
    if(res.return) {
      getFlightOrder(bookingData.orderData.id)
      .then((res) => {
        if(res.return)
          dispatch(setBookingData({...bookingData,orderData: res.data,services: res.data}))
      })

      dispatch(setBookingData({...bookingData,services: res.data}))
    }
    else dispatch(setAlertMsg(['error','Something went wrong removing service!']))

    setOpen(false);

    setLoading(false);
  }
  return (
    <div>
      <button className='btn1_outlined sml flex gap-2 items-center'
        onClick={() => setOpen(true)}
      >
        <Check className='h-4' />
        {data?.name}
      </button>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='h-full flex items-center'>
          <div className='flex flex-col rounded-md p-5 pt-10 items-center gap-4 relative'>
            <div name='service'
              className={`m-2 min-w-[150px] flex overflow-hidden flex-col border snap-center bg-secondary shadow-md p-4 rounded-md flex-1 cursor-pointer `}>
              <div className='flex-1 max-w-full'>
                <h3 className='break-words'>{data?.name}</h3>

                {data?.descriptions && data?.descriptions.map((descr,i) => (
                  <p className=' p-1' key={i}>{descr}</p>
                ))}
              </div>
              <h3 className='py-2'>{def.currency}{data?.price}</h3>
              <div className='flex gap-4 pt-4 justify-between'>
                <button className='btn1_outlined rounded-md' 
                  onClick={() => setOpen(false)}>Close</button>
                <button className='rounded-md px-4 py-2 !bg-red-500 text-white'
                  onClick={() => handleRemove()}
                >
                  {loading ? 
                    <div className='load'></div>
                    :
                    <Icon icon='ic:delete' />
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}

function Insurance({travelerId}) {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const [data,setData] = useState(InsuranceDataTemp);
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const [modalOpen,setModalOpen] = useState(false);

  console.log("data now",data)

  // useEffect(() => {
  //   try {
  //     setData(bookingData.insurance.result)
  //   } catch(ex) {console.log("FI")};
  // },[bookingData])

  async function load() {
    if(!bookingData.orderData) return false;

    setLoading(true)
    const res = await getFlightInsurance(bookingData.orderData.id);
    setLoading(false);
    if(res.return) {
      if(res.data) {
        console.log("dd",res.data)
        const d = res.data.find(obj => obj.travelerId === travelerId)
        if(d && d.data)
          setData(d.data)
      }
      setModalOpen(true)
    } else dispatch(setAlertMsg(['warning','No Insurance']))
  }
  async function addInsurance(pricingDetail) {
    if(!bookingData.orderData) return false;
    // let obj = {
    //   fareAmount: bookingData.offer.farePrice.fareTotal,
    //   total: bookingData.offer.totalAmount
    // }

    setLoading(true);
    // let pricingDetail = {...obj,...data.result.pricingDetail};
    // console.log("pricing detail : ",pricingDetail)
    let orders = [{travelerId,pricingDetail}]
    // console.log('req: ',{orders});
    const res = await addFlightInsurance(bookingData.orderData.id,{orders});
    setLoading(false);
    if(res.return) {
      // setData(res.data);
      getFlightOrder(bookingData.orderData.id)
      .then((res) => {
        if(res.return)
          dispatch(setBookingData({...bookingData,orderData: res.data,insurance: {...res.data,travelerId: travelerId}}))
      })
      dispatch(setBookingData({...bookingData,insurance: {...res.data,travelerId: travelerId}}))

    }
    else dispatch(setAlertMsg(['error','Failed Adding Insurance']))
    
    setModalOpen(false);
  }

  // let data = InsuranceDataTemp;
  return (
    <div >
      <button 
        onClick={load}
        className='btn1 sml flex gap-1 items-center '>
          {loading ? 
            <div className='load'></div>
            :
            bookingData.insurance && (bookingData.insurance.travelerId === travelerId) ? 
              ([<Check />,'Insurance'])
            : 
              [<Add className='h-5' />,'Add Insurance']
          }
        </button>
      <ModalLocal open={modalOpen} setOpen={setModalOpen}>
        <div className='h-full flex items-center'>
          <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-2 relative'>
            <div className='btn_close' onClick={() => setModalOpen(false)}>X</div>
            {/* <h4>Premium</h4> */}
            <h2>{(data.result && def.currency)}{data.result && data.result.pricingDetail.amount}</h2>
            <button className='btn2' disabled={loading} onClick={() => addInsurance(data.result && data.result.pricingDetail)}>{loading? 'Loading...' : 'Confirm Insurance'}</button>
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}

export default memo(FlightServices);