import mergeRecursive from "../../features/utils/mergeRecursive";

export const flightDataTemp = {
  id: '',
  bookingId: '',
  ticketId: '',
  bookingUsers: [],
  flightData: {
    "tax_amount": '',
    "fare_amount": '',
    "total_amount": '',
    "currency": '',
    booked_flights: [
      {
        cabin: '',
        bagage: '',
        duration: '',
        booking_id: '',
        status: '',
        airline_detail: {
          name: '',
        },
        origin: {
          name: '',
          date: '',
          time: '',
          location: '',
        },
        destination: {
          name: '',
          date: '',
          time: '',
          location: '',
        },
        booked_segments: [
          {
            carrierIcon: '',
          }
        ],
        number_of_stops: 2
      }
    ],
    "booked_travellers": [
      {
          "id": '',
          "type": '',
          "title": '',
          "gender": '',
          "currency": '',
          "last_name": '',
          "booking_id": '',
          "created_at": '',
          "first_name": '',
          "tax_amount": '',
          "updated_at": '',
          "fare_amount": '',
          "total_amount": '',
          "date_of_birth": '',
      }
    ],
  },
  selectedSeats: []
}

export function templateDetails(obj) {
  let data = mergeRecursive({...obj},flightDataTemp);
  return data;
}
