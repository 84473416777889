import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";


export default async function getCalendarSearch(data,userId) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  console.log('Request : ',data);

  let headers = {Authorization: `Bearer ${token}`}

  if(userId)
    headers['user-id'] = userId;
  
  await fetchServer({method: "POST",url: path.api+`/flights/calendar`,
      headers,
      data: data
  })
  .then((res) => {
    console.log('[c]: ',res)
    if(res) {
      if(res.data.success) {
        const {success,data,...cat} = res.data;
        result = {return: 1,msg: "Successfull",data: res.data.data,cat};
      }
    } 
  })
  .catch((err) => {
    console.log("Network Error");
    result.msg = "Network Error";
  })

  return result;
}