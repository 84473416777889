import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { decrypt } from '../../../features/utils/crypto';
import { useDispatch, useSelector } from 'react-redux';
import HotelOfferFilter from './OffersFilter';
import TourSearchInput from '../../../components/search/TourSearchInput';
import TourDisplay from '../../../components/Tour/TourDisplay';
import Pagination from '../../../components/mini/Pagination';
import { Cancel, CancelTwoTone, LocationOn, Person } from '@mui/icons-material';
import getTourOffers from '../../../controllers/search/getTourOffers';
import { def } from '../../../config';
import moment from 'moment';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';
import ReadMore from '../../../components/mini/ReadMore';
import getTourProOffers from '../../../controllers/search/getTourproOffers';


// const tempCat = {
//   Hotels: 257,
//   Motels: 57,
//   Resorts: 27,
// }

const fetchedData = createRef([]);

export default function Index() {
  const {tourBooking} = useSelector(state => state.tourBooking)
  const [data,setData] = useState([]);
  const [curDetail,setCurDetail] = useState();
  // const [cat] = useState(tempCat);
  const [loading,setLoading] = useState(false);
  const [resMsg] = useState("No Result");
  const [searchParam] = useSearchParams();
  const q = searchParam.get('q');
  const [pag] = useState({limit: 16})


  const dispatch = useDispatch();

  const fetchData = useCallback(async (req) => {
    if(!q) return {return: false};
    let obj = req || JSON.parse(decrypt(q));
    let query = {...obj};
    // try {
    //   if(obj.destinations[0].date !== '')
    //     query['product_start_duration'] = moment(obj.destinations[0].date).format('dddd, MMMM MM,YYYY')
  
    //   let short_address = obj.destinations[0].arrivalLocation;
    //   if(short_address !== '') {
    //     if(obj.destinations[0].arrivalLocation.city)
    //       short_address = obj.destinations[0].arrivalLocation.city.toString();
    //     // query['short_address'] = short_address
    //     query['country'] = short_address
    //     query['city'] = short_address
    //   }
    // } catch(ex) {}
    // console.log('filter ', query)

    const userId = tourBooking?.as?.id;
    const userType = tourBooking?.userType;
    // return await getTourOffers((new URLSearchParams(query)).toString());
    return await getTourProOffers((new URLSearchParams(query)).toString(),userId,userType);

    //eslint-disable-next-line
  },[q])


  useEffect(() => {
    let t = null;

    async function load() {
      setLoading(true);
      // let obj = JSON.parse(decrypt(q));
      // console.log(' -- ',q)
      // console.log(' -- ',obj)
      // const res = await getFlightOffers(obj);
      const res = await fetchData();

      // let obj = JSON.parse(decrypt(q));

      console.log('[p] res : ',res)
      setLoading(false);
  
      if(res.return) {
        fetchedData.current = res.data;
        setData(res.data)
      }
      // setResMsg(res.msg);
      // console.log(res);
    }  
    load();

    return () => clearTimeout(t);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchData,dispatch,q])



  // function search(reqObj) {
  //   if(reqObj) {
  //     let enc = encrypt(JSON.stringify(reqObj));
  //     navigate(`/search/flight/offers?q=${enc}`);
  //   }
  // }

  function showDetail(obj) {
    const as = tourBooking?.as;
    const userType = tourBooking?.userType
    // setCurDetail(obj);
    dispatch(setTourBooking({as,userType,selected: data}))
  }

  
  const [filter,setFilter] = useState();

  useEffect(() => {
    if(filter && fetchedData.current)
      setData(fetchedData.current.filter(obj => obj.type === filter))
  },[filter])

  let cats = [];
  try {
    cats = [
      ...(fetchedData.current)].filter((obj,i,arr) => arr.findIndex(obj2 => obj.type === obj2.type) === i).map(obj => obj.type)
  } catch(ex) {}

  
  return (
    <div className='w-full flex flex-col gap-2  py-4  '>
      {/* <PriceTimeout /> */}
      <div className='bg-secondary px-4'>
        <TourSearchInput />
      </div>

      {/* <div className=' flex overflow-x-auto p-2 px-4'>
        <div className='rounded-l-md bg-theme1 text-white p-2'>Sort by category</div>
        <div className='flex flex-1'>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
          <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
        </div>
      </div> */}
      {!cats ? null : (
        <div className=' flex overflow-x-auto p-2 px-4 w-full text-sm'>
          {/* <div className='rounded-l-md bg-theme1 text-white p-2'>Sort by category</div> */}
          <div className='flex flex-1 gap-2 items-center'>
            {cats.length && filter ? 
              <CancelTwoTone className='cursor-pointer text-theme1'
                onClick={() => {setData(fetchedData.current); setFilter()}} />
            :null}
            {cats.map((title,i) => (
              <div key={i} 
                onClick={() => setFilter(title)}
                className={` ${filter === title ? 'bg-theme1 ':''}
                  rounded-full
                  border transition-all hover:shadow border-primary/20 flex items-center py-1 px-4 hover:bg-theme1 cursor-pointer`}>{title}</div>
            ))}
            {/* <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
            <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
            <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div>
            <div className='border border-primary/20 flex items-center p-2 px-4'>CategoryTitle</div> */}
          </div>
        </div>
      )}


      <div className='flex gap-4 '>
        <div className='bg-secondary self-end sticky bottom-0 min-h-[100vh] '>
          <HotelOfferFilter orgi={fetchedData.current || data} data={data} setData={setData} />
        </div>
        <div className='flex-1 flex flex-col gap-4 '>
          {
            loading ?
              <h3 className='bg-secondary p-5 rounded-md flex items-center justify-center text-primary/30 '>Loading...</h3>
            : data.length < 1 ?
              <h3 className='bg-secondary p-5 rounded-md flex items-center justify-center text-primary/30 uppercase'>{resMsg}</h3>
            : null              
          }

          <div className='min-h-full flex-1 flex flex-col justify-between'>
            {/* <div className={`flex flex-wrap gap-4 `}> */}
            <div className={`flexx flex-wrapx gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3`}>
              {data.slice(0,pag.limit).map((obj,i) => (
                <a href={"/tours/book/"+obj._id+"?type="+obj.type} target="_blank" rel='noreferrer'>
                  <TourDisplay key={i} data={obj} showDetail={showDetail} />
                </a>
              ))}
            </div>
            <div className='justify-self-end '>
              <Pagination perPage={pag.limit} total={100} />
            </div>
          </div>
          {/* {pag.limit < data.length ? 
            <button className='btn1' onClick={() => setPag(pag => ({...pag,limit: pag.limit + 5 > data.length ? data.length : pag.limit + 5}))}>Load More</button>
          : null} */}
        </div>
        <div className='self-end sticky bottom-0 min-h-full'>
          <Detail data={curDetail} />
          {/* <FlightOfferDetail data={data} setData={setData} obj={curDetail} /> */}
        </div>
      </div>
    </div>
  )
}

function Detail({data,close}) {
  const {tourBooking} = useSelector(state => state.tourBooking)
  // let temp = tourDataTemp;
  let temp = data;
  const dispatch = useDispatch();
  const [signinModal,setSigninModal] = useState(false);
  // let temp = tourDataTemp;
  function handleSelect() {
    setSigninModal(true);
    const as = tourBooking?.as;
    const userType = tourBooking?.userType

    dispatch(setTourBooking({as,userType,selected: data}))
  }

  console.log('selected ',data)

  return !data ? null : (
    <div className='flex flex-col gap-4 px-2 pb-6'>
      <div className='border border-primary/10 max-w-[400px] bg-secondary p-4'>
        <div className="flex justify-end">
          <Cancel onClick={close} />
        </div>
        <div className="flex gap-2 justify-between py-2 pb-4 items-center">
          <h5 className="font-bold ">{temp.product_name}</h5>
          <Link to={"/tours/book/"+temp._id} onClick={handleSelect} className='btn2'>Book Now</Link>
          {/* <Button variant="contained" className="text-sm scale-75" onClick={handleSelect}>
            Book Now
          </Button> */}
        </div>
        <img src={temp.photo || temp.image} alt='tour' className='w-full max-h-[300px] rounded-md' />
        <div className='flex flex-col gap-4 py-2'>
          <p>
            {temp.small_description}
          </p>
          <div>
            <b>Description</b>
            <p>
              {temp.long_description}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <b>Price</b>
            <div>
            <Person /> <b>{def.currency}{temp.farePrice?.fare_adult_price}</b> per adult
            </div>
            <div>
            <Person /> <b>{def.currency}{temp.farePrice?.fare_child_price}</b> per child
            </div>
            <div>
            <Person /> <b>{def.currency}{temp.farePrice?.fare_infant_price}</b> per infant
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <b>Details</b>
            <div className="flex flex-col pl-5 ">
              <b>Tour Guide Availability</b>
              <p>{temp.tour_guide_availability || 'Not Available'}</p>
            </div>
            <div className="flex flex-col pl-5 ">
              <b>Travel Dates</b>
              <div dangerouslySetInnerHTML={{ __html: temp?.travel_dates?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
            </div>
            <div className="flex flex-col pl-5 ">
              <b>Transportation</b>
              <div dangerouslySetInnerHTML={{ __html: temp?.transportation?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
            </div>
          </div>
          <div className="flex flex-col gap-4 py-4">
            <b>Experience</b>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Highlights</h6>
              <div className='flex-1'>
                {/* <li>{data.highlights}</li> */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.highlights?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Full Description</h6>
              <p className='flex-1'>
                <ReadMore>
                  {temp?.long_description}
                </ReadMore>
              </p>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Includes</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.inclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Itinerary</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions?} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.itinerary?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Accomodation</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions?} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.accomodation?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Exclusions</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions?} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.exclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Meals</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions?} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.meals?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Special Requirements</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {temp?.inclusions?} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: temp?.special_requirements?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>

          </div>
          {/* <div dangerouslySetInnerHTML={{__html: temp?.inclusions}}></div> */}
          <small>
            {/* <LocationOn /> */}
           {temp?.short_address} </small>
        </div>
        <hr />
        <div className='flex gap-2 flex-wrap justify-between p-4'>
          <div className="font-bold flex justify-between w-full">
            <span>
              {moment(temp?.product_start_duration).format("DD/MM/YYYY")}
            </span>
             - 
            <span>
              {moment(temp?.product_stop_duration).format("DD/MM/YYYY")}
            </span>
          </div>
          {/* <div>
            <h4 className='font-bold'>{def.currency}{temp?.adult_price}</h4>
            <div>
              <small>per person</small>
            </div>
          </div> */}
        </div>
      </div>
      {/* <a href={"/packages/book/"+temp?._id} onClick={handleSelect} className="w-full"> */}
        {/* <Button variant="contained" className="w-full" onClick={handleSelect}>
          Book Now
        </Button> */}
        {/* <a href={"/packages/book/"+temp?._id} onClick={handleSelect} className="w-full"> */}
         {/* <button variant="contained" className="btn2 w-full" onClick={handleSelect}>
           Book Now
         </button> */}
        <Link to={"/tours/book/"+temp._id} onClick={handleSelect} className='btn2'>Book Now</Link>

       {/* </a> */}

      {/* </a> */}
      {/* <SignInDialog open={signinModal} redirect={"/packages/book/"+temp?._id} onClose={() => setSigninModal(false)} /> */}
    </div>
  )
}

// function Detail({data,close}) {
//   // let temp = tourDataTemp;
//   let temp = data;
//   const dispatch = useDispatch();
//   const [signinModal,setSigninModal] = useState(false);
//   // let temp = tourDataTemp;
//   function handleSelect() {
//     setSigninModal(true);
//     dispatch(setTourBooking({selected: data}))
//   }

//   console.log('selected ',data)

//   return !data ? null : (
//     <div className='flex flex-col gap-4 px-2 pb-6'>
//       <div className='border border-primary/10 max-w-[400px] bg-secondary p-4'>
//         <div className="flex justify-end">
//           <Cancel onClick={close} />
//         </div>
//         <div className="flex gap-2 justify-between py-2 pb-4 items-center">
//           <h5 className="font-bold ">{temp.product_name}</h5>
//           <Link to={"/tours/book/"+temp._id} onClick={handleSelect} className='btn2'>Book Now</Link>
//         </div>
//         <img src={temp.photo || temp.image} alt='tour' className='w-full max-h-[300px] rounded-md' />
//         <div className='flex flex-col gap-4 py-2'>
//           <p>
//             {temp.small_description}
//           </p>
//           <div>
//             <b>Description</b>
//             <p>
//               {temp.long_description}
//             </p>
//           </div>
//           <div className="flex flex-col gap-2">
//             <b>Price</b>
//             <div>
//             <Person /> <b>{def.currency}{temp.selling_adult_price}</b> per adult
//             </div>
//             <div>
//             <Person /> <b>{def.currency}{temp.selling_child_price}</b> per child
//             </div>
//             <div>
//             <Person /> <b>{def.currency}{temp.selling_infant_price}</b> per infant
//             </div>
//           </div>
//           <div className="flex flex-col gap-4">
//             <b>Details</b>
//             <div className="flex flex-col pl-5 ">
//               <b>Tour Guide Availability</b>
//               <p>{temp.tour_guide_availability || 'Not Available'}</p>
//             </div>
//             <div className="flex flex-col pl-5 ">
//               <b>Travel Dates</b>
//               <div dangerouslySetInnerHTML={{ __html: temp?.travel_dates?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//             </div>
//             <div className="flex flex-col pl-5 ">
//               <b>Transportation</b>
//               <div dangerouslySetInnerHTML={{ __html: temp?.transportation?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//             </div>
//           </div>
//           <div className="flex flex-col gap-4 py-4">
//             <b>Experience</b>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Highlights</h6>
//               <div className='flex-1'>
//                 {/* <li>{data.highlights}</li> */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.highlights?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Full Description</h6>
//               <p className='flex-1'>
//                 <ReadMore>
//                   {temp?.long_description}
//                 </ReadMore>
//               </p>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Includes</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.inclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Itinerary</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions?} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.itinerary?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Accomodation</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions?} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.accomodation?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Exclusions</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions?} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.exclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Meals</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions?} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.meals?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>
//             <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//               <h6 className='w-[123px]'>Special Requirements</h6>
//               <div className='flex-1 flex gap-2'>
//                 {/* <Check fontSize='small' /> */}
//                 {/* {temp?.inclusions?} */}
//                 <ReadMore>
//                   <div dangerouslySetInnerHTML={{ __html: temp?.special_requirements?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//                 </ReadMore>
//               </div>
//             </div>

//           </div>
//           {/* <div dangerouslySetInnerHTML={{__html: temp?.inclusions}}></div> */}
//           <small>
//             {/* <LocationOn /> */}
//            {temp?.short_address} </small>
//         </div>
//         <hr />
//         <div className='flex gap-2 flex-wrap justify-between p-4'>
//           <div className="font-bold flex justify-between w-full">
//             <span>
//               {moment(temp?.product_start_duration).format("DD/MM/YYYY")}
//             </span>
//              - 
//             <span>
//               {moment(temp?.product_stop_duration).format("DD/MM/YYYY")}
//             </span>
//           </div>
//           {/* <div>
//             <h4 className='font-bold'>{def.currency}{temp?.adult_price}</h4>
//             <div>
//               <small>per person</small>
//             </div>
//           </div> */}
//         </div>
//       </div>
//       {/* <a href={"/packages/book/"+temp?._id} onClick={handleSelect} className="w-full"> */}
//         {/* <button variant="contained" className="btn2 w-full" onClick={handleSelect}>
//           Book Now
//         </button> */}
//         <Link to={"/tours/book/"+temp._id} onClick={handleSelect} className='btn2'>Book Now</Link>

//       {/* </a> */}
//     </div>
//   )
// }

// function Detail({data}) {
//   let temp = data;
//   const dispatch = useDispatch();
//   // let temp = tourDataTemp;
//   function handleSelect() {
//     dispatch(setTourBooking({selected: data}))
//   }

//   return data && (
//     <div className='flex flex-col gap-4 px-2'>
//       <div className='border border-primary/10 max-w-[400px]'>
//         <img src={temp.photo||temp.image} alt='tour' className='w-full max-h-[300px]' />
//         <div className='p-2 flex flex-col gap-2'>
//           <h5>{temp.product_name}</h5>
//           <p>
//             {temp.long_description}
//           </p>
//           <h5>Inclusion</h5>
//           {
//             <div dangerouslySetInnerHTML={{ __html: temp.inclusions.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//           }
//           {/* <div dangerouslySetInnerHTML={{__html: temp.inclusions}}></div> */}
//           <small><LocationOn /> {temp.short_address} </small>
//         </div>
//         <hr />
//         <div className='flex gap-2 flex-wrap justify-between p-2'>
//           <p>{temp.product_start_duration} - {temp.product_stop_duration}</p>
//           <div>
//             <h4>{def.currency}{temp.adult_price}</h4>
//             <div>
//               <small>per person</small>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Link to={"/tours/book/"+temp._id} onClick={handleSelect} className='btn2'>Book</Link>
//     </div>
//   )
// }