import { ArrowBack, Check, LocationOn, Person } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import FilterTravelPassengers from '../../../components/flight/filters/TravelPassengers'
import { tourDataTemp } from '../../../data/tour/tourDataTemp'
import { def } from '../../../config';
import ReadMore from '../../../components/mini/ReadMore';
import { useDispatch, useSelector } from 'react-redux';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';
import { clone } from '../../../features/utils/objClone';
import { formatMoney } from '../../../features/utils/formatMoney';
import { Typography } from '@mui/material';

export default function BookOption({back,next,data}) {
  let temp = tourDataTemp;
  const [passenger,setPassenger] = useState([
    {
      name: 'adults',
      farePrice: data?.farePrice?.fare_adult_price,
      label: <div className='flex flex-wrap items-center gap-2'><b> Adult</b> <small className='whitespace-nowrap'>(Over 11)</small> </div>,
      value: 0
    },
    {
      name: 'children',
      farePrice: data?.farePrice?.fare_child_price,
      label: <div className='flex flex-wrap items-center gap-2'><b> Child</b> <small className='whitespace-nowrap'>(2 - 11)</small></div>,
      value: 0
    },
    {
      name: 'infants',
      farePrice: data?.farePrice?.fare_infant_price,
      label: <div className='flex flex-wrap items-center gap-2'><b> Infant</b> <small className='whitespace-nowrap'>(Under 2)</small></div>,
      value: 0
    },
  ]);
  const {tourBooking} = useSelector(state => state.tourBooking)
  const dispatch = useDispatch();

  let totalPassengers = passenger.reduce((p,c) => ({value: p.value+c.value}),{value:0}).value

  useEffect(() => {
    
    let res = passenger.map(obj => {
      let farePrice = data?.farePrice?.fare_adult_price;  
      if(obj.name === 'children')
        farePrice = data?.farePrice?.fare_child_price;
      else if(obj.name === 'infants')
        farePrice = data?.farePrice?.fare_infant_price;
  
      return ({["number_of_"+obj.name]: obj.value,farePrice,name: obj.name,count: obj.value})
    });
    // console.log("setting : ",JSON.stringify(clone(passenger)))
    dispatch(setTourBooking({...tourBooking,passengers: res}))
  },[passenger])

  let total = passenger.map(obj => {
    let farePrice = data?.farePrice?.fare_adult_price;
    if(obj.name === 'children')
      farePrice = data?.farePrice?.fare_child_price;
    else if(obj.name === 'infants')
      farePrice = data?.farePrice?.fare_infant_price;

    return obj.value * farePrice;

  }).reduce((prev,cur) => prev+cur,0);

  function handleSelect() {
    next && next()
  }


  const [selectedImage,setSelectedImage] = useState(data.photo || data.image);

  console.log("tourdata -- ",data)

  return (
    <div className='w-full flex flex-col gap-4'>
      <div className='px-10'>
        <button className='flex items-center gap-3' onClick={() => window.history.back()}>
          <ArrowBack />
          <h5>Search</h5>
        </button>
      </div>
      <div className=' relative'>
        <div className="px-10 flex gap-2 flex-col py-2 pb-4 ">
          <Typography variant='h2' className="font-black">{data.product_name}</Typography>
          <div className='flex gap-2'>
            <LocationOn />
            <p>{data.city}, {data.country}</p>
          </div>
        </div>
        <div className='px-10 flex flex-wrap gap-4 relative'>
          <div className='flex-1 min-w-[200px]'>
            <img src={selectedImage} alt='tour' className='w-full max-h-[50vh] sm:max-h-[80vh] object-cover rounded-md' />
            <div className='flex overflow-auto snap-x snap-mandatory gap-2 py-2'>
              <img src={data.photo || data.image} alt='tour' className='w-[200px] h-[180px] snap-start object-cover cursor-pointer' 
                onClick={() => setSelectedImage(data.photo || data.image)}
              />
              {data.gallery && data.gallery.map((url,i) => (
                <img src={url} alt='tour' className='w-[200px] h-[180px] snap-start object-cover cursor-pointer' key={i}
                  onClick={() => setSelectedImage(url)}
                />
                ))}
            </div>
          </div>

          <div className='min-w-[300px] w-[33%] mx-5 sticky top-0'>
            <div className='border border-primary/10 p-5 rounded-md flex flex-col gap-4'>
              <FilterTravelPassengers data={data} returnData={(res) => setPassenger(res)} value={passenger} />
              <div className='flex flex-col '>
                <div className='flex gap-4 justify-between py-6'>
                  <b>Total</b>
                  <h3 className='font-bold'>{def.currency}{formatMoney(total)}</h3>
                </div>
                <button disabled={!totalPassengers} variant='contained' className='btn2' onClick={handleSelect}>Book Now</button>
              </div>
              <div className='py-6'>
                <h3 className='font-bold'>Free cancellation</h3>
                <p>Up to 24 hours in advance.</p>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
        <div className='p-4 flex flex-col gap-6 py-2 max-w-[1200px]'>
          <p>
            {/* {data.small_description} */}
          </p>
          <div className='px-10'>
            <Typography variant='h4'>Overview</Typography>
            <p>
              {data.long_description}
            </p>
          </div>
          <hr />
          <div className='flex gap-4 justify-between flex-wrap-reverse px-10'>
            <div className='flex flex-col gap-10 flex-1 min-w-[400px]'>
              {/* <div className="flex flex-col gap-2">
                <Typography variant='h4'>Price</Typography>
                <div>
                <Person /> <b>{def.currency}{data.selling_adult_price}</b> per adult
                </div>
                <div>
                <Person /> <b>{def.currency}{data.selling_child_price}</b> per child
                </div>
                <div>
                <Person /> <b>{def.currency}{data.selling_infant_price}</b> per infant
                </div>
              </div> */}

              <div className="flex flex-col gap-4">
                <Typography variant='h4'>Details</Typography>
                <div className="flex flex-col  ">
                  <b>Tour Guide Availability</b>
                  <p>{data.tour_guide_availability || 'Not Available'}</p>
                </div>
                <div className="flex flex-col  ">
                  <b>Travel Dates</b>
                  <div dangerouslySetInnerHTML={{ __html: data.travel_dates?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </div>
                <div className="flex flex-col  ">
                  <b>Transportation</b>
                  <div dangerouslySetInnerHTML={{ __html: data.transportation?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </div>
              </div>
            </div>
            <hr />



            {/* Price */}

            {/* <div className='min-w-[25%] mx-5'>
              <div className='border border-primary/10 p-5 rounded-md flex flex-col gap-4'>
                <FilterTravelPassengers returnData={(res) => setPassenger(res)} />
                <div className='flex flex-col'>
                  <small>Total</small>
                  <h4>{def.currency}{formatMoney(total)}</h4>
                  <Button variant='contained' className='btn2' onClick={() => next && next()}>Book Now</Button>
                </div>
              </div>
            </div> */}

          </div>
          <hr />

          <div className="flex flex-col gap-4 py-4 px-10">

            <Typography variant='h4'>Experience</Typography>
            <div className="flex flex-wrap gap-4 justify-between py-4">
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Highlights</h6>
              <div className='flex-1'>
                {/* <li>{data.highlights}</li> */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.highlights?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Full Description</h6>
              <p className='flex-1'>
                <ReadMore>
                  {data.long_description}
                </ReadMore>
              </p>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Includes</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.inclusions?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Itinerary</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.itinerary?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Accomodation</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.accomodation?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Exclusions</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.exclusions?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Meals</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.meals?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            <div className='flex items-center w-[48%] gap-4 border-t border-primary/10 pt-4'>
              <h6 className='w-[123px]'>Special Requirements</h6>
              <div className='flex-1 flex gap-2'>
                {/* <Check fontSize='small' /> */}
                {/* {data.inclusions} */}
                <ReadMore>
                  <div dangerouslySetInnerHTML={{ __html: data.special_requirements?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') }} />
                </ReadMore>
              </div>
            </div>
            </div>
          </div>
          {/* <div dangerouslySetInnerHTML={{__html: data.inclusions}}></div> */}
          <small>
            {/* <LocationOn /> */}
          {data.short_address} </small>


        </div>
        <div className='w-full border border-primary/10 hidden sm:flex justify-center sticky bottom-0 bg-secondary shadow-md shadow-top'>
          <div className=' p-5 rounded-md flex flex-wrap items-center gap-10 w-full sm:w-[90%]'>
            <FilterTravelPassengers data={data} oneLine returnData={(res) => setPassenger(res)} value={passenger} />
            <div className='flex flex-1 items-center gap-3 justify-between min-w-[200px]'>
              <b className='flex gap-2 items-center'>
                <h3>Total</h3>
                <h4>{def.currency}{formatMoney(total)}</h4>
              </b>
              <button disabled={!totalPassengers} variant='contained' className='btn2 self-center whitespace-nowrap' onClick={handleSelect}>Book Now</button>
            </div>
          </div>
        </div>
        </div>


      </div>


    </div>
  )
}


// <div className='flex gap-2'>
// <div className='flex-1 w-[70%]'>
//   {data?.gallery?.slice(0,1).map((src,i) => (
//     <img src={src || temp.photo} key={i} alt='tour' className='w-full ' />
//   ))}
// </div>
// <div className='flex w-[30%] flex-col flex-wrap gap-2 '>
//   {data?.gallery?.slice(1).map((src,i) => (
//     <img src={src} alt='tour' key={i} className='flex-1' />
//   ))}
//   {/* <img src={require('../../../assets/img/tour.png')} alt='tour' className='flex-1' /> */}
// </div>
// </div>
// <div className='flex flex-col gap-4 py-2'>
//   <p>
//     {data.small_description}
//   </p>
//   <div>
//     <b>Description</b>
//     <p>
//       {data.long_description}
//     </p>
//   </div>
//   <div className='flex gap-4 justify-between flex-wrap-reverse'>
//     <div className='flex flex-col gap-10 flex-1 min-w-[400px]'>
//       <div className="flex flex-col gap-2">
//         <b>Price</b>
//         <div>
//         <Person /> <b>{def.currency}{data.selling_adult_price}</b> per adult
//         </div>
//         <div>
//         <Person /> <b>{def.currency}{data.selling_child_price}</b> per child
//         </div>
//         <div>
//         <Person /> <b>{def.currency}{data.selling_infant_price}</b> per infant
//         </div>
//       </div>

//       <div className="flex flex-col gap-4">
//         <b>Details</b>
//         <div className="flex flex-col pl-5 ">
//           <b>Tour Guide Availability</b>
//           <p>{data.tour_guide_availability || 'Not Available'}</p>
//         </div>
//         <div className="flex flex-col pl-5 ">
//           <b>Travel Dates</b>
//           <div dangerouslySetInnerHTML={{ __html: data.travel_dates.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </div>
//         <div className="flex flex-col pl-5 ">
//           <b>Transportation</b>
//           <div dangerouslySetInnerHTML={{ __html: data.transportation.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </div>
//       </div>
//     </div>



//     {/* Price */}

//     <div className='min-w-[25%] mx-5'>
//       <div className='border border-primary/10 p-5 rounded-md flex flex-col gap-4'>
//         <FilterTravelPassengers returnData={(res) => setPassenger(res)} />
//         <div className='flex flex-col'>
//           <small>Total</small>
//           <h4>{def.currency}{formatMoney(total)}</h4>
//           <button className='btn2' onClick={() => next && next()}>Book Now</button>
//         </div>
//       </div>
//     </div>

//   </div>
//   <div className="flex flex-col gap-4 py-4">
//     <b>Experience</b>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Highlights</h6>
//       <div className='flex-1'>
//         {/* <li>{data.highlights}</li> */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.highlights?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Full Description</h6>
//       <p className='flex-1'>
//         <ReadMore>
//           {data?.long_description}
//         </ReadMore>
//       </p>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Includes</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.inclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Itinerary</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions?} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.itinerary?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Accomodation</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions?} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.accomodation?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Exclusions</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions?} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.exclusions?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Meals</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions?} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.meals?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>
//     <div className='flex items-center gap-4 border-t border-primary/10 pt-4'>
//       <h6 className='w-[123px]'>Special Requirements</h6>
//       <div className='flex-1 flex gap-2'>
//         {/* <Check fontSize='small' /> */}
//         {/* {data?.inclusions?} */}
//         <ReadMore>
//           <div dangerouslySetInnerHTML={{ __html: data?.special_requirements?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }} />
//         </ReadMore>
//       </div>
//     </div>

//   </div>
//   {/* <div dangerouslySetInnerHTML={{__html: data?.inclusions}}></div> */}
//   <small>
//     {/* <LocationOn /> */}
//    {data?.short_address} </small>
// </div>