export const tourDataTemp = {
  "_id": "64620a750f8a0000ce049960",
  "product_code": "ba73cf94-6906-48e9-9fee-ae75640c6674",
  "product_name": "namec",
  "product_start_duration": "Monday, April 18,2023",
  "product_stop_duration": "Monday, April 20,2023",
  "product_category": "6437b82044718af42457b8fc",
  "add_to_pool": true,
  "collection_id": "some id",
  "inclusions": "some inclusons",
  "exclusions": "exclusions to add",
  "highlights": "some highlights",
  "terms_and_conditions": "some terms",
  "photo": "https://tourprong.s3.amazonaws.com/development/trips/ef46ee7d-5d25-4e43-9739-b7d574be7703.png",
  "longitude": "15.555555",
  "latitude": "9.5625486",
  "short_address": "some address",
  "detailed_address": "some detailed address",
  "image_key": "development/trips/ef46ee7d-5d25-4e43-9739-b7d574be7703.png",
  "small_description": "some desc",
  "long_description": "this is some longer description",
  "details": "some details",
  "adult_price": 2500,
  "infant_price": 5000,
  "child_price": 5000,
  "approved": true,
  "mark": "iterated",
  "__v": 0,
  "number_of_adults": -1,
  "number_of_infants": 0,
  "number_of_children": 9,
  "sub_category": [
      {
          "subcategory_name": "sub cat 1"
      }
  ],
  "category": [],
  "created_by": [
      {
          "email": "contact@esfasam.com",
          "phone": "+2519863214",
          "nature_of_business": "Sports",
          "website_url": "https://www.google.com",
          "city": "Addis Ababa",
          "__v": 0,
          "detailed_info": "645cd086f91b99315c224927",
          "first_name": "Updated Admin first_name",
          "last_name": "Habte"
      }
  ],
  "hotel": [],
  "flight": [],
  "activity": [
      {
          "_id": "645b410a370a817b2e1b61e0",
          "activity_code": 800,
          "activity_name": "Activity 1",
          "activity_price": 900,
          "activity_start_duration": "Monday, September 10,2023",
          "activity_stop_duration": "Friday, October 2,2023",
          "activity_category": "6437b82044718af42457b8fc",
          "add_to_pool": true,
          "collection_id": "643",
          "inclusions": "some inclusions",
          "exclusions": "some exclusions",
          "highlights": "none",
          "terms_and_conditions": "someting",
          "longitude": "9.43532",
          "latitude": "1.43252532",
          "short_address": "some where in africa",
          "detailed_address": "somewhere in Kenya",
          "created_by": "644a3688e6ff456c87ef733e",
          "__v": 0,
          "approved": true,
          "adult_price": 44,
          "child_price": 42,
          "infant_price": 44,
          "number_of_adults": 1,
          "number_of_children": 2,
          "number_of_infants": -1
      }
  ],
  "farePrice": {
      "fare_adult_price": 2500,
      "fare_child_price": 5000,
      "fare_infant_price": 5000
  }
}

export function tourProTemplate(obj) {
    let result = {
        ...obj,
        product_name: obj?.product_name || obj?.hotel_name || obj?.place_name || obj?.activity_name || obj?.package_name || obj?.airline_name || obj?.event_title || '',
        image: obj.image || obj.photo || '',
        start_duration: '9/9/23',
        zzz: 'sleep',
        stop_duration: obj?.product_stop_duration || obj?.end_date || obj?.activity_stop_duration || obj?.stop_duration ||  '',
        special_requirements: obj?.special_requirements || obj?.requirements_and_restrictions,
        meals: obj?.meals || obj?.meal_plans,
        selling_adult_price: obj?.farePrice?.fare_adult_price || obj?.selling_adult_price,
        selling_child_price: obj?.farePrice?.fare_child_price || obj?.selling_child_price,
        selling_infant_price: obj?.farePrice?.fare_infant_price || obj?.selling_infant_price,
    }

    return result;
}