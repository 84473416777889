import React, { useEffect, useState } from 'react'
import { customerTemp } from '../../../data/customer/customerData'
import { Breadcrumbs, Skeleton, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Cancel, Edit, Email, LocationOn, Message, Phone, Visibility } from '@mui/icons-material';
import { getUser } from '../../../controllers/user/getUser';
import EditProfile from '../../../components/user/EditProfile';
import ProfilePicture from '../../../components/user/ProfilePicture';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { useDispatch } from 'react-redux';
import ModalLocal from '../../../components/mini/ModalLocal';
import { SketchPicker } from 'react-color';
import { clone } from '../../../features/utils/objClone';
import { updateSubAgent } from '../../../controllers/user/updateSubAgent';
import { setUser } from '../../../redux/reducers/user/userSlice';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';


//might delete later

export default function Index() {
  const [data,setData] = useState(customerTemp);
  const {id} = useParams();
  const [searchParam] = useSearchParams();
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const edit = searchParam.get('edit');
  const firstTime = searchParam.get('firstTime');

  useEffect(() => {
    dispatch(setHeaderTitle('My Profile'))
  },[])
  useEffect(() => {
    load();
  },[id])
  async function load() {
    setLoading(true);
    const res = await getUser(id)
    setLoading(false);
    if(res.return)
      setData(res.data);
  }

  async function handleThemeChange(val) {
    if(!data || !val) return false;

    if(data?.apiUserData?.selectedTheme === val)
      return false;

    let upData = clone(data.apiUserData);
    upData = Object.fromEntries(
      Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
    );

    setLoading(true);
    const res = await updateSubAgent({...upData,...val},data.id);
    setLoading(false);
    if(res.return){
      const UD = await getUser("me");
      if(UD.return) {
        dispatch(setUser(UD.data))
      }
      return dispatch(setAlertMsg(['success','Profile updated.']));
    }
    else return dispatch(setAlertMsg(['error',res.msg]))

  }

  return (
    <div className='p-10 w-full flex flex-col gap-10'>
      <ProfileNotice firstTime={firstTime} />
      {/* <Breadcrumbs separator="/" className='text-theme1'>
        <Link to="/subagents">Agents</Link>
        {loading ? 
                <Skeleton variant="rectangular" className='w-full min-w-[100px] h-6' />
              : null}
        <h5 className='text-primary/60'>{data.firstName} {data.lastName}</h5>
      </Breadcrumbs> */}
      <div className='flex gap-20 p-3'>
        <div className=' flex flex-col gap-4'>
          <div className='flex gap-4 items-center'>
            <div className='bg-primary/40 rounded-md w-20 h-20 overflow-hidden '>
              {/* {data.profile ?  */}
                <ProfilePicture data={data} handleReturn={load} />
              {/* : null */}
              {/* } */}
              {loading ? 
                <Skeleton variant="rectangular" className='w-full h-full' />
              : null}
            </div>
            <div className=''>
              {loading ? 
                <Skeleton variant="rectangular" className='w-full min-w-[100px] h-6' />
              : 
                <h5 className='capitalize'>{data.firstName} {data.lastName}</h5>
              }

            </div>
          </div>
          <div className='flex flex-wrap gap-4 items-center'>
            <Link to={"?edit=true"} className='btn2 text-xs '>Manage Profile</Link>
            {/* <button className='btn bg-red-200  text-secondary'><Cancel className='w-[14px] h-[14px]' /></button> */}
          </div>
        </div>
        <div className=' flex flex-col gap-3'>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Phone className='sqr4' /></div>
            {loading ? 
              <Skeleton variant="rectangular" className='w-full min-w-[100px] h-6' />
            : 
              <p>{data.phoneNumber}</p>
            }
          </div>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Email className='sqr4' /></div>
            {loading ? 
              <Skeleton variant="rectangular" className='w-full min-w-[100px] h-6' />
            : 
              <p>{data.email}</p>
            }
          </div>
          <div className='flex gap-3 items-center '>
            <div className='circle text-theme1 border border-theme1 p-1'><LocationOn className='sqr4' /></div>
            {loading ? 
                <Skeleton variant="rectangular" className='w-full min-w-[100px] h-6' />
              :
              [
                <p>{data.addressLine1}</p>
                ,
                <p>{data.addressLine2}</p>
              ] 
            }
          </div>
        </div>
        <ThemePicker
          value={data?.apiUserData?.selectedTheme}
          onChange={(color) => handleThemeChange({selectedTheme: color})}
        />

      </div>
      {edit ? <EditProfile userData={data} reload={load} />:null}
    </div>
  )
}


function ThemePicker({value,onChange}) {
  const [open,setOpen] = useState(false);
  const [color,setColor] = useState(value || "")

  useEffect(() => {
    if(value)
      setColor(value);
  },[value])
  function handleChange(color) {
    setColor(color?.hex);
    onChange(color && color?.hex)
  }
  
  return !color ? null : (
    <div className='flex gap-2 items-start'>
  
      <div className=' w-3 ' style={{backgroundColor: color}}></div>
      <button className='btn2' onClick={() => setOpen(true)}>Change Theme</button>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='rounded-xl bg-secondary p-4'>
          <div className='py-5 -m-4 relative '>
            <div className='btn_close z-10' onClick={() => setOpen(false)}>
              <Cancel />
            </div>
          </div>
          <SketchPicker 
            color={color}
            onChangeComplete={handleChange}
            />
        </div>
      </ModalLocal>
    </div>
  )
}

function ProfileNotice({firstTime}) {
  const [open,setOpen] = useState(firstTime || false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(firstTime)
  },[firstTime])

  const handleClose = () => {
    setOpen(false);
    searchParams.delete('firstTime'); // Replace 'paramName' with the name of the query parameter you want to remove
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return (
    <div>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='rounded-xl bg-secondary p-4'>
          <div className='py-5 -m-4 relative '>
            <div className='btn_close z-10' onClick={() => handleClose()}>
              <Cancel  />
            </div>
          </div>
          <div className='p-6 flex items-center justify-center flex-wrap gap-6'>
            <Typography variant='h2' className='max-w-[300px]'>
              You need to complete your user profile in order to book on JourneyEasy.
            </Typography>
            <button className='btn2 self-center' onClick={() => handleClose()}>
              Complete Profile
            </button>
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}