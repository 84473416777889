import { ChildCareOutlined, ChildFriendlyOutlined, Person } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import IncDec from "../../mini/IncDec";

export default function HotelGuests({returnData,q}) {
  const [passenger,setPassenger] = useState([
    {
      name: 'adults',
      label: <div className='flex flex-col'> Adult<small>Over 11</small> </div>,
      icon: <Person className='sqr4' />,
      value: parseInt(q && q.adults) || 1
    },
    {
      name: 'children',
      label: <div className='flex flex-col'> Child<small>2 - 11</small></div>,
      icon: <ChildCareOutlined className='sqr4' />,
      value: parseInt(q && q.children) || 0
    },
    {
      name: 'infants',
      label: <div className='flex flex-col'> Infant<small>Under 2</small></div>,
      icon: <ChildFriendlyOutlined className='sqr4' />,
      value: parseInt(q && q.infants) || 0
    },
  ])

  const handleReturnData = useCallback((passenger) => {
    if(returnData)
      returnData(passenger);
  },[returnData])

  // useEffect(() => {
  //   if(passenger)
  //     handleReturnData(passenger)
  // },[passenger,handleReturnData])

  function handleChange(val,i) {
    let temp = [...passenger];
    temp[i].value = val;
    console.log('setting : ',temp)
    setPassenger(temp);
    handleReturnData(temp)
  }

  return (
    <div className='flex flex-col gap-3'>
      {/* <h4>Guests</h4> */}
      {passenger.map((obj,i) => (
        <div key={i} className='flex gap-2 flex-nowrap items-center'>
          {obj.icon}
          <div className='flex-1 flex justify-between'>
            {obj.label}
            <IncDec value={obj.value} returnData={(val) => handleChange(val,i)} />
          </div>
        </div>      
      ))
      }

    </div>
  )
}
