import { ArticleOutlined, PersonOutline } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { Avatar } from "@mui/material";
import { Link, useParams } from 'react-router-dom';
import StatsOverview from '../../components/StatsOverview';
import { templateCustomer, templateCustomerReport } from '../../data/customer/customerData';
import { getCustomers } from '../../controllers/user/getCustomers';
import DotMenu from '../../components/mini/DotMenu';
import downloadCSV from '../../features/utils/downloadCSV';
import moment from 'moment';
import { getPassengers } from '../../controllers/user/getPassengers';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/reducers/titleSlice';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))

function ActionCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();

  return (
    <div className='flex justify-between w-full relative'>
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem><Link to={`/customers/edit/${params.id}`}>Edit</Link></MenuItem>
      </DotMenu>
    </div>
  )
}
const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'active'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'inactive'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    dispatch(setHeaderTitle('Passengers'))
    console.log(type);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    setLoading(true);
    const res = await getPassengers();
    setLoading(false);
    if(res.return) {
      // let data = res.data.map((row) => templateCustomer(row))
      setFetchedData(res.data);
      setData(res.data);
    }
  }

  const columns = [
    {field: 'firstName',headerName: 'First Name',flex:1,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            <Avatar>{params.value[0]}</Avatar>
            {params.value}
          </div>
        )
      }
    },
    {field: 'lastName',headerName: 'Last Name',flex: 1,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'phoneNumber',headerName: 'Phone',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col '>
            {params.value}
          </div>
        )
      }
    },
    {field: 'email', headerName: 'Email', flex: 1, minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <div className='flex flex-col gap-0'>
            <b className='!font-normal'>{params.value}</b>
          </div>
        )
      },
    },
    {field: 'createdDate', headerName: 'Date', flex: 1, minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <div className='flex flex-col gap-0'>
            <b className='!font-normal'>{moment(params.value).format("MMM, DD YYYY")}</b>
          </div>
        )
      },
    },
    {field: 'action', headerName: 'Action',
      renderCell: (params) => {
        // if(!params.value) return null;
        
        return (
          <ActionCol params={params} reload={load} />
        )
      },
    },
  
  ];
  
  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.firstName.toLowerCase().includes(val) ||
      obj.lastName.toLowerCase().includes(val) ||
      (new Date(obj.date)).toDateString().toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    
    // Prepend the column headers to the CSV data
    const csvData = data.map((row) => templateCustomerReport(row));
    csvData.unshift(columns.map(col => col.headerName));
    // console.log('csvData', csvData)

    downloadCSV(csvData,'Customers')
  }

  console.log(data)
  
  return (
    <div className='w-full p-4 m-2'>
      <div className='pb-4'>
        <StatsOverview />
      </div>
      <div className='flex gap-4 justify-between items-center my-3 '>
        <div className='flex-1  flex justify-start'>
          <Tabs  onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
          variant='scrollable'
          scrollButtons={false}
          className='shadow-md min-w-[100px] w-auto'>
            <Tab label={`All (${data.length})`} />
          </Tabs>
        </div>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <div className='flex flex-wrap gap-2'>
          <Link to="/customers/create" className='btn1 rounded-md !bg-[#1C2E3A] whitespace-nowrap'><PersonOutline /> Create Passenger</Link>
          <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
        </div>
      </div>
      <Box className=''>
        <DataGrid rows={data||[]} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "createdDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
