import { path } from "../../config";
import fetchServer from "../fetchServer";
// import { store } from "../../redux/store";

export async function verifyOTPReq(data) {
  let result = {return: 0,msg: "Something went wrong updating password."}

  // let token = store.getState().user.userData.accessToken;

  console.log('req Got: ',data)

  await fetchServer({method: 'POST',url: path.api+"/users/verify-otp",data})
  .then((res) => {
    console.log('serv => ',res);
    if(res.data && res.data.success)
      result = {return: 1,msg: "Successfull",data: res.data.data}
    else if(res.error) result['msg'] = res.error.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}