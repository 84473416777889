import React, { useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material';
import FetcherInput from '../mini/FetcherInput';
import getIATAData from '../../controllers/flight/getIATAData';
import { Flight } from '@mui/icons-material';


export default function IataInput({val,returnData,label,icon,className,display,required}) {
  const [IATA,setIATA] = useState([]);
  let modVal = val;
  // if(typeof(modVal) === 'object')
  //   modVal = display && display?.includes('city') ? modVal?.city:modVal?.iata
  const [data,setData] = useState(modVal || "")
  
  useEffect(() => {
    if(val) {
      // let modVal = val;
      // if(typeof(modVal) === 'object')
      //   setData(display && display?.includes('city') ? modVal?.city:modVal?.iata)
      // else
        setData(val)
    }
  },[val])
  console.log(IATA,data);

  async function handleChange(val) {
    console.log('+ ',val)
    getIata(val);
    setData(val);
    handleReturn(val);
  }
  function handleReturn(val) {
    console.log('obj +  ',val)

    if(returnData)
      returnData(val);
  }
  
  async function getIata(val) {
    if(val === '' || val.icao || val.iata) return false;

    const res = await getIATAData(val);
    if(res.return)
      setIATA(res.data);
  }

  const filterOptions = (options, inputValue) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option?.iata?.toLowerCase().startsWith(inputValueLowerCase) ||
        option?.city?.toLowerCase().startsWith(inputValueLowerCase) ||
        option?.name?.toLowerCase().startsWith(inputValueLowerCase) ||
        option?.country?.toLowerCase().startsWith(inputValueLowerCase)
    );
  };


  return (
    <FetcherInput className={'min-w-[200px] '+className}
      options={IATA}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : (display?.includes('city') ? option.city : option.iata)
      }
      filterOptions={(options,{inputValue}) => filterOptions(options,inputValue)}
      value={data} size='small'
      onInputChange={(ev,newVal) => handleChange(newVal)}
      onChange={(ev,newVal) => handleReturn(newVal)}
      
      renderInput={(params) => (
        <TextField {...params} label={label} required={required}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment position='start'>
                {icon}
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: true,
          }} />
      )}
      renderOption={(props,opt) => {
        return (
          <div {...props} className='flex !p-2 gap-2 !cursor-pointer items-center justify-start' style={{padding: 10,cursor: 'pointer'}}>
            <Flight className='self-center text-primary/60' />
            <div className='flex flex-1 flex-col max-w-full overflow-hidden'>
              {/* {display?.includes('city') ?  */}
              
              <div>
                <h5>{opt?.country}, {opt.city}</h5>
                <small className='!whitespace-nowrap !text-ellipsis overflow-hidden !block' title={opt.name}>{opt.name}</small>
              </div>
              {/* :} */}
            </div>
            <div>
              <p className='font-mono px-1 border mx-1'>{opt.iata}</p>
              {/* <small className='!whitespace-nowrap !text-ellipsis !block' title={opt.tz}>{opt.tz}</small> */}
            </div>
          </div>
        )
      }}
    />
  )
}
