import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  q: {
    "destinations": [
        {
            "departureLocation": "BRU",
            "arrivalLocation": "FCO",
            "date": "2023-06-20"
        },
        {
            "departureLocation": "FCO",
            "arrivalLocation": "BRU",
            "date": "2023-06-30"
        }
    ],
    "flex": "true",
    "nonStop": "false",
    "passengers": {
        "adults": "1"
    },
    "travelClass": "ECONOMY",
  },
  "range": [],
}

export const flightSearch = createSlice({
  name: 'flightSearch',
  initialState,
  reducers: {
    setQ: (state,action) => {
      state.q = action.payload;
    },
    setRange: (state,action) => {
      state.range = action.payload;
    },
    clear: (state) => {
      state.q = initialState;
    }
  }
})

export const {setQ,setRange,clear} = flightSearch.actions;

export default flightSearch.reducer;