import { Bed, Cancel, Group, MeetingRoom } from '@mui/icons-material'
import { Box, Button,  Popover } from '@mui/material'
import { DatePicker } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
// import moment from 'moment/moment';
import moment from 'moment/moment';
import { decrypt, encrypt } from '../../features/utils/crypto';
import { clone } from '../../features/utils/objClone';
import IataInput from '../form/IataInput';
import HotelGuests from '../hotel/filters/HotelGuests';
import { offerSearchTemp } from '../../data/hotel/offerSearchData';
import { def } from '../../config';
import CitySearch from './CitySearch';


const {RangePicker} = DatePicker;

export default function HotelSearchInput({newWindow}) {
  const navigate = useNavigate();

  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const [qObj,setQObj] = useState();


  useEffect(() => {
    if(q) {
      let obj = JSON.parse(decrypt(q));
      setQObj(obj)
      // setTravelClass((obj && obj.travelClass) || 'All')
    }
  },[q])

  function handleSearch(ev,dataInp) {
    ev.preventDefault();

    let searchObj = dataInp;
    searchObj['currencyOverride'] = def.currencyCode;

    console.log("Search Request - ",searchObj);
    // setData(newData);

    let enc = encrypt(JSON.stringify(searchObj));

    // SET TIME ------
    // dispatch(setBookingData({...bookingData}))

    if(newWindow)
      window.open(`/search/hotels?q=${enc}`);
    else
      navigate(`/search/hotels?q=${enc}`);

  }

  return (
    <div className='py-5 flex flex-col gap-4'>      
      <RoundTripInput handleSearch={handleSearch} q={qObj} />
    </div>
  )
}

function Occupancy({returnPassenger,q}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rooms,setRooms] = useState(q || [...offerSearchTemp.rooms]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(q)
      setRooms(q);
  },[q])

  const totalCounts = rooms.reduce((acc, curr) => {
    for (const [key, value] of Object.entries(curr)) {
      acc[key] = (acc[key] || 0) + value;
    }
    return acc;
  }, {});

  function handleGuests(objs,ind) {
    const newRooms = [...rooms];

    Object.values(objs).map((obj,i) => newRooms[ind][obj.name] = obj.value)
    setRooms(newRooms)
    returnPassenger(newRooms)
  }

  function addRoom() {
    setRooms(rooms => {
      let newRooms = [...rooms,clone(offerSearchTemp.rooms[0])]
      returnPassenger(newRooms)
      return newRooms
    })

  }
  function removeRoom(index) {
    setRooms(prevItems => {
      const updatedItems = [...prevItems]; // create a copy of the array
      updatedItems.splice(index, 1); // remove the item at the specified index
      returnPassenger(updatedItems)

      return updatedItems; // return the updated array to set as the new state
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'TravelInfo': undefined;


  return (
    <div>
    <Button
        aria-describedby={id}
        onClick={handleClick}
      >
      <div className='flex gap-2 self-start text-gray-700 cursor-pointer relative'>
        <div className='flex gap-1 items-center font-mono'>
          <MeetingRoom />
          {rooms.length}
        </div>
        <div className='flex gap-1 items-center font-mono'>
          <Group />
          {totalCounts.adults + totalCounts.children + totalCounts.infants}
        </div>
      </div>
    </Button>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // TransitionComponent={Fade}
      >
        <Box className='flex flex-col gap-5 p-4 px-6 min-w-[300px]'>
          {rooms.map((room,i) => (
            <div key={i}>
              <div className='flex gap-2 justify-between pb-2'>Room {i+1}
                {i !== 0 ? 
                 <span className='px-1 cursor-pointer' onClick={() => removeRoom(i)}><Cancel className='text-sm' /></span>
                : null}
              </div>
              <HotelGuests returnData={(objs) => handleGuests(objs,i)} q={room} />
            </div>
          ))}
          <button className='btn2 small self-end' onClick={addRoom}>Add Room</button>
          {/* <HotelRooms returnData={(objs) => setLuggage(objs)} /> */}
        </Box>
    </Popover>

    </div>
  )
}

function Loc({returnData,value}) {
  const [data,setData] = useState((value) || "");

  useEffect(() => {
    if(value)
      setData(value)
  },[value])

  function handleChange(val) {
    setData(val)
    if(returnData)
      returnData(val);
  }
  return (
    <div className='flex gap-2 flex-1 justify-stretch relative'>
      <IataInput display={['city']} className='flex-1 w-full ' label="Enter Destination" val={data} returnData={(val) => handleChange(val.iata || val)}
        icon={<Bed />}
      />
      {/* <IataInput className='flex-1' label="To" val={data.arrivalLocation} returnData={(val) => handleChange({...data,arrivalLocation: val.iata || val})} 
        icon={<Flight className='rotate-180' />}
      /> */}
    </div>
  )
}

function RoundTripInput({handleSearch,q}) {
  const [data,setData] = useState(q || offerSearchTemp)

  useEffect(() => {
    if(q)
      setData(q);
  },[q])

  const handleGuest = useCallback((newPassengers) => {
    let data = clone(newPassengers);
    data.rooms.map((room,i) => {
      let units = Object.values(room).map(val => val).reduce((prev,cur) => prev+cur,0) - 1;
      data.rooms[i]['units'] = units;
      return true;
    })

    // console.log(" unites : ",data)
    setData(data);
  }, []);


  function handleChange(dates) {
    if(!Array.isArray(dates))
      return false;
    
    
    let temp = clone(data);
    temp.start_date = moment(dates[0].$d).format("YYYY-MM-DD");

    if(!dates[1]) dates[1] = "";
    
    temp.end_date = moment(dates[1].$d).format("YYYY-MM-DD");

    setData(temp);
  }
  function setLoc(obj) {
    const {lat:latitude,lng:longitude,formatted_address,...detail} = obj;
    setData(data => ({...data,latitude,longitude,detail,range:200,destination: 'DXB'}));
  } 
  return (
    
    <form onSubmit={(ev) => handleSearch(ev,data)} className='flex justify-stretch gap-2'>
      <CitySearch value={data?.detail?.name} onChange={(obj) => setLoc(obj)} />
      {/* <Loc returnData={setLoc} value={data.destination} /> */}
      <RangePicker placeholder={[data?.start_date || "Check In Date",data?.end_date || "Check Out Date"]} className='flex-1'
        onChange={handleChange}
        />
      <Occupancy returnPassenger={(val) => handleGuest({...data,rooms: val})} q={q && q.rooms} />
      
      <button className='btn2 !rounded-sm '>Search</button>
    </form>

  )
}
