import { MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'
import { fareRuleTypesData } from '../../data/markup/fareRuleType';
import FetchUsersInput from '../../components/form/FetchUsersInput';
import { addMarkup } from '../../controllers/settings/addMarkups';
import AirlinesInput from '../../components/form/AirlinesInput';
import { addMarkupUserType } from '../../controllers/settings/addMarkupUserType';
import { markupReqTemp } from '../../data/markup/markupReqData';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { useDispatch } from 'react-redux';

export default function AddMarkup({returnData}) {
  const [userType,setUserType] = useState("");
  const [markup,setMarkup] = useState("");
  const [markupType,setMarkupType] = useState("");
  const [markupValue,setMarkupValue] = useState(0);
  const [user,setUser] = useState();
  const [description,setDescription] = useState('');
  const [reqField,setReqField] = useState();
  const dispatch = useDispatch();

  async function handleSubmit(ev) {
    ev.preventDefault();
    let res = null;
    let req = markupReqTemp;
    req.fareRuleType = markup;
    req.markType = markupType;
    req.markUp = parseInt(markupValue);
    req.ruleName = "";
    req.ruleName = description;
    req.for = userType.toUpperCase();
    if(markup === 'AIRLINE')
      req['airLines'] = [reqField];
    else req[markup] = reqField;

    console.log('reqData',req)
    if(user && user.id)
      res = await addMarkup(user.id,req)
    else res = await addMarkupUserType(userType.toUpperCase(),req)

    console.log('res',res)
    if(res.return)
      dispatch(setAlertMsg(['success','Markup Added']));
    else dispatch(setAlertMsg(['error','Failed Adding Markup']))
    
  }

  function handleUserTypeChange(val) {
    setUserType(val);
    if(returnData)
      returnData(val.toUpperCase());
  }

  return (
    <form onSubmit={handleSubmit} className='my-5 p-6 bg-secondary rounded-lg'>
      <div className='flex flex-col gap-8'>
        <div className='flex gap-5 flex-wrap'>
          <TextField select value={markup} onChange={(ev) => setMarkup(ev.target.value)} label='Markup Rule' size='small' className='min-w-[100px]'>
            {fareRuleTypesData.map((val,i) => (
              <MenuItem key={i} value={val} className='capitalize'>{val.toLowerCase()}</MenuItem>
            ))}
          </TextField>
          <TextField select value={markupType} onChange={(ev) => setMarkupType(ev.target.value)} label='Markup Type' size='small' className='min-w-[150px]'>
            <MenuItem value='PERCENTAGE'>Percentage</MenuItem>
            <MenuItem value="Value">Value</MenuItem>
          </TextField>
          <TextField select value={userType} onChange={(ev) => handleUserTypeChange(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
            <MenuItem value="Customer">Customer</MenuItem>
            <MenuItem value="Sub_Agent">Agent</MenuItem>
            <MenuItem value="Corporate">Company</MenuItem>
          </TextField>
          <FetchUsersInput label={userType} value={user} onChange={(val) => setUser(val)} />
        </div>
        <hr />
        <div className='flex gap-5 flex-wrap items-center'>
          {markup === 'AIRLINE' ? (
            <AirlinesInput val={reqField} returnData={(val) => setReqField(val ? val.id : null)} label={markup} />
          ):(
            <TextField value={reqField || ''} onChange={(ev) => setReqField(ev.target.value)} size='small' label={markup} />
          )}
          <TextField size='small' value={markupValue} onChange={(ev) => setMarkupValue(ev.target.value)} label={"Markup Value"} />
          <TextField size='small' value={description} onChange={(ev) => setDescription(ev.target.value)} label={"Description"} />
          <button className='btn_outlined'>Save Data</button>
        </div>
      </div>

    </form>

  )
}
