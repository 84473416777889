import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapse: false
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state,action) => {
      state.collapse = (action.payload !== null && action.payload !== undefined) ? action.payload : !state.collapse
      // window.dispatchEvent(new CustomEvent('resize'))
    }
  }
})

export const {toggleSidebar} = sidebarSlice.actions;

export default sidebarSlice.reducer