import { path } from "../../config";
import fetchServer from "../fetchServer";
import { store } from "../../redux/store";

export async function updateRole(data,userId) {
  let result = {return: 0,msg: "Something went wrong updating Role."}

  let token = store.getState().user.userData.accessToken;

  console.log('req Got: ',data)


  let headers = {
    Authorization: `Bearer ${token}`
  }
  // if(userId)
  //   headers['user-id'] = userId

  await fetchServer({method: 'POST',url: path.api+"/settings/update-roles/"+userId,data,
    headers
  })
  .then((res) => {
    console.log('serv => ',res);
    if(res.data && res.data.success)
      result = {return: 1,msg: "Successfull",data: res.data}
    else result['msg'] = res.error.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}