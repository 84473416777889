import mergeRecursive from "../../features/utils/mergeRecursive";

export const agentSignupTemp = {
  "phoneNumber": "",
  "firstName": "",
  "password": "",
  "email": "",
  "lastName": "",
  "companyName": "",
  "companyDescription": "",
  "remark": "",
  "cacRegNo": "",
  "location": "",
  "contactSurName": "",
  "contactFirstName": "",
  "contactEmail": "",
  "contactPhone": "",
  "confirmPassword": "",
}

export function templateAgentSignup(obj) {
  let data = mergeRecursive({...obj},agentSignupTemp);
  return {...data};
}
