import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  open: false,
  comp: null
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state,action) => {
      state.open = action.payload;
    },
    setModalComp: (state,action) => {
      state.comp = action.payload;
    }
  }
})

export const {setModal,setModalComp} = modalSlice.actions

export default modalSlice.reducer;