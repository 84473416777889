export default function downloadCSV(csvData,name) {
    const csvString = csvData.map(row => row.join(',')).join('\n');

    let file_name = name || "Report";


    // Create a Blob object from the CSV data
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8'});

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${file_name}.csv`;

    // Click the download link to initiate the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}