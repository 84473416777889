import { useEffect, useState } from "react";
import { cancelFlightOrder } from "../../controllers/flight/cancelFlightOrder";
import { setModal } from "../../redux/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";

export function CancelPNRModal({id:gotId,returnData}) {
  const [id,setId] = useState(gotId);
  const [loading,setLoading] = useState(false);
  const [msg,setMsg] = useState({type: 1,msg: ""});
  const dispatch = useDispatch();

  useEffect(() => {
    gotId && setId(gotId)
    setMsg({type:1,msg: ""})
  },[gotId])

  async function handleCancel() {
    setLoading(true);
    let res = await cancelFlightOrder(id);
    setLoading(false);
    if(res.return) {
      returnData && returnData(true)
      return setMsg({type: 1,msg:"Successfull"})
    }
    
    setMsg({type:0,msg:res.msg || "Couldn't Delete PNR"});
  }
  return (
    <div className='h-full flex items-center'>
      <div className='bg-white p-6 pt-10 flex-col flex gap-4 justify-center relative'>
        <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
        <div>Are you sure you want to cancel PNR?</div>
        <h6 className={msg.type !== 1?'!text-red-500':'text-green-500'}>{msg.msg}</h6>
        <input type='button' className='btn1 self-center' disabled={loading} value={loading?"Loading...":"Yes, Cancel PNR"}
          onClick={handleCancel}
        />
      </div>
    </div>
  )
}
