import { path } from "../../../config";
import { store } from "../../../redux/store";
import fetchServer from "../../fetchServer";

export async function getPromoCodes() {
  var response = {return: 0,msg: "Error",data: null}

  let token = store.getState().user.userData.accessToken;

  await fetchServer({method: 'GET',url: path.api+"/promo-codes/",
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    console.log(" -- ",res)
    if(res && res.data) {
      if(res.data.success)
        response = {return: 1,msg: "Successfull",data: res.data.data}
    }
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}