import { AirplanemodeActiveOutlined, Cancel, CheckCircle } from '@mui/icons-material'
import { MenuItem, Step, StepLabel, Stepper, TextField } from '@mui/material'
import React, { useState } from 'react'
import Modal from '../../../components/mini/Modal'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../redux/reducers/modal/modalSlice'
import { Link } from 'react-router-dom'
import { CancelPNRModal } from '../../../components/flight/CancelPNRModal'
import { IssueTicketModal } from '../../../components/flight/IssueTicketModal'

function OrderPolicy() {
  return (
    <div className='flex justify-between gap-4 flex-wrap'>
      <div className='p-6'>
        <h5>Order Change Policy</h5>
        {
          false ? (
            <p><CheckCircle sx={{color: 'green'}} /> This order is changable</p>
            ) : (
            <p><Cancel sx={{color: 'red'}} /> This order is not changable</p>
          )
        }
      </div>
      <div className='p-6'>
        <h5>Order refund policy</h5>
        {
          false ? (
            <p><Cancel sx={{color: 'red'}} />This order is not refundable </p>
          ) : (
            <p><CheckCircle sx={{color: 'green'}} />This order is refundable up until the initial departure date</p>
          )
        }
      </div>
    </div>
  )
}

export default function FlightDetails({data}) {
  const dispatch = useDispatch();
  const [modalComp,setModalComp] = useState(null);
  function cancelPNR() {
    setModalComp(<CancelPNRModal id={data.id}  />);
    dispatch(setModal(true));
  }
  function issueTicket() {
    setModalComp(<IssueTicketModal id={data.id} />);
    dispatch(setModal(true));
  }
  return (
    <div className='relative'>
      <Modal>{modalComp}</Modal>
      <div className='flex justify-between items-center mb-3'>
        <h5>Flight Details</h5>
        <div className='flex gap-4'>
          <input type='button' value='Export Library' />
        </div>
      </div>
      {data.flightData.booked_flights.map((flight,i) => (
        <div key={i}>
          <div className='text-end py-2'>
            <TextField select label="Manage this order" size='small' sx={{minWidth: 180}}>
              <MenuItem value="Add Seats">
                <Link to={`/flights/seats/${flight.id}?dd=${flight.origin.date}&d=${flight.origin.location}&arv=${flight.destination.location}&airline=${flight.airline_detail.iata}&alname=${flight.airline_detail.name}`}>
                  Add Seats
                </Link>
              </MenuItem>
              <MenuItem>Add Bags</MenuItem>
              <MenuItem onClick={cancelPNR}>Cancel PNR</MenuItem>
              <MenuItem onClick={issueTicket}>Issue Ticket</MenuItem>
            </TextField>
          </div>
          <hr />
          <div className='mt-3 px-2'>
            <div className='flex justify-between items-center gap-10'>
              <div className='flex flex-col gap-2 items-center'>
                <img src={(flight.booked_segments[0].carrierIcon)} width={30} alt='turkish' />
                <small className='!font-extrabold md:whitespace-nowrap'>{flight.airline_detail.name}</small>
              </div>
              <div className='flex-1 flex gap-4 items-center'>
                <h6>{flight.origin.location}</h6>
                <div className='flex items-center'>
                  <div className='inline border border-[#777] h-0 min-w-[100px]' />
                  <AirplanemodeActiveOutlined className='rotate-90' />
                </div>
                <h6>{flight.destination.location}</h6>
              </div>
              <div className='inline'>
                {flight.origin.time} - {flight.destination.time}
                &nbsp;
                ({flight.duration}, {flight.number_of_stops} stops)
                <div className='inline-block px-2'>
                  {flight.origin.location} - {flight.destination.location}
                </div>
              </div>
            </div>
          </div>
          <div className='p-2'>
            <Stepper orientation='vertical' activeStep={4} >
              <Step>
                <StepLabel>
                  <div className='flex gap-3'>
                    {/* Sat, 08 April 2022, 16:17 */}
                    {(new Date(`${flight.origin.date}T${flight.origin.time}`).toDateString())} {flight.origin.time}
                    {/* <p>Departing from Murtala Muhammed International Airport</p> */}
                    <p>Departing from {flight.origin.name}</p>
                  </div>
                  {/* <small>Flight duration, 12hours  40mins</small> */}
                  <small>Flight duration{flight.duration}</small>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <div className='flex gap-3'>
                    {/* Sat, 08 April 2022, 16:17 */}
                    {(new Date(`${flight.destination.date}T${flight.destination.time}`).toDateString())} {flight.destination.time}
                    {/* <p>Departing from Murtala Muhammed International Airport</p> */}
                    <p>Ariving to {flight.destination.name}</p>
                  </div>
                  {/* <small>Flight duration, 12hours  40mins</small> */}
                  <small>Flight duration{flight.duration}</small>
                </StepLabel>
              </Step>
            </Stepper>
          </div>
          <hr />
          <div className='p-2 font-bold flex gap-7'>
            <small className=''>{flight.cabin}  {flight.class || data.bookingClass}</small>
            <small className=''>{flight.weightUnit || data.weightUnit} {flight.weightType || data.weightType}</small>
          </div>
        </div>
      ))}
      <div className='mt-4'>
        {/* <OrderPolicy /> */}
      </div>
    </div>
  )
}

