import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { Avatar } from "@mui/material";
import { Link, useParams } from 'react-router-dom';
import { getTransactions } from '../../controllers/transactions/getTransactions';
import { getCurrencySymbol } from '../../features/utils/currency';
import StatsOverview from '../../components/StatsOverview';
import { templatePackage } from '../../data/package/packageData';
import { getTourBookings } from '../../controllers/tour/getBookings';
import DotMenu from '../../components/mini/DotMenu';
import { paymentStatus } from '../../data/ENUM/PaymentStatus';
import { formatMoney } from '../../features/utils/formatMoney';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice';
import TourPayment from '../../components/payment/TourPayment';
import { def } from '../../config';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];



function StatusCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  let method;
  try {
    method = params.row.transactions[0].from
  } catch(ex) {}

  async function verifyPayment() {
    const res = await confirmBankTransaction()
    if(res.return) {
      reload();
      dispatch(setAlertMsg(['success','Payment Confirmed']))
    } else dispatch(setAlertMsg(['warning','Payment still pending']))
  }

  function handlePayment() {
    console.log("couldn't close modal")
    dispatch(setModal(false));
    reload();
  }

  async function pay() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    console.log(params.id)
    console.log(params.row)
    dispatch(setModal(true))
    dispatch(setModalComp(
      <div className='relative'>
        <div className='btn_close z-10 ' onClick={() => dispatch(setModal(false))}>X</div>
        <TourPayment returnData={handlePayment} orderData={params && params.row} />
      </div>
    ))
  }


  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis capitalize" title={params.value}>
        {paymentStatus[params.value] || params.value.toLowerCase()}
      </p>
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
        <MenuItem><Link to={`/packages/details/${params.id}`}>View Detail</Link></MenuItem>
        {method === 'BANK' ? 
          <MenuItem onClick={verifyPayment}>Verify</MenuItem>
        :null}
        {!method ? 
          <MenuItem onClick={pay}>Pay</MenuItem>
        :null}
      </DotMenu>

    </div>
  )
}


export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  
  useEffect(() => {
    console.log(type);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    setLoading(true);
    const res = await getTourBookings();
    setLoading(false);
    if(res.return) {
      let data = res.data.map((row) => templatePackage(row))
      setFetchedData(data);
      setData(data);
    }
  }

  const columns = [
    {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            <Avatar>{params.value[0]}</Avatar>
            {params.value}
          </div>
        )
      }
    },
    {field: 'bookingDate',headerName: 'Booking date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {(new Date(params.value)).toDateString()}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'packageName',headerName: 'Package name',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col '>
            {params.value}
          </div>
        )
      }
    },
    {field: 'amount', headerName: 'Amount',
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <div className='flex flex-col gap-0'>
            <b className='!font-normal'>{def.currency}{formatMoney(params.value)}</b>
          </div>
        )
      },
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return (
          <StatusCol params={params} reload={load} />
        )
      }
    },
  ];
  

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.date)).toDateString().toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }
  
  return (
    <div className='w-full p-4 m-2'>
      <div className='pb-4'>
        <StatsOverview />
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={"All ("+data.length+")"} sx={{textTransform: 'capitalize'}} />
          <Tab label="Paid" sx={{textTransform: 'capitalize'}} />
          <Tab label="Pending" sx={{textTransform: 'capitalize'}} />
          <Tab label="Cancelled" sx={{textTransform: 'capitalize'}} />
          <Tab label="Refund" sx={{textTransform: 'capitalize'}} />
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap'><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
