import { MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EmailInput from '../../../components/form/EmailInput';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { ArrowBack } from '@mui/icons-material';
import CountriesInput from '../../../components/form/CountriesInput';
import PriceSummary from '../../../components/Tour/PriceSummary';
import { clone } from '../../../features/utils/objClone';
import { guestLogin } from '../../../controllers/user/guestLogin';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { bookTour } from '../../../controllers/tour/bookTour';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';


export default function GuestInfo({next,back,data,step}) {
  const {tourBooking} = useSelector(state => state.tourBooking)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTourBooking({...tourBooking,step}))
  },[step])

  console.log(" -- ",data)
  async function book(id,guests) {
    if(tourBooking.passengers) {
      let number_of_participants = 0;
      let passCount = tourBooking.passengers.map(obj => {
        let {farePrice,name,count,...rest} = obj;
        number_of_participants += count;
        return rest
      }).reduce((acc,cur) => ({...acc,...cur}),{})
      let obj = {
        ...tourBooking.selected,
        customer_id: id,
        // tour_id: data._id,
        reservation_details: guests,
        number_of_participants,
        "booking_source":"your booking source",
        "prices_and_rates_id":110,
        "mode_of_payment":"your preffered payment",
        "booking_comments":"some comments",
        "internal_comments":"some internal comments",
        "payment_time":"Paylater",
        ...passCount
      }
      // @my_fix: default dummy datas passed to book 
      obj[obj.type+'_id'] = data._id;

      const as = tourBooking?.as;
      const userType = tourBooking?.userType
  
      const res = await bookTour(obj,as?.id,userType);
      if(res.return) {
        dispatch(setAlertMsg(['success','Booking Successful.']))
        dispatch(setTourBooking({...tourBooking,bookingData: res.data.bookingData}))
        next()
      } else dispatch(setAlertMsg(['error','Failed to book!']))
    }
  }
  return (
    <div className='flex flex-col gap-3 flex-1 p-10 w-full'>
      <button className='flex items-center gap-3' onClick={() => back && back()}>
        <ArrowBack />
        <h5>Confirm Package</h5>
      </button>
      <div className='flex gap-5 flex-wrap'>
        <div className='flex flex-col gap-4 flex-1'>
          <Typography variant='h3' className='py-3'>Checkout: {data?.product_name}</Typography>
          <CheckInForm next={next} returnData={(customerId,guests) => book(customerId,guests)} />
        </div>
        <div className='w-[33%] min-w-[300px]'>
          <PriceSummary onBook />
        </div>
      </div>
    </div>
  )
}

function CheckInForm({next,returnData}) {
  const {tourBooking} = useSelector(state => state.tourBooking)
  const [email,setEmail] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");
  const [guests,setGuests] = useState([{}]);
  const [loading,setLoding] = useState(false);
  const {user: userAcc} = useSelector(state => state.user.userData);


  const dispatch = useDispatch();

  useEffect(() => {
    if(tourBooking.as) {
      if(tourBooking.as.email && tourBooking.as.phoneNumber) {
        setEmail(tourBooking.as.email)
        setPhoneNumber(tourBooking.as.phoneNumber)
      }
    } else if(userAcc?.email && userAcc?.phoneNumber) {
      setEmail(userAcc.email)
      setPhoneNumber(userAcc.phoneNumber)
    }

  },[tourBooking])


  function handleGuest(i,val) {
    let temp = clone(guests)
    temp[i] = val;
    setGuests(temp);
  }

  async function handleSubmit() {

    if(email === "" || phoneNumber === "")
      return dispatch(setAlertMsg(['error','Invalid email/phone']));

    let invalids = guests.map(obj => {
      return obj.firstName === "" || !obj.firstName ||
      obj.lastName === "" || !obj.lastName
      // obj.gender === "" || !["Male","Female"].includes(obj.gender) ||
      // obj.birthDate === "" || !obj.birthDate
    })
    if(invalids.some(val => val)) 
      return dispatch(setAlertMsg(['error','Please input required guest informations!']))

    
    setLoding(true);
    // const res = await guestLogin({email,phoneNumber});
    // if(res.return) {
      if(returnData)
        await returnData(userAcc?.id,guests)
    // returnData(res.data.id,guests)
    // } else dispatch(setAlertMsg(['error','Invalid customer credentials!']))
    setLoding(false);
  }

  return (
    <div className='flex flex-col gap-10'>
      <div className='flex gap-5'>
        <EmailInput value={email} disabled={tourBooking.as || userAcc.email} onChange={(val) => tourBooking.as || userAcc.email || setEmail(val)} className='flex-1' />
        <PhoneNumberInput value={phoneNumber} disabled={tourBooking.as || userAcc.email} onChange={(val) => tourBooking.as || userAcc.email || setPhoneNumber(val)} className='flex-1' />
      </div>
      <div className='flex flex-col gap-4'>
        <h4>Primary Guest</h4>
        {guests.map((obj,i) => (
          <div key={i} className='flex flex-col gap-4'>
            <div className='flex gap-5 '>
              <TextField size="small" className='flex-1' label="Given name" required
                value={obj.firstName || ""}
                onChange={(ev) => handleGuest(i,{...obj,firstName: ev.target.value})}
              />
              <TextField size="small" className='flex-1' label="Surname" required
                value={obj.lastName || ""}
                onChange={(ev) => handleGuest(i,{...obj,lastName: ev.target.value})}
              />
            </div>
            {/* <div className='flex gap-5'>
              <CountriesInput className={'flex-1'} label={'Nationality'} />
              <TextField size="small" className='flex-1' select label="Gender" required
                value={obj.gender || ""}
                onChange={(ev) => handleGuest(i,{...obj,gender: ev.target.value})}
              >
                <MenuItem value='Male'>Male</MenuItem>
                <MenuItem value='Female'>Female</MenuItem>
              </TextField>
              <TextField size="small" className='flex-1' type='date' label='Birth Date' required
                value={obj.birthDate || ""}
                InputLabelProps={{shrink: true}}
                onChange={(ev) => handleGuest(i,{...obj,birthDate: ev.target.value})}
              />
            </div> */}
            <hr />
          </div>
        ))}
        {/* <div className='flex items-center justify-center p-2'>
          <button onClick={() => setGuests(guests => [...guests,{}])}>+ Add another guest</button>
        </div> */}
        <button onClick={() => handleSubmit()} disabled={loading} className='btn2 flex gap-2 items-center justify-center'>
          {loading && <div className='load'></div>}
          Continue</button>
      </div>
    </div>
  )
}