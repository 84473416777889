import { AirplanemodeActiveOutlined } from '@mui/icons-material';
import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import PlaneSeat from '../../../components/PlaneSeat';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import getFlightSeats from '../../../controllers/flight/getFlightSeats';
import { flightSeatTemplate, templateSeats } from '../../../data/flight/seats';
import PlaneSeat from '../../../components/flight/PlaneSeat';


export default function Index({label}) {
  const [data,setData] = useState(flightSeatTemplate);
  const {id} = useParams();
  const [query] = useSearchParams();
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle("Seats"));
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch,id,query])

  async function load() {
    const res = await getFlightSeats({
      departureDate: query.get("dd"),
      departure: query.get("d"),
      arrival: query.get("arv"),
      airline: query.get("airline"),
      flightNumber: id,
    });
    if(res.return) {
      setData(templateSeats(res.data));
    }
  }
  


  return (
    <div className='w-full p-4 m-4'>
      <Breadcrumbs separator=">">
        <Link to="/order">Orders</Link>,
        <span >{label || "GB23N1"}</span>
      </Breadcrumbs>
      <h3 className='!text-[#444]'>{label || "GB23N1"}</h3>

      <div className='inline-block gap-4 flex-wrap mt-5'>
        <div className='flex-1 flex flex-col gap-5 '>
          <div>
            <h4>Add Seat</h4>
            <p>Add seats to different routes.</p>
          </div>
          <div className='flex flex-col border border-gray-300 rounded-sm'>
            <div className='flex justify-between items-center gap-10 px-3 py-5'>
              <div className='flex flex-col gap-2 items-center'>
                <img src={require('../../../assets/img/image 10.png')} width={30} alt='turkish' />
                <small className='!font-extrabold md:whitespace-nowrap'>{query.get("alname")}</small>
              </div>
              <div className='flex-1 flex gap-4 items-center'>
                <h6>{data.seatmapInformation.flightDateInformation.boardPointDetails.trueLocationId}</h6>
                <div className='flex items-center'>
                  <div className='inline border border-[#777] h-0 min-w-[100px]' />
                  <AirplanemodeActiveOutlined className='rotate-90' />
                </div>
                <h6>{data.seatmapInformation.flightDateInformation.offpointDetails.trueLocationId}</h6>
              </div>
              <div>
                <input type='button' className='btn1' value='Select seat' />
              </div>
            </div>
            <hr />
            <div className='flex flex-col justify-center px-3 py-5'>
              <h5 className='text-center py-5'>Select seat on the map</h5>
              <div className='py-4'>
                <PlaneSeat data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
