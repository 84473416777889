import { MenuItem, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { passengerDataTemp, passengerDataTempFilled } from '../../../data/user/passengerData';
import { ArrowBack } from '@mui/icons-material';
import { guestLogin } from '../../../controllers/user/guestLogin';
import moment from 'moment';
import CountriesInput from '../../../components/form/CountriesInput';
import { useDispatch, useSelector } from 'react-redux';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { addBookingsData } from '../../../controllers/flight/addMultipleBooking';
import { clone } from '../../../features/utils/objClone';
import Collapse from '../../../components/mini/Collapse';
import { bookFlight } from '../../../controllers/flight/bookFlight';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { getPassengers } from '../../../controllers/user/getPassengers';
import FetchUsersInput from '../../../components/form/FetchUsersInput';


export default function PassengerInfo({back,next}) {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const urlData = bookingData;
  const {user: userAcc} = useSelector(state => state.user.userData);

  const dispatch = useDispatch();

  // const array = urlData.offer && Object.entries(urlData.offer.passengers).map((passengerType) =>
  //   ({type: passengerType[0],total: passengerType[1].total})
  // )
  const array = urlData.offer && Object.entries(urlData.offer.passengers).map(([k,v]) => [...Array(v.total)].map((o,i) => ({type: k}))).flat(1)

  // console.log('passengers: ',urlData.offer && urlData.offer.passengers)


  let [user,setUser] = useState({email: '',phoneNumber: ''});

  const [passengers,setPassengers] = useState([]);
  // const [data,setData] = useState([])
  const [loading,setLoading] = useState(false);
  const [err,setErr] = useState("");
  
  // console.log(" On Pasenger Info : ",bookingData)
 
  useEffect(() => {
    if(urlData.as) {
      if(urlData.as?.email && urlData.as?.phoneNumber)
        setUser({id: urlData.as?.id, email: urlData.as.email, phoneNumber: urlData.as.phoneNumber})
    } else if(userAcc?.email && userAcc?.phoneNumber)
      setUser({email: userAcc.email,phoneNumber: userAcc.phoneNumber})

  },[urlData])
  
  async function handleSubmit() {
    let modPass = passengers.map((data,index) => {
      let {ffn,...reqData} = data;
      let dat = JSON.parse(JSON.stringify({...reqData}));
      // data.middleName && (dat.firstName = data.firstName+""+data.middleName);
      // data.title && (dat.firstName = data.title+""+data.firstName);
      dat.birthDate = moment(data.birthDate?.toString()).format("YYYY/MM/DD") || "";
      dat.passportExpirationDate = moment(data.passportExpirationDate?.toString()).format("YYYY/MM/DD") || "";
      dat.issuanceDate = moment(Date()).format("YYYY/MM/DD");
      dat.issuanceLocation = dat.validityCountry = dat.issuanceCountry;

      if(
        (dat.firstName === "") ||
        (dat.birthDate === "") ||
        (dat.passportExpirationDate === "") ||
        (dat.issuanceDate === "") ||
        (dat.issuanceLocation === "" )
      ) return false;
      
      const today = moment();
      const age = today.diff(dat.birthDate, 'years')

      
      let userType = ''
      if(age < 0)
        userType = 'Unborn child'
      else if (age < 2) {
        userType = 'INFANT'
      } else if (age <= 11) {
        userType = 'CHILD'
      } else if(age > 11 ){
        userType = 'ADULT'
      }

      console.log('check : ',age,' : ',dat.boardingUserType,' vs ',userType)
      
      if(dat.boardingUserType.toUpperCase() !== userType) {
        // dat.boardingUserType = userType
        // Got ${userType}
        dispatch(setAlertMsg(['error',`Kindly check date of birth for ${dat.boardingUserType && dat.boardingUserType.toLowerCase()}`]))
        return false;
      }

      if(dat.boardingUserType.toUpperCase() === 'INFANT')
        dat.boardingUserType = "HELD_INFANT";

      if(dat.issuanceCountry?.length !== 2 || dat.issuanceCountry3?.length !== 3) {
        console.log(dat)
        dispatch(setAlertMsg(['error','Kindly reselect passport issuance country for passenger '+(index+1)]))
        return false;
      }
      if(dat.nationality?.length !== 2 || dat.nationality3?.length !== 3) {
        dispatch(setAlertMsg(['error','Kindly reselect passenger nationality for passenger'+(index+1)]))
        return false;
      }
      
      return dat;

        
    })

    const diffPassport = new Set(passengers?.map(obj => obj.passportId)).size === passengers.length;
    if(!diffPassport)
      return dispatch(setAlertMsg(['error','Passengers passport must be different!']))

    if(!modPass.every(d => d))
      return false;

    if(bookingData.cwt) {
      if(bookingData?.cwtTravelers?.length) {
        if(passengers.length < array.length) {
          dispatch(setAlertMsg(['error','Please provide all passenger info.']))
          return false
        }    
    
        console.log("to pass : ",modPass);
        // if(true)
        // return false;
        
        next()
      }
      else dispatch(setAlertMsg(['error','Please provide all passenger info.']))
    }
    else {
      const bookingInfo = await bookPassenger(modPass);
      console.log(bookingInfo)
    }
    // if(bookingInfo)
    //   book(urlData,bookingInfo);
  }
  async function bookPassenger(modPass) {
    if(urlData.orderData) next && next();
    let newBookingData = urlData;
    if(!bookingData.as) { 
      if(user.email !== userAcc.email) {
        newBookingData = {...bookingData,contact: {email: user.email,phoneNumber: user.phoneNumber}}
        dispatch(setBookingData(newBookingData))
      }
    }



    // setLoading(true);
    try {
      if(urlData.orderData) return urlData.bookingInfo;
      
      
      // let contactInfo = {email: data.email,phoneNumber: data.phoneNumber};
      
      // const res = await guestLogin(user);
      // setLoading(false);
      // if(res.return) {
        setLoading(true);
        const bookRes = await addBookingsData({bookingData: modPass},user?.id);
        setLoading(false);
        // save data on url
        if(bookRes.return) {
          next({...newBookingData,bookingInfo: bookRes.data})
          return bookRes.data;
        } else setErr("Please input valid information. ");
      // } else setErr(res.msg || "Logging user failed!");
    } catch(e) {
      setLoading(false);
    }
    setLoading(false);

    return false;
  }


  function handlePassengerInput(obj,i) {
    let temp = [...passengers];
    temp[i] = obj;
    // console.log('updated: '+i,temp)
    setPassengers(temp);
    
  }

  function getUserType(type) {
    let val = type.toUpperCase()?.replaceAll('S','')
    if(val === 'CHILDREN')
      val = 'CHILD'

    return val;
  } 
  return (
    <form onSubmit={(ev) => {ev.preventDefault();handleSubmit()}} className='p-5 flex flex-col gap-5 bg-secondary rounded-2xl'>
      <h6>Booking contact</h6>
      <div className='flex gap-2'>
        <TextField className='flex-1' 
          value={user.email} 
          onChange={(ev) => urlData.as || setUser({...user,email: ev.target.value})}
          size='small' label='Enter user email'
          InputLabelProps={{
            shrink: true,
          }} />
        <PhoneNumberInput
          label={'Phone number'}
          value={user.phoneNumber}
          onChange={(val) => urlData.as || setUser({...user,phoneNumber: val})}
          />
      </div>
      {err ? (
        <h5 className='text-red-500 py-2'>{err}</h5>
      ):null}

      
      {
        array.map((obj,i) => {
            return (
              <Collapse key={i} show={i===0} label={<h3>Passenger {i+1} <small className='capitalize text-primary/50'>{obj.type}</small></h3>}>
                  <PassengerInputs type={getUserType(obj.type)} ind={i} key={i} returnData={(obj) => handlePassengerInput(obj,(i))} />
              </Collapse>
            )
        })
      }
      <div className='p-3 flex gap-6 justify-between text-primary/80'>
        <div>
          {back ? (
            <button className='p-2 ' onClick={back}><ArrowBack /> Back</button>
            ): null}
        </div>
        <button type='submit' className='btn2 min-w-[150px]'>{loading ? "Please Wait..." : "Next"}</button>
      </div>
    </form>
  )
}

//might delete later


export function PassengerInputs({returnData,ind,type}) {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const [newPassenger,setNewPassenger] = useState(false);
  const urlData = bookingData;
  
  const dispatch = useDispatch();

  const dataInit = (urlData.bookingInfo && urlData.bookingInfo[ind]) || {...passengerDataTemp,boardingUserType: type,middleName: '',title: '',ffn: '',idType: ''};
  const [data,setDataa] = useState(clone(dataInit))
  
  function setData(obj) {
    let data = {...obj,firstName: obj?.firstName};//+(obj?.middleName||'')
    if(!data.middleName)
      data.middleName = undefined;
    
    if(!data.comment)
      delete data.comment;
    returnData(data)
    setDataa(obj);
  }

  const [passengerList,setPassengerList] = useState([]);
  useEffect(() => {
    loadPassengers();
  },[])

  const lastArrivalDate = bookingData?.offer?.segments?.at(-1)?.arrivalDate;

  async function loadPassengers() {
    const res = await getPassengers(); //bookingData?.as?.id
    if(res.return) {
      let data = res.data
      const uniqueEmails = new Set();
      data = data.filter(obj => type === obj?.boardingUserType && !uniqueEmails.has(obj.passportId) && uniqueEmails.add(obj.passportId));

      setPassengerList(data)
    }
  }

  function handleSelectPassenger(obj) {
    if(obj === 'Add User') return setNewPassenger(true);
    else setNewPassenger(false);

    if(moment(obj.passportExpirationDate).isBefore(moment(lastArrivalDate), 'day'))
      dispatch(setAlertMsg(['error','Warning: Passport expires before arrival date!']))

    console.log(' -> ',obj)

    const {
      title,
      birthDate,
      firstName,
      lastName,
      nationality,
      gender,
      passportId,
      boardingUserType,
      email,
      phoneNumber,
      issuanceLocation,
      issuanceDate,
      issuanceCountry,
      validityCountry,
      passportExpirationDate
    } = obj;
    setData({
      title,
      birthDate,
      firstName,
      lastName,
      nationality,
      gender,
      passportId,
      boardingUserType,
      email,
      phoneNumber,
      issuanceLocation,
      issuanceDate,
      issuanceCountry,
      validityCountry,
      passportExpirationDate      
    })
  }


  const getAgeType = (birthDate) => {
    const today = moment();
    const age = today.diff(moment(birthDate).format("YYYY-MM-DD"), 'years')

    
    let userType = ''

    if(age < 0)
      userType = 'Unborn child'
    else if (age < 2) {
      userType = 'INFANT'
    } else if (age <= 11) {
      userType = 'CHILD'
    } else {
      userType = 'ADULT'
    }

    return userType;
  }
  function testFill(ev) {
    let data = {...clone(passengerDataTempFilled),boardingUserType: type,idType: 'Passport'};
    const Rand = parseInt(Math.random() * 999);
    data.firstName = `Test${type.toLowerCase()}`
    data.email = `Test${Rand}@gmail.com`
    data.gender =["MALE","FEMALE"][parseInt(Math.random()*2)]

    setData(data)
    returnData(data);
  }
  return (
    <div className='flex flex-col gap-3'>
      <FetchUsersInput from={passengerList} enableNew onChange={(obj) => handleSelectPassenger(obj)} label={'Passengers'} />
      {/* {urlData.as && urlData.as.id ? null :( */}
          {/* <div className='flex gap-2'>
            <TextField className='flex-1' 
              value={data.email} 
              onChange={(ev) => setData({...data,email: ev.target.value})}
              size='small' label='Passenger Email'
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput
              value={data.phoneNumber}
              onChange={(val) => setData({...data,phoneNumber: val})}
              />
            </div> */}
      {/* )} */}
      {newPassenger ? (
        <div className='gap-3 flex flex-col'>
          <h6 onDoubleClick={testFill}>Passenger Detail</h6>
          <hr />
          <div className='flex gap-2'>
            <TextField className='min-w-[60px]' size='small' label='Title' select required
              value={data.title||""}
              onChange={(ev) => setData({...data,title: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}>
                <MenuItem value='Mr'>Mr</MenuItem>
                <MenuItem value='Ms'>Ms</MenuItem>
                <MenuItem value='Mrs'>Mrs</MenuItem>
              </TextField>
            <TextField required className='flex-1' size='small' label='First Name' name='firstName'
              value={data.firstName}
              onChange={(ev) => setData({...data,firstName: ev.target.value})}
              inputProps={{
                pattern: '[a-zA-Z]+'
              }}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField className='flex-1' size='small' label='Middle Name' name='middleName'
              value={data.middleName}
              onChange={(ev) => setData({...data,middleName: ev.target.value})}
              inputProps={{
                pattern: '[a-zA-Z]+'
              }}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField required className='flex-1' size='small' label='Surname' name='lastName'
              value={data.lastName}
              onChange={(ev) => setData({...data,lastName: ev.target.value})}
              inputProps={{
                pattern: '[a-zA-Z]+'
              }}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <div className='flex gap-2'>
            <TextField required className='flex-1 min-w-[100px]' size='small' label='Date of birth' type='date'
              value={moment(data.birthDate).format("YYYY-MM-DD") || ""}
              onChange={(ev) => setData({...data,birthDate: ev.target.value,boardingUserType: type})}
              error={data.birthDate !== "" && (getAgeType(data.birthDate) !== type)}
              aria-errormessage={`Must be ${type}`}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField required className='flex-1' size='small' label='Gender' select
              value={data.gender}
              onChange={(ev) => setData({...data,gender: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}>
                <MenuItem value='FEMALE'>Female</MenuItem>
                <MenuItem value='MALE'>Male</MenuItem>
            </TextField>
          </div>
          <div className='flex gap-2'>
            <CountriesInput required
              value={data.nationality}
              // onChange={(val) => handleChange(val.name || val)}
              onChange={(val) => {setData({...data,
                nationality: val.alpha2 || val,
                nationality3: val.alpha3 || data?.nationality3 || val
              })}}
            />
            <TextField required className='flex-1 min-w-[150px]' size='small' label='Identification Type' select
              value={data.idType || "Passport"}
              onChange={(ev) => setData({...data,idType: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}>
                <MenuItem value='Passport'>Passport</MenuItem>
                <MenuItem value='NationalID'>National ID</MenuItem>
            </TextField>

          </div>
          <div className='flex gap-2 flex-wrap'>
            <CountriesInput required label="Issuance Country" 
              value={data.issuanceCountry} name='issuanceCountry'
              onChange={(val) => setData({...data,
                issuanceCountry: val.alpha2 || val,
                issuanceCountry3: val.alpha3 || data?.issuanceCountry3 || val
              })}
            />
            <TextField required className='flex-1' size='small' label={data.idType === 'NationalID' ? 'National ID No' : 'Passport Number'}
              value={data.passportId}
              onChange={(ev) => setData({...data,passportId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField required className='flex-1' size='small' label='Expiry Date' type='date' 
              value={moment(data.passportExpirationDate).format("YYYY-MM-DD") || ""}
              onChange={(ev) => setData({...data,passportExpirationDate: ev.target.value})}
              inputProps={{
                min: new Date().toISOString().slice(0,10)
              }}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div>
            <TextField className='w-[200px]' size='small' label='Frequent Flyer Number'
              value={data.ffn}
              onChange={(ev) => setData({...data,ffn: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className=''>
            <TextField multiline rows={4} label='Remark' className='w-full' size='small'
              value={data?.comment}
              onChange={(ev) => setData({...data,comment: ev.target.value})}
            />
          </div>
        </div>
      ) : null}

    </div>
  )
}