import React from 'react'
import { Link } from 'react-router-dom';

export default function UserLink({data}) {
  let name = "";
  let href = "";

  try {
    name = data.firstName + " " + (data.lastName || data.surName)
    if(data.type === 'SUB_AGENT')
      href = "/subagents/detail/"+data.id;
    else href = "/customers/detail/"+data.id;
  } catch(ex) {}

  return data && (
    <Link to={href||"#"} className='text-blue-500'>{name}</Link>
  )
}
