import React, { useEffect, useState } from 'react'
import { def } from '../config'
// import { currencies } from 'country-data'
import getCurrencies from '../controllers/settings/currency/getCurrencies'
import { MenuItem, TextField } from '@mui/material'


export default function CurrencyManagment() {
  // const currencyCodes = Object.keys(currencies)
  const [value,setValue] = useState(def.currencyCode)
  const [currencies,setCurrencies] = useState([])
  // const navigate = useNavigate();

  let defCurrency = {
    "currencyName": "Nigerian Nira",
    "currencyCode": "NGN",
    "exchangeRateInNaira": 1,
  }
  useEffect(() => {
    loadCurrencies();
  },[])

  async function loadCurrencies() {
    const res = await getCurrencies();
    if(res.return) {
      setCurrencies(res.data)
    }
  }

  function handleCurrencyChange(obj) {
    setValue(obj.currencyCode)
    console.log(obj)
    window.localStorage.setItem("currency",obj.currencyCode)
    window.localStorage.setItem("currencyRate",obj.exchangeRateInNaira)
    window.localStorage.setItem("currencyObj",JSON.stringify(obj))
    window.location.reload();
  }
  return (
    <TextField select className='!border-none border-red-500' size='small' variant='standard'
      
      InputProps={{
        variant: '',
        sx: { border: 'none' }
      }}
      value={value||""} onClick={loadCurrencies}
    >
      <MenuItem value={defCurrency.currencyCode} onClick={() => handleCurrencyChange(defCurrency)}>{defCurrency.currencyCode}</MenuItem>
      {currencies.map((obj,i) => (
        <MenuItem key={i} value={obj.currencyCode} onClick={(() => handleCurrencyChange(obj))}>{obj.currencyCode}</MenuItem>
      ))}
    </TextField>
    // <select defaultValue={value}
    //   value={value} onChange={handleCurrencyChange} onClick={loadCurrencies}>
    //   {/* {currencyCodes.map((code,i) => (
    //     <option key={i} className={code === def.currencyCode ? ' order-first ' : ''} value={code}>{code}</option>
    //   ))} */}
    //   <option value={defCurrency}>{defCurrency.currencyCode}</option>
    //   {currencies.map((obj,i) => (
    //     <option value={obj}>{obj.currencyCode}</option>
    //   ))}
    //   {/* <option value={"USD"}>{"USD"}</option> */}
    // </select>
  )
}
