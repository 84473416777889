import React, { useCallback, useEffect, useState } from 'react'
import { promoReqData } from '../../../data/settings/promo/promoData'
import { MenuItem, Pagination, TextField } from '@mui/material';
import { createPromoCode } from '../../../controllers/settings/promo/createPromoCode';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { getPromoCodes } from '../../../controllers/settings/promo/getPromoCodes';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import DotMenu from '../../../components/mini/DotMenu';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';



const columns = [
  {field: 'code',headerName: 'Code Name',flex:1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex gap-4 items-center'>
          {params.value}
        </div>
      )
    }
  },
  {field: 'discountPercentage',headerName: '%',flex: 1,minWidth: 100,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
        </div>
      )
    }
  },
  {field: 'discountAmount',headerName: 'Amount',flex: 1,minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
        </div>
      )
    }
  },
  {field: 'expiryDate',headerName: 'Expiry Date',flex: 1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {moment(params.value).format("YYYY-MM-DD")}
          </p>
        </div>
      )
    }
  },
  {field: 'action',headerName: 'Action',flex: 1,minWidth: 130,
    renderCell: (params) => {
      // if(!params.value)
      // return null;

      return (
        <ActionCol params={params} />
      )
    }
  },
];

function ActionCol({params}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    console.log("params: ",params);
    dispatch(setModalComp(<></>))

  }
  return (
    <div className='flex justify-between w-full relative'>
      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </DotMenu>
    </div>
  )
}


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];


export default function Index() {
  const [datas,setDatas] = useState([]);
  const [data,setData] = useState({...promoReqData,discount: 0,type: 'Amount'});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})


  const fetchData = useCallback(async () => {
    const res = await getPromoCodes();
    return res;
  },[])
  useEffect(() => {
    async function load() {
      const res = await fetchData();
      if(res.return)
        setDatas(res.data)
    }
    load();
  },[fetchData])


  async function handleSubmit() {
    //if(Object.values(data).some(val => val === '')) return false;
    if(data.code === "" || data.expiryDate === "") return false;

    let reqData = data;
    if(reqData.type === "Amount") reqData.discountAmount = reqData.discount;
    else if(reqData.type === "Percentage") reqData.discountPercentage = reqData.discount

    setLoading(true);
    const res = await createPromoCode(data);
    setLoading(false);

    if(res.return)
      dispatch(setAlertMsg(['success','Promo Code Created.']))
    else dispatch(setAlertMsg(['error','Failed to create promo code!']))
  }

  return (
    <div className='bg-[#f3f3f3] p-4 w-full flex flex-col gap-4'>
      <div className='bg-secondary rounded-lg p-4 flex gap-3 flex-wrap'>
        <div className='w-full'>
          <TextField label="Type" name='promotype' size='small' className='min-w-[100px]' select
            value={data.type}
            onChange={(ev) => setData({...data,type: ev.target.value,discountAmount: 0,discountPercentage: 0})}
          >
            <MenuItem value='Percentage'>Percentage</MenuItem>  
            <MenuItem value='Amount'>Amount</MenuItem>  
          </TextField> 
        </div>
        <TextField label="Code Name" name='codename' size='small'
          value={data.code}
          onChange={(ev) => setData({...data,code: ev.target.value})}
          />
        <TextField label="Expiry Date" type='date' size='small'
          value={data.expiryDate}
          onChange={(ev) => setData({...data,expiryDate: ev.target.value})}
          InputLabelProps={{
            shrink: true
          }}
          />
        <TextField label={'Discount '+data.type} name='discount' size='small'
          value={data.discount}
          onChange={(ev) => setData({...data,discount: ev.target.value})}
          />
        <button className='btn2 flex gap-2' onClick={handleSubmit}>
          {loading?(<div className='load'></div>):null}
          Submit</button>
      </div>



      <div className='bg-secondary rounded-lg p-4'>
        <DataGrid rows={datas} columns={columns} loading={loading} autoHeight hideFooter={false}
            // getRowId={(row) => row.id}
          // apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} 
          // apiRef={apiRef}
        />
      </div>

    </div>
  )
}
