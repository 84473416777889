import mergeRecursive from "../../features/utils/mergeRecursive";

export const subAgentTemp = {
  "id": "50ebceef-bc61-41c1-a06f-b82c8593e065",
  "username": "admin",
  "firstName": "miles",
  "email": "admin@admin.com",
  "lastName": "admin",
  "password": "$2b$10$q/EEYM5luOhjRtfGSA75JubJmH0LMsr9ej7VnDT4bxettFyYGUeCe",
  "profile": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/604.jpg",
  "isAdmin": true,
  "phoneNumber": "0912345678",
  "type": "CUSTOMER",
  "customSettingid": null,
  "gender": "MALE",
  "companyName": "PERSONAL",
  "addressLine1": null,
  "addressLine2": null,
  "postalCode": null,
  "cityName": null,
  "countryCode": null,
  "apiUserData": {
    "companyName": ""
  },
  "_count": {
    "approvedApiRequests": 0,
    "bookingData": 0,
    "bookedTrips": 0
  },
  "status": 'Active'
}

export function templateSubAgent(obj) {
  let data = mergeRecursive({...obj},subAgentTemp);
  return {...data,
    travelAgency: data.apiUserData.companyName,
    name: data.firstName + " " +data.lastName,
    phone: data.phoneNumber,
    email: data.email,
    status: data.status
  };
}

export function templateSubAgentReport(obj) {
  let data = mergeRecursive({...obj},subAgentTemp);
  return {
    travelAgency: data.apiUserData.companyName,
    name: data.firstName + " " +data.lastName,
    phone: data.phoneNumber,
    email: data.email,
    bookings: '',
    status: data.status
  };
}
