import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';


// const options = [
//   { label: 'Option 1', value: 'Option 1' },
//   { label: 'Option 2', value: 'Option 2' },
//   { label: 'Option 3', value: 'Option 3' },
//   { label: 'Option 4', value: 'Option 4' },
//   { label: 'Option 5', value: 'Option 5' },
// ];

const MultipleSelect = ({options,label,className,value,onChange}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log(" ---- ",value)

  useEffect(() => {
    if(value) {
      let temped = value;
      try {
        if(!temped.label)
        temped = value.map(val => ({value: val,label: val.replace('_',' ')}))
      } catch(ex) {}

      setSelectedOptions(temped);
    }
  },[value])


  const handleChange = (event, value) => {
    setSelectedOptions(value);
    if(onChange)
      onChange(value);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleChange}
      className={className}
      size='small'
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          // label={label}
          placeholder={label}
        />
      )}
    />
  );
};

export default MultipleSelect;