import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodes } from "../../controllers/settings/promo/getPromoCodes";
import getFlightOrder from "../../controllers/flight/getFlightOrder";
import { setBookingData } from "../../redux/reducers/flight/flightBookingSlice";
import { applyPromoCode } from "../../controllers/settings/promo/applyPromoCodeFlight";
import { MenuItem, TextField } from "@mui/material";
import { def } from "../../config";
import { Cancel, Check } from "@mui/icons-material";
import Select from 'react-select';
import { applyPromoCodeHotel } from "../../controllers/settings/promo/applyPromoCodeHotel";
import { applyPromoCodeTour } from "../../controllers/settings/promo/applyPromoCodeHotelTour";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { setHotelBooking } from "../../redux/reducers/hotel/hotelBookingSlice";
import { setTourBooking } from "../../redux/reducers/tour/tourBookingSlice";


export default function PromoCode({id,type,returnData}) {
  const [selected,setSelected] = useState();
  const [options,setOptions] = useState([]);
  const [loading,setLoading] = useState(false);
  const [res,setRes] = useState()
  const {bookingData} = useSelector((state) => state.flightBooking);
  const {tourBooking} = useSelector(state => state.tourBooking)
  const {hotelBooking} = useSelector(state => state.hotelBooking)

  
  const dispatch = useDispatch();

  console.log(" && id: ",id)
  useEffect(() => {
    // async function load() {
    //   const res = await getPromoCodes();
    //   if(res.return) {
    //     let data = res.data;
    //     // @my_fix: date check is from client. should be from server
    //     data = data.filter(obj => new Date(obj.expiryDate) > new Date())
    //     data = res.data.sort((prev,cur) => new Date(cur.updatedDate) - new Date(prev.updatedDate));
    //     console.log(data)
    //     setOptions(data);
    //   }
    // }
    // load();
  },[])

  useEffect(() => {
    setSelected()
    setRes()
  },[id])

  async function handleSubmit() {
    setLoading(true);
    const promoData = {code: selected};
    let res;
    if(type === 'hotel')
      res = await applyPromoCodeHotel(id,promoData);
    else if(type === 'tour')
      res = await applyPromoCodeTour(id,promoData);
    else
      res = await applyPromoCode(id,promoData)
    setRes(res);
    setLoading(false);
    if(res.return) {
      if(type === 'hotel')
        dispatch(setHotelBooking({...hotelBooking,booked: res.data}))
      else if(type === 'tour')
        dispatch(setTourBooking({...tourBooking,bookingData: res.data}))
      else
        dispatch(setBookingData({...bookingData,orderData: res.data}))
      // getFlightOrder(id)
      // .then((res) => {
      //   if(res.return) {
      //     dispatch(setBookingData({...bookingData,orderData: res.data}))
      //     if(returnData)
      //       returnData()
      //   }
      // })
    } else dispatch(setAlertMsg(['error',res.msg]))

  }
  return (
    <div className='py-3 flex gap-2 items-center'>
      <TextField size='small' className='min-w-[160px]' label='Enter Promo Code'
        value={(selected) || ""} 
        onChange={(ev) => setSelected(ev.target.value)}
        // InputProps={{
        //   inputComponent: Select,
        //   inputProps: {
        //     options,
        //     isClearable: true,
        //     isSearchable: true,
        //     menuPlacement: 'top',
        //     menuShouldScrollIntoView: false,
        //     menuPortalTarget: document.body,
        //     styles: {
        //       menuPortal: base => ({ ...base, zIndex: 9999 }),
        //     },
        //   },
        // }}
        InputLabelProps={{
          shrink: true
        }}
      />
        {/* {options.map((opt,i) => (
          <MenuItem key={i} value={opt.id} onClick={() => setSelected(opt)} className='flex flex-col items-start'>
            {opt.code}
            <div className='flex gap-2'>
              <small>{opt.discountPercentage}%</small>
              <small>{def.currency}{opt.discountAmount}</small>
            </div>
          </MenuItem>
        ))}
      </TextField> */}
      {/* {selected && ( */}
        <button className='btn1 flex gap-2 items-center' onClick={handleSubmit} disabled={!selected}>
          {loading ? (<div className='load'></div>) : null}
          {res ? 
            res.return ? (<Check className='w-4 h-4' />) : (<Cancel className='w-4 h-4 text-red-500' />)
          : null
          }
          Apply Code</button>
      {/* )} */}
    </div>
  )
}