import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { MenuItem, TextField } from '@mui/material';
import { customerTemp } from '../../../data/customer/customerData';
import { signup } from '../../../controllers/user/signup';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import CountriesInput from '../../../components/form/CountriesInput';
import moment from 'moment';
import PasswordInput from '../../../components/form/PasswordInput';
import EmailInput from '../../../components/form/EmailInput';
import RolesInput from '../../../components/form/RolesInput';
import { PassengerInputs } from '../../Flights/book/PassengerInfo';
import { passengerDataTemp, passengerDataTempFilled } from '../../../data/user/passengerData';
import { clone } from '../../../features/utils/objClone';
import { addBookingsData } from '../../../controllers/flight/addMultipleBooking';
import AirlinesInput from '../../../components/form/AirlinesInput';


export default function Index() {
  const type = null;
  const [data,setDataa] = useState({...passengerDataTemp})
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle('Create Passenger'))
  },[])
  function setData(obj) {
    setDataa(obj);
  }

  async function createPassenger() {

    let validate = (data) => {
      let {ffn,title,...reqData} = data;
      let dat = JSON.parse(JSON.stringify({...reqData}));
      data.middleName && (dat.firstName = data.firstName+""+data.middleName);
      data.title && (dat.firstName = data.title+""+data.firstName);
      dat.birthDate = moment(data.birthDate.toString()).format("YYYY/MM/DD") || "";
      dat.passportExpirationDate = moment(data.passportExpirationDate.toString()).format("YYYY/MM/DD") || "";
      dat.issuanceDate = moment(Date()).format("YYYY/MM/DD");
      dat.issuanceLocation = dat.validityCountry = dat.issuanceCountry;

      if(
        (dat.firstName === "") ||
        (dat.birthDate === "") ||
        (dat.passportExpirationDate === "") ||
        (dat.issuanceDate === "") ||
        (dat.issuanceLocation === "" )
      ) return false;
      
      const today = moment();
      const age = today.diff(dat.birthDate, 'years')

      
      let userType = ''
      if(age < 0)
        userType = 'Unborn child'
      else if (age < 2) {
        userType = 'INFANT'
      } else if (age <= 11) {
        userType = 'CHILD'
      } else if(age > 11 ){
        userType = 'ADULT'
      }

      dat.boardingUserType = userType;

        
      return dat;
        
    }


    let dat = validate(data);
    if(!dat)
      return dispatch(setAlertMsg(['error','Please fill all required fields.']));


    setLoading(true);
    try {

      const bookRes = await addBookingsData({bookingData: [dat]});
        setLoading(false);
        // save data on url
        if(bookRes.return) {
          dispatch(setAlertMsg(['success','Customer creation successful.']))
          setData(clone(passengerDataTemp));
        } else dispatch(setAlertMsg(["error","Please input valid information. "]));
      // } else setErr("Logging user failed!");
    } catch(e) {
      setLoading(false);
    }
    setLoading(false);

    return false;
  }

  const getAgeType = (birthDate) => {
    const today = moment();
    const age = today.diff(moment(birthDate).format("YYYY-MM-DD"), 'years')

    
    let userType = ''
    if (age < 2 && age >= 0) {
      userType = 'INFANT'
    } else if (age <= 11) {
      userType = 'CHILD'
    } else {
      userType = 'ADULT'
    }

    return userType;
  }
  function testFill(ev) {
    let data = {...clone(passengerDataTempFilled),boardingUserType: type,idType: 'Passport'};
    const Rand = parseInt(Math.random() * 999);
    data.firstName = `Test${type.toLowerCase()}`
    data.email = `Test${Rand}@gmail.com`
    data.gender =["MALE","FEMALE"][parseInt(Math.random()*2)]

    setData(data)
  }
  return (
    <div className='flex flex-col gap-3 my-5 w-full px-5 mx-5 max-w-[900px]'>
      
      {/* {urlData.as && urlData.as.id ? null :( */}
          <div className='flex gap-2'>
            <TextField className='flex-1' 
              value={data.email} 
              onChange={(ev) => setData({...data,email: ev.target.value})}
              size='small' label='Passenger Email'
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput
              value={data.phoneNumber}
              onChange={(val) => setData({...data,phoneNumber: val})}
              />
          </div>
      {/* )} */}
      <hr />
      <div className='flex gap-2'>
        <TextField className='min-w-[60px]' size='small' label='Title' select
          value={data.title||""}
          onChange={(ev) => setData({...data,title: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }}>
            <MenuItem value='Mr'>Mr</MenuItem>
            <MenuItem value='Ms'>Ms</MenuItem>
            <MenuItem value='Mrs'>Mrs</MenuItem>
          </TextField>
        <TextField className='flex-1' size='small' label='First Name' name='firstName'
          value={data.firstName}
          onChange={(ev) => setData({...data,firstName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1' size='small' label='Middle Name' name='middleName'
          value={data.middleName}
          onChange={(ev) => setData({...data,middleName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1' size='small' label='Surname' name='lastName'
          value={data.lastName}
          onChange={(ev) => setData({...data,lastName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />

      </div>
      <div className='flex gap-2'>
        <TextField className='flex-1 min-w-[100px]' size='small' label='Date of birth' type='date'
          value={moment(data.birthDate).format("YYYY-MM-DD") || ""}
          onChange={(ev) => setData({...data,birthDate: ev.target.value})}
          // error={data.birthDate !== ""}
          aria-errormessage={`Must be ${type}`}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1' size='small' label='Gender' select
          value={data.gender}
          onChange={(ev) => setData({...data,gender: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }}>
            <MenuItem value='FEMALE'>Female</MenuItem>
            <MenuItem value='MALE'>Male</MenuItem>
        </TextField>
        <TextField className='flex-1 min-w-[150px]' size='small' label='Identification Type' select
          value={data.idType || ""}
          onChange={(ev) => setData({...data,idType: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }}>
            <MenuItem value='passport'>Passport</MenuItem>
            <MenuItem value='NationalID'>National ID</MenuItem>
        </TextField>
        <CountriesInput 
          value={data.nationality}
          onChange={(val) => setData({...data,nationality: val.alpha2 || val})}
         />


      </div>
      <div className='flex gap-2 flex-wrap'>
        <TextField className='flex-1' size='small' label={'Unique ID'}
          value={data.uniqueId}
          onChange={(ev) => setData({...data,uniqueId: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <CountriesInput label="Issuance Country" 
          value={data.issuanceCountry} name='issuanceCountry'
          onChange={(val) => setData({...data,issuanceCountry: val.alpha2 || val})}
         />
        <TextField className='flex-1' size='small' label='Expiry Date' type='date' 
          value={moment(data.passportExpirationDate).format("YYYY-MM-DD") || ""}
          onChange={(ev) => setData({...data,passportExpirationDate: ev.target.value})}
          inputProps={{
            min: new Date().toISOString().slice(0,10)
          }}
          InputLabelProps={{
            shrink: true,
          }} />
      </div>
      <div>
        <TextField className='w-[200px]' size='small' label='Frequent Flyer Number'
          value={data.ffn}
          onChange={(ev) => setData({...data,ffn: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
      </div>

      <div className='flex gap-4 flex-wrap'>
        <TextField className='w-[200px]' size='small' label='Passport Id'
          value={data.passportId}
          onChange={(ev) => setData({...data,passportId: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <PhoneNumberInput label={'Emergency contact phone'}
          value={data.emergencyContactNumber}
          onChange={(val) => setData({...data,emergencyContactNumber: val})}
        />
        <EmailInput label='Alternate Email'
          value={data.alternateEmail}
          onChange={(val) => setData({...data,alternateEmail: val})}
        />
        <TextField className='w-[200px]' size='small' label='Primary Profile Name'
          value={data.primaryProfile}
          onChange={(ev) => setData({...data,primaryProfile: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='w-[200px]' size='small' label='Primary Profile Relation'
          value={data.primaryProfileRelation}
          onChange={(ev) => setData({...data,primaryProfileRelation: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1 min-w-[150px]' size='small' label='Privilege' select
          value={data.btmPrivilege || ""}
          onChange={(ev) => setData({...data,btmPrivilege: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }}>
            <MenuItem value='Premium'>Premium</MenuItem>
            <MenuItem value='Normal'>Normal</MenuItem>
        </TextField>
        <TextField className='w-[200px]' size='small' label='Company Department'
          value={data.companyDepartment}
          onChange={(ev) => setData({...data,companyDepartment: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='w-[200px]' size='small' label='Company Designation'
          value={data.companyDesignation}
          onChange={(ev) => setData({...data,companyDesignation: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='w-[200px]' size='small' label='National ID Number'
          value={data.nationalIdNumber}
          onChange={(ev) => setData({...data,nationalIdNumber: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1 min-w-[100px]' size='small' label='National Id Expiry Date' type='date'
          value={moment(data.nationalIdExpiry).format("YYYY-MM-DD") || ""}
          onChange={(ev) => setData({...data,nationalIdExpiry: ev.target.value})}
          // error={data.birthDate !== ""}
          aria-errormessage={`Must be ${type}`}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='w-[200px]' size='small' label='Driving License Number'
          value={data.drivingLicenseNumber}
          onChange={(ev) => setData({...data,drivingLicenseNumber: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='flex-1 min-w-[100px]' size='small' label='drivingLicenseExpiryDate' type='date'
          value={moment(data.drivingLicenseExpiryDate).format("YYYY-MM-DD") || ""}
          onChange={(ev) => setData({...data,drivingLicenseExpiryDate: ev.target.value})}
          // error={data.birthDate !== ""}
          aria-errormessage={`Must be ${type}`}
          InputLabelProps={{
            shrink: true,
          }} />
        <AirlinesInput label={'Prefered Airline'} size='small'
          val={data.airlinePreference}
          returnData={(val) => setData({...data,airlinePreference: val.id || data.airlinePreference})}
          />
        <TextField className='w-[200px]' size='small' label='Seat Preference' type='number'
          value={data.seatPreference}
          onChange={(ev) => setData({...data,seatPreference: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField className='w-[200px]' size='small' label='Meal Preference'
          value={data.mealsPreference}
          onChange={(ev) => setData({...data,mealsPreference: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
      </div>

      <button className='btn2 self-end flex gap-2 items-center ' onClick={() => createPassenger()}>
        {loading?<div className='load'></div>:null}
        Create Passenger</button>
    </div>
  )
}
