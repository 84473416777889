import { Box, MenuItem } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/reducers/titleSlice';
import DotMenu from '../../components/mini/DotMenu';
import { getSettings } from '../../controllers/settings/getSettings';
import AddMarkup from './AddMarkup';
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice';
import RemoveMarkupModal from './RemoveMarkupModal';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))



const columns = [
  {field: 'for',headerName: 'User',flex:1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex gap-4 items-center'>
          {params.value}
        </div>
      )
    }
  },
  {field: 'markUp',headerName: 'Value',flex: 1,minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
        </div>
      )
    }
  },
  {field: 'status',headerName: 'Status',flex: 1,minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
        </div>
      )
    }
  },
  {field: 'action',headerName: 'Action',flex: 1,minWidth: 130,
    renderCell: (params) => {
      // if(!params.value)
      // return null;

      return (
        <ActionCol params={params} />
      )
    }
  },
];

function ActionCol({params}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    console.log("params: ",params);
    dispatch(setModalComp(<RemoveMarkupModal id={id} />))

  }
  return (
    <div className='flex justify-between w-full relative'>
      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </DotMenu>
    </div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];



export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})


  const [userType,setUserType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(type);
    dispatch(setHeaderTitle("Markups"))
    load();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userType])
  async function load() {
    setLoading(true);
    let q = {
      for: userType.toUpperCase()
    }
    const res = await getSettings(new URLSearchParams(q));
    if(res.return) {
      let data = res.data.map(d => d.fareRules.map(obj => ({for: d['for'] || (d['customUser'].firstName+" "+d['customUser'].lastName),...obj}) )).flat(1);
      console.log('flatted', data)
      setData(data);
    }
    setLoading(false);
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.date)).toDateString().toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }
  
  return (
    <div className='w-full p-4 m-2 bg-[#f3f3f3]'>
      
      <AddMarkup returnData={(val) => setUserType(val)} />
      <Box className='bg-secondary'>
        <div className='flex gap-4 justify-between items-center my-3 p-3'>
          <SearchInput className='text-theme1' onChange={handleSearch} />
        </div>

        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
