import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function addMarkupUserType(userType,data) {
  var response = {return: 0,msg: "Error",data: null}

  let token = store.getState().user.userData.accessToken;
  // console.log('q got : ',q)

  await fetchServer({method: 'POST',url: path.api+"/settings/markup/user-type/"+userType,data,
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    // console.log(" -- ",res)
    if(res && res.data) {
      if(res.data.success)
        response = {return: 1,msg: "Successfull",data: res.data.data}
    }
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}