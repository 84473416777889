import mergeRecursive from "../../features/utils/mergeRecursive";

export default function templateBookedHotels(obj) {
  let temp = {
    bookedBy: {
      firstName: "",
      lastName: "",
    },
    createdDate: "",
    updatedDate: "",
    hotelData: {
      price: '',
      currency: '',
      start_date: '',
      end_date: '',
      name: ''
    },
    bookingId: '',
    status: '',
    editedtotalPrice: ""
  }
  
  // try {
  //   if(obj.flightData.booked_flights.length === 1) {
  //     console.log(obj);
  //     const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));
  //     console.log("temped - ",data);

  //   }
  // } catch(ex) {console.log("--")}
  const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));

  // console.log(" -== ",obj);
  let result = {
    ...data,
    customer: data.bookedBy.firstName + " " + data.bookedBy.lastName,
    updatedDate: data.updatedDate,
    checkIn: data.hotelData.start_date,
    checkOut: data.hotelData.end_date,
    hotelName: data.hotelData.name,
    bookingId: data.bookingId,
    amount: data.editedtotalPrice,
    status: data.status,
  }

  // if(data.booked_flights.length > 1)
  //   result["returnDate"] = {
  //     date: data.flightData.booked_flights[1].origin.date,
  //     time: data.flightData.booked_flights[1].origin.time,
  //   }


  return result;
}

export function templateBookedHotelReport(obj) {
  let temp = {
    bookedBy: {
      firstName: "",
      lastName: "",
    },
    createdDate: "",
    updatedDate: "",
    hotelData: {
      price: '',
      currency: '',
      start_date: '',
      end_date: '',
      name: ''
    },
    bookingId: '',
    status: '',
    editedtotalPrice: ""
  }
  
  // try {
  //   if(obj.flightData.booked_flights.length === 1) {
  //     console.log(obj);
  //     const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));
  //     console.log("temped - ",data);

  //   }
  // } catch(ex) {console.log("--")}
  const data = mergeRecursive(JSON.parse(JSON.stringify(obj)),JSON.parse(JSON.stringify(temp)));

  // console.log(" -== ",obj);
  let result = {
    customer: data.bookedBy.firstName + " " + data.bookedBy.lastName,
    updatedDate: data.updatedDate,
    checkIn: data.hotelData.start_date,
    checkOut: data.hotelData.end_date,
    hotelName: data.hotelData.name,
    bookingId: data.bookingId,
    status: data.status,
  }
  
  result = Object.values(result);

  // if(data.booked_flights.length > 1)
  //   result["returnDate"] = {
  //     date: data.flightData.booked_flights[1].origin.date,
  //     time: data.flightData.booked_flights[1].origin.time,
  //   }


  return result;
}

