import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function walletPayHotel(orderId) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  // console.log(" --- on Single Booking Data ---")
  // console.log("data - ",data)
  // console.log(" --------- -----------")

  await fetchServer({method: "PATCH",url: path.api+'/hotels/pay-for-order/wallet/'+orderId,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  .then((res) => {
    console.log(" => ",res)
    if(res) {
      if(res?.data?.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      } else if(res?.error) result['msg'] = res?.error?.message;
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}