import React, { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Login from './Login'
import Sidebar from './Sidebar'
import Header from './Header'
import { useSelector } from 'react-redux'
import AlertMsg from './mini/SnackBar'
import Modal from './mini/Modal'
import { def, path } from '../config'
import AgentProfile from './user/EditAgentProfile'
import Footer from './Footer'

export function checkProfileComplete(user) {
  let complete = false;

  if(user.companyName === 'Agency' && user.apiUserData === null)
    return false;
  else if(!user.firstName || !user?.lastName)
    return false;
  else if(!user.addressLine1)
    return false;
  
  return complete;
}

//might delete later
export default function Navbar() {
  const {loggedIn} = useSelector((state) => state.user.userData);
  const {comp} = useSelector((state) => state.modal);
  const {user} = useSelector((state) => state.user.userData);

  const [searchParam] = useSearchParams();


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/5c9cd9f11de11b6e3b05ac5c/default';
    script.async = true;
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
    
  useEffect(() => {
    const targetId = location.hash.substr(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    
  }, [location]);
  
  useEffect(() => {
    let path = location.pathname;
    try {
      path = path.split("/")[1]
    } catch(ex) {}
    console.log('loc : ',user)
    if(loggedIn && (checkProfileComplete(user)) && (path !== 'subagents'))
      navigate("subagents/detail/"+user.id+"?edit=true&firstTime=true")

  },[location,user,loggedIn])


  let shared = false;
  let refCode = searchParam.get('referralCode');
  if (window.parent !== window)
    shared = true;
  if(refCode && refCode !== "null")
    shared = true;
  
  return (loggedIn || shared ) ? (
    <div className='h-screen max-w-[100vw]'>
      <AlertMsg />
      <Modal>{comp}</Modal>
      <div className='flex min-h-screen h-full overflow-auto'>
        {!shared ? (
          <Sidebar />
        ) : null}
        {/* <div className='flex justify-center w-full '> */}
        <div className='flex-1 flex flex-col w-full min-h-full overflow-auto xmax-w-[1000px]'>
          {!shared ? (
          <Header />
          ) : null }
          <div className='flex-1 flex justify-center'>
            {/* {user.profile ?  */}
              <Outlet />
            {/* : <div className='bg-[#f3f3f3] w-full p-5'>
                <div className='bg-secondary p-8'>
                  <AgentProfile />
                </div>
              </div>
            } */}
          </div>
          {!shared ? (
            <Footer />
          ) : null }
        </div>
        {/* </div> */}
      </div>
    </div>
  ) : (
    <div className='flex flex-col min-h-screen'>
      <AlertMsg />
      <div className='bg-secondary p-2 border-b flex '>
        <Link to={path.home} className='text-secondary p-2'>{def.logo ? <img src={def.logo} className='w-[100px]' alt='logo' /> : def.siteName}</Link>
      </div>
      <div className='flex-1 flex justify-center items-center max-w-full px-4 overflow-hidden'>
        <Login />
      </div>
    </div>
  )
}
