import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Passengers from './Passengers';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import HotelDetails from './HotelDetails';
import { bookedHotelDataTemp } from '../../../data/hotel/bookedDetail';
import getSingleHotelOrder from '../../../controllers/hotel/getSingleOrder';


let temp = bookedHotelDataTemp;

export default function Index() {
  const [data,setData] = useState(temp);
  const {id} = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle("Hotel booking"));
    load(id);
  },[id,dispatch])
  
  async function load(id) {
    const res = await getSingleHotelOrder(id);
    if(res.return) {
      // let data = templateDetails(res.data)
      setData(res.data);
    }
  }

  
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      <div className='p-4 bg-secondary'>
        <Breadcrumbs separator=">">
          <Link to="/hotels">Orders</Link>,
          <span >{data.hotelData.code}</span>
        </Breadcrumbs>
        <h3 className='!text-[#444]'>{data.hotelData.control_number}</h3>


        <div className='flex gap-4 flex-wrap'>
          <div className='flex-1 flex flex-col gap-10 pb-4'>
            <div className='mt-8'>
              <HotelDetails data={data} />
            </div>
            <div className=''>
              <Passengers data={data} />
            </div>
          </div>
          <div>
            <MinDetail data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

function MinDetail({data}) {
  // let temp = bookedHotelDataTemp;

  return (
    <div>
      <h5 className='px-4'>Flight Details</h5>
      <hr />
      <div className='px-4 pt-5 flex flex-col gap-4'>
        <div>
          <div>Status</div>
          {
            data?.status === 'Confirmed' ? 
              <span className='text-xs p-2 inline-block rounded-md !border-[#45BF43] border !bg-[#58ffb038]'>Confirmed</span>
              :
              <span className='text-xs p-2 inline-block rounded-md !border-[#aaa] border'>{data?.status}</span>
          }
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between gap-10'>
            <div>Hotel</div>
            <div>Issuing Date</div>
          </div>
          <div className='flex  justify-between gap-10'>
            <b className='flex gap-1'>
              {data?.hotelData?.name}
            </b>
            <b>
              {(new Date(`${data?.hotelData?.created_at}`).toDateString())}
            </b>
          </div>
        </div>
        <div>
          <div>Order Id</div>
          <b>{data?.hotelData?.code}</b>
        </div>
        <div>
          <span>Booking Number</span>
          <div>
            <div className='target'>{data?.hotelData?.control_number}</div>
          </div>
        </div>
      </div>
    </div>
  )
}