import { useState } from 'react';
import FlightDisplay from './FlightDisplay';
import FlightInfoCard from './FlightInfoCard';
import Collapse from '../mini/Collapse';
import { formatMoney } from '../../features/utils/formatMoney';
import { def } from '../../config';
import ViewFareRule, { ViewFareRuleVerteil } from './ViewFareRule';
import { checkBookable } from '../../controllers/flight/checkBookable';
import getSupplierOffice from '../../features/flights/getSupplierOffice';
// import { offerDataTemp } from '../../data/flight/offerData';

export default function FlightOfferDisplay({data,showDetail,lockSelect,setLockSelect}) {
  const [loading,setLoading] = useState(false);
  // const data = offerDataTemp;
  // data.flightData.booked_flights[1] = (flightDataTemp.flightData.booked_flights[0])
  // {
  //   "itineraries": [
  //       {
  //           "from": "LOS",
  //           "to": "IST",
  //           "segments": [
  //               {
  //                   "departureDate": "2024-01-02",
  //                   "from": "LOS",
  //                   "to": "CMN",
  //                   "marketingCarrier": "AT",
  //                   "flightNumber": "554",
  //                   "bookingClass": "N",
  //                   "nrOfPassengers": 1,
  //                   "arrivalDate": "2024-01-02"
  //               }
  //           ]
  async function loadDetail(data) {
    console.log("airsell: ",data)
    if(lockSelect) return false
    setLockSelect(true);
    setLoading(true);
    // const modData = {
    //   itineraries: data.segments?.map(obj => ({
    //     from: obj.departureLocation,
    //     to: obj.arrivalLocation,
    //     segments: obj.flights.map(flight => ({
    //       from: flight.departureLocation,
    //       to: flight.arrivalLocation,
    //       bookingClass: flight.cabinCode,
    //       nrOfPassengers: Object.keys(data.passengers||{}).length,
    //       ...flight
    //     }))
    //   }))
    // }
    let segmentsCheck = [];

    // new implementation
    let itineraries = [];
    for(let obj of data.segments) {
      let modData = {
        itineraries: [{
          from: obj.departureLocation,
          to: obj.arrivalLocation,
          segments: obj.flights.map(flight => ({
            from: flight.departureLocation,
            to: flight.arrivalLocation,
            bookingClass: flight.cabinCode,
            nrOfPassengers: Object.keys(data.passengers||{}).length,
            ...flight
          }))
        }],
        // office: getSupplierOffice(data?.supplier)
      }
      itineraries.push(modData.itineraries?.at(0))

    }
    let res = {return: true};
    if(data.supplier !== 'VERTEIL') {
      res = await checkBookable({
        itineraries,
        office: getSupplierOffice(data?.supplier)
      });
      let itineraryDetails = res.data?.itineraryDetails;
      if(!Array.isArray(itineraryDetails))
        itineraryDetails = [itineraryDetails]

      segmentsCheck = itineraryDetails?.map(obj => {
        let segInfo = obj?.segmentInformation;
        if(!Array.isArray(segInfo))
          segInfo = [segInfo];

        return segInfo?.every(obj => obj?.actionDetails?.statusCode === 'OK')
      })
    }
    // if(res.return)
    //   segmentsCheck.push(true);
    // else
    //   segmentsCheck.push(false);

    // old implementation
    // for(let obj of data.segments) {
    //   let modData = {
    //     itineraries: [{
    //       from: obj.departureLocation,
    //       to: obj.arrivalLocation,
    //       segments: obj.flights.map(flight => ({
    //         from: flight.departureLocation,
    //         to: flight.arrivalLocation,
    //         bookingClass: flight.cabinCode,
    //         nrOfPassengers: Object.keys(data.passengers||{}).length,
    //         flightNumber: flight?.flightNumber,
    //         arrivalDate: flight?.arrivalDate,
    //         departureDate: flight?.departureDate,
    //         marketingCarrier: flight?.marketingCarrier,
    //           // ...flight
    //       }))
    //     }],
    //     office: getSupplierOffice(data?.supplier)
    //   }

    //   let res = {return: true};
    //   if(data.supplier !== 'VERTEIL')
    //     res = await checkBookable(modData);
    //   if(res.return)
    //     segmentsCheck.push(true);
    //   else
    //     segmentsCheck.push(false);
    // }

    await showDetail(data,segmentsCheck)
    // console.log(modData,data)
      
    setLockSelect(false)
    setLoading(false);
  }

  let initLoc = "";
  try {
    initLoc = data.segments[0].departureLocation || "";
  } catch(ex) {
    
  }

  return (
      <div className='bg-secondary rounded-2xl overflow-clip'>
        <div className='flex'>
        <div className='flex flex-col justify-stretch grow '>
          {
            data.segments.map((obj,i) => {
              // let flight = obj.flights[0];
              return (
                <div key={i} className='flex flex-col justify-stretch grow'>
                  <FlightDisplay key={i} flight={obj} />
                </div>
            )})
          }
        </div>
        <div className='flex flex-col p-2 gap-2 justify-center items-center w-[35%] border border-[#e7e7e7] py-4'>
          <div className='flex flex-col items-center'>
            <h4>{def.currency}{(data.farePrice && formatMoney(data.farePrice.fareTotal,data?.farePrice?.currencyData?.at(0)?.currency?.currencyCode)) || data.formatedTotalAmount}</h4>
            {/* {data.formatedTotalAmount} */}
            <p>{data.segments[0].cabin} {data.segments[0]?.bookingClass}</p>
          </div>
          {/* <p>N Seats left at this price</p> */}
          <div className=''>
            <button className={`flex items-center gap-2 btn1`} disabled={lockSelect} onClick={() => loadDetail(data)}>
              {loading ? <div className='load'></div> : null}
              Select
            </button>
          </div>
          <h6>
            {data.AmadeusNG ? 'Amadeus NG' 
            :
            data.AmadeusDXB ? 'Amadeus DXB' 
            :
            data.travelPortOriginal ? 'Travelport' : 
            data.supplier}
          </h6>
        </div>
      </div>
      <div className='border border-[#e7e7e7] p-4'>
        <Collapse label={<b className='text-theme1'>Flight Details</b>} className=''>
          {data.segments.map((flights,i) => (
            <FlightInfoCard key={i} data={flights} label={initLoc === flights.arrivalLocation ? 'Return' : 'Departure'} />
          ))}
          {data.supplier?.toLowerCase() !== 'verteil' ? 
            <ViewFareRule data={data} />
          :
            <ViewFareRuleVerteil data={data} />
          }
        </Collapse>
      </div>
    </div>
  )
}
