import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { decrypt } from "../../features/utils/crypto";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { Email } from "@mui/icons-material";
import ModalLocal from "../mini/ModalLocal";
import EmailInput from "../form/EmailInput";
import { Icon } from "@iconify/react";
import { shareHotelOffer } from "../../controllers/hotel/shareHotelOffer";

export default function ShareHotelOffer({offer}) {
  const [openShare,setOpenShare] = useState(false);
  const [shareData,setShareData] = useState({hotels: offer, email: ''})
  const [shareLoading,setShareLoading] = useState(false);

  const {user} = useSelector(state => state.user.userData)
  const {bookingData} = useSelector(state => state.flightBooking);
  const [searchParam] = useSearchParams();
  const q = searchParam.get('q');

  const dispatch = useDispatch();

  useEffect(() => {
    setShareData(shareData => ({...shareData,hotels: offer}))
  },[offer])
  

  async function shareHotels() {
    console.log('offers: ',offer)
    if(!offer.length) return dispatch(setAlertMsg(['warning','No Hotel Offers! Please wait for hotels to load.']))
    let reqData = {...shareData};

    if(!reqData.email) 
      return dispatch(setAlertMsg(['error','Email is required']))

    setShareLoading(true);
    const res = await shareHotelOffer(reqData);
    setShareLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Link sent to email']))
      setOpenShare(false);
    } else dispatch(setAlertMsg(['error',res.msg]))
    // console.log(req,reqData)
  }

  return (
    <div className="flex self-end  justifiy-end">
      {/* <Share className='cursor-pointer' onClick={() => setOpenShare(true)} /> */}
      {/* {!onBook ?  */}
      <div onClick={() => setOpenShare(true)} className="flex gap-2 items-center">
        <Email className='cursor-pointer'  />
        <Icon icon='material-symbols:share' />
      </div>
      {/* :null} */}
      <ModalLocal open={openShare} setOpen={setOpenShare}>
        <div className='bg-secondary rounded-md flex flex-col gap-3 p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
          <div className='btn_close' onClick={() => setOpenShare(false)}>X</div>
          <h3>Share Hotel offer</h3>
          <EmailInput value={shareData.email} required
            onChange={(email) => setShareData({...shareData,email})} />
          <button className='flex gap-2 btn2' onClick={shareHotels} disabled={shareLoading}>
            {shareLoading?<div className="load"></div>:null}
            Send link to email
          </button>
        </div>
      </ModalLocal>
    </div>

  )
}
