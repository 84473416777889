import { getCurrencySymbol } from "./features/utils/currency";

export const path = {
  api: process.env.REACT_APP_API || "https://btm-staging-service.onrender.com/api",
  home: 'https://my.journeyeasy.net',
  site: window.location.protocol+"//"+window.location.host,
  sys: 'https://my.journeyeasy.net'
}

export const priceTimeout = 1000 * 60 * 15; // 15 minutes

let cur = window.localStorage.getItem("currency") || "NGN";
export const def = {
  get currency() {
    return getCurrencySymbol(cur)
  },
  get currencyCode() {
    cur = window.localStorage.getItem('currency') || "NGN";
    return cur;
  },
  siteName: 'JourneyEasy',
  logo: require('./assets/icons/logo.png')
}