import { ArticleOutlined, FlightOutlined, PersonOutline } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { Avatar } from "@mui/material";
import { Link, useParams } from 'react-router-dom';
import StatsOverview from '../../components/StatsOverview';
import { getCustomers } from '../../controllers/user/getCustomers';
import {ReactComponent as KeyIcon} from '../../assets/icons/key.svg'
import {ReactComponent as PackageIcon} from '../../assets/icons/package.svg'
import { templateSubAgent } from '../../data/user/subAgentData';
import DotMenu from '../../components/mini/DotMenu';
import { templateCustomerReport } from '../../data/customer/customerData';
import downloadCSV from '../../features/utils/downloadCSV';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))


const columns = [
  {field: 'travelAgency',headerName: 'Travel Agency',flex:1,minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex gap-4 items-center'>
          <Avatar>{params.value[0]}</Avatar>
          {params.value}
        </div>
      )
    }
  },
  {field: 'name',headerName: 'Officer Name',flex: 1,minWidth: 150,
    renderCell: (params) => {
      if(!params.value)
        return null;

      return (
        <div className='flex flex-col'>
          {params.value}
        </div>
      )
    }
  },
  {field: 'phone',headerName: 'Phone',minWidth: 170,
    renderCell: (params) => {
      if(!params.value) return null;
      return (
        <div className='flex flex-col '>
          {params.value}
        </div>
      )
    }
  },
  {field: 'email', headerName: 'Email', flex: 1, minWidth: 200,
    renderCell: (params) => {
      if(!params.value) return null;
      
      return (
        <div className='flex flex-col gap-0'>
          <b className='!font-normal'>{params.value}</b>
        </div>
      )
    },
  },
  {field: 'bookings', headerName: 'Bookings', flex: 1, minWidth: 150,
    renderCell: (params) => {      
      return (
        <div className='flex justify-center gap-0 text-[#aaa]'>
          <div className='p-2'><FlightOutlined /></div>
          <div className='p-2'><PackageIcon className='scale-75' /></div>
          <div className='p-2'><KeyIcon className='scale-75' /></div>
        </div>
      )
    },
  },
  {field: 'status',headerName: 'Status',flex: 1,minWidth: 130,
    renderCell: (params) => {
      if(!params.value)
      return null;

      return (
        <div className='flex justify-between w-full relative'>
          <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
            {params.value}
          </p>
          <DotMenu>
            <MenuItem><Link to={`/subagents/detail/${params.id}`}>Detail</Link></MenuItem>
          </DotMenu>

        </div>
      )
    }
  },
];


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'active'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'inactive'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    console.log(type);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    setLoading(true);
    const res = await getCustomers('SUB_AGENT');
    setLoading(false);
    if(res.return) {
      let data = res.data.map((row) => templateSubAgent(row))
      setFetchedData(data);
      setData(data);
    }
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.date)).toDateString().toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    
    // Prepend the column headers to the CSV data
    const csvData = data.map((row) => templateCustomerReport(row));
    csvData.unshift(columns.map(col => col.headerName));
    // console.log('csvData', csvData)

    downloadCSV(csvData,'Sub_Agents')
  }

  
  return (
    <div className='w-full p-4 m-2'>
      <div className='pb-4'>
        <StatsOverview />
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label="All (216)" />
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <div className='flex flex-wrap gap-2'>
          <Link to="/subagents/create" className='btn1 rounded-md !bg-[#1C2E3A] whitespace-nowrap'><PersonOutline /> Create Agent</Link>
          <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
        </div>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
