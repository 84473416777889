import React from 'react'
// import { pacakgeDetailData } from '../../../data/package/packageDetail'
import { getCurrencySymbol } from '../../../features/utils/currency';
import UserLink from '../../../components/mini/UserLink';
import { def } from '../../../config';
import { formatMoney } from '../../../features/utils/formatMoney';

export default function BookingDetail({data}) {
  // let temp = pacakgeDetailData;
  return (
    <div>
      <div>
        {/* <div className='text-end py-2'>
          <TextField select label="Manage this order" size='small' sx={{minWidth: 180}}>
            <></>
          </TextField>
        </div> */}
        <hr />
        <div className='mt-3 px-2'>
          <h4>{data.packageInfoData.product_name}</h4>
          <small>{data.packageInfoData.short_address}</small>
        </div>
        <div className='p-2'>
          <div className=''>
            <div className='flex gap-2'><h5>Package Booking id: </h5> {data.tourProBookingId}</div>
            {/* <div className='flex gap-2'><h5>Description: </h5> {data.hotelData.}</div> */}
            {/* <div className='flex gap-2'><h5>cancellation: </h5> {data.hotelD}</div> */}
            {/* <div className='flex gap-2'><h5>guarenteed: </h5> {data.bookingData.guarenteed}</div> */}
            <div className='flex gap-2'><h5>type: </h5> {data.packageType}</div>
            {/* <div className='flex gap-2'><h5>currency: </h5> {data.}</div> */}
            {/* <div className='flex gap-2'><h5>rate: </h5> {data.bookingData.rate}</div> */}
            {/* <div className='flex gap-2'><h5>ratePlan: </h5> {data.bookingData.ratePlan}</div> */}
            <br />
            Total <h5>{def.currency}{formatMoney(data.totalFarePrice)}</h5>
          </div>
        </div>
      </div>
      <hr />
      <div className='flex gap-2 p-2'>
        <h5>Booked By : </h5>
        <UserLink data={data.bookedBy} />
      </div>
    </div>
  )
}
