import { Breadcrumbs, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import RoomDisplay from '../../../components/hotel/RoomDisplay';
import { Check } from '@mui/icons-material';
import EmailInput from '../../../components/form/EmailInput';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from '../../../features/utils/objClone';
import { bookHotel } from '../../../controllers/hotel/bookHotel';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { setHotelBooking } from '../../../redux/reducers/hotel/hotelBookingSlice';
import ModalLocal from '../../../components/mini/ModalLocal';
import { getPassengers } from '../../../controllers/user/getPassengers';
import FetchUsersInput from '../../../components/form/FetchUsersInput';


export default function GuestInfo({next,back,data}) {
  const {hotelBooking} = useSelector((state) => state.hotelBooking);
  const dispatch = useDispatch();
  const [contactEmail,setContactEmail] = useState(hotelBooking?.as?.email || '')


  async function handleBook(rooms) {
    if(hotelBooking.booked) return next();
    
    let {details,city,price,units,nights,currency,...selected} = hotelBooking.selected;
    console.log("bookin with: ",{...selected,rooms})
    let onBehalf = hotelBooking.as && hotelBooking.as.id;
    const userType = hotelBooking?.userType
    const res = await bookHotel({...selected,rooms,contactEmail},onBehalf,userType);
    if(res.return) {
      dispatch(setAlertMsg(['success','Hotel Booked Successfull.']))
      dispatch(setHotelBooking({...hotelBooking,booked: res.data}))
      next();
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <div className='flex flex-col gap-3 flex-1'>
      <Breadcrumbs separator=">" className='text-xs'>
        <Link to="/search#Hotel">Hotels</Link>
        <span className='cursor-pointer' onClick={() => back && back()}>Detail</span>
        <span className='capitalize'>{hotelBooking.selected && hotelBooking.selected.name.toLowerCase()}</span>
      </Breadcrumbs>
      <div className='bg-secondary rounded-md flex flex-col '>
        <RoomDisplay review data={data} />
      </div>
      <div className='flex flex-col '>
        <div className='pt-4 px-4'>
          <TextField size='small' className='w-full' label='Contact Email' value={contactEmail} onChange={(ev) => setContactEmail(ev?.target?.value)} />
        </div>
        <CheckInForm next={next} selected={hotelBooking && hotelBooking.selectedRooms} returnData={handleBook} />
      </div>
    </div>
  )
}


const passData = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  number: 1,
}

function CheckInForm({selected,returnData}) {
  const roomsMemo = useMemo(() => [...selected],[selected]);
  const [rooms,setRooms] = useState(roomsMemo);
  const [loading,setLoading] = useState(false);
  const [confirmModal,setConfirmModal] = useState(false);
  const [confirmed,setConfirmed] = useState(false);
  const [sameGuest, setSameGuest] = useState(false);
  const [data,setData] = useState();

  
  const dispatch = useDispatch();
  // console.log(roomsMemo)


  useEffect(() => {
    let newPass = clone(roomsMemo);
    let number = 0;
    roomsMemo.map((obj,i) => (
      Object.entries(obj.guests).map(([key,val],j) => {
        if(key === 'units') return false;

        ([...Array(1)]).map((n,k) => {
          number++;
          if(!Array.isArray(newPass[i].guests[key]))
            newPass[i].guests[key] = [];
          newPass[i].guests[key].push({...passData,number})

          return true
        })
        return true;
      })
    ))

    setRooms(newPass)
    
  },[roomsMemo])
  
  useEffect(() => {
    let newPass = [...rooms];
    console.log(selected, ' < ----------- ')

    if(sameGuest) {
      console.log(data)
      newPass.map((obj,i) => (
        Object.entries(obj.guests).map(([key,val],j) => {
          if(key === 'units') return false;
  
          ([...Array(val)]).map((n,k) => {
            // if(!Array.isArray(newPass[i].guests[key]))
            //   newPass[i].guests[key] = [];
            newPass[i].guests[key][k] = data;
  
            return true
          })
          return true;
        })
      ))
      setRooms(newPass)
    }
  },[sameGuest,data])


  function handleReturn(pass=false) {

    let errors = [];
    let updatedRooms = null;

    let number = 0;

    updatedRooms = rooms.map((room) => {
      const { guests, ...rest } = room;
      
      const updatedGuests = Object.entries(guests).reduce((acc, [key, value]) => {
        const guestsArray = value.map((guest) => {
          number++;
          let guestMod = {...guest,number}
          if(guest.firstName === "" ||
            guest.lastName === "" ||
            guest.email === "" ||
            guest.phoneNumber === ""
            // guest.title === "" || !guest.title
          ) {errors.push("Please fill all required fields! "); console.log(guest)}

          return ({ type: key, ...guestMod })}
        );
        return [...acc, ...guestsArray];
      }, []);
      return { ...rest, guests: updatedGuests };
    });

    if(!confirmed) {
  
      console.log('updated ',updatedRooms)
      if(errors.length > 0)
        return dispatch(setAlertMsg(['error',errors[0]]))

      setConfirmModal(true);
    }

    console.log(' -- ',confirmed,pass)
    if(confirmed || pass)
      confirmBook(updatedRooms)

  }

  async function confirmBook(updatedRooms) {
    if(returnData) {
      setLoading(true);
      await returnData(updatedRooms)
      setLoading(false);
    }

  }



  function handleGuest(data,[i,key,k]) {
    let newPass = [...rooms];

    setData(data);

    try {

      if(sameGuest) {
        newPass.map((obj,i) => (
          Object.entries(obj.guests).map(([key,val],j) => {
            if(key === 'units') return false;
    
            ([...Array(1)]).map((n,k) => {
              // if(!Array.isArray(newPass[i].guests[key]))
              //   newPass[i].guests[key] = [];
              newPass[i].guests[key][k] = data;
    
              return true
            })
            return true;
          })
        ))
    
      } else 
        newPass[i].guests[key][k] = data;
    } catch(ex) {console.log(ex,data)}


    console.log('updating fields ',data,newPass,newPass[i].guests[key],i,key)

    setRooms(newPass)
  }

  const [passengerList,setPassengerList] = useState([]);
  useEffect(() => {
    loadPassengers();
  },[])

  async function loadPassengers() {
    const res = await getPassengers();
    if(res.return) {
      let data = res.data
      const uniqueEmails = new Set();
      data = data.filter(obj => !uniqueEmails.has(obj.email) && uniqueEmails.add(obj.email));

      setPassengerList(data)
    }
  }

  function handleSelectPassenger(obj,[i,key,k]) {

    let {
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      // birthDate,
    } = obj
    const titlePattern = /^(Mrs|Mr|Ms).*$/;
    const match = firstName?.match(titlePattern)

    if(match) {
      title = match[1]
    }
    firstName = firstName?.replace(title,'')
    handleGuest({
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      // birthDate,
    },[i,key,k])

  }


  console.log(roomsMemo)
  console.log(rooms)

  return rooms && (
    <div className='shadow-md p-5 rounded-md flex flex-col gap-3'>
      <ModalLocal open={confirmModal} setOpen={setConfirmModal} >
        <div className='gap-4 bg-secondary p-5 flex flex-col'>
          <h3>Confirm Booking</h3>
          <p className='py-5'>Are you sure you want to book this hotel?</p>
          <div className='flex gap-10 justify-between'>
            <button className='btn1 rounded-md flex-1' onClick={() => setConfirmModal(false)}>Cancel</button>
            <button className='btn2 flex-1' onClick={() => {setConfirmed(true);handleReturn(true);setConfirmModal(false)}}>Confirm</button>
          </div>
        </div>
      </ModalLocal>
      <h4>Who's Checking In?</h4>
      {
        rooms.map((obj,i) => (
          Object.entries(obj.guests).map(([key,val],j) => Array.isArray(val) && (
            val.map((obj,k) => (
              <PassengerInputs key={k} {...{obj,passengerList,handleSelectPassenger,i,j,k,label:key,handleGuest,sameGuest,setSameGuest,rooms}} />
            ))
          )
        )))
      }
      <button className='btn2 flex gap-2 items-center' onClick={() => handleReturn()}>
        {loading?(
          <div className='load'></div>
        ):null}
        Book</button>

    </div>
  )
}

function PassengerInputs({obj,passengerList,handleSelectPassenger,i,j,k,label,handleGuest,sameGuest,setSameGuest,rooms}) {
  const [newPassenger,setNewPassenger] = useState(false);

  let key = label;
  function handleNewPassenger(obj) {
    setNewPassenger(true);
    // if(obj === 'Add User') return setNewPassenger(true);
    // else setNewPassenger(false);
  }

  return (
    <div className='flex flex-col gap-4 pb-5'>

                <div className='flex flex-wrap gap-2 items-center'>
                  {j === 0 && 
                    <h6>Room {i+1}:</h6>
                  }
                  <h6>Guest {obj?.number}</h6>
                  {/* <h6>Guest {(i > 0 ? Object.values(rooms[i-1].guests).length : 0)+j+1}</h6> */}
                  {/* 2 Adults, 2 Twin Beds, Smoking */}
                </div>
                {/* <div className='text-green-500 flex gap-4 flex-wrap'>
                  <div className='flex gap-1 items-center font-bold text-sm'>
                    <Check className='text-sm' style={{ strokeWidth: '3px' }} />
                    Free Parking
                  </div>
                  <div className='flex gap-1 items-center font-bold text-sm'>
                    <Check className='text-sm' style={{ strokeWidth: '3px' }} />
                    Free WIFI
                  </div>
                </div> */}

                <FetchUsersInput from={passengerList} enableNew onChange={(obj) => {handleSelectPassenger(obj,[i,key,k]);handleNewPassenger(obj)}} label={'Passengers'} />

                {newPassenger ? (
                  
                <div key={k} className='flex flex-col gap-4'>

                  <div className='flex gap-4 justify-stretch'>
                    <TextField select className='min-w-[80px]' size='small' name='title' label='Title'
                      value={obj?.title}
                      onChange={(ev) => handleGuest({...obj,title: ev.target.value},[i,key,k])}
                    >
                      <MenuItem value='Mr'>Mr</MenuItem>
                      <MenuItem value='Ms'>Ms</MenuItem>
                      <MenuItem value='Mrs'>Mrs</MenuItem>
                    </TextField>
                    <TextField className='flex-1' size='small' name='firstname' label={'First name'} required
                      value={obj?.firstName}
                      onChange={(ev) => handleGuest({...obj,firstName: ev.target.value},[i,key,k])}
                    />
                    <TextField className='flex-1' size='small' name='lastname' label={'Last name'} required
                      value={obj?.lastName}
                      onChange={(ev) => handleGuest({...obj,lastName: ev.target.value},[i,key,k])}
                    />
                  </div>
                  <EmailInput 
                    value={obj?.email}
                    onChange={(email) => handleGuest({...obj,email},[i,key,k])}
                  />
                  <PhoneNumberInput 
                    value={obj?.phoneNumber}
                    onChange={(phoneNumber) => handleGuest({...obj,phoneNumber},[i,key,k])}
                    
                  />
                </div>
                ):null}

                {
                  i+j === 0 && (rooms.length > 1) ? 
                    <div className='py-2'>
                      <label className='flex gap-2 cursor-pointer'>
                        <input type='checkbox' checked={sameGuest} onChange={(ev) => setSameGuest(ev.target.checked)} value='sameGuest' />
                        Same guest for all rooms
                      </label>
                    </div>          
                  : null
                }
              </div>
  )
}