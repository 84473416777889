import React from 'react'

export default function Terms() {
  return (
    <div className='flex justify-center p-5'>
    <div className='max-w-[1000px] flex flex-col gap-4'>
      <h3>
      TERMS AND CONDITIONS
      </h3>

<p>
You are hereby automatically accepting the terms and conditions for JourneyEasy when you download or use this app. Please read these terms and conditions carefully before you download or use the JourneyEasy app. This is a free app, it is intended for personal use only. please note that you are not authorised to share or send it to anyone neither are you authorised to modify, copy, reverse engineer all or any aspect of the app, our images and Trademarks.
</p>

<p>
You are not authorised to attempt to access or obtain the source code of the app.
</p>

<p>
It is also forbidden to create apps purporting to work with the JourneyEasy app or translate the app into any other language neither are you authorised to make versions emulating or derived from the app.
The JourneyEasy app along with all its intellectual property, i.e. patents, copyrights, trademarks, and database belong to JourneyEasy.
</p>

<p>
Products and services can be obtained using applicable loyalty points and vouchers. This is however subject to terms and conditions to be communicated from time to time.
</p>

<p>
Please always refer to current links on our website for our terms and conditions which apply to you when you use the JourneyEasy app. From time to time we shall upgrade the app and its conditions of service. This may be done at any time without notice to you. We shall be transparent with all our charges for the app where applicable in order for you to know the exact costs. The JourneyEasy app will process the personal data given to us in order to provide you with our services. We do not bear responsibility if you allow access to your mobile device and your details through the app are accessed on your mobile device. Kindly do not remove manufacturer’s software restrictions on the operating system of your devices which can render your device vulnerable to malicious interference, hacking or viruses which may cause the JourneyEasy app not to function.
</p>

<p>
Some functions of the JourneyEasy app requires an active internet connection which can either be cellular, data or wifi. JourneyEasy bears no responsibility for non-functioning of the app where there is no internet connection. Your mobile network provider’s terms and conditions remain applicable where you are not using wifi. You will therefore be charged by them for cellular data charges which you hereby accept to bear responsibility for.
</p>

<p>
Kindly note that when you are outside your home country or outside your mobile network provision area, you will be charged roaming charges by your network provider unless you turn off data roaming on your mobile device. JourneyEasy assumes all users of the app have the permission of the devices bill payer for all charges resulting from the use of the app.
</p>

<p>
JourneyEasy will from time to time sell third party products and services on the app. By purchasing such third party products and services, you acknowledge that you are bound by whatever terms and conditions to which they are made available to you and JourneyEasy assumes no legal responsibility and liability arising from the use of any third party products and services
</p>

<p>
Many airlines and hotels still require a print out of tickets and reservations and you are hereby advised to take them along with you when checking in. JourneyEasy does not assume responsibility for any out of date or any incorrect information for third party products which may not have been updated by JourneyEasy at the time we pass on same information to you. Always check your mobile device for updated information from service providers, and also check the monitors and listen to loudspeaker communiqué at airports and stations.
</p>

<p>
  JourneyEasy expects you to use all available means to keep yourself informed regarding your flight and reservations. JourneyEasy from time to time will update the JourneyEasy app which is available on androids and IOS. From time to time you will need to download the manufacturer’s updates in order for the JourneyEasy app to continue to work. JourneyEasy cannot guarantee that it will always be updated in line with your current IOS or android version.
</p>

<p>
  It is therefore important that you accept the updates that JourneyEasy offers you from time to time. If at any time we intend to discontinue the app, notice will be given to you at which point all rights and licences in respect of the JourneyEasy app to you will cease. In such a case, you hereby agree to delete the app from your device.
</p>

</div>
    </div>
  )
}
