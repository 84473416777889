import { path } from "../../config";
import fetchServer from "../fetchServer";
import { store } from "../../redux/store";

export async function updateProfilePic(data,userId) {
  let result = {return: 0,msg: "Something went wrong updating user."}

  let token = store.getState().user.userData.accessToken;

  console.log('req Got: ',data)

  await fetchServer({method: 'PATCH',url: path.api+"/users/change-profile",data,
    headers: {
      Authorization: `Bearer ${token}`,
      'user-id': userId
    }
  })
  .then((res) => {
    console.log('serv => ',res);
    if(res.data && res.data.success)
      result = {return: 1,msg: "Successfull",data: res.data}
    else result['msg'] = res.error.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}