import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./reducers/sidebar/sidebarSlice";
import userReducer from "./reducers/user/userSlice";
import titleSlice from "./reducers/titleSlice";
import modalReducer from "./reducers/modal/modalSlice";
import snackReducer from "./reducers/modal/snackBarSlice";
import flightSearchReducer from "./reducers/search/FlightSearchSlice";
import bookingDataReducer from "./reducers/flight/flightBookingSlice";
import hotelBookingReducer from "./reducers/hotel/hotelBookingSlice";
import tourBookingReducer from "./reducers/tour/tourBookingSlice";

export const store = configureStore({
  reducer: {
    flightBooking: bookingDataReducer,
    hotelBooking: hotelBookingReducer,
    tourBooking: tourBookingReducer,
    modal: modalReducer,
    snackBar: snackReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    flightSearch: flightSearchReducer,
    title: titleSlice,
  }
})

