import React, { useState } from 'react'
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';

export default function SearchInput(props) {
  const [q,setQ] = useState("");
  
  function handleSearch(ev) {
    if(ev)
      ev.preventDefault();
    props.onChange && props.onChange(q);
  }
  return (
    <form action='' onSubmit={handleSearch} className={'shadow-md pl-6 px-4 p-2 rounded-3xl flex gap-2 '+props.className}>
      <input value={q} onChange={(ev) => setQ(ev.target.value)} className='flex-1 min-w-[40px] !outline-none' type='text' placeholder='Search here' />
      <SearchIcon onClick={handleSearch} className='cursor-pointer text-theme1 scale-75' />
    </form>
  )
}
