import { PersonRounded } from '@mui/icons-material'
import React from 'react'
// import { bookedHotelDataTemp } from '../../../data/hotel/bookedDetail';

export default function Passengers({data}) {
  // let temp = bookedHotelDataTemp;
  let temp = data;

  return (
    <div className='flex flex-col gap-3 '>
      <div>
        <div className='flex justify-between items-center'>
          <h5>Passengers</h5>
          {/* <button className='btn2'><PersonRounded /> One Adult</button> */}
        </div>
      </div>
      <hr />
      <table className='w-full mx-2'>
        <thead>
          <tr>
            <td>Title</td>
            <td className='flex-1'>Name</td>
            <td>Type</td>
          </tr>
        </thead>
        <tbody className='font-bold'>
          {
          // temp?.hotelData?.booked_rooms?.map((room,i) => (
            temp?.hotelData?.guests?.map((passenger,i) => (
              <tr key={i}>
                <td>{passenger.title}</td>
                <td>{passenger.first_name} {passenger.last_name}</td>
                <td>{passenger.type}</td>
              </tr>
            ))
          // ))
          }
        </tbody>
      </table>
    </div>
  )
}
