import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FlightSearchInput from '../../components/search/FlightSearchInput'
import FetchUsersInput from '../../components/form/FetchUsersInput';
import { useDispatch } from 'react-redux';
import { setBookingData } from '../../redux/reducers/flight/flightBookingSlice';
import HotelSearchInput from '../../components/search/HotelSearchInput';
import { useLocation } from 'react-router-dom';
import TourSearchInput from '../../components/search/TourSearchInput';

export default function Index(props) {
  // const [userType,setUserType] = useState("");
  const location = useLocation()
  let cat = location.hash.substr(1) || false;
  const [category,setCategory] = useState(cat || "Flight");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setBookingData({}))
  // },[dispatch,userType])
  useEffect(() => {
    if(cat)
      setCategory(cat)
  },[cat])

  function addUser(val) {
    dispatch(setBookingData({as: val}))
  }

  function handleCat(val) {
    setCategory(val);
    window.history.pushState(null, null, `#${val}`);
  }
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      <div className='bg-secondary p-4'>
        <div className='flex gap-5 flex-wrap '>
          <TextField select label='Search' size='small' className='min-w-[100px]'
            value={category} 
            onChange={(ev) => handleCat(ev.target.value)}
          >
            <MenuItem value='Flight'>Flight</MenuItem>
            <MenuItem value='Hotel'>Hotel</MenuItem>
            <MenuItem value='Tour'>Package</MenuItem>
          </TextField>
          
          {/* <TextField select value={userType} onChange={(ev) => setUserType(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
            <MenuItem value="Customer">Customer</MenuItem>
            <MenuItem value="Sub_Agent">Agent</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
          </TextField> */}
          {
            // userType !== "" ? (
            //   <FetchUsersInput label={userType} onChange={addUser} />
            //   ) : null
            }
        </div>
        {categoryInput(category,props)}
      </div>
    </div>
  )
}

function categoryInput(category,props={}) {
  if(category === 'Hotel')
    return <HotelSearchInput {...props} />
  else if(category === 'Tour')
    return <TourSearchInput {...props}/>
  else
    return <FlightSearchInput cur={1} {...props} />
}
