import { useState } from "react";
import Collapse from "../../mini/Collapse";
import { Rating } from "@mui/material";


export default function FilterRate({returnData}) {
  const [rate,setRate] = useState(0);

  function handleChange(val) {
    returnData(val)
    setRate(val)
  }
  return (
    <Collapse label={<h5>Rating</h5>} show>
      <div className="flex justfy-between">
        <Rating
          name="simple-controlled"
          value={rate}
          onChange={(ev,val) => handleChange(val)}
          />
      </div>
    </Collapse>
  )
}
