import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { login } from '../controllers/user/login';
import { useDispatch } from 'react-redux';
import { setUser, setUserData } from '../redux/reducers/user/userSlice';
import { Link, useLocation } from 'react-router-dom';
import Signup from './Signup';
import { getUser } from '../controllers/user/getUser';
import { forgotPasswordReq } from '../controllers/user/forgotPassword';
import { setAlertMsg } from '../redux/reducers/modal/snackBarSlice';
import ModalLocal from './mini/ModalLocal';
import { verifyOTPReq } from '../controllers/user/verifyOTP';
import PasswordInput from './form/PasswordInput';
import { changeForgottenPassword } from '../controllers/user/changeForgottenPassword';


export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState("");
  const [forgotEmail,setForgotEmail] = useState("");
  const [password,setPassword] = useState("");
  const [forgotModal,setForgotModal] = useState(false);
  const [forgotLoading,setForgotLoading] = useState(false);
  const [keepLog,setKeepLog] = useState(false);
  const [msg,setMsg] = useState("");
  const [msgtype,setMsgType] = useState(0);
  const [valid,setValid] = useState({email:null,password:null});
  const [loading,setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const view = searchParams.get('view')
  const [signup,setSignup] = useState(view === 'signup');
  const [verify,setVerify] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  async function handleLogin(ev) {
    if(ev)
      ev.preventDefault();
    
    if(!(valid.email === null || valid.password === null)) {
      setMsg("Invalid Login")
      return false
    };
    
    setLoading(true);

    const res = await login({email,password,keepMeLoggedIn: keepLog})
    setMsg(res.msg);
    setMsgType(res.return)
    if(res.return) {
      dispatch(setUserData({...res.data,loggedIn: true}))
      const UD = await getUser("me");
      if(UD.return) {
        dispatch(setUser({...UD.data}))
      }
    }

    setLoading(false);
  }

  function handleEmail(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,email: null}))
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ev.target.value))
      setValid((obj) => ({...obj,email:'Invalid Email'}))
    else setValid((obj) => ({...obj,email: null}))

    setEmail(ev.target.value)
  }

  function handlePassword(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,password: null}))
    else if(!/^[A-Za-z0-9._%+!@#$%^&*()]$/i.test(ev.target.value))
      setValid((obj) => ({...obj,password:'Invalid Password'}))
    else setValid((obj) => ({...obj,password: null}))

    setPassword(ev.target.value)
  }

  async function handleForgotPassword(ev) {
    if(ev)
      ev.preventDefault();

    if(forgotEmail === "" || !forgotEmail)
      return dispatch(setAlertMsg(['error','Email field empty!']))

    setForgotLoading(true);
    const res = await forgotPasswordReq({email: forgotEmail})
    setForgotLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Password reset link sent to your email.']))
      setForgotModal(false)
      setVerify(true);
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return signup ? <Signup login={() => setSignup(false)} /> : (
    <div className='flex gap-10 items-center max-w-full'>
      <div className='self-stretch overflow-hidden rounded-lg flex-1 hidden sm:block'>
        <img src={require('../assets/img/welcome.png')} className='h-[70vh] w-full object-cover' alt='Welcome' />
      </div>
      <form onSubmit={handleLogin} className='flex flex-col gap-4 w-[400px] max-w-full '>
        <h3>Login</h3>
        <p>Login to access your JourneyEasy account</p>
        <h4 className={msgtype?'text-green-500':'text-red-500'}>{msg}</h4>
        <div className='flex flex-col gap-4'>
          <TextField label="Email" value={email} required onChange={handleEmail}
            error={valid.email?true:false}
            helperText={valid.email}
            variant='outlined' />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              value={password}
              name='password'
              required
              onChange={handlePassword}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <div className='flex gap-5 justify-between'>
            <label className='flex gap-4 cursor-pointer'>
              <input type='checkbox' checked={keepLog} onChange={() => setKeepLog(!keepLog)} />
              Remember me
            </label>
            <label className='flex gap-4 cursor-pointer text-red-400' onClick={(ev) => {ev.preventDefault();setForgotModal(true)}}>
              Forgot password?
            </label>
            <ModalLocal open={forgotModal} setOpen={setForgotModal}>
              <div className='h-full flex items-center'>
                <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-4 relative'>
                  <div className='btn_close' onClick={() => setForgotModal(false)}>X</div>
                  <TextField label="Email" value={forgotEmail} required onChange={(ev) => setForgotEmail(ev.target.value)}
                    name={'forgot email'}
                    // error={valid.email?true:false}
                    // helperText={valid.email}
                    variant='outlined' />

                  <label className='btn2 flex items-center gap-2' onClick={(ev) => handleForgotPassword(ev)}>
                    {forgotLoading?<div className='load'></div>:null}
                    Send Reset Link with email</label>

                </div>
              </div>
            </ModalLocal>
          </div>
        </div>
        <div className='py-4'>
          <input type='submit' name='submit' className='btn2 w-full' onClick={() => handleLogin()} value={!loading?'Login':'Please Wait...'} disabled={loading} />
        </div>
        <div className='flex items-center gap-2 justify-center'>
          Dont have an account? <button onClick={() => setSignup(true)} className='text-red-400'>Signup</button>
        </div>
      </form>
      <VerifyOTP show={verify} forgotEmail={forgotEmail} />
    </div>
  )
}

function VerifyOTP({show,forgotEmail}) {
  const [open,setOpen] = useState(false);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [otp,setOTP] = useState('');
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const [token,setToken] = useState();

  useEffect(() => {
    setOpen(show);
  },[show])

  useEffect(() => {
    setEmail(forgotEmail)
  },[forgotEmail])
  if(!show) return null;

  async function handleSubmit(ev) {
    ev.preventDefault();

    if(token)
      return changePassword();

    setLoading(true);
    const res = await verifyOTPReq({email,otp})
    if(res.return) {
      setToken(res.data && res.data.tempToken)
      dispatch(setAlertMsg(['success','OTP Verified.']))
    } else dispatch(setAlertMsg(['error',res.msg]))
    setLoading(false);
  }
  
  async function changePassword() {
    setLoading(true);
    const res = await changeForgottenPassword({password},token);
    if(res.return) {
      dispatch(setAlertMsg(['success','Password Changed.']))
      setOpen(false);
    } else dispatch(setAlertMsg(['error',res.msg]))
    setLoading(false);

    return true;
  }

  return (
    <ModalLocal open={open} setOpen={setOpen}>
      {/* <label onClick={() => setOpen(true)}>Verify Code</label> */}
        <form onSubmit={handleSubmit} className=''>
        <div className='h-full flex items-center'>
          <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-4 relative'>
            <div className='btn_close' onClick={() => setOpen(false)}>X</div>
            {/* <input type='hidden' value={token} /> */}
            {token ? (
              <div className='flex flex-col gap-4'>
                <PasswordInput value={password} onChange={(val) => setPassword(val)} label={'New Password'} />
                <button className='btn2 flex gap-2 items-center'>
                  {loading?<div className='load'></div>:null}
                  Change Password</button>
              </div>
            ) : (
              <div className='flex flex-col gap-4'>
                <TextField label="Email" value={email} required onChange={(ev) => setEmail(ev.target.value)} size='small'
                name={'otp email'}
                // error={valid.email?true:false}
                // helperText={valid.email}
                variant='outlined' />
                <TextField size='small' label="otp"
                value={otp}
                onChange={(ev) => setOTP(ev.target.value)}
                />
                <button className='btn2 flex items-center gap-2'>
                {loading?<div className='load'></div>:null}
                Verify</button>
              </div>
            )}
          </div>
        </div>
       </form>
    </ModalLocal>
  )
}