import React, { useState } from 'react';

const ReadMore = ({children,size=2.5}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <p className={` ${showMore ? ' ':` overflow-hidden`}`} style={{maxHeight: !showMore ? `${size}rem` : '100%'}}>
        {children}
      </p>
      <button onClick={toggleShowMore} className='text-[11px]'>
        {showMore ? 'Show Less' : 'Read More'}
      </button>
    </div>
  );
};

export default ReadMore;