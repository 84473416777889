import React, { useEffect, useState } from 'react'
import PromoCode from './PromoCode'
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import ModalLocal from '../mini/ModalLocal';
import { ListBanksReq } from '../../controllers/payment/listBanks';
import { MenuItem, TextField } from '@mui/material';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { bankPayTour } from '../../controllers/payment/bankPayTour';
import { walletPayTour } from '../../controllers/tour/walletPay';

export default function TourPayment({returnData,orderData}) {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const {hotelBooking} = useSelector(state => state.hotelBooking);
  // const [meth,setMeth] = useState('wallet');
  const [listBanks,setListBanks] = useState(false);
  const [banks,setBanks] = useState([]);
  const [banksLoading,setBanksLoading] = useState(false);
  const [selectedBank,setSelectedBank] = useState();

  console.log(orderData)
  useEffect(() => {
    setSelectedBank()
  },[])

  
  async function loadBanksList() {
    let params = {
      limit: 10,
      skip: 0,
    }
    setListBanks(true);
    setBanksLoading(true);
    const res = await ListBanksReq((new URLSearchParams(params)).toString())
    setBanksLoading(false);
    if(res.return) {
      setBanks(res.data);
    }
  }

  let orderId = orderData.id;
  let userId = orderData.userId;
  if(orderData.row)
    userId = orderData.row.userId;
    
  console.log("orderId: ",orderId)
  console.log("userId: ",userId)

  async function handlePayment(meth,msg) {
    if(meth === 'wallet') {
      setLoading(true)
      const res = await walletPayTour(orderId,userId)
      setLoading(false)
      if(res.return) {
        dispatch(setAlertMsg(['success','Wallet Debited']));
      } else dispatch(setAlertMsg(['error','Failed debiting wallet']))
      // return true;
    } else if(meth === 'bank') {
      setLoading(true);
      if(!orderData.row || (orderData.row && orderData.row.transactions && !orderData.row.transactions.length))
        await bankPayTour(orderId);
      setLoading(false);
      if(msg === 'verify') {
        setLoading(true);
        const confirmed = await confirmBankTransaction(orderId)
        setLoading(false);
        if(confirmed.return) {
          dispatch(setAlertMsg(['success','Payment Successfull']))
        } else  {
          dispatch(setAlertMsg(['warning','Payment Still Pending']))
        }
        if(returnData)
          returnData(confirmed.return)
        
      }
    }
    
    if(returnData)
      returnData();
    setListBanks(false);
  }
  return (
    <div className='flex flex-col gap-2 p-5 bg-secondary'>
      <h5>Payment method</h5>
      <p>Choose your payment method</p>
      <hr />
      <ModalLocal open={listBanks} setOpen={setListBanks}>
          <div className=' bg-secondary p-5 flex flex-col gap-5 relative'>
            <div className='py-5 -m-4 relative '>
              <div className='btn_close z-10' onClick={() => setListBanks(false)}>X</div>
            </div>

            {banksLoading?(
              <div className='w-full flex items-center border-primary justify-center p-2'>
                <div className='load'></div>
              </div>
            ):null}
            {selectedBank ? (
              <div className='flex flex-col'>
                <div className='flex justify-between gap-2'>
                  Account No : <span> {selectedBank.accountNumber}</span>
                </div>
                <div className='flex justify-between gap-2'>
                  Country : <span> {selectedBank.country}</span>
                </div>
              </div>
            ):null}
            <TextField label="Select Banks" value={selectedBank && selectedBank.bankName} select size='small' className='min-w-[250px]'>
              <MenuItem></MenuItem>
              {banks.map((obj,i) => (
                <MenuItem value={obj.bankName} onClick={() => setSelectedBank(obj)}>{obj.bankName}</MenuItem>
              ))}
            </TextField>
            {/* <button className='btn2' onClick={() => handlePayment('bank','verify')}>{loading ? "Please Wait..." : "Verify"}</button> */}

            <button className='btn1' onClick={() => handlePayment('bank')}>Verify Later</button>
          </div>
        </ModalLocal>

      {
        // hotelBooking.as && (
        ((orderData.bookedBy && orderData.bookedBy.type !== 'CUSTOMER') || (!orderData.bookedBy && hotelBooking.as) ?
          <label className='bg-theme1 text-secondary p-5 rounded-md flex cursor-pointer gap-2' onClick={() => handlePayment('wallet')}>
            <b className='flex-1 text-center flex justify-center gap-2'>
              {loading ? <div className='load'></div>:null}
              Debit Wallet</b>
            {/* <input type='radio' name='paymentMethod' value='Debit' onClick={handlePayment} /> */}
          </label>
          :null
        )
      }
      

      {/* <label className='flex gap-2'> */}
      <label className='bg-theme1 text-secondary p-5 rounded-md flex cursor-pointer gap-2 justify-center' onClick={() => loadBanksList()}>
        {/* <input type='radio' name='paymeth' checked={meth === 'bank'} onChange={(ev) => setMeth(ev.target.value)} onClick={() => loadBanksList()} value="bank" /> */}
        Bank Transfer
      </label>

      <PromoCode id={orderData && orderData.id} type="tour" returnData={returnData} />


      {/* <label className='bg-theme1 text-secondary p-5 rounded-md flex cursor-pointer gap-2' onClick={() => handlePayment()}>
        <b className='flex-1 text-center'>Pay later</b>
        <input type='radio' name='paymentMethod' value='Debit' />
      </label> */}
      {/* <label className='bg-theme1 text-secondary p-5 rounded-md flex cursor-pointer gap-2'>
        <b className='flex-1 text-center'>Pay now with card</b>
        <input type='radio' name='paymentMethod' value='Debit' />
      </label> */}
    </div>
  )
}