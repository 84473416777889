export const InsuranceDataTemp = {
  "result": {
    "premium": '',
    "currency": "",
    "currencySymbol": "",
    "sumAssured": "",
    "excess": '',
    "countryCategory": "",
    "pricingDetail": {
      "amount": '',
      "fareAmount": ''
    }
  },
  "targetUrl": null,
  "success": true,
  "error": null,
  "unAuthorizedRequest": false,
  "__abp": true
}