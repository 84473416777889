export const fareRuleTypesData = [
"ADULT",
"CHILD",
"FAMILY",
"ONEWAY",
"RETURN",
"AIRLINE",
"DEPARTURE_AIRPORT",
"ARRIVAL_AIRPORT",
"TRANSIT_AIRPORT",
"WAIT_TIME",
"CABIN",
"TICKET_CLASS",
"ARRIVAL_TIME",
"BAGGAGE",
"INSURANCE ",
"TOUR_PRO_FLIGHT ",
"TOUR_PRO_HOTEL ",
"TOUR_PRO_TOUR ",
"TOUR_PRO_PLACE ",
"TOUR_PRO_EVENT ",
"TOUR_PRO_ACTIVITY"
]