import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  hotelBooking: {

  },
}
const UDStorage = window.localStorage.getItem('hotelBooking');
if(UDStorage) {
  try {
    initialState = {hotelBooking: JSON.parse(UDStorage)};
  } catch(ex) {console.log("Failed reading hotelBooking from storage")}
  // console.log(" from LS : ",JSON.parse(UDStorage))
} 
else console.log("Got none from LS")

export const hotelBookingSlice = createSlice({
  name: 'flightBooking',
  initialState,
  reducers: {
    setHotelBooking: (state,action) => {
      try {
        state.hotelBooking = action.payload;
        window.localStorage.setItem('hotelBooking',JSON.stringify(action.payload))
      } catch(ex) {console.log('Failed reading booking data from storage')}
    },
    clearHotelBooking: (state) => {
      state.hotelBooking = initialState;
      window.localStorage.removeItem('hotelBooking');
    }
  },
})

export const {setHotelBooking,clearHotelBooking} = hotelBookingSlice.actions;

export default hotelBookingSlice.reducer;