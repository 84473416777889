import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
// import moment from 'moment/moment';
import { decrypt, encrypt } from '../../features/utils/crypto';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import CitySearch from './CitySearch';



export default function TourSearchInput({cur,gotQ,newWindow}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {tourBooking} = useSelector(state => state.tourBooking);
  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const [data,setData] = useState({});


  // const [data,setData] = useState(offerSearchTemp);
  // console.log('rendering SearchINputs: ',qObj)
  // console.log('TC: ',travelClass)
  // const handleSetPassengers = useCallback((newPassengers) => {
  //   setPassengers(newPassengers);
  // }, []);

  useEffect(() => {
    if(q) {
      let obj = JSON.parse(decrypt(q));
      setData(obj)
    }
  },[q])

  function getCityAndCountry(addressComponents) {
    let city = '';
    let country = '';
  
    for (const component of (addressComponents || [])) {
      if (component.types.includes('locality') || component.types.includes("administrative_area")) {
        city = component.long_name;
      } else if (component.types.includes('country')) {
        country = component.long_name;
      }
    }
  
    return { city, country };
  }


  function handleSearch(ev,dataInp) {
    ev.preventDefault();

    // if(!(tourBooking?.as || tourBooking?.cwt || tourBooking?.userType))
    // return dispatch(setAlertMsg(['error','Please Select Chanel/Company']))

    let searchObj = getCityAndCountry(data?.address_components);
    console.log("Search Request - ",searchObj);
    // setData(newData);

    let enc = encrypt(JSON.stringify(searchObj));

            // SET TIME ------
    // dispatch(setBookingData({...bookingData}))

    // setTimeout(() => {
    //   dispatch(setModal(true))
    //   dispatch(setModalComp(PriceTimeout))
    // },priceTimeout)

    if(newWindow)
      window.open(`/search/tours?q=${enc}`);
    else
      navigate(`/search/tours?q=${enc}`);

  }

  return (
    <div className='py-5 flex flex-col gap-4'>
      <div className='flex flex-wrap-reverse items-center gap-4'>
        {/* <div className='flex tabs flex-1'>
          <button onClick={() => setType(1)} className={type === 1 ? 'active' : ''}>One-Way</button>
          <button onClick={() => setType(2)} className={type === 2 ? 'active' : ''}>Round trip</button>
        </div> */}

        {/* <div className='flex gap-4 flex-1'>
          <TextField value={travelClass} onChange={(ev) => setTravelClass(ev.target.value)} className='min-w-[100px]'
            select label="Travel Class" size='small'>
            <MenuItem value='All'>All</MenuItem>
            <MenuItem value='ECONOMY'>Economy</MenuItem>
            <MenuItem value="PREMIUM">Premium Economy</MenuItem>
            <MenuItem value="BUSINESS">Business</MenuItem>
            <MenuItem value="FIRST_SUPERSONIC">First Class</MenuItem>
          </TextField>
          <TravelInfo q={qObj} returnPassenger={handleSetPassengers} />
        </div>

        <div className='flex gap-6 pb-3'>
          <label className='flex gap-1 items-center'>
            <input type='checkbox' defaultChecked={noStops} onChange={(ev) => setNoStops(ev.target.checked)} />
            No stops
          </label>
          <AirlinesInput val={airline} returnData={(val) => setAirline(val ? val.id : null)} label='Prefered Airline' size='small' />
        </div> */}
      </div>
      <form onSubmit={handleSearch} className='flex flex-wrap gap-2'>
        <div className='flex-1'>
          <CitySearch className='w-full' value={`${data?.city ? data?.city+', ' : ''}${data?.country||''}` || ''} onChange={(obj) => setData(obj)} />
        </div>
        <button className='btn2 !rounded-sm '>Search</button>
      </form>
    </div>
  )
}
