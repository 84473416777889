import React from 'react'

export default function Policy() {
  return (
    <div className='flex justify-center p-5'>
      <div className='max-w-[1000px] flex flex-col gap-4'>
      <h3>
        About Us
      </h3>
<p>
We are JourneyEasy a brand name of Business Travel Management Limited (BTM) 
In this policy “Our”, “We”, or “Us”, is referring to JourneyEasy.
</p>

<p>
We will be handling your data, i.e., we will be your data controllers as permitted by you. We will only use your data to enhance your experience of our services. We appreciate your trust in us and thank you for your patronage. This notice is to give you information about how we will use and secure your data and how we protect your privacy. This privacy policy is a supplement to our Terms and Conditions to be found on our Websites and Apps.  
</p>

<h3>
Glossary of terms:
</h3>

<p>
Data Controller: Processors of information about you, such as, but not limited to your name, date of birth, email, telephone numbers and such. This may be us or third party service providers such as airlines, hotels, booking partners, global distribution systems.
</p>

<p>
Data Processor: JourneyEasy or third party service providers who may be required to assist us in providing our services.
      </p>

<p>
Legal Basis:  The legal basis for processing your data, this will usually be your consent and our contract with you, or where applicable, a legitimate or vital interest,  public task or legal obligation may be the legal basis for our lawful processing of your data. 
      </p>

<p>
Your Data: is personal information specific to and about you. 
      </p>

<p>
Platforms:  Our digital and physical points of interaction with you such as our apps and Websites as well as our offices and call centres. 
      </p>


<p>
We need information from and about you in order to offer and provide you with  comprehensive and value added  travel management and ancillary services. 
      </p>


<h3>
Data collection for our Services
</h3>

<p>
We will obtain information from you and also about you whilst ascertaining the specifics of your travel requirements. We will, when offering you travel and ancillary services  and whilst we are making your bookings for you collect and save your data such as your full names, date of birth, address, e mail address, telephone number, nationality and such other data as may be relevant to the booking and your travel and ancillary services  related preferences.  Bookings, requests and offers may be through our websites or Apps such as JourneyEasy, as well as through our call centres or even at our offices where applicable.
      </p>

<h3>
Communications
</h3>

<p>
We will email, telephone or send you texts regarding your travel bookings or requirements and to offer you travel and ancillary services from time to time. 
Some communication may also come directly from our third party service providers. We will need to save some of your information to complete or modify your bookings and to facilitate future bookings and offerings.
      </p>

<p>
We will also save your data in order to get in touch with you from time to time to contact you in case of a travel related update or query. We personalise our service in order to give you information relevant to your travel, such as emergency travel updates. We may also ask you to kindly give us your customer experience feedback or participate in a market research, once in a while.
      </p>

<p>
If you create a user account on our platform, this will make it easier for you to interact with us, you will be able to make or modify your bookings, you will not have to fill in your data everytime you log onto our website or make use of our App. If you have given us your payment details this will be securely stored only for future payments as required.
      </p>

<p>
We do not always record telephone calls and chats, however for training purposes, quality control and disputes we often do for a limited amount of time. Third party data controllers or processors will have our specified instructions as to how they should comply with our policies and protect your data, however when you leave our site for a third party website, whether through a link or referral on our site, or otherwise, you will be subject to their own terms and conditions and privacy policies.
      </p>


<h3>
Marketing
</h3>
<p>
We will be able to offer you promotional travel and ancillary services based on your preferences and will use your data for this purpose.  You may unsubscribe from our marketing emails, should you wish to do so. We will also show you customised offers on our platforms. Such offers may be third party products we feel may be of interest to you. When you give us your email you are subscribed to our newsletters from which you can unsubscribe at anytime.
      </p>

      <h3>
Analytics
      </h3>

<p>
In order to continuously improve and develop unique tailor made online services we analyse our customer‘s data. This is in a bid to ensure a first class customer experience on our platforms. Analytics help to optimise the operations on our platforms and drive improvement and development.   The data used for analytics is generally anonymous and group data as opposed to personal data. 
      </p>






      <h3>
Safety of your Personal Data
      </h3>

<p>
We have strong firewalls and preventive measures against hackers. We also encourage you to create a strong password for your user account so as to ensure the safety of your data. Your data will be used for your sign in and authentication, to ensure you are the one logging in to your user account. Where we detect unusual activity, or that your access may be compromised, in order to prevent a possible fraud or unauthorised use, for yours, our partners and our protection we may temporarily suspend a booking until it is verified. We may also require you to change your password or reactivate your account.
      </p>

<p>
We store the passwords and payment information, such as card details in an encrypted form. We may also store data you give to us and data we collect from you, such as your search history, travel preferences, location and other relevant information we  have from details saved on your account or other BTM related platforms. Some of this information is collected by using Cookies or similar technology.
We use Cookies  such as Essential Cookies that make our platforms work, We also use non essential Cookies such as Functional Cookies and  Analytics Cookies, to improve our platforms, Marketing Cookies to offer you ours and third party travel related products. You may opt out of the non essential cookies but will have a reduced experience on our platforms, and some aspects may not work or be available to you.
We do not process nor keep sensitive personal information except in rare circumstances such as for a health reason. An example of this could be severe allergies. If you make a special request based on religious reasons, this may be passed on to a supplier such as a hotel for example. However we do not require or ask for any such sensitive personal information. Children’s data is only processed as part of an adult’s data and provided by a parent or guardian or with their consent.  
      </p>

<p>
Examples of other Data Controllers and Data Processors, such as but not limited to:

·	Social platforms  as the Data Controller. 
·	When you login with your social media, or click on a social media button from our Platforms, or interact with us through social media, your data can be shared between us and the social media providers (e.g. your user names, email address, profile pictures, your contact, etc.). 
·	Finance, administrative and legal services and tools Data Processors and Data Controllers such as payment gateways, accounting systems, legal service providers, collection agencies, corporate insurances, etc.)
      </p>
 
      <h3>
Our group companies
      </h3>
<p>
We share your data within our group of companies for internal purposes only. Our group have a common Privacy Policy. 
      </p>

      <h3>
Competent authorities
      </h3>
<p>
We may have to disclose your data to law enforcement only as far as it is mandated by law for the prevention, detection or prosecution of crime. We may need to further disclose your data to competent authorities to protect and defend our rights or properties, or the rights and properties of third parties.
      </p>
 
  <h3>
Continuity of our online data processing and protection.
  </h3>
<ul style={{listStyle: 'outside'}}>
  <li>
In the event of a physical or technical incident, we are committed to restoring our online services as promptly as we can.
  </li>
<li>
We are committed to regularly testing, assessing and evaluating the effectiveness of our measures for ensuring the security of data processing.
  </li>
      </ul>
 
      <h3>
Retention of Data
      </h3>

<p>
 All  data retained is subject to this Privacy Policy.
Usually, we hold your data for a  period of Six years, from your last booking or any further action related to it ended or since you last logged into  your account.
      </p>

 
      <h3>
International data transfers
      </h3>

<p>
To facilitate your travel management and other ancillary service to you we may  transfer some of your data to other countries  and your  data protection  may not be as comprehensive as it will be subject to their own local protection laws.
      </p>

<p>
The disclosure of your data will be done, when applicable, in accordance with the applicable laws and safeguards. 
Keep your data safe
      </p>

<p>
We will do our best to keep your data safe. What can you do? Please do not share your Passwords or Booking ID.
      </p>

<p>
Please keep your passwords and Booking ID confidential. Do not share your account data with anyone and use a unique and strong password
      </p>

<p>
To make sure that access to your account on our Platforms is safe please do not share your log-in data with anyone. When you have finished on our platforms, please make sure you log out of the platform or  someone else might access your device. do not access your account from a non trusted device or network .
      </p>
 
<p>
Be careful and protect yourself from internet fraud and “Phishing”
      </p>

<p>
Do not open attachments or click on links where you are not sure you can trust or have doubts.
      </p>

<p>
Please use only the official applications from Google Play or Apple App Store.
You may access and update some of your data through your account settings or Customer Services.
      </p>

      <h3>
Exercising your data protection rights
      </h3>
<ul style={{listStyle: 'outside'}}>
  <li>
You have the right to ask us to correct inaccurate or incomplete data about you (and which you cannot update yourself with your account settings or through our Customer Services).
  </li>
<li>
You may request information relating to your data and copies of such data.
  </li>
<li>
Erasure or block your data
  </li>
<li>
You may request to have your data deleted. We may not be able to erase it due to the fact that the data processing may be necessary for the performance of the contract between you and us, for our legitimate business interests (i.e. fraud prevention, security-enhancing), to comply with our Legal Obligations (Object or limit the use of your data
  </li>
  <li>
You may require us not to process your data for certain specific purposes (including profiling) where such processing is based on legitimate interest, such as, for direct marketing. If you object to such processing, we will no longer process your data for these purposes unless we can demonstrate compelling legitimate grounds for such processing or such processing is required for the exercise or defense of legal claims.
  </li>
</ul>

<p>
Where your account remains inactive for a specified length of period, such account will be deactivated/ closed and all accumulated reward points and balances will be deleted.
Withdrawing Your Consent
If we are processing your data based on Your Consent you may withdraw Your Consent at any time, specifying which consent you are withdrawing. Please note that the withdrawal of Your Consent does not affect the lawfulness of any processing activities based on such consent before its withdrawal.
      </p>

      </div>
    </div>
  )
}
