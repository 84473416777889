import React, { useEffect, useState } from 'react'
import FlightInfoCard from '../../../components/flight/FlightInfoCard'
import { Cancel, CheckCircle, Flight } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom';
import { formatMoney } from '../../../features/utils/formatMoney';
import { def } from '../../../config';


export default function FlightReview({data,book}) {
  // const data = flightDataTemp;
  const {id} = useParams();
  const [priceFail,setPriceFail] = useState(false);

  useEffect(() => {
    setTimeout(() => setPriceFail(true),120000)
  },[])

  let initLoc = "";
  try {
    initLoc = data.segments[0].departureLocation
  } catch(ex) {}

  return (data && data.segments) ? (
    <div className=' w-full bg-gray-100 h-full'>
      <div className='flex gap-3 h-full'>
        <div className='flex flex-col gap-2 flex-1'>
          {data.segments.map((flights,i) => (
            <FlightInfoCard key={i} data={flights} label={initLoc === flights.arrivalLocation ? 'Return' : 'Departure'} />
          ))}
          {/* <div className='bg-secondary p-3 flex flex-col gap-3'>
            <h5>Bags</h5>
            <div>
              <CheckCircle className='w-4 h-4 text-green-500' /> Carry on bags included
            </div>
            <div>
              <CheckCircle className='w-4 h-4 text-green-500' /> 2 Checked in bags included
            </div>
          </div> */}
          {/* <div className='flex justify-between gap-4 flex-wrap bg-secondary'>
            <div className='p-6'>
              <h5>Order Change Policy</h5>
              {
                false ? (
                  <p><CheckCircle sx={{color: 'green'}} /> This order is changable</p>
                  ) : (
                  <p><Cancel sx={{color: 'red'}} /> This order is not changable</p>
                )
              }
            </div>
            <div className='p-6'>
              <h5>Order refund policy</h5>
              {
                false ? (
                  <p><Cancel sx={{color: 'red'}} />This order is not refundable </p>
                ) : (
                  <p><CheckCircle sx={{color: 'green'}} />This order is refundable up until the initial departure date</p>
                )
              }
            </div>
          </div> */}
          <div className='p-4 flex justify-between items-stretch gap-6 flex-wrap'>
            <Link to={`/search/flight/offers?q=${id}`} className='btn1'>Go back</Link>
            <button onClick={book} className='btn2 self-stretch min-w-[40%]'>Book</button>
          </div>
        </div>
        <div className='flex-1 sticky bottom-0 self-end min-h-screen'>
          <div className='bg-secondary p-4 rounded-2xl'>
            <div className='flex flex-col py-2 items-center gap-3'>
              <div className='flex gap-3'>
              <h5>
                {data.segments[0].flights[0].departureLocation}
              </h5>
              <div className='mr' /><Flight className='rotate-90' /><div className='mr' />
              <h5>
                {data.segments[0].flights[0].arrivalLocation}
              </h5>
            </div>
          </div>
          <h4 className='text-theme1 text-center'>{def.currency}{data.farePrice && formatMoney(data.farePrice.fareTotal)}</h4>
          <div className='py-2 flex flex-col gap-4'>
            {Object.entries(data?.passengers || []).map(([key,obj],i) => 
              <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-4 justify-between w-full" key={i}>
                  <span className="capitalize">{obj?.total ? obj.total+'x ':''}{key}</span>
                  {/* <span>{Object.keys(bookingData.offer.passengers).length}x Passenger</span> */}
                  <span>
                    {def.currency}
                    {
                      formatMoney(obj.totalAmount)
                      // formatMoney(Object.values(bookingData.offer.passengers).map((val) => val.totalAmount).reduce((prev,cur) => prev + cur,0))
                    }
                  </span>
                </div>
                <div className="flex gap-4 justify-between pl-4">
                  <span>Flight fare</span>
                  <span>{def.currency}{formatMoney(obj.baseAmount)}</span>
                </div>
                <div className="flex gap-4 justify-between pl-4">
                  <span>Tax</span>
                  <span>{def.currency}{formatMoney(obj.totalAmount - obj.baseAmount)}</span>
                </div>
              </div>
            )}

            <hr />
            
            <div className='flex flex-col gap-2'>
              {data?.farePrice?.fareBase && (
                <div className="flex gap-4 justify-between w-full">
                  Total Fare
                  <span>
                    {def.currency}{formatMoney(data?.farePrice?.fareBase?.toString()?.replace(',',''))}
                  </span>
                </div>
              )}

              {data?.taxAmount && (
                <div className="flex gap-4 justify-between w-full">
                  Total Tax
                  <span>
                    {def.currency}{formatMoney(data?.taxAmount?.toString()?.replace(',',''))}
                  </span>
                </div>
              )}
            </div>

          </div>

          <div className='py-5'>
            <button onClick={book} className='btn2 self-stretch min-w-[40%] w-full'>Book</button>
          </div>


            {/* <PriceSummary data={flightDataTemp.flightData} /> */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='overflow-hidden relative flex flex-col items-center justify-center w-full '>
      {priceFail ? 
        <div className='flex flex-col items-center justify-center'>
          <h4>Loading taking too long.</h4>
          <Link to={'/search/flight/offers?q='+id} className='underline'>Go back to offers</Link>
        </div>
      :null}
      <img src={'/btm-preloader.gif'} alt='Loading' className='object-cover h-[500px] ' />
    </div>

    // <div className='w-full flex items-center justify-center py-5 bg-secondary'>
    //   <div className='load'></div>
    // </div>
  )
}
