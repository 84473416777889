import mergeRecursive from "../../features/utils/mergeRecursive";

export const bookedHotelDataTemp = {
    "id": "",
    "bookingId": "",
    "status": "",
    "expiryDate": null,
    "ticketId": null,
    "editedBasePrice": "",
    "editedtotalPrice": "",
    "userId": "",
    "createdDate": "",
    "updatedDate": "",
    "bookedByAdminId": null,
    "hotelData": {
        "id": "",
        "code": "",
        "name": "",
        "price": "",
        "nights": "",
        "status": "",
        "address": "",
        "user_id": "",
        "currency": "",
        "end_date": "",
        "location": {
            "id": "",
            "city": "",
            "iata": "",
            "icao": "",
            "name": "",
            "type": "",
            "country": "",
            "latitude": "",
            "longitude": ""
        },
        "created_at": "",
        "start_date": "",
        "updated_at": "",
        "booked_rooms": [
            {
                "id": "",
                "rate": "",
                "type": "",
                "total": "",
                "currency": "",
                "rate_plan": "",
                "created_at": "",
                "guaranteed": "",
                "updated_at": "",
                "description": "",
                "cancellation": "",
                "booked_guests": [
                    {
                        "id": "",
                        "type": "",
                        "title": "",
                        "last_name": "",
                        "created_at": "",
                        "first_name": "",
                        "updated_at": "",
                        "booked_room_id": ""
                    }
                ],
                "control_number": "",
                "hotel_booking_id": ""
            }
        ],
        "booked_contact": {
            "id": "",
            "email": "",
            "booking_id": "",
            "created_at": "",
            "updated_at": "",
            "booking_type": "",
            "mobile_number": ""
        },
        "control_number": "",
        "destination_code": "",
        "booked_payment_method": {
            "id": "",
            "type": "",
            "detail": {
                "freeText": "",
                "creditCardExpiry": "",
                "creditCardHolder": "",
                "creditCardNumber": "",
                "creditCardCvcCode": ""
            },
            "booking_id": "",
            "created_at": "",
            "updated_at": "",
            "booking_type": ""
        }
    },
    "bookedBy": {
        "id": "",
        "username": "",
        "firstName": "",
        "email": "",
        "lastName": "",
        "password": "",
        "otp": "",
        "profile": "",
        "isAdmin": true,
        "isSuperAdmin": false,
        "isActive": true,
        "phoneNumber": "",
        "type": "",
        "customSettingid": null,
        "gender": "",
        "companyName": "",
        "addressLine1": "",
        "addressLine2": null,
        "postalCode": "",
        "cityName": "",
        "countryCode": "",
        "createdDate": "",
        "updatedDate": ""
    }
}

export function templateDetails(obj) {
  let data = mergeRecursive({...obj},bookedHotelDataTemp);
  return data;
}
