import React, { useEffect, useState } from 'react'
import { updateRole } from '../../controllers/user/updateRole';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import MultipleSelect from './MultiSelect';
import { RolesData } from '../../data/ENUM/Roles';
import { capitalize } from '../../features/utils/capitalize';

export default function RolesInput({id}) {
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState();
  const [changed,setChanged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(id && id!=="" && changed)
      handleRole({preventDefault: () => {}})
  },[id])

  const options = RolesData.map(val => ({label: capitalize(val.replace("_"," ")),value: val}));

  async function handleRole(ev) {
    ev.preventDefault();

    let roleValues = data.map(obj => obj.value);
    setLoading(true);
    const res = await updateRole({roles: roleValues},id);
    setLoading(false);
    if(res.return)
      return dispatch(setAlertMsg(['success','Roles updated.']))
    else return dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <form onSubmit={handleRole} className='flex flex-col gap-3 flex-1'>
      <div className='flex gap-4'>
        <MultipleSelect label={'Roles'} value={data} onChange={(roles) => {setData(roles);setChanged(true)}} className={"min-w-[300px]"} options={options} />
      </div>
      {id && 
        <button className='btn2 self-start'>
          {loading ? (
            <div className='load'></div>
          ) : null}
          Update Role</button>
      }

    </form>
  )
}
