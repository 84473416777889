import React, { useState } from 'react'
import { Rating } from '@mui/material'
import { Coffee, LocationOn } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { templateDetails } from '../../data/hotel/detail'
import { getCurrencySymbol } from '../../features/utils/currency'
import { useDispatch, useSelector } from 'react-redux'
import { setHotelBooking } from '../../redux/reducers/hotel/hotelBookingSlice'
import { def } from '../../config'
import { formatMoney } from '../../features/utils/formatMoney'

export default function HotelDisplay({size,data}) {
  const {hotelBooking} = useSelector(state => state.hotelBooking)
  const dispatch = useDispatch();
  const [readMore,setReadMore] = useState(false);
  
  // let temp = hotelDataTemp;
  let templated = templateDetails(data);
  console.log(data)
  const perNightPrice = Number(templated?.price?.replaceAll(',','') || 0) / Number(templated?.nights)
  
  function handleSelect() {
    dispatch(setHotelBooking({...hotelBooking,selected: data,selectedRooms: []}))
  }

  return (
    <div className={'bg-secondary rounded-2xl flex shadow-md w-full  '+(size==='small'?' text-[10px] max-w-[300px] ':'')}>
      <img src={(templated.details?.image || [])[0]} alt='Hotel' className='w-1/4 object-cover' />
      <div className={'flex flex-col gap-1 flex-1  w-full '+(size==='small'?' p-2 ':' p-5 gap-2 ')}>
        {size !== 'small' ? 
          <div className='flex gap-2 justify-between'>
            <h3 className='capitalize'>{templated.name.toLowerCase()}</h3>
            <div className='flex flex-col'>
              <small className='self-start'>starting from</small>
              <div className='flex'>
                <h4>{def.currency}{formatMoney(perNightPrice)}</h4>
                /night
              </div>
              <small className='self-end'>excl tax</small>
            </div>
          </div>
        :
          <b className=''>{templated.name}</b>
        }
        <p className={`px-2 ${readMore ? '' : ' overflow-hidden max-h-[2.5rem] text-ellipsis '} `} title={templated.details.text}>{templated.details.text}</p>
        <small className='px-2 cursor-pointer' onClick={() => setReadMore(!readMore)}>{readMore ? 'show Less' : 'Read more'}</small>
        <p className='px-2 capitalize'><LocationOn className='text-sm mx-1' /> {templated.address.toLowerCase()} </p>
        {size !== 'small' ? 
          <div className='flex gap-4 justify-between items-cetner px-2'>
            <Rating readOnly value={Number(templated.details?.awards?.at(0)?.Rating)} size='small' />
            <div className='self-end'>
              <Coffee className='text-sm mx-1' />
              <b>{templated.details.facilities.length && templated.details.facilities.length+" + "}</b> Amenities
            </div>
          </div>
        :null}
        {/* <div className='flex gap-2 items-center'>
          <div className='border border-theme1 p-1 text-xs'>{((571 / 1000) * 5).toFixed(1)}</div>
          <b>Very Good</b>
          <p>371 Reviews</p>
        </div> */}
        {size !== 'small' ? 
          <div className='flex flex-col justify-end flex-1'>
            <Link to={"/hotels/book/"+data.code||""} className=' btn2 ' onClick={handleSelect}>Select Hotel</Link>
          </div>
        :null}
      </div>
    </div>
  )
}
