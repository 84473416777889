import { cloneElement, useCallback, useEffect, useState } from 'react';
import BookOption from './BookOption';
import GuestInfo from './GuestInfo';
import Confirmation from './Confirmation';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Payment from './Payment';
import { getSingleTour } from '../../../controllers/tour/getSingleTour';
import { getSingleHotelPackage } from '../../../controllers/tour/getSingleHotelPackage';
import { getSingleFlightPackage } from '../../../controllers/tour/getSingleFlightPackage';
import { getSinglePlacePackage } from '../../../controllers/tour/getSinglePlacePackage';
import { getSingleEventPackage } from '../../../controllers/tour/getSingleEventPackage';
import { getSingleActivityPackage } from '../../../controllers/tour/getSingleActivityPackage';
import { setTourBooking } from '../../../redux/reducers/tour/tourBookingSlice';
import { tourProTemplate } from '../../../data/tour/tourDataTemp';


const steps = [
  <BookOption />,
  <GuestInfo />,
  <Payment />,
  <Confirmation />
]
export default function Index() {
  const [data,setData] = useState()
  const [loading,setLoading] = useState(false);
  const {id} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const {tourBooking} = useSelector(state => state.tourBooking)
  const [step,setStep] = useState(tourBooking.step || 0);
  const dispatch = useDispatch();

  useEffect(() => {
    async function load() {
      let res = {return: 0,msg: 'Package Type not available yet'};

      const userId = tourBooking?.as?.id
      const userType = tourBooking?.userType
      console.log('tour: ',userId,userType)

      if(type === 'tour')
        res = await getSingleTour(id,userId,userType);
      else if(type === 'hotel')
        res = await getSingleHotelPackage(id,userId,userType);
      else if(type === 'place')
        res = await getSinglePlacePackage(id,userId,userType);
      else if(type === 'flight') {
        console.log('getting flights')
        res = await getSingleFlightPackage(id,userId,userType);
      }
      else if(type === 'event')
        res = await getSingleEventPackage(id,userId,userType);
      else if(type === 'activity')
        res = await getSingleActivityPackage(id,userId,userType);
      if(res.return) {
        let data = tourProTemplate(res.data);
        setData(data)
        dispatch(setTourBooking({as: tourBooking?.as,userType,selected: data}))

      } else setData({id,...tourBooking.selected})
    }
    load();

    //eslint-disable-next-line
  },[])
  
  const CurComp = useCallback((props) => {
    return cloneElement(props.elem || <></>,props)
  },[])

  const stepNext = () => {
    try {
      // if(step === 0 && !hotelBooking.selectedRooms.length) return dispatch(setAlertMsg(['warning','No rooms selected!']))
      setStep(step => step < steps.length - 1 ? step+1 : step)
    } catch(ex) {}
  }
  const stepBack = () => {
    setStep(step => step > 0 ? step-1 : step)
  }

  return data ? (
    <div className='flex gap-8  justify-start w-full'>
      <CurComp elem={steps[step]} step={step} next={stepNext} back={stepBack} loading={loading} data={data} />
    </div>
  ) : (
    <div className='w-full p-5 bg-secondary flex flex-col items-center justify-center gap-2 '>
      <div className='flex items-center gap-2 border-[#555]'>
        <div className='load'></div> Loading
      </div>
    </div>
  )
}
