import { useSearchParams } from 'react-router-dom'
import Search from '../Search/Index'
import { useEffect, useState } from 'react';
import { getUser } from '../../controllers/user/getUser';
import getCurrencies from '../../controllers/settings/currency/getCurrencies';

export default function Index() {
  const [searchParam] = useSearchParams();
  let userId = searchParam.get('userId');
  let currency = searchParam.get('currency');
  const [userData,setUserData] = useState({})

  useEffect(() => {
    if(userId)
      load()
    if(currency)
      loadCurrencies();

    //eslint-disable-next-line
  },[])

  async function loadCurrencies() {
    const res = await getCurrencies();
    if(res.return) {
      let currencyObj = res.data.find(obj => obj.id === currency)
      handleCurrencyChange(currencyObj)
    }
  }

  function handleCurrencyChange(obj) {
    if(!obj) return false;

    window.localStorage.setItem("currency",obj.currencyCode)
    window.localStorage.setItem("currencyRate",obj.exchangeRateInNaira)
    window.localStorage.setItem("currencyObj",JSON.stringify(obj))
  }

  async function load() {
    const res = await getUser(userId)
    if(res.return) {
      setUserData(res.data);
    }
  }

  return (
    <div className="w-screen h-screen flex flex-col  ">
      {currency}
      <div className='overflow-auto max-w-full max-h-full'>
        <Search newWindow />
      </div>
    </div>
  )
}