import React from 'react'
import ReadMore from '../mini/ReadMore';
import { formatMoney } from '../../features/utils/formatMoney';
import { def } from '../../config';
import { Typography } from '@mui/material';
// import { tourDataTemp } from '../../data/tour/tourDataTemp'

export default function TourDisplay({data,showDetail}) {
  // let temp = tourDataTemp;
  let temp = data;
  let product_name = temp?.product_name || temp?.hotel_name || temp?.place_name || temp?.activity_name || ''

  return (
    <div className='border border-primary/10 rounded-md p-[4px] flex flex-col gap-2 cursor-pointer hover:shadow-lg flex-1 ' onClick={() => showDetail(data)}>
      <div className='rounded-md overflow-clip '>
        <img src={temp.photo || temp.image} alt='Tour' className='w-full h-[250px] object-cover hover:scale-110 transition-all duration-500' />
      </div>
      {/* <h5 className='font-bold max-h-[4em] overflow-clip px-4 py-1 uppercase text-primary/50' title={'type'}>{temp.type}</h5> */}
      <Typography variant='h5' className=' max-h-[4em] text-black overflow-clip py-4 px-4 pb-1' title={product_name}>
        {product_name}
      </Typography>
      {/* <h5 className='font-bold max-h-[4em] text-black overflow-clip py-4 px-4 pb-1' title={product_name}>{product_name}</h5> */}

      <div className='px-4 min-h-[100px]'>
        <ReadMore>
          <Typography variant='p' className='text-black text-light'>
            {temp?.long_description}
          </Typography>
        </ReadMore>
      </div>

      <div className='flex-1 flex flex-col justify-end'>
        <div className='rounded-md flex gap-2 items-center p-2 px-4'>
          <span>
            <small>from </small>
            <b>{def.currency}{formatMoney(temp?.farePrice?.fare_adult_price)}</b>
          </span>
          <small>per person</small>
        </div>
      </div>
    </div>
  )
}
