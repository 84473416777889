import mergeRecursive from "../../features/utils/mergeRecursive";

export const hotelDataTemp = {
  "code": "",
  "name": "",
  "cityCode": "",
  "chainCode": "",
  "chainName": "",
  "areaId": "",
  "categoryCode": "",
  "address": "",
  "city": "",
  "postalCode": "",
  "countryCode": "",
  "price": "",
  "start_date": "",
  "end_date": "",
  "units": "",
  "nights": 19,
  "currency": "",
  "details": {
      "code": "",
      "name": "",
      "facilities": [
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          },
          {
              "code": "",
              "icon": "",
          }
      ],
      "guestRoomInfo": "",
      "text": "",
      "image": "",
  }
}

export function templateDetails(obj) {
  let data = mergeRecursive({...obj},hotelDataTemp);
  return data;
}
