import { useState } from "react";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { useDispatch } from "react-redux";

export default function IncDec({min,max,value,returnData,size}) {
  const [n,setN] = useState(value || 0);
  const dispatch = useDispatch();

  function inc() {
    if(max !== null || max !== undefined)
      if(n+1 > max) return dispatch(setAlertMsg(['error','Max allowed limit reached!']));
    setN(n+1);
    if(returnData)
      returnData(n+1)
  }
  function dec() {
    if(min !== null || min !== undefined)
      if(n-1 < min) return dispatch(setAlertMsg(['error','Min allowed limit reached!']));
    setN(n-1);
    if(returnData)
      returnData(n-1)
  }
  return (
    <div className={'flex gap-1 items-center justify-between '+(size==='small'?' scale-75 ':'')}>
      <label onClick={dec} className={' select-none cursor-pointer flex items-center justify-center rounded-full bg-slate-300 text-primary sqr5'}> - </label>
      <div className="font-mono min-w-[30px] select-none text-center">{n}</div>
      <label onClick={inc} className={' select-none flex items-center justify-center rounded-full text-slate-300 bg-primary sqr5 '+(n >= max ? 'cursor-not-allowed' : 'cursor-pointer')}> + </label>
    </div>
  )
}