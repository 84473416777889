import { Step, StepConnector, StepLabel, Stepper, stepConnectorClasses, styled } from '@mui/material'
import React from 'react'


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.light,
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));
function QontoStepIcon(props) {
  const { active,icon } = props;
  // console.log('here',props);

  return (
    <div>
      {active ? (
        <div className='circle w-5 h-5 bg-white border border-theme1 text-theme1'>{icon}</div>
      ) : (
        <div className='circle w-3 h-3 bg-theme1 translate-y-[40%]'></div>
      )}
    </div>
  );
}

export default function CustomStepper({className,steps,current}) {
  
  return steps > 1 ? (
    <div>
      <Stepper alternativeLabel activeStep={current} connector={<QontoConnector />} className={className}>
        {[...Array(steps)].map((label,i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  ) : null
}
